import AppType from "@models/AppType";
import LinkIcon from "@mui/icons-material/Link";
import { IconButton, IconButtonProps } from "@mui/material";
import { getCurrentUserAppType } from "@slices/user";
import { useAppSelector } from "@store/hooks";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";

const shortUrlPrefixes: Record<AppType, string> = {
  [AppType.PUNCH]: "https://punch.link",
  [AppType.CHIPZ]: "https://chipsapp.link",
};

export default function UserShortUrlButton({ nickname, ...buttonProps }: { nickname: string } & IconButtonProps) {
  const appType = useAppSelector(getCurrentUserAppType);
  const { enqueueSnackbar } = useSnackbar();
  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      const url = `${shortUrlPrefixes[appType]}/${nickname}`;
      copy(url);
      enqueueSnackbar(<FormattedMessage defaultMessage="Copied: {copyValue}" values={{ copyValue: url }} />, {
        variant: "success",
      });
    },
    [enqueueSnackbar, nickname, appType]
  );
  return (
    <IconButton size="small" {...buttonProps} onClick={onClick}>
      <LinkIcon fontSize="inherit" />
    </IconButton>
  );
}
