import { OffsetLimit, ResponseDto } from "@api";
import { DELETE, GET, POST, buildQuery, currentAppClientUserRequestContext } from "@utils/fetch";
import { MentionDto } from "./contentCommon";
import { UserShortDto } from "./user";

export interface CreateCommentRequestDto {
  contentId: number;
  mentions: MentionDto[];
  text: string;
  skipTextValidation: boolean;
}

export interface ReplyCommentRequestDto {
  commentId: number;
  mentions: MentionDto[];
  text: string;
  skipTextValidation: boolean;
}

export interface CommentDto {
  id: number;
  user: UserShortDto;
  message: string;
  commentCount: number;
  mentions?: MentionDto[];
}

export const ClientCommentsApi = {
  create: (request: CreateCommentRequestDto) => POST(`api/v1/comments`, request, currentAppClientUserRequestContext),
  reply: (request: ReplyCommentRequestDto) =>
    POST(`api/v1/comments/reply`, request, currentAppClientUserRequestContext),
  delete: (id: number) => DELETE(`api/v1/comments/${id}`, currentAppClientUserRequestContext),
  getReplies: ({ commentId, offset, limit }: { commentId: number } & OffsetLimit): Promise<ResponseDto<CommentDto>> =>
    GET(`api/v1/comments/${commentId}/replies?${buildQuery({ offset, limit })}`, currentAppClientUserRequestContext),
  getForContent: ({
    contentId,
    offset,
    limit,
  }: { contentId: number } & OffsetLimit): Promise<ResponseDto<CommentDto>> =>
    GET(`api/v1/contents/${contentId}/comments?${buildQuery({ offset, limit })}`, currentAppClientUserRequestContext),
  getById: (commentId: number): Promise<CommentDto> =>
    GET(`api/v1/comments/${commentId}`, currentAppClientUserRequestContext),
};
