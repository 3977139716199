import { POST } from "@utils/fetch";

export interface ClientDataRequest {
  userId: number;
  ids: number[];
}

export type ClientDataResponse = Record<number, boolean>;

export const ClientDataApi = {
  getFollowingData: (request: ClientDataRequest): Promise<ClientDataResponse> =>
    POST<ClientDataRequest, ClientDataResponse>("api/v1/admin/user/subscriptions/get-following", request).then(
      // server doesn't return not followed ids in response, so filling it here for consistency
      (data) => ({
        ...request.ids.reduce((a, x) => {
          a[x] = false;
          return a;
        }, {} as ClientDataResponse),
        ...data,
      })
    ),
  getLikeData: (request: ClientDataRequest): Promise<ClientDataResponse> =>
    POST("api/v1/admin/content/likes/get-by-user", request),
};
