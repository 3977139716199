import { ChildSortingType, OffsetLimit, ResponseDto } from "@api";
import AppType from "@models/AppType";
import { DELETE, GET, PATCH, POST, RequestContext, buildQuery } from "@utils/fetch";
import { LoginType, UserRole } from "./user";

export enum AdminStatusType {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  WAIT_FOR_ACTIVATION = "WAIT_FOR_ACTIVATION",
}

export interface AdminDto {
  id: number;
  login?: string;
  name?: string;
  role?: UserRole;
  status?: AdminStatusType;
  createdAt?: string;
  updatedAt?: string;
  isPasswordChangeRequired?: boolean;
  loginType?: LoginType;
}

export interface UpdateAdminDto {
  name?: string;
  role?: UserRole;
  status?: AdminStatusType;
  loginType?: LoginType;
  isPasswordChangeRequired?: boolean;
  login?: string;
}

export interface CreateAdminDto {
  name: string;
  role: UserRole;
  status: AdminStatusType;
  loginType: LoginType;
  isPasswordChangeRequired: boolean;
  password: string;
  login: string;
}

export interface AuthAdminDto {
  login: string;
  password: string;
}

export interface PasswordSelfChangeDto {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

export interface SecurityAdminTokenDto {
  user?: AdminDto;
  token?: string;
  expiredAt?: string;
}

export interface AdminAuthCodeInsightDto {
  code: string;
  issuedAt: string;
  deviceUuid: string;
}

export const AdminApi = {
  list: (
    request: {
      /** this is direction actually */
      sortType?: ChildSortingType;
      /** this is order of sort actually */
      filterUserEnum?: string;
      name?: string;
      role?: UserRole;
    } & OffsetLimit
  ): Promise<ResponseDto<AdminDto>> => GET(`api/v1/admin/admins?${buildQuery(request)}`),
  changePassword: ({ id, password, ...rest }: { id: number; password: string; isPasswordChangeRequired: boolean }) =>
    PATCH(`api/v1/admin/admins/${id}/password`, { newPassword: password, newPasswordRepeat: password, ...rest }),
  delete: (id: number) => DELETE(`api/v1/admin/admins/${id}`),
  create: (request: CreateAdminDto) => POST(`api/v1/admin/admins`, request),
  update: (adminId: number, request: UpdateAdminDto) => PATCH(`api/v1/admin/admins/${adminId}`, request),
  login: (request: AuthAdminDto, appType: AppType): Promise<SecurityAdminTokenDto> =>
    POST("api/v1/admin/auth", request, { appType }),
  changeOwnPassword: (request: PasswordSelfChangeDto, appType: AppType, token: string) =>
    PATCH(`api/v1/admin/admins/password`, request, { appType, token }),
  searchPhoneValidationCodes: (phone: string, requestContext?: RequestContext): Promise<AdminAuthCodeInsightDto[]> =>
    GET(`api/v1/admin/auth/login/phone/codes?phone=${phone}`, requestContext),
};
