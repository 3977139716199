import { ConfigProperty, properties, usePropertyValue } from "@config";
import { useAppSelector } from "@store/hooks";
import Snowfall from "react-snowfall";

const snowflake = document.createElement("img");
snowflake.src = "/ny/snowflake.webp";
const images = [snowflake];

export default function SnowfallProvider() {
  const newYearModeOn = usePropertyValue(properties[ConfigProperty.NEW_YEAR_2024_MODE]);
  const enabledByUser = useAppSelector((state) => state.appSettings.snowflakesEnabled);
  if (!newYearModeOn || !enabledByUser) {
    return null;
  }
  return <Snowfall snowflakeCount={100} speed={[0.2, 1]} wind={[-0.5, 0.5]} radius={[2, 10]} images={images} />;
}
