import { UserRole } from "@api/user";
import AppType from "@models/AppType";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { isEqual } from "lodash";
import { ClientUser } from "./client";

export interface LoggedInUser {
  token: string;
  appType: AppType;
  role: UserRole;
  login: string;
  name: string;
  userId: number;
}

export interface UserState {
  currentUser: LoggedInUser | null;
  availableUsers: LoggedInUser[];
  // TODO remove later
  clientUsers: Partial<Record<AppType, ClientUser>>;
}

const initialState: UserState = {
  currentUser: null,
  availableUsers: [],
  clientUsers: {},
};

const name = "user";

const userSlice = createSlice({
  name,
  initialState,
  reducers: {
    addUser(state, action: PayloadAction<LoggedInUser>) {
      const user = action.payload;
      state.availableUsers.push(user);
      if (state.currentUser === null) {
        state.currentUser = user;
      }
    },
    activateUser(state, action: PayloadAction<LoggedInUser>) {
      const user = action.payload;
      state.currentUser = user;
      const userExists = !!state.availableUsers.find((x) => isEqual(x, user));
      if (!userExists) {
        state.availableUsers.push(user);
      }
    },
    removeUser(state, action: PayloadAction<LoggedInUser>) {
      const user = action.payload;
      const userIsCurrent = isEqual(state.currentUser, user);
      state.availableUsers = state.availableUsers.filter((x) => !isEqual(user, x));
      if (userIsCurrent) {
        if (state.availableUsers.length > 0) {
          state.currentUser = state.availableUsers[0];
        } else {
          state.currentUser = null;
        }
      }
    },
    removeAllUsers: () => initialState,
  },
});

export const { addUser, activateUser, removeUser, removeAllUsers } = userSlice.actions;

export default userSlice.reducer;

export const getCurrentUserRole = (state: RootState) => state.user.currentUser?.role || UserRole.ANONYMOUS;

export const getIsReadonlyUser = (state: RootState) => getCurrentUserRole(state) === UserRole.WEBADMIN_VIEWER;

export const getCurrentUserAppType = (state: RootState) => state.user.currentUser?.appType || AppType.PUNCH;
