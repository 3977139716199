import { Page } from "@api";
import { POST, buildQuery } from "@utils/fetch";
import { CommentTriggeredPhrase } from "./commentsModeration";

const urlPrefix = `api/v1/admin/moderation-manager`;

export enum ManualContentDescriptionModerationStatus {
  DELETED = "DELETED",
  APPROVED = "APPROVED",
  HIDDEN = "HIDDEN",
}

export enum ContentDescriptionModerationStatus {
  NEW = "NEW",
  DELETED = "DELETED",
  APPROVED = "APPROVED",
  AUTO_HIDDEN = "AUTO_HIDDEN",
  HIDDEN = "HIDDEN",
}

export interface ContentDescriptionModerationLog {
  id: string;
  contentId: number;
  description: string;
  triggeredPhrases: CommentTriggeredPhrase[];
  createdAt: string;
}

export interface ContentDescriptionViolationsResponse {
  violations: ContentDescriptionModerationLog[];
}

export interface ContentDescriptionDto {
  contentId: number;
  description: string;
  createdAt: string;
  version: number;
  moderationStatus: ContentDescriptionModerationStatus;
  moderatorId?: number;
}

export interface SetContentDescriptionModerationStatusRequest {
  contentId: number;
  version: number;
  // one of two must be present
  moderationStatus?: ContentDescriptionModerationStatus;
  manualModerationStatus?: ManualContentDescriptionModerationStatus;
}

export interface BatchSetContentDescriptionModerationStatusRequest {
  batch: SetContentDescriptionModerationStatusRequest[];
}

export interface BatchSetContentDescriptionModerationStatusResponse {
  moderatedDescriptions?: ContentDescriptionDto[];
  changedDescriptions?: ContentDescriptionDto[];
}

export const ContentDescriptionModerationApi = {
  loadViolations: (contentIds: number[]): Promise<ContentDescriptionViolationsResponse> =>
    POST(`${urlPrefix}/content/description-violations`, { contentIds }),
  loadDescriptions: (contentIds: number[]): Promise<Page<ContentDescriptionDto>> =>
    POST(`${urlPrefix}/content-description/list?${buildQuery({ page: 0, size: contentIds.length })}`, { contentIds }),
  setModerationStatuses: (
    request: BatchSetContentDescriptionModerationStatusRequest
  ): Promise<BatchSetContentDescriptionModerationStatusResponse> =>
    POST(`${urlPrefix}/content-description/status-batch`, request),
};
