import { UserRole } from "@api/user";
import useActivateUser from "@hooks/useActivateUser";
import { appTypeToLogo } from "@models/AppIcons";
import AppType from "@models/AppType";
import { Divider, List, ListItemButton, ListItemText, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { useAppSelector } from "@store/hooks";
import { Fragment } from "react";
import { FormattedMessage } from "react-intl";

const AccessDeniedView = ({
  requiredAppTypes,
  requiredRoles,
}: {
  requiredAppTypes?: AppType[];
  requiredRoles?: UserRole[];
}) => {
  const activateUser = useActivateUser();
  const { currentUser, availableUsers } = useAppSelector((state) => state.user);
  if (!currentUser) {
    return null;
  }
  const { appType, role } = currentUser;
  const matchingUsers = availableUsers.filter((x) => {
    if (requiredAppTypes && !requiredAppTypes.includes(x.appType)) {
      return false;
    }
    if (requiredRoles && !requiredRoles.includes(x.role)) {
      return false;
    }
    return true;
  });
  return (
    <Container maxWidth="sm" data-testid="access-denied">
      {requiredAppTypes && !requiredAppTypes.includes(appType) && (
        <Alert severity="error">
          <FormattedMessage
            defaultMessage="This page is available only for {appType}"
            values={{
              appType: (
                <span>
                  {requiredAppTypes.map((x) => {
                    const Logo = appTypeToLogo[x];
                    return (
                      <Fragment key={x}>
                        &nbsp;
                        <Logo height="1em" />
                      </Fragment>
                    );
                  })}
                </span>
              ),
            }}
          />
        </Alert>
      )}
      {requiredRoles && !requiredRoles.includes(role) && (
        <Alert severity="error">
          <FormattedMessage
            defaultMessage="The following roles are required to view this page: {roles}"
            values={{ roles: requiredRoles.join(", ") }}
          />
        </Alert>
      )}
      {matchingUsers.length > 0 && (
        <>
          <br />
          <Divider />
          <br />
          <Typography variant="h6">
            <FormattedMessage defaultMessage="Available matching users:" />
          </Typography>
          <List data-testid="available-users-list">
            {matchingUsers.map((x) => {
              const Logo = appTypeToLogo[x.appType];
              return (
                <ListItemButton
                  key={`${x.login}-${x.role}-${x.appType}`}
                  onClick={() => activateUser(x)}
                  data-testid={`${x.login}-${x.role}-${x.appType}-switch-button`}
                >
                  <ListItemText
                    primary={
                      <>
                        {x.login}@
                        <Logo height="10px" /> ({x.role})
                      </>
                    }
                  />
                </ListItemButton>
              );
            })}
          </List>
        </>
      )}
    </Container>
  );
};

export default AccessDeniedView;
