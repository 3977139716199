import { Tooltip } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { RouteDescriptor } from "@navigation/routesConfig";
import { useAppSelector } from "@store/hooks";
import { useSnackbar } from "notistack";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import * as styles from "./NavigationDrawer.module.scss";

const Item = ({ route, selected }: { route: RouteDescriptor; selected: boolean }) => {
  const Icon = route.icon!;
  const navigationBlockedMessage = useAppSelector((state) => state.appSettings.blockNavigationMessage);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Tooltip placement="right" title={<FormattedMessage {...route.title} />} disableInteractive>
      <ListItem disablePadding data-testid={`route-${route.path}`}>
        <ListItemButton
          component={Link}
          className={styles.listItemButton}
          to={route.path}
          selected={selected}
          onClick={(e) => {
            if (navigationBlockedMessage) {
              enqueueSnackbar(navigationBlockedMessage, { variant: "error" });
              e.stopPropagation();
              e.preventDefault();
            }
          }}
        >
          <ListItemIcon className={styles.listItemIcon}>
            <Icon />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};

const NavigationDrawer = ({ routes, className }: { routes: RouteDescriptor[]; className: string }) => {
  const { pathname } = useLocation();
  const cleanPathname = useMemo(() => pathname.substring(1), [pathname]);
  return (
    <div className={className}>
      <List>
        {routes.map((route) => (
          <Item key={route.path} route={route} selected={route.path === cleanPathname} />
        ))}
      </List>
    </div>
  );
};

export default NavigationDrawer;
