import { UserRole } from "@api/user";
import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import AppType from "@models/AppType";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import { getCurrentUserAppType, getCurrentUserRole } from "@slices/user";
import { useAppSelector } from "@store/hooks";
import NavigationTabs from "@widgets/NavigationTabs";
import { useMemo } from "react";
import { defineMessages } from "react-intl";
import { ExplorePageType } from "./types";

const tabMessages = defineMessages({
  [ExplorePageType.COLLECTIONS]: { defaultMessage: "Collections" },
  [ExplorePageType.COMPILATIONS]: { defaultMessage: "Compilations" },
  [ExplorePageType.SECTIONS]: { defaultMessage: "Explore beat sections" },
  [ExplorePageType.STATIC_SECTIONS]: { defaultMessage: "Sections" },
  [ExplorePageType.BUTTONS]: { defaultMessage: "Buttons" },
  [ExplorePageType.SEARCH_SUGGESTIONS]: { defaultMessage: "Search suggestions" },
});

const tabsPerApp = {
  [AppType.PUNCH]: Object.values(ExplorePageType).filter(
    (x) => ![ExplorePageType.STATIC_SECTIONS, ExplorePageType.SEARCH_SUGGESTIONS].includes(x)
  ),
  [AppType.CHIPZ]: [ExplorePageType.STATIC_SECTIONS, ExplorePageType.SEARCH_SUGGESTIONS, ExplorePageType.COLLECTIONS],
};

export default function ExplorePageTitle() {
  const app = useAppSelector(getCurrentUserAppType);
  const role = useAppSelector(getCurrentUserRole);
  const disabledTabs = useMemo(() => {
    if (role === UserRole.SUPERVISOR || role === UserRole.BEAT_PRODUCER) {
      return [ExplorePageType.COLLECTIONS];
    }
    return [];
  }, [role]);
  const route = useCurrentRouteDescriptor();
  useSetDocumentTitle({ messageDescriptor: route!.title! });
  return (
    <NavigationTabs
      possibleValues={tabsPerApp[app]}
      tabQueryKey="view"
      tabMessagesMap={tabMessages}
      disabledTabs={disabledTabs}
    />
  );
}
