import { SerializedError } from "@reduxjs/toolkit";
import { addErrorToQueue } from "@slices/rejectedErrorsQueue";
import { useAppDispatch } from "@store/hooks";
import { useCallback } from "react";

export default () => {
  const dispatch = useAppDispatch();
  return useCallback(
    (e: SerializedError) => {
      dispatch(addErrorToQueue(e));
    },
    [dispatch]
  );
};
