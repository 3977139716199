import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useDefaultNetworkErrorHandler from "./useDefaultNetworkErrorHandler";

interface LoaderConfig<Type> {
  params: any;
  loader: (args: any) => Promise<Type>;
  initialValue: Type;
}

const useLoader = <Type>({ params, loader, initialValue }: LoaderConfig<Type>) => {
  const errorHandler = useDefaultNetworkErrorHandler();
  const wasEverLoadingRef = useRef(false);
  const [state, setState] = useState({ value: initialValue, isLoading: false, hasError: false, error: null });
  const paramsRef = useRef({});
  const refreshHandleRef = useRef<number | null>(null);
  const currentRequestIdRef = useRef("");
  const scheduleRefresh = useCallback(() => {
    if (refreshHandleRef.current) {
      window.cancelIdleCallback(refreshHandleRef.current);
    }
    refreshHandleRef.current = window.requestIdleCallback(() => {
      wasEverLoadingRef.current = true;
      setState((state) => ({ ...state, isLoading: true, hasError: false, error: null }));
      const requestId = uuidv4();
      currentRequestIdRef.current = requestId;
      loader(paramsRef.current)
        .then((result) => {
          if (currentRequestIdRef.current === requestId) {
            setState({ value: result, isLoading: false, hasError: false, error: null });
          }
        })
        .catch((e) => {
          if (currentRequestIdRef.current === requestId) {
            setState({ value: initialValue, isLoading: false, hasError: true, error: e });
            errorHandler(e);
          }
        });
    });
    // skipping initialValue in deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorHandler, loader]);
  const refresh = useCallback(() => {
    scheduleRefresh();
  }, [scheduleRefresh]);
  useEffect(() => {
    paramsRef.current = params;
    scheduleRefresh();
  }, [scheduleRefresh, params]);
  useEffect(() => {
    return () => {
      if (refreshHandleRef.current) {
        window.cancelIdleCallback(refreshHandleRef.current);
      }
    };
  }, []);
  const updateValue = useCallback((handler: (type: Type) => Type) => {
    setState((current) => ({ ...current, value: handler(current.value) }));
  }, []);
  return useMemo(
    () => ({ state, refresh, updateValue, wasEverLoading: wasEverLoadingRef.current }),
    [state, refresh, updateValue]
  );
};

export default useLoader;
