import { ChildSortingType } from "@api";
import { AdminStatusType } from "@api/admin";
import { PunchModeEnum } from "@api/audioContent";
import { BeatAssignedStatus, ControlStatus, TextControlStatus } from "@api/beatsModeration";
import { ContentStatusType } from "@api/contentCommon";
import { ContentModerationStatus, ContentSubtype, ThreadPrivacyFilterEnum } from "@api/contentModeration";
import { ContentPromotionType } from "@api/contentPromotions";
import { StyleType } from "@api/coverGenerator";
import { SupervisionBadgeType } from "@api/supervisedBadges";
import {
  SupervisedCreatorStatus,
  SupervisedCreatorStatusFilter,
  SupervisedPersonStatus,
  SupervisedPersonStatusFilter,
  SupervisedUserStatus,
  SupervisedUserStatusFilter,
} from "@api/supervisedUser";
import { SocialNetworkType } from "@api/user";
import { ModerationUserInfoType } from "@api/userInfoModeration";
import { defineMessages } from "react-intl";

export const ContentStatusTranslations = defineMessages({
  [ContentStatusType.NEW]: { defaultMessage: "New", description: "content status NEW" },
  [ContentStatusType.AVAILABLE]: { defaultMessage: "Available", description: "content status AVAILABLE" },
  [ContentStatusType.UNAVAILABLE]: { defaultMessage: "Unavailable", description: "content status UNAVAILABLE" },
  [ContentStatusType.UNPUBLISHED]: { defaultMessage: "Unpublished", description: "content status UNPUBLISHED" },
  [ContentStatusType.UPLOAD_FINISHED]: {
    defaultMessage: "Upload finished",
    description: "content status UPLOAD FINISHED",
  },
});

export const SupervisedPersonStatusTranslations = defineMessages({
  [SupervisedPersonStatusFilter.NONE]: { defaultMessage: "Not assigned" },
  [SupervisedPersonStatus.APPLICATION_INSTALLED]: { defaultMessage: "Application Installed" },
  [SupervisedPersonStatus.COMMUNICATIONON_HOLD]: { defaultMessage: "Communication On Hold" },
  [SupervisedPersonStatus.COMMUNICATION_CANCELED]: { defaultMessage: "Communication Canceled" },
  [SupervisedPersonStatus.COMMUNICATION_FINISHED]: { defaultMessage: "Communication Finished" },
  [SupervisedPersonStatus.ONBOARDING]: { defaultMessage: "Onboarding", description: "Supervised person status" },
});

export const SupervisedUserStatusTranslations = defineMessages({
  [SupervisedUserStatusFilter.NONE]: { defaultMessage: "Not assigned" },
  [SupervisedUserStatus.ACTIVE_USER]: { defaultMessage: "Active User" },
  [SupervisedUserStatus.ANONYMOUS]: { defaultMessage: "Anonymous" },
  [SupervisedUserStatus.INACTIVE_USER]: { defaultMessage: "Inactive User" },
});

export const SupervisedCreatorStatusTranslations = defineMessages({
  [SupervisedCreatorStatusFilter.NONE]: { defaultMessage: "Not assigned" },
  [SupervisedCreatorStatus.NO_UGC]: { defaultMessage: "No UGC" },
  [SupervisedCreatorStatus.ACTIVE_IN_PERIOD]: { defaultMessage: "Active In Period" },
  [SupervisedCreatorStatus.CREATION_ON_HOLD]: { defaultMessage: "Creation On Hold" },
  [SupervisedCreatorStatus.CREATION_STOPPED]: { defaultMessage: "Creation Stopped" },
  [SupervisedCreatorStatus.INACTIVE_IN_PERIOD]: { defaultMessage: "Inactive In Period" },
  [SupervisedCreatorStatus.NEW_CREATOR]: { defaultMessage: "New Creator" },
});

export const ModerationUserInfoTypeTranslations = defineMessages({
  [ModerationUserInfoType.AVATAR]: { defaultMessage: "Ava", description: "avatar for user info moderation, short" },
  [ModerationUserInfoType.NAME]: { defaultMessage: "Name" },
  [ModerationUserInfoType.NICKNAME]: {
    defaultMessage: "Nick",
    description: "nickname for user info moderation, short",
  },
  [ModerationUserInfoType.PROFILE_DESCRIPTION]: {
    defaultMessage: "Descr",
    description: "profile description for user info moderation, short",
  },
  [ModerationUserInfoType.SOCIAL_NETWORK_ID]: {
    defaultMessage: "Soc.Network",
    description: "social network for user info moderation, short",
  },
});

export const AdminStatusTypeTranslations = defineMessages({
  [AdminStatusType.ACTIVE]: { defaultMessage: "Active" },
  [AdminStatusType.BLOCKED]: { defaultMessage: "Blocked", description: "long" },
  [AdminStatusType.WAIT_FOR_ACTIVATION]: { defaultMessage: "Wait for activation" },
});

export const socialNetworkTypeToShortName = {
  [SocialNetworkType.AppleMusic]: "AM",
  [SocialNetworkType.Instagram]: "IG",
  [SocialNetworkType.SberZvuk]: "SZ",
  [SocialNetworkType.Spotify]: "SPOT",
  [SocialNetworkType.Telegram]: "TG",
  [SocialNetworkType.VK]: "VK",
  [SocialNetworkType.YandexMusic]: "YM",
};

export const GeneratedImageStyleTypeTranslations = defineMessages({
  [StyleType.ILLUSTRATION]: { defaultMessage: "Illustration", description: "cover generator style" },
  [StyleType.PAINTING]: { defaultMessage: "Painting", description: "cover generator style" },
  [StyleType.PHOTOGRAPHY]: { defaultMessage: "Photography", description: "cover generator style" },
  [StyleType.PHOTOREALISM]: { defaultMessage: "Photorealism", description: "cover generator style" },
  [StyleType.THREE_D_RENDER]: { defaultMessage: "3D render", description: "cover generator style" },
});

export const ContentModerationStatusTranslations = defineMessages({
  [ContentModerationStatus.NEW]: { defaultMessage: "New", description: "content moderation status" },
  [ContentModerationStatus.APPROVED]: { defaultMessage: "Approved", description: "content moderation status" },
  [ContentModerationStatus.APPROVED_18]: { defaultMessage: "Approved (18+)", description: "content moderation status" },
  [ContentModerationStatus.DELETED]: { defaultMessage: "Deleted", description: "content moderation status" },
  [ContentModerationStatus.HIDDEN]: { defaultMessage: "Hidden", description: "content moderation status" },
});

export const ContentPromotionTypeTranslations = defineMessages({
  [ContentPromotionType.WELCOME_BATCH]: { defaultMessage: "ONBOARDING", description: "ugc tab title" },
  [ContentPromotionType.PINNED_IN_FIRST_BATCH]: { defaultMessage: "PINNED", description: "ugc tab title" },
  [ContentPromotionType.PROMOTED]: { defaultMessage: "PROMOTED", description: "ugc tab title" },
});

export const ThreadPrivacyFilterEnumTranslations = defineMessages({
  [ThreadPrivacyFilterEnum.ALL_WITHOUT_PRIVATE_THREADS]: {
    defaultMessage: "Public",
    description: "thread privacy ALL_WITHOUT_PRIVATE_THREADS",
  },
  [ThreadPrivacyFilterEnum.PRIVATE_THREAD_1_TO_1]: {
    defaultMessage: "1 to 1",
    description: "thread privacy PRIVATE_THREAD_1_TO_1",
  },
  [ThreadPrivacyFilterEnum.PRIVATE_THREAD_1_TO_N]: {
    defaultMessage: "Group",
  },
});

export const ContentSubtypeTranslations = defineMessages({
  [ContentSubtype.original]: { defaultMessage: "Origin", description: "chips ugc type" },
  [ContentSubtype.repeat]: { defaultMessage: "Repeat", description: "chips ugc type" },
});

export const BeatAssignedStatusTranslations = defineMessages({
  [BeatAssignedStatus.NEW]: { defaultMessage: "New", description: "content moderation status" },
  [BeatAssignedStatus.APPROVED]: { defaultMessage: "Approved", description: "content moderation status" },
  [BeatAssignedStatus.DELETED]: { defaultMessage: "Deleted", description: "content moderation status" },
  [BeatAssignedStatus.HIDDEN]: { defaultMessage: "Hidden", description: "content moderation status" },
});

export const PunchModeEnumTranslations = defineMessages({
  [PunchModeEnum.FREESTYLE]: { defaultMessage: "Freestyle", description: "punch mode enum" },
});

export const ChildSortingTypeTranslations = defineMessages({
  [ChildSortingType.DESC]: { defaultMessage: "Descending", description: "child sorting type" },
  [ChildSortingType.ASC]: { defaultMessage: "Ascending", description: "child sorting type" },
});

export const SupervisionBadgeTypeTranslations = defineMessages({
  [SupervisionBadgeType.PERMANENT]: { defaultMessage: "Permanent" },
  [SupervisionBadgeType.TEMPORARY]: { defaultMessage: "Temporary" },
});

export const ControlStatusTranslations = defineMessages({
  [ControlStatus.NEW]: { defaultMessage: "New", description: "content status NEW" },
  [ControlStatus.AVAILABLE]: { defaultMessage: "Available", description: "content status AVAILABLE" },
  [ControlStatus.REVOKED]: { defaultMessage: "Revoked", description: "control status REVOKED" },
  [ControlStatus.UNPUBLISHED]: { defaultMessage: "Unpublished", description: "content status UNPUBLISHED" },
  [ControlStatus.UPLOAD_FINISHED]: {
    defaultMessage: "Upload finished",
    description: "content status UPLOAD FINISHED",
  },
});

export const TextControlStatusTranslations = defineMessages({
  [TextControlStatus.NEW]: { defaultMessage: "New", description: "content status NEW" },
  [TextControlStatus.AVAILABLE]: { defaultMessage: "Available", description: "content status AVAILABLE" },
  [TextControlStatus.UNPUBLISHED]: { defaultMessage: "Unpublished", description: "content status UNPUBLISHED" },
  [TextControlStatus.PENDING]: { defaultMessage: "Pending", description: "control status PENDING" },
});
