import { UserRole } from "@api/user";
import { ConfigProperty, properties, usePropertyValue } from "@config";
import useActivateUser from "@hooks/useActivateUser";
import { useActIfNavigationAllowed } from "@hooks/useBlockNavigation";
import useDialogPayloadState from "@hooks/useDialogPayloadState";
import useDialogState from "@hooks/useDialogState";
import useMenuAnchorAndHandlers from "@hooks/useMenuAnchorAndHandlers";
import { appTypeToLogo } from "@models/AppIcons";
import AppType from "@models/AppType";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
} from "@mui/material";
import { LoggedInUser, addUser, removeAllUsers, removeUser } from "@slices/user";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import AppVersion from "@widgets/AppVersion";
import ConfirmationDialog from "@widgets/ConfirmationDialog";
import OmniSearchDialog from "@widgets/OmniSearch/OmniSearchDialog";
import ClientLoginDialogContent from "@widgets/client/ClientLoginDialogContent";
import { groupBy, isEqual, sortBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { LanguagePicker } from "../AppLocalizationProvider";
import LoginWidget from "../login/LoginWidget";
import ModerationNotificationSettingsDialogContent from "../moderationNotificator/ModerationNotificationSettingsDialogContent";
import usePreferredNavigationDrawerPosition from "../usePreferredNavigationDrawerPosition";
import ClientUserMenuItem from "./ClientUserMenuItem";
import * as styles from "./UserButton.module.scss";

const UserButton = ({ compact = false }: { compact?: boolean }) => {
  const dispatch = useAppDispatch();
  const activateUser = useActivateUser();
  const availableUsers = useAppSelector((state) => state.user.availableUsers);
  const currentUser = useAppSelector((state) => state.user.currentUser!);
  const [logoutMode, setLogoutMode] = useState<"current" | "all" | null>(null);
  const onLogoutCancelClick = useCallback(() => {
    setLogoutMode(null);
  }, []);
  const onLogoutConfirmClick = useCallback(() => {
    if (logoutMode === "all") {
      dispatch(removeAllUsers());
    } else {
      dispatch(removeUser(currentUser!));
    }
    setLogoutMode(null);
  }, [dispatch, currentUser, logoutMode]);
  const { anchorEl, onOpenMenu, onCloseMenu } = useMenuAnchorAndHandlers();
  const { open, closeDialog, openDialog } = useDialogState();
  const {
    open: notificationSettingsDialogOpen,
    closeDialog: closeNotificationSettingsDialog,
    openDialog: openNotificationSettingsDialog,
  } = useDialogState();
  const onLoginCompleted = useCallback(
    (user: LoggedInUser) => {
      dispatch(addUser(user));
      closeDialog();
    },
    [dispatch, closeDialog]
  );
  const [drawerPosition, setDrawerPosition] = usePreferredNavigationDrawerPosition();
  const toggleDrawerPosition = useCallback(() => {
    setDrawerPosition(drawerPosition === "left" ? "right" : "left");
    onCloseMenu();
  }, [drawerPosition, setDrawerPosition, onCloseMenu]);
  const hasAnyModerationRoles = availableUsers.find(
    (x) => x.role === UserRole.MODERATOR || x.role === UserRole.SUPER_MODERATOR
  );
  const moderationNotificationFlowEnabled = usePropertyValue(
    properties[ConfigProperty.MODERATION_NOTIFICATIONS_ENABLED]
  );
  const { open: searchDialogOpen, openDialog: openSearchDialog, closeDialog: closeSearchDialog } = useDialogState();
  const clientLoginDialogState = useDialogPayloadState<AppType>();
  const navigationGuard = useActIfNavigationAllowed();
  const Logo = appTypeToLogo[currentUser!.appType];
  const usersPerApp = useMemo(() => groupBy(sortBy(availableUsers, "role"), "appType"), [availableUsers]);
  const newYearModeOn = usePropertyValue(properties[ConfigProperty.NEW_YEAR_2024_MODE]);
  return (
    <>
      {compact ? (
        <IconButton onClick={onOpenMenu} color="primary">
          {newYearModeOn ? <img src={"/ny/santa_animated.png"} className={styles.santaIcon} /> : <AccountCircleIcon />}
        </IconButton>
      ) : (
        <Button
          color="primary"
          size="small"
          onClick={onOpenMenu}
          data-testid="header-user-button"
          className={styles.button}
        >
          {currentUser!.login}
          {newYearModeOn ? <img src="/ny/snowflake_animated.png" className={styles.snowflake} /> : "@"}
          <Logo height="10px" />
        </Button>
      )}
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onCloseMenu} MenuListProps={{ dense: true }}>
        <div className={styles.version}>
          <AppVersion />
        </div>
        <LanguagePicker className={styles.localePicker} />
        {!compact && (
          <MenuItem onClick={toggleDrawerPosition}>
            <ListItemText color="primary.main">
              <FormattedMessage
                defaultMessage="Stick navigation drawer to {location}"
                values={{
                  location: (
                    <b>
                      {drawerPosition === "left" ? (
                        <>
                          <FormattedMessage defaultMessage="right" /> ➡️
                        </>
                      ) : (
                        <>
                          <FormattedMessage defaultMessage="left" /> ⬅️
                        </>
                      )}
                    </b>
                  ),
                }}
              />
            </ListItemText>
          </MenuItem>
        )}
        {hasAnyModerationRoles && moderationNotificationFlowEnabled && "Notification" in window && (
          <MenuItem onClick={openNotificationSettingsDialog} data-testid="notification-settings-menu-item">
            <ListItemText>
              <FormattedMessage defaultMessage="Moderation notification settings" />
            </ListItemText>
            {Notification.permission !== "granted" && (
              <ListItemIcon>
                <PriorityHighIcon color="warning" />
              </ListItemIcon>
            )}
          </MenuItem>
        )}
        {compact && (
          <MenuItem
            onClick={() => {
              openSearchDialog();
              onCloseMenu();
            }}
            data-testid="open-search-dialog-button"
          >
            <ListItemText>
              <FormattedMessage defaultMessage="Search" />
            </ListItemText>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
          </MenuItem>
        )}
        <Divider />
        {Object.entries(usersPerApp).map(([appType, users]) => {
          const Logo = appTypeToLogo[appType as AppType];
          return [
            <MenuItem disabled key={appType} className={styles.disabledMenuItem}>
              <span>
                <small>
                  <FormattedMessage defaultMessage="Admin users" />
                </small>
                &nbsp;
                <Logo height="10px" />
              </span>
            </MenuItem>,
            ...users.map((x) => (
              <MenuItem
                key={`${x.login}${x.role}${x.appType}`}
                disabled={isEqual(x, currentUser)}
                onClick={() => navigationGuard(() => activateUser(x))}
                data-testid={`user-${x.userId}-menu-item`}
              >
                <ListItemText>
                  {x.login} ({x.role})
                </ListItemText>
              </MenuItem>
            )),
            <Divider key={`${appType}-divider`} />,
          ];
        })}
        <MenuItem onClick={openDialog} data-testid="add-user-menu-item">
          <ListItemText>
            <i>
              <FormattedMessage defaultMessage="Add user" />
            </i>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setLogoutMode("current")} data-testid="logout-current-menu-item">
          <ListItemText>
            <i>
              <FormattedMessage defaultMessage="Logout current" />
            </i>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setLogoutMode("all")} data-testid="logout-all-menu-item">
          <ListItemText>
            <i>
              <FormattedMessage defaultMessage="Logout from all accounts" />
            </i>
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem disabled>
          <small>
            <FormattedMessage defaultMessage="Client users" />
          </small>
        </MenuItem>
        {Object.values(AppType).map((x) => (
          <ClientUserMenuItem key={x} appType={x} onOpenClientLoginDialog={clientLoginDialogState.openDialog} />
        ))}
      </Menu>
      {newYearModeOn && (
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          sx={{
            pointerEvents: "none",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          disableRestoreFocus
          slotProps={{ paper: { className: styles.santaHatPopover } }}
        >
          <img src={`/ny/santa_hat.webp`} className={styles.santaHat} />
        </Popover>
      )}
      <ConfirmationDialog
        customTestId="logout-confirmation-dialog"
        open={!!logoutMode}
        title={
          logoutMode === "current" ? (
            <FormattedMessage defaultMessage="Are you sure you want to logout?" />
          ) : (
            <FormattedMessage defaultMessage="Are you sure you want to logout from all accounts?" />
          )
        }
        confirmButtonProps={{ color: "warning" }}
        confirmButtonContent={
          logoutMode === "current" ? (
            <FormattedMessage defaultMessage="Logout" />
          ) : (
            <FormattedMessage defaultMessage="Logout from all accounts" />
          )
        }
        onConfirm={onLogoutConfirmClick}
        onDismiss={onLogoutCancelClick}
      />
      <Dialog
        open={notificationSettingsDialogOpen}
        onClose={closeNotificationSettingsDialog}
        fullWidth
        maxWidth="lg"
        data-testid="notification-settings-dialog"
      >
        {notificationSettingsDialogOpen && (
          <ModerationNotificationSettingsDialogContent closeDialog={closeNotificationSettingsDialog} />
        )}
      </Dialog>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm" data-testid="login-modal">
        <DialogContent>
          <LoginWidget onLoginCompleted={onLoginCompleted} />
        </DialogContent>
      </Dialog>
      {compact && searchDialogOpen && <OmniSearchDialog closeDialog={closeSearchDialog} />}
      <Dialog
        open={clientLoginDialogState.open}
        onClose={clientLoginDialogState.closeDialog}
        data-testid={`client-${clientLoginDialogState?.payload ?? AppType.PUNCH}-login-dialog`}
      >
        {clientLoginDialogState.payload && (
          <ClientLoginDialogContent
            closeDialog={clientLoginDialogState.closeDialog}
            targetApp={clientLoginDialogState.payload}
          />
        )}
      </Dialog>
    </>
  );
};

export default UserButton;
