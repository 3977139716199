import { ChildSortingType, CommonModerationStatus } from "@api";
import {
  BatchSetUserInfoModerationStatusRequest,
  ModerationUserInfo,
  ModerationUserInfoFilter,
  ModerationUserInfoType,
  ModerationUserType,
  UserInfoModerationApi,
} from "@api/userInfoModeration";
import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { RootState, ThunkApiType } from "@store/store";
import { groupBy, uniq } from "lodash";

export enum UserInfoCategory {
  IMAGES = "IMAGES",
  TEXTS = "TEXTS",
}

export const categoryToAllowedTypes = {
  [UserInfoCategory.IMAGES]: [ModerationUserInfoType.AVATAR],
  [UserInfoCategory.TEXTS]: [
    ModerationUserInfoType.NAME,
    ModerationUserInfoType.NICKNAME,
    ModerationUserInfoType.PROFILE_DESCRIPTION,
    ModerationUserInfoType.SOCIAL_NETWORK_ID,
  ],
};

export interface UserInfoModerationListData {
  isLoading: boolean;
  error: SerializedError | null;
  itemIds: number[];
}

export interface UserInfoModerationLoaderParams {
  filter: ModerationUserInfoFilter;
  order: string;
}

export interface ModerationUserInfoExtended extends ModerationUserInfo {
  pendingModerationStatus?: CommonModerationStatus;
}

export interface UserInfoModerationState {
  categoriesData: Record<UserInfoCategory, UserInfoModerationListData>;
  categoriesParams: Record<UserInfoCategory, UserInfoModerationLoaderParams>;
  itemsMap: Record<number, ModerationUserInfoExtended>;
  categoriesProportion: number;
  showProportionSlider: boolean;
}

export const defaultCategoryProportion = 75;

const initialState: UserInfoModerationState = {
  categoriesData: Object.values(UserInfoCategory).reduce((a, x) => {
    a[x] = {
      isLoading: false,
      error: null,
      itemIds: [],
    };
    return a;
  }, {} as Record<UserInfoCategory, UserInfoModerationListData>),
  categoriesParams: Object.values(UserInfoCategory).reduce((a, x) => {
    a[x] = {
      filter: {
        moderationStatuses: [CommonModerationStatus.NEW],
        userInfoTypes: categoryToAllowedTypes[x],
        userTypes: Object.values(ModerationUserType),
      },
      order: "createdAt",
    };
    return a;
  }, {} as Record<UserInfoCategory, UserInfoModerationLoaderParams>),
  itemsMap: {},
  categoriesProportion: defaultCategoryProportion,
  showProportionSlider: false,
};

const name = "userInfoModeration";
export const loadUserInfoModerationDataListAction = createAsyncThunk<
  { itemIds: number[]; itemsMap: Record<number, ModerationUserInfo>; category: UserInfoCategory },
  {
    category: UserInfoCategory;
    filter: ModerationUserInfoFilter;
    page?: number;
    size?: number;
    order: string;
  },
  ThunkApiType
>(`${name}/loadUserInfoModerationDataListAction`, async ({ category, filter, page = 0, size = 100, order }) => {
  const data = await UserInfoModerationApi.list({
    filter,
    page,
    size,
    order,
    direction: ChildSortingType.DESC,
  });
  const itemIds = (data.content || []).map((x) => x.id!);
  const itemsMap = (data.content || []).reduce((a, x) => {
    a[x.id] = x;
    return a;
  }, {} as Record<number, ModerationUserInfo>);
  return { itemIds, itemsMap, category };
});

export const setUserInfoItemsStatusAction = createAsyncThunk(
  `${name}/setUserInfoItemsStatusAction`,
  async ({ items }: { items: ModerationUserInfoExtended[] }) => {
    const request: BatchSetUserInfoModerationStatusRequest = {
      batch: items
        .filter((x) => !!x.pendingModerationStatus)
        .map((x) => ({
          userInfoId: x.id!,
          moderationStatus: x.pendingModerationStatus!,
          version: x.version!,
        })),
    };
    const result = await UserInfoModerationApi.setStatusesBatch(request);
    return {
      itemsMap: [...(result.changedUserInfos || []), ...(result.moderatedUserInfos || [])].reduce((a, x) => {
        a[x.id] = x;
        return a;
      }, {} as Record<number, ModerationUserInfo>),
    };
  }
);

export const executeAllPendingItemsAction = createAsyncThunk<void, void, ThunkApiType>(
  `${name}/executeAllPendingItemsAction`,
  async (_, thunkApi) => {
    const itemsMap = thunkApi.getState().userInfoModeration.itemsMap;
    const pendingItems = Object.values(itemsMap).filter((x) => x.pendingModerationStatus);
    if (pendingItems.length) {
      thunkApi.dispatch(setUserInfoItemsStatusAction({ items: pendingItems }));
    }
  }
);

export const loadUserInfoModerationDataByIdsAction = createAsyncThunk<
  { itemsMap: Record<number, ModerationUserInfo> },
  {
    userIds: number[];
    moderationStatuses?: CommonModerationStatus[];
    userInfoTypes?: ModerationUserInfoType[];
  },
  ThunkApiType
>(
  `${name}/loadUserInfoModerationDataByIdsAction`,
  async ({ userIds, moderationStatuses = Object.values(CommonModerationStatus), userInfoTypes }) => {
    const data = await UserInfoModerationApi.list({
      filter: { userIds: uniq(userIds), moderationStatuses, userInfoTypes },
      page: 0,
      size: 10_000,
      order: "createdAt",
      direction: ChildSortingType.DESC,
    });
    const itemsMap = (data.content || []).reduce((a, x) => {
      a[x.id] = x;
      return a;
    }, {} as Record<number, ModerationUserInfo>);
    return { itemsMap };
  }
);

const userInfoModerationSlice = createSlice({
  name,
  initialState,
  reducers: {
    toggleProportionSliderAction: (state) => {
      state.showProportionSlider = !state.showProportionSlider;
    },
    setCategoriesProportionAction: (state, action: PayloadAction<number>) => {
      state.categoriesProportion = action.payload;
    },
    setPendingStatuses: (state, action: PayloadAction<Record<number, CommonModerationStatus>>) => {
      Object.entries(action.payload).forEach(([id, status]) => {
        const item = state.itemsMap[Number.parseInt(id)];
        if (item.moderationStatus === status) {
          item.pendingModerationStatus = undefined;
        } else {
          item.pendingModerationStatus = status;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUserInfoModerationDataListAction.pending, (state, action) => {
      const { category, filter, order } = action.meta.arg;
      state.categoriesParams[category].filter = filter;
      state.categoriesParams[category].order = order;
      state.categoriesData[category].isLoading = true;
      state.categoriesData[category].error = null;
    });
    builder.addCase(loadUserInfoModerationDataListAction.rejected, (state, action) => {
      const { category } = action.meta.arg;
      state.categoriesData[category].isLoading = false;
      state.categoriesData[category].error = action.error;
    });
    builder.addCase(loadUserInfoModerationDataListAction.fulfilled, (state, action) => {
      const { category } = action.meta.arg;
      const data = state.categoriesData[category];
      data.isLoading = false;
      data.error = null;
      data.itemIds = action.payload.itemIds;
      Object.assign(state.itemsMap, action.payload.itemsMap);
    });
    builder.addCase(setUserInfoItemsStatusAction.fulfilled, (state, action) => {
      Object.assign(state.itemsMap, action.payload.itemsMap);
    });
    builder.addCase(loadUserInfoModerationDataByIdsAction.fulfilled, (state, action) => {
      Object.assign(state.itemsMap, action.payload.itemsMap);
    });
  },
});

export const { toggleProportionSliderAction, setCategoriesProportionAction, setPendingStatuses } =
  userInfoModerationSlice.actions;
export default userInfoModerationSlice.reducer;

export const getItemsGroupedByUserMap = createSelector(
  (state: RootState) => state.userInfoModeration.itemsMap,
  (items) => groupBy(Object.values(items), "userId") as Record<number, ModerationUserInfoExtended[]>
);
