import { UserRole } from "@api/user";
import { ConfigProperty, ConfigPropertyDescriptor, properties } from "@config";
import AppType from "@models/AppType";
import { SvgIconComponent } from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import CampaignIcon from "@mui/icons-material/Campaign";
import ContrastIcon from "@mui/icons-material/Contrast";
import ExploreIcon from "@mui/icons-material/Explore";
import HeadsetIcon from "@mui/icons-material/Headset";
import HistoryIcon from "@mui/icons-material/History";
import LyricsIcon from "@mui/icons-material/Lyrics";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MicIcon from "@mui/icons-material/Mic";
import MovieIcon from "@mui/icons-material/Movie";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ReportIcon from "@mui/icons-material/Report";
import ScienceIcon from "@mui/icons-material/Science";
import SellIcon from "@mui/icons-material/Sell";
import SendIcon from "@mui/icons-material/Send";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import TagIcon from "@mui/icons-material/Tag";
import VideocamIcon from "@mui/icons-material/Videocam";
import AbTestsHeaderTitle from "@pages/abTests/AbTestsHeaderTitle";
import BeatsModerationPageTitle from "@pages/beatsModeration/BeatsModerationPageTitle";
import ComplaintsModerationHeaderTitle from "@pages/complaintsModeration/ComplaintsModerationHeaderTitlte";
import ContentCardHeader from "@pages/contentCard/ContentCardHeader";
import { allowedRoles } from "@pages/directories/subpagesConfig";
import ExplorePageTitle from "@pages/explore/ExplorePageTitle";
import ManualNotificationsHeaderTitle from "@pages/manualNotifications/ManualNotificationsHeaderTitle";
import ModerationBlackWhiteListsPageTitle from "@pages/moderationBlackWhiteLists/ModerationBlackWhiteListsPageTitle";
import ModerationLogsHeaderTitle from "@pages/moderationLogs/ModerationLogsHeaderTitle";
import ModerationTagsPageTitle from "@pages/moderationTags/ModerationTagsPageTitle";
import SupervisedUsersHeaderTitle from "@pages/supervisedUsers/SupervisedUsersHeaderTitle";
import { UGCHeaderContentFactory } from "@pages/ugc/UGCHeaderContent";
import UserInfoModerationHeader from "@pages/userInfoModeration/UserInfoModerationHeader";
import UserSearchPageTitle from "@pages/userSearch/UserSearchPageTitle";
import VideoModerationPageHeaderTitle from "@pages/videoModeration/VideoModerationPageHeaderTitle";
import React, { lazy } from "react";
import { MessageDescriptor, defineMessage } from "react-intl";
import MakeRedirect from "./MakeRedirect";
import UserProfileHeaderTitle from "./UserProfileHeaderTitle";

export interface RouteDescriptor {
  path: string;
  component: React.FC;
  title?: MessageDescriptor;
  allowedRoles?: UserRole[];
  icon?: SvgIconComponent;
  headerTitleComponent?: React.FC;
  allowedApps?: AppType[];
  killswitch?: ConfigPropertyDescriptor<boolean>;
  hideFromReadonlyUser?: boolean;
}

export const canUseByRole = (config: RouteDescriptor, role: UserRole) =>
  (role === UserRole.WEBADMIN_VIEWER && !config.hideFromReadonlyUser) ||
  !config.allowedRoles ||
  config.allowedRoles.includes(role);

export const canUseByApp = (config: RouteDescriptor, appType: AppType) =>
  !config.allowedApps || config.allowedApps.includes(appType);

export const canUseByKillswitch = (
  config: RouteDescriptor,
  resolvedKillswitches: Partial<Record<ConfigProperty, boolean>>
) => config.killswitch === undefined || resolvedKillswitches[config.killswitch.property] === true;

export const canShowInNavigationMenu = (
  config: RouteDescriptor,
  role: UserRole,
  appType: AppType,
  resolvedKillswitches: Partial<Record<ConfigProperty, boolean>>
) =>
  !!config.icon &&
  !!config.title &&
  canUseByKillswitch(config, resolvedKillswitches) &&
  canUseByApp(config, appType) &&
  canUseByRole(config, role);

/**
 * Note that icon and title are required for an item to show up in sidebar navigation
 */
const routes: RouteDescriptor[] = [
  {
    title: defineMessage({ defaultMessage: "User Search" }),
    path: "userSearch",
    allowedRoles: [UserRole.MODERATOR, UserRole.SUPER_MODERATOR, UserRole.CONTENT_MANAGER, UserRole.ADMINISTRATOR],
    component: lazy(() => import(/* webpackChunkName: "userSearch" */ "@pages/userSearch/UserSearchPage")),
    icon: PersonSearchIcon,
    headerTitleComponent: UserSearchPageTitle,
  },
  {
    title: defineMessage({ defaultMessage: "Users" }),
    path: "supervisedUsers",
    allowedRoles: [UserRole.SUPERVISOR, UserRole.ADMINISTRATOR],
    component: lazy(() => import(/* webpackChunkName: "supervisedUsers" */ "@pages/supervisedUsers/SupervisedUsers")),
    icon: SupervisorAccountIcon,
    headerTitleComponent: SupervisedUsersHeaderTitle,
  },
  {
    title: defineMessage({ defaultMessage: "Video Moderation" }),
    path: "videoModeration",
    icon: VideocamIcon,
    allowedRoles: [UserRole.MODERATOR, UserRole.SUPER_MODERATOR],
    component: lazy(
      () => import(/* webpackChunkName: "videoModeration" */ "@pages/videoModeration/VideoModerationPage")
    ),
    headerTitleComponent: VideoModerationPageHeaderTitle,
  },
  {
    title: defineMessage({ defaultMessage: "Comments Moderation" }),
    path: "commentsModeration",
    icon: AnnouncementIcon,
    allowedRoles: [UserRole.MODERATOR, UserRole.SUPER_MODERATOR],
    component: lazy(
      () => import(/* webpackChunkName: "commentsModeration" */ "@pages/commentsModeration/CommentsModeration")
    ),
  },
  {
    title: defineMessage({ defaultMessage: "Profiles Moderation" }),
    path: "userInfoModeration",
    icon: RecentActorsIcon,
    allowedRoles: [UserRole.MODERATOR, UserRole.SUPER_MODERATOR],
    component: lazy(
      () => import(/* webpackChunkName: "userInfoModeration" */ "@pages/userInfoModeration/UserInfoModeration")
    ),
    headerTitleComponent: UserInfoModerationHeader,
  },
  {
    title: defineMessage({ defaultMessage: "Hashtags Moderation" }),
    path: "hashtagsModeration",
    icon: TagIcon,
    allowedRoles: [UserRole.MODERATOR, UserRole.SUPER_MODERATOR],
    component: lazy(
      () => import(/* webpackChunkName: "hashtagsModeration" */ "@pages/hashtagsModeration/HashtagsModeration")
    ),
    headerTitleComponent: lazy(
      () => import(/* webpackChunkName: "hashtagsModeration" */ "@pages/hashtagsModeration/HeaderTitle")
    ),
  },
  {
    title: defineMessage({ defaultMessage: "Complaints Moderation" }),
    path: "complaintsModeration",
    icon: ReportIcon,
    allowedRoles: [UserRole.MODERATOR, UserRole.SUPER_MODERATOR],
    component: lazy(
      () => import(/* webpackChunkName: "complaintsModeration" */ "@pages/complaintsModeration/ComplaintsModeration")
    ),
    headerTitleComponent: ComplaintsModerationHeaderTitle,
  },
  {
    title: defineMessage({ defaultMessage: "Moderation Tags" }),
    path: "moderationTags",
    allowedRoles: [UserRole.SUPER_MODERATOR],
    component: lazy(() => import(/* webpackChunkName: "moderationTags" */ "@pages/moderationTags/ModerationTagsPage")),
    icon: SellIcon,
    headerTitleComponent: ModerationTagsPageTitle,
  },
  {
    title: defineMessage({ defaultMessage: "Moderation Logs" }),
    path: "moderationLogs",
    allowedRoles: [UserRole.SUPER_MODERATOR, UserRole.MODERATOR],
    component: lazy(() => import(/* webpackChunkName: "moderationLogs" */ "@pages/moderationLogs/ModerationLogs")),
    icon: ReceiptLongIcon,
    headerTitleComponent: ModerationLogsHeaderTitle,
  },
  {
    path: "coverTextsModeration",
    component: lazy(
      () => import(/* webpackChunkName: "coverTextsModeration" */ "@pages/coverTextsModeration/CoverTextsModeration")
    ),
    allowedApps: [AppType.PUNCH],
    allowedRoles: [UserRole.MODERATOR, UserRole.SUPER_MODERATOR],
    title: defineMessage({ defaultMessage: "Cover texts moderation" }),
    icon: LyricsIcon,
    killswitch: properties[ConfigProperty.KS_COVER_TEXTS_MODERATION_RELEASED],
  },
  {
    title: defineMessage({ defaultMessage: "Directories" }),
    path: "directories",
    icon: MenuBookIcon,
    allowedRoles: allowedRoles,
    component: lazy(() => import(/* webpackChunkName: "directories" */ "@pages/directories/DirectoriesPage")),
    headerTitleComponent: lazy(
      () => import(/* webpackChunkName: "directories" */ "@pages/directories/DirectoriesPageTitle")
    ),
  },
  {
    path: "libraries",
    component: MakeRedirect({ to: "/directories", preserveQuery: true }),
  },
  {
    title: defineMessage({ defaultMessage: "SPICH content" }),
    path: "videoUGC",
    allowedRoles: [
      UserRole.CONTENT_MANAGER,
      UserRole.MODERATOR,
      UserRole.SUPER_MODERATOR,
      UserRole.ADMINISTRATOR,
      UserRole.WEBADMIN_VIEWER,
      UserRole.SUPERVISOR,
    ],
    allowedApps: [AppType.CHIPZ],
    component: lazy(() => import(/* webpackChunkName: "videoUGC" */ "@pages/ugc/VideoUGC")),
    icon: MovieIcon,
    headerTitleComponent: UGCHeaderContentFactory(AppType.CHIPZ),
  },
  {
    title: defineMessage({ defaultMessage: "PUNCH content" }),
    path: "audioUGC",
    allowedRoles: [
      UserRole.CONTENT_MANAGER,
      UserRole.MODERATOR,
      UserRole.SUPER_MODERATOR,
      UserRole.SUPERVISOR,
      UserRole.ADMINISTRATOR,
      UserRole.WEBADMIN_VIEWER,
    ],
    allowedApps: [AppType.PUNCH],
    component: lazy(() => import(/* webpackChunkName: "AudioUGC" */ "@pages/ugc/AudioUGC")),
    icon: MicIcon,
    headerTitleComponent: UGCHeaderContentFactory(AppType.PUNCH),
  },
  {
    title: defineMessage({ defaultMessage: "Administrators" }),
    path: "admins",
    allowedRoles: [UserRole.SUPER_ADMINISTRATOR, UserRole.ADMINISTRATOR, UserRole.SUPER_MODERATOR],
    component: lazy(() => import(/* webpackChunkName: "admins" */ "@pages/admins/Admins")),
    icon: AdminPanelSettingsIcon,
  },
  {
    title: defineMessage({ defaultMessage: "Configuration" }),
    path: "abTests",
    allowedRoles: [UserRole.ADMINISTRATOR, UserRole.DATA_ANALYST],
    component: lazy(() => import(/* webpackChunkName: "abTests" */ "@pages/abTests/AbTests")),
    icon: ScienceIcon,
    headerTitleComponent: AbTestsHeaderTitle,
  },
  {
    title: defineMessage({ defaultMessage: "Notification Templates" }),
    path: "notificationTemplates",
    allowedRoles: [UserRole.ADMINISTRATOR],
    component: lazy(
      () => import(/* webpackChunkName: "notificationTemplates" */ "@pages/notificationTemplates/NotificationTemplates")
    ),
    icon: CampaignIcon,
  },
  {
    title: defineMessage({ defaultMessage: "Manual notifications" }),
    path: "manualNotifications",
    allowedRoles: [UserRole.CONTENT_MANAGER, UserRole.SUPERVISOR, UserRole.ADMINISTRATOR],
    icon: SendIcon,
    hideFromReadonlyUser: true,
    component: lazy(
      () => import(/* webpackChunkName: "manualNotifications" */ "@pages/manualNotifications/ManualNotifications")
    ),
    headerTitleComponent: ManualNotificationsHeaderTitle,
  },
  {
    title: defineMessage({ defaultMessage: "Explore" }),
    path: "explore",
    allowedRoles: [UserRole.ADMINISTRATOR, UserRole.CONTENT_MANAGER, UserRole.SUPERVISOR, UserRole.BEAT_PRODUCER],
    component: lazy(() => import(/* webpackChunkName: "explore" */ "@pages/explore/ExplorePage")),
    headerTitleComponent: ExplorePageTitle,
    icon: ExploreIcon,
  },
  {
    path: "collections",
    component: MakeRedirect({ to: "/explore?view=COLLECTIONS" }),
  },
  {
    title: defineMessage({ defaultMessage: "Beats moderation" }),
    path: "beatsModeration",
    component: lazy(
      () => import(/* webpackChunkName: "beatsModeration" */ "@pages/beatsModeration/BeatsModerationPage")
    ),
    icon: HeadsetIcon,
    allowedRoles: [UserRole.BEAT_PRODUCER, UserRole.ADMINISTRATOR],
    allowedApps: [AppType.PUNCH],
    headerTitleComponent: BeatsModerationPageTitle,
  },
  {
    path: "blackWhiteLists",
    component: lazy(
      () =>
        import(
          /* webpackChunkName: "moderationBlackWhiteLists" */ "@pages/moderationBlackWhiteLists/ModerationBlackWhiteLists"
        )
    ),
    headerTitleComponent: ModerationBlackWhiteListsPageTitle,
    allowedRoles: [UserRole.MODERATOR, UserRole.SUPER_MODERATOR],
    icon: ContrastIcon,
    title: defineMessage({ defaultMessage: "Black and white lists for moderation" }),
  },
  {
    title: defineMessage({ defaultMessage: "Events Journal" }),
    path: "adminLogs",
    component: lazy(() => import(/* webpackChunkName: "adminLogs" */ "@pages/adminLogs/AdminLogs")),
    icon: HistoryIcon,
  },
  {
    path: "user",
    component: lazy(() => import(/* webpackChunkName: "userProfile" */ "@pages/userProfile/UserProfile")),
    headerTitleComponent: UserProfileHeaderTitle,
  },
  {
    path: "contentCard",
    component: lazy(() => import(/* webpackChunkName: "contentCard" */ "@pages/contentCard/ContentCard")),
    headerTitleComponent: ContentCardHeader,
  },
];

export default routes;
