import { ChildSortingType, IdDto } from "@api";
import { ANY_BADGE_ID } from "@slices/supervisedUsers";
import { DELETE, GET, PATCH, POST, PUT, buildQuery } from "@utils/fetch";
import { isEqual } from "lodash";
import { BadgeIdsPredicateType, SupervisedBadgeFilterType, SupervisionBadgesDto } from "./supervisedBadges";
import { SocialNetworkType } from "./user";

const urlPrefix = "api/v1/admin/supervised_users";

export interface LocationDto {
  id: number;
  name?: string;
}

export interface CreatorCategoryDto {
  /** @format int64 */
  id?: number;
  name?: string;
  description?: string;
  value?: string;
}

export interface SupervisionUserStatusDto {
  supervisedUserStatus?: SupervisedUserStatus;
  supervisedCreatorStatus?: SupervisedCreatorStatus;
  statusAtSupervisedUser?: string;
}

export interface SupervisionPersonStatusDto {
  supervisedPersonStatus?: SupervisedPersonStatus;
  statusAtSupervisedPerson?: string;
}

export interface SupervisorDto {
  /** @format int64 */
  id?: number;
  name?: string;
}

export interface UserPersonDto {
  /** @format int64 */
  id?: number;
  avatar?: string;
  description?: string;
  nickname?: string;

  /** @format date-time */
  createdAt?: string;
  statusType?: "ACTIVE" | "BLOCKED" | "WAIT_FOR_ACTIVATION";

  nickName?: string | null;
  contentCreatedAt?: string | null;
}

export interface UserSocialNetworkDto {
  /** @format int64 */
  id?: number;
  socialNetworkTypeId?: string;
  socialNetworkId?: string;
  subscriberCount?: string;
}

export interface SupervisedPersonDto {
  /** @format int64 */
  id?: number;
  user?: UserPersonDto;
  fullName?: string;
  appleId?: string;
  phone?: string;
  email?: string;
  socialNetworks?: UserSocialNetworkDto[];
  location?: LocationDto;
  platform?: SupervisedPersonPlatform;
  supervisedUserStatus?: SupervisedUserStatus;

  /** @format date-time */
  statusAtSupervisedUser?: string;
  supervisedPersonStatus?: SupervisedPersonStatus;

  /** @format date-time */
  statusAtSupervisedPerson?: string;
  supervisedCreatorStatus?: SupervisedCreatorStatus;
  note?: string;
  badges?: SupervisionBadgesDto[];
  supervisor?: SupervisorDto;
  creatorCategory?: CreatorCategoryDto;
  createdAt?: string | null;
  createdByUser?: SupervisorDto | null;
  updatedAt?: string | null;
  updatedByUser?: SupervisorDto | null;
  contentCount?: number;
  moderatedContentCount?: number;
  contentCreatedAt?: string | null;
}

export interface CreateSocialNetworkDto {
  id?: string;
  type?: SocialNetworkType;
}
export interface CreateSupervisedPersonDto {
  fullName?: string;
  appleId?: string;
  phone?: string;
  socialNetworks?: CreateSocialNetworkDto[];
  subscriberCount?: number;
  locationId?: number;
  platform?: SupervisedPersonPlatform;
  supervisedUserStatus?: SupervisedUserStatus;
  supervisedPersonStatus?: SupervisedPersonStatus;
  supervisedCreatorStatus?: SupervisedCreatorStatus;
  note?: string;
  badges?: string[];
  userId?: number;
  creatorCategoryId?: number;
  supervisorId?: number;
}

export enum SupervisedPersonPlatform {
  ANDROID = "ANDROID",
  IOS = "IOS",
}

export enum SupervisedPersonStatus {
  ONBOARDING = "ONBOARDING",
  APPLICATION_INSTALLED = "APPLICATION_INSTALLED",
  COMMUNICATION_CANCELED = "COMMUNICATION_CANCELED",
  COMMUNICATION_FINISHED = "COMMUNICATION_FINISHED",
  COMMUNICATIONON_HOLD = "COMMUNICATIONON_HOLD",
}

export enum SupervisedPersonStatusFilter {
  NONE = "NONE",
  ONBOARDING = "ONBOARDING",
  APPLICATION_INSTALLED = "APPLICATION_INSTALLED",
  COMMUNICATION_CANCELED = "COMMUNICATION_CANCELED",
  COMMUNICATION_FINISHED = "COMMUNICATION_FINISHED",
  COMMUNICATIONON_HOLD = "COMMUNICATIONON_HOLD",
}

export enum SupervisedUserStatus {
  ANONYMOUS = "ANONYMOUS",
  INACTIVE_USER = "INACTIVE_USER",
  ACTIVE_USER = "ACTIVE_USER",
}

export enum SupervisedUserStatusFilter {
  NONE = "NONE",
  ANONYMOUS = "ANONYMOUS",
  INACTIVE_USER = "INACTIVE_USER",
  ACTIVE_USER = "ACTIVE_USER",
}

export enum SupervisedCreatorStatus {
  NO_UGC = "NO_UGC",
  NEW_CREATOR = "NEW_CREATOR",
  ACTIVE_IN_PERIOD = "ACTIVE_IN_PERIOD",
  INACTIVE_IN_PERIOD = "INACTIVE_IN_PERIOD",
  CREATION_ON_HOLD = "CREATION_ON_HOLD",
  CREATION_STOPPED = "CREATION_STOPPED",
}

export enum SupervisedCreatorStatusFilter {
  NONE = "NONE",
  NO_UGC = "NO_UGC",
  NEW_CREATOR = "NEW_CREATOR",
  ACTIVE_IN_PERIOD = "ACTIVE_IN_PERIOD",
  INACTIVE_IN_PERIOD = "INACTIVE_IN_PERIOD",
  CREATION_ON_HOLD = "CREATION_ON_HOLD",
  CREATION_STOPPED = "CREATION_STOPPED",
}

export enum SupervisedUserSortBy {
  NICKNAME = "NICKNAME",
  SUPERVISEDPERSONSTATUS = "SUPERVISEDPERSONSTATUS",
  SUPERVISEDCREATORSTATUS = "SUPERVISEDCREATORSTATUS",
  SUPERVISEDUSERSTATUS = "SUPERVISEDUSERSTATUS",
}

export interface SupervisedUsersFilter {
  nickName?: string | null;
  fullName?: string | null;
  supervisorId?: number | null;
  locationId?: number | null;
  supervisorPersonStatus?: SupervisedPersonStatusFilter | null;
  supervisorUserStatus?: SupervisedUserStatusFilter | null;
  supervisorCreatorStatus?: SupervisedCreatorStatusFilter | null;
  labels?: number[] | null;
  platform?: SupervisedPersonPlatform | null;
  contentCountMin?: number;
  contentCountMax?: number;
  createdFrom?: Date | null;
  createdTo?: Date | null;
  profileBadgeIds?: number[] | null;
  supervisedBadgeFilter?: SupervisedBadgeFilterType | null;
}

interface SupervisedUsersListResponse {
  content: SupervisedPersonDto[];
  total: number;
}

export const SupervisedUsersApi = {
  list: ({
    offset,
    limit,
    sorting,
    sortBy,
    filter,
  }: {
    offset: number;
    limit: number;
    sorting?: ChildSortingType;
    sortBy?: SupervisedUserSortBy;
    filter?: SupervisedUsersFilter;
  }): Promise<SupervisedUsersListResponse> => {
    const { profileBadgeIds, ...rest } = filter || {};
    return GET(
      `${urlPrefix}?${buildQuery({
        offset,
        limit,
        sorting,
        sortBy,
        ...(profileBadgeIds?.length && {
          badgeIds: profileBadgeIds,
          badgeIdsPredicate: isEqual(profileBadgeIds, [ANY_BADGE_ID])
            ? BadgeIdsPredicateType.NOT_IN
            : BadgeIdsPredicateType.IN,
        }),
        ...rest,
      })}`
    );
  },
  delete: (id: number): Promise<void> => DELETE(`${urlPrefix}/${id}`),
  create: (request: CreateSupervisedPersonDto): Promise<IdDto> => POST(`${urlPrefix}`, request),
  update: (id: number, request: CreateSupervisedPersonDto): Promise<SupervisedPersonDto> =>
    PUT(`${urlPrefix}/${id}?forced=true`, request),
  updatePartial: (id: number, request: Partial<CreateSupervisedPersonDto>): Promise<SupervisedPersonDto> =>
    PATCH(`${urlPrefix}/${id}?forced=true`, request),
  getById: (id: number): Promise<SupervisedPersonDto> => GET(`${urlPrefix}/${id}`),
  loadStatusHistoryForUser: (id: number): Promise<SupervisionUserStatusDto[]> => GET(`${urlPrefix}/${id}/user_status`),
  loadStatusHistoryForPerson: (id: number): Promise<SupervisionPersonStatusDto[]> =>
    GET(`${urlPrefix}/${id}/person_status`),
  listLocations: (): Promise<LocationDto[]> => GET(`api/v1/admin/supervised_person_location`),
  listCreatorCategories: (): Promise<CreatorCategoryDto[]> => GET(`api/v1/admin/creator_categories`),
  batchByUserIds: (userIds: number[]): Promise<SupervisedPersonDto[]> =>
    POST(`api/v1/admin/supervised_users/get-by-user-ids`, { userIds }),
};
