import { ClientAuthApi } from "@api/clientAuth";
import useDefaultNetworkErrorHandler from "@hooks/useDefaultNetworkErrorHandler";
import { appTypeToLogo } from "@models/AppIcons";
import AppType from "@models/AppType";
import { Button, DialogActions, DialogContent, DialogTitle, Stack, TextField, ThemeProvider } from "@mui/material";
import { setClientUserAction } from "@slices/client";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useCallback, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useAppThemes } from "../../app/AppTheme";

const phoneNumberLength = 11;
const codeLength = 6;

export default function ClientLoginDialogContent({
  targetApp,
  closeDialog,
}: {
  targetApp: AppType;
  closeDialog: (reason: "logged_in" | "cancel") => void;
}) {
  const deviceUuid = useAppSelector((state) => state.client.deviceUuid);
  const [phoneNumber, setPhoneNumber] = useState("7");
  const [code, setCode] = useState("");
  const [codeRequested, setCodeRequested] = useState(false);

  const codeInputRef = useRef<any>(null);
  const phoneInputRef = useRef<any>(null);

  const errorHandler = useDefaultNetworkErrorHandler();
  const dispatch = useAppDispatch();
  const reset = useCallback(() => {
    setPhoneNumber("7");
    setCode("");
    setCodeRequested(false);
    setTimeout(() => {
      phoneInputRef.current?.focus();
    }, 10);
  }, []);
  const requestCode = useCallback(() => {
    ClientAuthApi.requestCode({ phoneNumber, deviceUuid }, targetApp)
      .then(() => {
        setCodeRequested(true);
        setTimeout(() => {
          codeInputRef.current?.focus();
        }, 10);
      })
      .catch(errorHandler);
  }, [phoneNumber, deviceUuid, targetApp, errorHandler]);
  const confirmCode = useCallback(() => {
    ClientAuthApi.confirmCode({ phoneNumber, deviceUuid, code }, targetApp)
      .then((authResponse) => {
        dispatch(setClientUserAction({ authResponse, deviceUuid, appType: targetApp }));
        closeDialog("logged_in");
      })
      .catch(errorHandler);
  }, [phoneNumber, deviceUuid, code, targetApp, closeDialog, dispatch, errorHandler]);
  const appThemes = useAppThemes();
  const Logo = appTypeToLogo[targetApp];
  return (
    <ThemeProvider theme={appThemes[targetApp]}>
      <DialogTitle>
        <FormattedMessage defaultMessage="Login as client user" /> <Logo height="12px" />
      </DialogTitle>
      <DialogContent>
        <br />
        <Stack direction="row" spacing="8px">
          <TextField
            sx={{ flex: 3 }}
            inputRef={phoneInputRef}
            size="small"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, "").substring(0, phoneNumberLength))}
            disabled={codeRequested}
            autoFocus
            label={<FormattedMessage defaultMessage="Phone number" />}
            onKeyDown={(e) => {
              if (e.code === "Enter" && phoneNumber.length === phoneNumberLength) {
                requestCode();
              }
            }}
          />
          <Button
            sx={{ flex: 2 }}
            variant="outlined"
            size="small"
            disabled={phoneNumber.length !== phoneNumberLength || codeRequested}
            onClick={requestCode}
          >
            <FormattedMessage defaultMessage="Request code" />
          </Button>
        </Stack>
        <br />
        <Stack direction="row" spacing="8px">
          <TextField
            sx={{ flex: 3 }}
            inputRef={codeInputRef}
            size="small"
            value={code}
            onChange={(e) => setCode(e.target.value.replace(/\D/g, "").substring(0, codeLength))}
            disabled={!codeRequested}
            label={<FormattedMessage defaultMessage="Validation code" />}
            onKeyDown={(e) => {
              if (e.code === "Enter" && code.length === codeLength) {
                confirmCode();
              }
            }}
          />
          <Button
            sx={{ flex: 2 }}
            variant="outlined"
            size="small"
            disabled={code.length !== codeLength || !codeRequested}
            onClick={confirmCode}
          >
            <FormattedMessage defaultMessage="Confirm code" />
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeDialog("cancel")}>
          <FormattedMessage defaultMessage="Dismiss" />
        </Button>
        <Button onClick={reset}>
          <FormattedMessage defaultMessage="Reset" />
        </Button>
      </DialogActions>
    </ThemeProvider>
  );
}
