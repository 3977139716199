import { AdminStatusType } from "@api/admin";
import { ProfileBadgesApi } from "@api/profileBadges";
import { SuspendableUserPrivilege, UserApi, UserFullDto } from "@api/user";
import AppType from "@models/AppType";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentUserAppType } from "@slices/user";
import { ThunkApiType } from "@store/store";
import { maxBy } from "lodash";

export interface ChangeUserAccountStatusPayload {
  userId: number;
  newAccountStatus: AdminStatusType;
}

const name = "user/common";

export interface ResumeUserPrivilegeActionPayload {
  user: UserFullDto;
  privileges: SuspendableUserPrivilege[];
  withPush: boolean;
}

export interface SuspendUserPrivilegeActionPayload extends ResumeUserPrivilegeActionPayload {
  until: Date;
  reason: string;
}

export const suspendUserPrivilegeAction = createAsyncThunk<
  { user: UserFullDto; appType: AppType },
  SuspendUserPrivilegeActionPayload,
  ThunkApiType
>(`${name}/suspendUserPrivilegeAction`, async ({ user, privileges, until, reason, withPush }, thunkApi) => {
  const appType = getCurrentUserAppType(thunkApi.getState());
  const payload = {
    userId: user.id!,
    privileges,
    until,
    reason,
    withPush,
  };

  if (!payload.privileges.includes(SuspendableUserPrivilege.DELETE_USER)) {
    const deleteUserBlockedUntil = user.suspendedPrivileges?.[SuspendableUserPrivilege.DELETE_USER];
    if (!deleteUserBlockedUntil || Date.parse(deleteUserBlockedUntil) < until.getTime()) {
      payload.privileges = [...payload.privileges, SuspendableUserPrivilege.DELETE_USER];
    }
  }
  const updatedUser = await UserApi.suspendPrivileges(payload);
  return { appType, user: updatedUser };
});

interface BlockedPrivilege {
  privilege: SuspendableUserPrivilege;
  until: number;
}

export const resumeUserPrivilegesAction = createAsyncThunk<
  { user: UserFullDto; appType: AppType },
  ResumeUserPrivilegeActionPayload,
  ThunkApiType
>(`${name}/resumeUserPrivilegesAction`, async ({ user, privileges, withPush }, thunkApi) => {
  const appType = getCurrentUserAppType(thunkApi.getState());
  const payload = {
    userId: user.id!,
    privileges,
    reason: "",
    withPush,
  };

  const blockedPrivileges = Object.entries(user.suspendedPrivileges || {})
    .filter(([, v]) => Date.parse(v) > Date.now())
    .map(([k]) => k as SuspendableUserPrivilege);
  const blockedPrivilegesAfterResume = blockedPrivileges.filter(
    (x) => x !== SuspendableUserPrivilege.DELETE_USER && !privileges.includes(x)
  );
  if (!blockedPrivilegesAfterResume.length && !payload.privileges.includes(SuspendableUserPrivilege.DELETE_USER)) {
    payload.privileges.push(SuspendableUserPrivilege.DELETE_USER);
  }
  const updatedUser = await UserApi.resumePrivileges(payload);
  const blockedPrivilegesFromUpdated = Object.entries(updatedUser.suspendedPrivileges || {})
    .map(([k, v]) => ({
      privilege: k as SuspendableUserPrivilege,
      until: Date.parse(v),
    }))
    .filter((x) => x.until > Date.now()) as BlockedPrivilege[];

  if (!blockedPrivilegesFromUpdated.length) {
    return { appType, user: updatedUser };
  }

  const maxBlockedPrivilege = maxBy(
    blockedPrivilegesFromUpdated.filter((x) => x.privilege !== SuspendableUserPrivilege.DELETE_USER),
    (x) => x.until
  );

  const deleteUserPrivilege = blockedPrivilegesFromUpdated.find(
    (x) => x.privilege === SuspendableUserPrivilege.DELETE_USER
  );
  if (!maxBlockedPrivilege) {
    if (!deleteUserPrivilege) {
      return { appType, user: updatedUser };
    }
    const userAfterResume = await UserApi.resumePrivileges({
      userId: user.id!,
      privileges: [SuspendableUserPrivilege.DELETE_USER],
      reason: `autoresuming DELETE_USER`,
      withPush: false,
    });
    return { appType, user: userAfterResume };
  }
  if (deleteUserPrivilege?.until !== maxBlockedPrivilege.until) {
    const userAfterAdjust = await UserApi.suspendPrivileges({
      userId: user.id!,
      privileges: [SuspendableUserPrivilege.DELETE_USER],
      reason: `adjusting DELETE_USER expiration to match ${maxBlockedPrivilege.privilege}`,
      until: new Date(maxBlockedPrivilege.until),
      withPush: false,
    });
    return { appType, user: userAfterAdjust };
  }
  return { appType, user: updatedUser };
});

export const assignProfileBadgesAction = createAsyncThunk<
  { user: UserFullDto; appType: AppType },
  { userId: number; badgeIds: number[] },
  ThunkApiType
>(`${name}/assignProfileBadgesAction`, async ({ userId, badgeIds }, thunkApi) => {
  const appType = getCurrentUserAppType(thunkApi.getState());
  const user = await ProfileBadgesApi.assignToUser(userId, badgeIds);
  return { appType, user };
});
