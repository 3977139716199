import { ClientAuthApi } from "@api/clientAuth";
import useDefaultNetworkErrorHandler from "@hooks/useDefaultNetworkErrorHandler";
import { appTypeToLogo } from "@models/AppIcons";
import AppType from "@models/AppType";
import { ListItemText, MenuItem } from "@mui/material";
import { removeClientUserAction } from "@slices/client";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { FormattedMessage } from "react-intl";

export default function ClientUserMenuItem({
  appType,
  onOpenClientLoginDialog,
}: {
  appType: AppType;
  onOpenClientLoginDialog: (targetApp: AppType) => void;
}) {
  const Logo = appTypeToLogo[appType];
  const client = useAppSelector((state) => state.client.users[appType]);
  const errorHandler = useDefaultNetworkErrorHandler();
  const dispatch = useAppDispatch();
  if (!client) {
    return (
      <MenuItem onClick={() => onOpenClientLoginDialog(appType)} data-testid={`add-${appType}-client-user-menu-item`}>
        <ListItemText>
          <FormattedMessage defaultMessage="Add user" />
          &nbsp;
          <Logo height="10px" />
        </ListItemText>
      </MenuItem>
    );
  }
  return (
    <MenuItem
      onClick={() => {
        if (client) {
          ClientAuthApi.logout(client.deviceUuid, client.token, appType).catch(errorHandler);
        }
        dispatch(removeClientUserAction(appType));
      }}
      data-testid={`logout-${appType}-client-user-menu-item`}
    >
      <ListItemText>
        <Logo height="10px" />: <b>{client.nickName}</b> (<FormattedMessage defaultMessage="Logout" />)
      </ListItemText>
    </MenuItem>
  );
}
