import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import OmniSearchBox from "./OmniSearchBox";

const OmniSearchDialog = ({ closeDialog }: { closeDialog: () => void }) => {
  const location = useLocation();
  const pathnameSearchRef = useRef(`${location.pathname}${location.search}`);
  useEffect(() => {
    const pathnameSearch = `${location.pathname}${location.search}`;
    if (pathnameSearchRef.current !== pathnameSearch) {
      closeDialog();
    }
    pathnameSearchRef.current = pathnameSearch;
  }, [location, closeDialog]);
  return (
    <Dialog open onClose={closeDialog} fullWidth maxWidth="sm" data-testid="omni-search-dialog">
      <DialogContent>
        <OmniSearchBox autoFocus fullWidth />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} data-testid="dismiss-button">
          <FormattedMessage defaultMessage="Dismiss" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OmniSearchDialog;
