import { ChildSortingType, OffsetLimit, Page, ResponseDto, SortingSortByOptional } from "@api";
import { GET, PATCH, POST, buildQuery } from "@utils/fetch";
import {
  AssetDto,
  BaseContentFilterType,
  ContentStatusType,
  ContentType,
  CreateUgcTagModel,
  DeviceInfo,
  MentionDto,
  NewAssetDto,
  PeriodDto,
  PlatformType,
  UploadResponseDto,
  filterAdapter,
} from "./contentCommon";
import { ContentModerationStatus, ContentSubtype } from "./contentModeration";
import { ThemeDto } from "./contentThemes";
import { InsideTagDto } from "./internalTags";
import { ShortTagDto } from "./tags";
import { UserShortDto } from "./user";

export interface SetContentIdOrdinalDto {
  contentId: number;
  ordinal: number;
}

export enum ThreadPrivacyLevel {
  PUBLIC = "PUBLIC",
  AUTHORS = "AUTHORS",
  PRIVATE_REPEAT = "PRIVATE_REPEAT",
  PRIVATE_THREAD = "PRIVATE_THREAD",
}

export interface ReactionDto {
  id: number;
  name: string;
  reactionUrl: string;
}

export interface ReactionsDto {
  count: number;
  reaction: ReactionDto;
}

export interface ContentShortChipzAdminDto {
  id: number;
  contentType?: ContentType;
  title?: string;
  description?: string;
  viewCount?: number;
  likeCount?: number;
  commentCount?: number;
  shareCount?: number;
  listAssets?: AssetDto[];
  tags?: ShortTagDto[];
  createdAt?: string;
  user?: UserShortDto;
  contentParentId?: number;
  isTest?: boolean;
  expiredAt?: string;
  isDraft?: boolean;
  contentStatus?: ContentStatusType;
  contentChildCount?: number | null;
  moderationStatus?: ContentModerationStatus;
  insideTags?: InsideTagDto[];
  deviceInfo?: DeviceInfo;
  periods?: PeriodDto[];
  descriptionModerated?: string;
  removedTags?: ShortTagDto[];
  ordinal?: number;
  marks?: string[];
  originalDescription?: string;
  replyPermissionType?: ThreadPrivacyLevel;
  reactionUniqueUsersCount?: number;
  reactions: ReactionsDto[];
  themes?: ThemeDto[];
  cover?: string;
}

export interface VideoUgcListFilterType extends BaseContentFilterType {
  contentParentId?: number | null;
  contentModerationStatus?: ContentModerationStatus | null;
  contentSubtype?: ContentSubtype | null;
  tagIds?: number[] | null;
}

export interface ThreadPrivacyDto {
  replyPermissionType: ThreadPrivacyLevel;
}

export interface CreateUgcVideoDto {
  tags?: CreateUgcTagModel[];
  contentParentId?: number;
  mentions?: MentionDto[];
  isDraft?: boolean;
  skipTextValidation?: boolean;
  isPrivateGroup?: boolean;
  description?: string;
  title?: string;
  assets: NewAssetDto[];
  isPrivate?: boolean;
  period?: PeriodDto;
  threadPrivacy?: ThreadPrivacyDto;
}

export interface UpdateUgcVideoDto {
  tags?: CreateUgcTagModel[];
  description?: string;
  threadPrivacy?: ThreadPrivacyDto;
}

export const VideoContentApi = {
  loadBatch: (contentIds: number[]): Promise<ContentShortChipzAdminDto[]> =>
    POST(`api/v1/admin/video/ugc/get-by-ids`, { contentIds }),
  list: ({
    sortBy = "CREATED_AT",
    sorting = ChildSortingType.DESC,
    filter,
    ...rest
  }: {
    filter?: VideoUgcListFilterType;
  } & OffsetLimit &
    SortingSortByOptional): Promise<ResponseDto<ContentShortChipzAdminDto>> =>
    GET(
      `api/v1/admin/video/ugc?${buildQuery({
        sortBy,
        sorting,
        ...rest,
        ...(filter && filterAdapter(filter)),
      })}`
    ),
  loadThreadChildren: ({
    parentId,
    page,
    size,
  }: {
    parentId: number;
    page: number;
    size: number;
  }): Promise<Page<number>> =>
    GET(
      `api/v1/admin/video/get-children-ids?${buildQuery({
        parentId,
        page,
        size,
        sort: "ordinal,asc",
      })}`
    ),
  saveChildrenOrdinals: (parentContentId: number, requests: SetContentIdOrdinalDto[]) =>
    PATCH(`api/v1/admin/content/${parentContentId}/child-order`, requests),
  createVideoContent: ({
    userId,
    request,
    devicePlatform,
    usePresignedUrls,
  }: {
    userId: number;
    request: CreateUgcVideoDto;
    devicePlatform: PlatformType;
    usePresignedUrls: boolean;
  }): Promise<UploadResponseDto> =>
    POST(`api/v1/admin/video/ugc?${buildQuery({ userId, devicePlatform, usePresignedUrls })}`, request),
  updateVideoContent: (contentId: number, request: UpdateUgcVideoDto): Promise<ContentShortChipzAdminDto> =>
    PATCH(`api/v1/admin/video/ugc/${contentId}`, request),
};
