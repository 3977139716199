import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import NavigationTabs from "@widgets/NavigationTabs";
import { ModerationLogViews, viewTabTitles } from "./types";

const tabs = Object.values(ModerationLogViews);

const ModerationLogsHeaderTitle = () => {
  const route = useCurrentRouteDescriptor();
  useSetDocumentTitle({ messageDescriptor: route!.title! });
  return <NavigationTabs possibleValues={tabs} tabQueryKey="view" tabMessagesMap={viewTabTitles} />;
};

export default ModerationLogsHeaderTitle;
