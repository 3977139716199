import ChipsLogoIcon from "@img/chipz_logo.svg";
import PunchLogoIcon from "@img/punch_logo.svg";
import AppType from "./AppType";

export const appTypeToLogo = {
  [AppType.PUNCH]: PunchLogoIcon,
  [AppType.CHIPZ]: ChipsLogoIcon,
};

export const PunchLogo = PunchLogoIcon;
export const ChipsLogo = ChipsLogoIcon;
