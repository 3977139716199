import { ConfigProperty, properties, usePropertyValue } from "@config";
import { SnackbarProvider } from "notistack";
import { PropsWithChildren } from "react";
import * as styles from "./AppSnackbarProvider.module.scss";

const iconOverrides = {
  success: <img className={styles.icon} src="/ny/glasses_animated.png" />,
  warning: <img className={styles.icon} src="/ny/champagne_animated.png" />,
  error: <img className={styles.icon} src="/ny/gift.png" />,
};

export default function AppSnackbarProvider({ children }: PropsWithChildren) {
  const autoHideDuration = usePropertyValue(properties[ConfigProperty.SNACKBAR_AUTOHIDE_DEFAULT_MILLIS]);
  const newYearModeOn = usePropertyValue(properties[ConfigProperty.NEW_YEAR_2024_MODE]);
  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={autoHideDuration}
      iconVariant={newYearModeOn ? iconOverrides : undefined}
    >
      {children}
    </SnackbarProvider>
  );
}
