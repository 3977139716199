import { ContentPromotionDto, ContentPromotionType } from "@api/contentPromotions";
import { UserRole } from "@api/user";

export enum UgcView {
  ugc = "ugc",
  promoted = "promoted",
  pinned = "pinned",
  onboarding = "onboarding",
}

export interface PromotedContentItemProps {
  role: UserRole;
  promotion: ContentPromotionDto;
  disableDrag: boolean;
  hidePromoInfo?: boolean;
  onEditPromotion?: (promotion: ContentPromotionDto) => void;
  onDeletePromotion: (promotion: ContentPromotionDto) => void;
  onRaiseOrLowerPromotion?: (promotion: ContentPromotionDto, action: "raise" | "lower") => void;
}

export const viewToContentPromotionType = {
  [UgcView.onboarding]: ContentPromotionType.WELCOME_BATCH,
  [UgcView.pinned]: ContentPromotionType.PINNED_IN_FIRST_BATCH,
  [UgcView.promoted]: ContentPromotionType.PROMOTED,
};
