import { getPersistentValueForKey, setKey } from "@slices/keyValueStorage";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useCallback } from "react";

const usePersistentState = <Type>({
  key,
  defaultValue,
}: {
  key: string;
  defaultValue: Type;
}): [Type, (value: Type) => void] => {
  const dispatch = useAppDispatch();
  const value = useAppSelector(getPersistentValueForKey<Type>(key, defaultValue));
  const setValue = useCallback(
    (value: Type) => {
      dispatch(setKey({ key, value, persistent: true }));
    },
    [dispatch, key]
  );
  return [value, setValue];
};

export default usePersistentState;
