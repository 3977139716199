import { ApplicationType, ChildSortingType, Page } from "@api";
import { POST, RequestContext, buildQuery } from "@utils/fetch";
import { ModerationPhrase } from "./moderationPhrases";

export enum ModerationContentSortType {
  DEFAULT = "DEFAULT",
  RATING = "RATING",
}

export enum CommentModerationStatus {
  NEW = "NEW",
  APPROVED = "APPROVED",
  DELETED = "DELETED",
  DELETED_HIDDEN = "DELETED_HIDDEN",
  DELETED_FOR_BLOCKED_USER = "DELETED_FOR_BLOCKED_USER",
}

export interface ModerationCommentsFilter {
  contentIds?: number[];
  userIds?: number[];
  parentIds?: number[];
  moderationStatuses?: CommentModerationStatus[];
  commentIds?: number[];
}

export interface ModerationComment {
  id: number;
  userId?: number;
  contentId?: number;
  createdAt?: string;
  deletedAt?: string;
  parentId?: number;
  moderationStatus?: CommentModerationStatus;
  applicationType?: ApplicationType;
  moderatorId?: number;
  moderationActor?: "MODERATOR" | "AUTO_APPROVE_EMOJI" | "AUTO_APPROVE_TRUSTED_COMMENTS";
}

export interface CommentTriggeredPhrase {
  startIdx: number;
  length: number;
  expression: ModerationPhrase;
}

export interface CommentBlacklistModerationLogEntry {
  id: string;
  commentId: number;
  text: string;
  triggeredPhrases: CommentTriggeredPhrase[];
  createdAt: string;
}

export interface SetCommentModerationStatusRequest {
  commentId: number;
  moderationStatus: CommentModerationStatus;
}

export interface BatchSetCommentModerationStatusRequest {
  batch: SetCommentModerationStatusRequest[];
}

export interface ModerationCommentUserStat {
  userId: number;
  moderatedCommentsCounts: Partial<Record<CommentModerationStatus, number>>;
}

const urlPrefix = `api/v1/admin/moderation-manager`;

export const CommentsModerationApi = {
  listForModeration: (
    {
      page,
      size,
      filter,
      sortBy,
      createdAtDirection,
      blacklistFirst,
    }: {
      page: number;
      size: number;
      filter: ModerationCommentsFilter;
      sortBy: ModerationContentSortType;
      createdAtDirection: ChildSortingType;
      blacklistFirst?: boolean;
    },
    requestContext?: RequestContext
  ): Promise<Page<ModerationComment>> =>
    POST(
      `${urlPrefix}/comments/list?${buildQuery({
        page,
        size,
        sortBy,
        createdAtDirection,
        blacklistFirst,
      })}`,
      filter,
      requestContext
    ),
  batchSetStatuses: (request: BatchSetCommentModerationStatusRequest): Promise<ModerationComment[]> =>
    POST(`${urlPrefix}/comments/status-batch`, request),
  loadBlacklistLog: (commentIds: number[]): Promise<Page<CommentBlacklistModerationLogEntry>> =>
    POST(`${urlPrefix}/comments/blacklist/log?${buildQuery({ page: 0, size: commentIds.length })}`, { commentIds }),
  loadModerationStatsByUserIds: (userIds: number[]): Promise<ModerationCommentUserStat[]> =>
    POST(`api/v1/admin/moderation-manager/comments/user-stats`, userIds),
};
