import AppType from "@models/AppType";
import { defaultForAllApps, newNumberParameter, newStringParameter } from "../builders";
import { ConfigProperty, PropertyGroupType } from "../types";

const group = PropertyGroupType.CONTENT_LABELLING;

export default {
  [ConfigProperty.CONTENT_LABELLING_SPECIAL_TAG_NAME]: newStringParameter({
    property: ConfigProperty.CONTENT_LABELLING_SPECIAL_TAG_NAME,
    description:
      "Специальный внутренний тег для разметки контента - может применяться на все видимые видео и вынесен на карточке видео отдельно",
    group,
    defaultValues: defaultForAllApps("Просмотрен модератором"),
  }),
  [ConfigProperty.CONTENT_LABELLING_SPECIAL_TAG_DISPLAY_NAME]: newStringParameter({
    property: ConfigProperty.CONTENT_LABELLING_SPECIAL_TAG_DISPLAY_NAME,
    description: "Укороченное имя для специального тега в разметке контента",
    group,
    defaultValues: defaultForAllApps("Просмотрен"),
  }),
  [ConfigProperty.CONTENT_LABELLING_INTERNAL_TAGS_PER_ROW]: newNumberParameter({
    property: ConfigProperty.CONTENT_LABELLING_INTERNAL_TAGS_PER_ROW,
    description: "Сколько тегов показывать на одной строке в разметке контента",
    group,
    defaultValues: {
      [AppType.PUNCH]: 4,
      [AppType.CHIPZ]: 5,
    },
  }),
  [ConfigProperty.CONTENT_LABELLING_INTERNAL_TAGS_NAME_SYMBOLS_LIMIT]: newNumberParameter({
    property: ConfigProperty.CONTENT_LABELLING_INTERNAL_TAGS_NAME_SYMBOLS_LIMIT,
    description: "До скольки символов сокращать имя внутреннего тега на карточке контента в разметке",
    group,
    defaultValues: {
      [AppType.PUNCH]: 100,
      [AppType.CHIPZ]: 3,
    },
  }),
};
