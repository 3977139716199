import { ThemeProvider } from "@emotion/react";
import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import { appTypeToLogo } from "@models/AppIcons";
import AppType from "@models/AppType";
import PushPinIcon from "@mui/icons-material/PushPin";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import StarIcon from "@mui/icons-material/Star";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import { getCurrentUserRole } from "@slices/user";
import { useAppSelector } from "@store/hooks";
import { rolesForPromotions } from "@utils/promotions";
import NavigationTabs from "@widgets/NavigationTabs";
import { FormattedMessage, defineMessages } from "react-intl";
import { useAppThemes } from "../../app/AppTheme";
import * as styles from "./UGCHeaderContent.module.scss";
import { UgcView } from "./models";

const messagesMap = defineMessages({
  [UgcView.ugc]: { defaultMessage: "Content" },
  [UgcView.onboarding]: { defaultMessage: "ONBOARDING", description: "ugc tab title" },
  [UgcView.pinned]: { defaultMessage: "PINNED", description: "ugc tab title" },
  [UgcView.promoted]: { defaultMessage: "PROMOTED", description: "ugc tab title" },
});

const iconsMap = {
  [UgcView.onboarding]: RocketLaunchIcon,
  [UgcView.pinned]: PushPinIcon,
  [UgcView.promoted]: StarIcon,
};

const tabValues = Object.values(UgcView);

const UGCHeaderContent = ({ appType }: { appType: AppType }) => {
  const route = useCurrentRouteDescriptor();
  const role = useAppSelector(getCurrentUserRole);
  const Logo = appTypeToLogo[appType];
  const appThemes = useAppThemes();
  const mainView = (
    <div className={styles.mainTitle}>
      <FormattedMessage {...messagesMap[UgcView.ugc]} />
      <Logo className={styles.logo} />
    </div>
  );
  useSetDocumentTitle({ messageDescriptor: route?.title });
  return (
    <ThemeProvider theme={appThemes[appType]}>
      {rolesForPromotions.includes(role) ? (
        <NavigationTabs
          possibleValues={tabValues}
          tabMessagesMap={messagesMap}
          tabQueryKey="view"
          iconsMap={iconsMap}
          tabLabels={{
            [UgcView.ugc]: mainView,
          }}
        />
      ) : (
        mainView
      )}
    </ThemeProvider>
  );
};

export const UGCHeaderContentFactory = (appType: AppType) => () => <UGCHeaderContent appType={appType} />;
