import { OffsetLimit, ResponseDto } from "@api";
import { DELETE, GET, POST, PUT, buildQuery, formDataWithFile } from "@utils/fetch";

export interface AwardImageDto {
  id: number;
  imageUrl: string;
}

export interface AwardOnContentDto {
  imageId: number;
  imageUrl: string;
  place: number;
}

export interface ApplyAwardToTagFeedRequestDto {
  contentId: number;
  place: number;
  awardImageId: number;
}

export const ContentAwardsApi = {
  loadAwardedContentOnTag: ({
    tagId,
    ...rest
  }: {
    tagId: number;
    authorId?: number | null;
  } & OffsetLimit): Promise<
    ResponseDto<{
      contentId: number;
      award: AwardOnContentDto;
    }>
  > => GET(`api/v1/admin/tags/${tagId}/content/awards?${buildQuery(rest)}`),
  loadAwardedContentOnAudioTrack: ({
    trackId,
    ...rest
  }: {
    trackId: number;
    authorId?: number | null;
  } & OffsetLimit): Promise<
    ResponseDto<{
      contentId: number;
      award: AwardOnContentDto;
    }>
  > => GET(`api/v1/admin/audio_tracks/${trackId}/content/awards?${buildQuery(rest)}`),
  uploadAwardImage: ({ image }: { image: File }): Promise<AwardImageDto> =>
    POST(`api/v1/admin/awards/images`, formDataWithFile("image", image)),
  updateAwardImage: ({ id, image }: { image: File; id: number }): Promise<AwardImageDto> =>
    POST(`api/v1/admin/awards/images/${id}`, formDataWithFile("image", image)),
  loadAwardImages: (request: OffsetLimit): Promise<ResponseDto<AwardImageDto>> =>
    GET(`api/v1/admin/awards/images?${buildQuery(request)}`),
  deleteAwardImage: (id: number) => DELETE(`api/v1/admin/awards/images/${id}`),
  pinContentOnTag: ({ tagId, ...request }: { tagId: number } & ApplyAwardToTagFeedRequestDto) =>
    PUT(`api/v1/admin/awards/tags/${tagId}`, request),
  unpinContentOnTag: ({ tagId, contentId }: { tagId: number; contentId: number }) =>
    DELETE(`api/v1/admin/awards/tags/${tagId}/content/${contentId}`),
  pinContentOnAudioTrack: ({ trackId, ...request }: { trackId: number } & ApplyAwardToTagFeedRequestDto) =>
    PUT(`api/v1/admin/awards/audio_tracks/${trackId}`, request),
  unpinContentOnAudioTrack: ({ trackId, contentId }: { trackId: number; contentId: number }) =>
    DELETE(`api/v1/admin/awards/audio_tracks/${trackId}/content/${contentId}`),
};
