import { useActIfNavigationAllowed } from "@hooks/useBlockNavigation";
import AppType, { appTypeToDisplayName } from "@models/AppType";
import { Badge, IconButton, Tooltip } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { NotificationData } from "@slices/moderationNotificator";
import { useAppSelector } from "@store/hooks";
import { groupBy } from "lodash";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useAppThemes } from "../AppTheme";
import * as styles from "./NotificationsWidget.module.scss";
import useEnsureValidModeratorUser from "./useEnsureValidModeratorUser";
import { notificationTypeToIcon, notificationTypeToMessage, routeToNotificationType } from "./utils";

const NotificationButton = ({ data }: { data: NotificationData }) => {
  const Icon = notificationTypeToIcon[data.contentType];
  const ensureValidUser = useEnsureValidModeratorUser();
  const navigate = useNavigate();
  const navigationGuard = useActIfNavigationAllowed();
  const onClick = useCallback(() => {
    navigationGuard(() => {
      ensureValidUser(data);
      navigate(routeToNotificationType[data.contentType]);
    });
  }, [ensureValidUser, navigate, data, navigationGuard]);
  return (
    <Tooltip
      title={
        <>
          {appTypeToDisplayName[data.appType]}
          {": "}
          <FormattedMessage {...notificationTypeToMessage[data.contentType]} />
        </>
      }
    >
      <Badge
        badgeContent={data.itemIds.length}
        variant="standard"
        data-testid={`notification-${data.appType}-${data.contentType}`}
      >
        <IconButton size="small" color="primary" onClick={onClick}>
          <Icon fontSize="inherit" />
        </IconButton>
      </Badge>
    </Tooltip>
  );
};

const ModerationNotificationsWidget = () => {
  const { loadedData, settings } = useAppSelector((state) => state.moderationNotificator);
  const { itemsToShow, groupedItems } = useMemo(() => {
    const itemsToShow = loadedData.filter((x) => x.itemIds.length > 0 && settings[x.contentType]?.enabled);
    return {
      itemsToShow,
      groupedItems: groupBy(itemsToShow, "appType"),
    };
  }, [loadedData, settings]);
  const appThemes = useAppThemes();
  if (!itemsToShow.length) {
    return null;
  }
  return (
    <div className={styles.buttonsContainer} data-testid="moderation-notifications-widget">
      {Object.values(AppType).map((appType) => {
        const items = groupedItems[appType];
        if (!items || !items.length) {
          return null;
        }
        return (
          <ThemeProvider key={appType} theme={appThemes[appType]}>
            {items.map((item) => (
              <NotificationButton key={`${item.appType}-${item.contentType}`} data={item} />
            ))}
          </ThemeProvider>
        );
      })}
    </div>
  );
};

export default ModerationNotificationsWidget;
