import { defaultForAllApps, newBooleanParameter } from "../builders";
import { ConfigProperty, PropertyGroupType } from "../types";

const group = PropertyGroupType.COMMENTS_MODERATION;

export default {
  [ConfigProperty.COMMENTS_MODERATION_SHOW_COMMENT_ID]: newBooleanParameter({
    property: ConfigProperty.COMMENTS_MODERATION_SHOW_COMMENT_ID,
    description: "Показывать айди комментариев в модерке комментов или нет",
    group,
    defaultValues: defaultForAllApps(false),
  }),
  [ConfigProperty.COMMENTS_MODERATION_DISTRUST_USERS_FLOW_ENABLED]: newBooleanParameter({
    property: ConfigProperty.COMMENTS_MODERATION_DISTRUST_USERS_FLOW_ENABLED,
    description: "Предлагать удалить пользователей из доверенных при удалении их комментариев",
    group,
    defaultValues: defaultForAllApps(true),
  }),
};
