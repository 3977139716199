import { ConfigProperty, properties, usePropertyValue } from "@config";
import { Tooltip } from "@mui/material";
import { toggleSnowflakesEnabledAction } from "@slices/appSettings";
import { useAppDispatch } from "@store/hooks";
import OmniSearchBox from "@widgets/OmniSearch/OmniSearchBox";
import { FormattedMessage } from "react-intl";
import ModerationNotificationsWidget from "../moderationNotificator/ModerationNotificationsWidget";
import * as styles from "./HeaderContent.module.scss";
import PageTitle from "./PageTitle";
import UserButton from "./UserButton";

const FurTree = () => {
  const newYearModeOn = usePropertyValue(properties[ConfigProperty.NEW_YEAR_2024_MODE]);
  const dispatch = useAppDispatch();
  if (!newYearModeOn) {
    return null;
  }
  return (
    <Tooltip title={<FormattedMessage defaultMessage="Можно выключить снежинки, если бесят :)" />}>
      <img
        src="/ny/tree_animated.png"
        className={styles.tree}
        onClick={() => dispatch(toggleSnowflakesEnabledAction())}
      />
    </Tooltip>
  );
};

const HeaderContent = () => (
  <>
    <FurTree />
    <PageTitle className={styles.title} />
    <ModerationNotificationsWidget />
    <OmniSearchBox className={styles.omniSearch} />
    <UserButton />
  </>
);

export default HeaderContent;
