import { ComplaintsApi } from "@api/complaints";
import { UserApi, UserFullDto } from "@api/user";
import { SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  assignProfileBadgesAction,
  resumeUserPrivilegesAction,
  suspendUserPrivilegeAction,
} from "@store/commonActions/userActions";
import { ThunkApiType } from "@store/store";
import { blockUserAction, loadWaitPunchCountersAction, unblockUserAction, updateUserField } from "./usersCache";

export interface ComplaintsStat {
  onThisUser: number;
  onOtherUsers: number;
  onOthersUgc: number;
  onOthersComments: number;
}

export interface UserProfileState {
  userId: number;
  userProfile: UserFullDto | null;
  complaintsStat: ComplaintsStat;
  error: SerializedError | null;
}

const defaultComplaintsStat: ComplaintsStat = {
  onThisUser: 0,
  onOtherUsers: 0,
  onOthersUgc: 0,
  onOthersComments: 0,
};

const initialState: UserProfileState = {
  userId: 0,
  userProfile: null,
  error: null,
  complaintsStat: defaultComplaintsStat,
};

const name = "userProfile";

export const loadUserProfile = createAsyncThunk<UserFullDto, number, ThunkApiType>(
  `${name}/loadProfile`,
  async (userId, thunkApi) => {
    const data = await UserApi.getById(userId);
    thunkApi.dispatch(loadWaitPunchCountersAction([userId]));
    return data;
  }
);

export const refreshUserComplaintsStat = createAsyncThunk(
  `${name}/refreshUserComplaintsStat`,
  async (userId: number) => {
    const [onThisUser, onOtherUsers, onOthersUgc, onOthersComments] = await Promise.all([
      ComplaintsApi.listOnUsers({ offset: 0, limit: 1, filter: { onUserId: userId } })
        .then(({ total }) => total || 0)
        .catch(() => 0),
      ComplaintsApi.listOnUsers({ offset: 0, limit: 1, filter: { fromUserId: userId } })
        .then(({ total }) => total || 0)
        .catch(() => 0),
      ComplaintsApi.listOnContent({ offset: 0, limit: 1, filter: { userId } })
        .then(({ total }) => total || 0)
        .catch(() => 0),
      ComplaintsApi.listOnComments({ offset: 0, limit: 1, filter: { userId } })
        .then(({ total }) => total || 0)
        .catch(() => 0),
    ]);
    return { onThisUser, onOtherUsers, onOthersUgc, onOthersComments };
  }
);

const userProfileSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadUserProfile.pending, (state, action) => {
      const userId = action.meta.arg;
      if (userId !== state.userId) {
        return { ...initialState, userId };
      }
      state.error = null;
      state.userId = userId;
    });
    builder.addCase(loadUserProfile.fulfilled, (state, action) => {
      state.userProfile = action.payload;
    });
    builder.addCase(loadUserProfile.rejected, (state, action) => {
      state.error = action.error;
    });
    builder.addCase(blockUserAction.fulfilled, (state, action) => {
      const userProfile = Object.values(action.payload).flatMap((x) => Object.values(x))[0];
      if (userProfile && action.meta.arg.userId === state.userId) {
        state.userProfile = userProfile;
      }
    });
    builder.addCase(unblockUserAction.fulfilled, (state, action) => {
      const userProfile = Object.values(action.payload).flatMap((x) => Object.values(x))[0];
      if (userProfile && action.meta.arg.userId === state.userId) {
        state.userProfile = userProfile;
      }
    });
    builder.addCase(refreshUserComplaintsStat.fulfilled, (state, action) => {
      if (state.userId !== action.meta.arg) {
        return state;
      }
      state.complaintsStat = action.payload;
    });
    builder.addCase(suspendUserPrivilegeAction.fulfilled, (state, action) => {
      if (state.userId !== action.meta.arg.user.id) {
        return;
      }
      if (action.payload.user) {
        state.userProfile = action.payload.user;
      }
    });
    builder.addCase(resumeUserPrivilegesAction.fulfilled, (state, action) => {
      if (state.userId !== action.meta.arg.user.id) {
        return;
      }
      if (action.payload.user) {
        state.userProfile = action.payload.user;
      }
    });
    builder.addCase(assignProfileBadgesAction.fulfilled, (state, action) => {
      if (state.userId !== action.meta.arg.userId) {
        return;
      }
      if (action.payload.user) {
        state.userProfile = action.payload.user;
      }
    });
    builder.addCase(updateUserField.fulfilled, (state, action) => {
      const users = Object.values(action.payload)
        .flatMap((x) => Object.values(x))
        .filter((x) => x.id === state.userId);
      if (users.length) {
        state.userProfile = users[0];
      }
    });
  },
});

export default userProfileSlice.reducer;
