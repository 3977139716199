import { AdminBeatTextDto, BeatTextsApi } from "@api/beatTexts";
import {
  BeatTextDto,
  BeatTextViolationLog,
  BeatTextsModerationApi,
  ManualBeatTextModerationStatus,
} from "@api/beatTextsModeration";
import AppType from "@models/AppType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ThunkApiType } from "@store/store";
import { mapValues, uniq } from "lodash";
import { defineMessage } from "react-intl";
import { addErrorToQueue } from "./rejectedErrorsQueue";
import { getCurrentUserAppType } from "./user";

export interface BeatTextsState {
  contentIdToBeatTextId: Record<number, number>;
  beatTexts: Record<number, AdminBeatTextDto>;
  beatTextsModerationData: Record<number, BeatTextDto>;
  beatTextsVioliations: Record<number, BeatTextViolationLog>;
}

const initialState: BeatTextsState = {
  contentIdToBeatTextId: {},
  beatTexts: {},
  beatTextsModerationData: {},
  beatTextsVioliations: {},
};

const name = "beatTexts";

const loadBeatTextsViolationsAction = createAsyncThunk<BeatTextViolationLog[], number[], ThunkApiType>(
  `${name}/loadBeatTextsViolationsAction`,
  async (textIds) => {
    const { violations } = await BeatTextsModerationApi.getViolations(textIds);
    return violations ?? [];
  }
);

const loadBeatTextsModerationDataAction = createAsyncThunk<BeatTextDto[], number[], ThunkApiType>(
  `${name}/loadBeatTextsModerationDataAction`,
  async (textIds) => {
    const response = await BeatTextsModerationApi.list({
      filter: { textIds },
      pagination: { page: 0, size: textIds.length },
    });
    return response.content ?? [];
  }
);

export const loadBeatTextsByContentIdsAction = createAsyncThunk<
  Record<number, AdminBeatTextDto>,
  { contentIds: number[]; withModerationData: boolean },
  ThunkApiType
>(
  `${name}/loadBeatTextsByContentIdsAction`,
  async ({ contentIds, withModerationData }, thunkApi) => {
    const texts = await BeatTextsApi.getByContentIds(uniq(contentIds));
    if (texts === null) {
      return {};
    }
    if (withModerationData) {
      const textIds = uniq(Object.values(texts).map((x) => x.id));
      if (textIds.length) {
        thunkApi.dispatch(loadBeatTextsModerationDataAction(textIds));
        thunkApi.dispatch(loadBeatTextsViolationsAction(textIds));
      }
    }
    return texts;
  },
  {
    condition: ({ contentIds }, thunkApi) => {
      const state = thunkApi.getState();
      return contentIds.length > 0 && getCurrentUserAppType(state) === AppType.PUNCH;
    },
  }
);

export const setBeatTextModerationStatusAction = createAsyncThunk<
  BeatTextDto | undefined,
  { textId: number; moderationStatus: ManualBeatTextModerationStatus },
  ThunkApiType
>(
  `${name}/setBeatTextModerationStatusAction`,
  async ({ textId, moderationStatus }, thunkApi) => {
    const current = thunkApi.getState().beatTexts.beatTextsModerationData[textId];
    const response = await BeatTextsModerationApi.setStatuses({
      batch: [{ textId, manualModerationStatus: moderationStatus, version: current?.version ?? 0 }],
    });
    if (response.changedTexts?.length) {
      const dto = response.changedTexts[0];
      thunkApi.dispatch(
        addErrorToQueue({
          localizedError: {
            message: defineMessage({
              defaultMessage:
                "Version mismatch for text {id}, known version: {oldVersion}, actual version: {newVersion}",
            }),
            values: { id: textId, oldVersion: current?.version, newVersion: dto.version },
          },
        })
      );
      return dto;
    }
    if (response.moderatedTexts?.length) {
      return response.moderatedTexts[0];
    }
  },
  {
    condition: ({ textId }, thunkApi) => !!thunkApi.getState().beatTexts.beatTextsModerationData[textId],
  }
);

const beatTextsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadBeatTextsByContentIdsAction.fulfilled, (state, action) => {
      Object.assign(
        state.contentIdToBeatTextId,
        mapValues(action.payload, (x) => x.id)
      );
      Object.assign(
        state.beatTexts,
        Object.values(action.payload).reduce(
          (a, x) => {
            a[x.id] = x;
            return a;
          },
          {} as Record<number, AdminBeatTextDto>
        )
      );
    });
    builder.addCase(setBeatTextModerationStatusAction.fulfilled, (state, action) => {
      if (action.payload) {
        state.beatTextsModerationData[action.payload.textId] = action.payload;
      }
    });
    builder.addCase(loadBeatTextsModerationDataAction.fulfilled, (state, action) => {
      Object.assign(
        state.beatTextsModerationData,
        action.payload.reduce(
          (a, x) => {
            a[x.textId] = x;
            return a;
          },
          {} as Record<number, BeatTextDto>
        )
      );
    });
    builder.addCase(loadBeatTextsViolationsAction.fulfilled, (state, action) => {
      Object.assign(
        state.beatTextsVioliations,
        action.payload.reduce(
          (a, x) => {
            a[x.textId] = x;
            return a;
          },
          {} as Record<number, BeatTextViolationLog>
        )
      );
    });
  },
});

export default beatTextsSlice.reducer;
