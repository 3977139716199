import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import { IconButton, Tooltip } from "@mui/material";

import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import HideImageIcon from "@mui/icons-material/HideImage";
import TuneIcon from "@mui/icons-material/Tune";
import DefaultHeaderTitle from "@navigation/DefaultHeaderTitle";
import {
  defaultCategoryProportion,
  setCategoriesProportionAction,
  toggleProportionSliderAction,
} from "@slices/userInfoModeration";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";

const UserInfoModerationHeader = () => {
  const proportion = useAppSelector((state) => state.userInfoModeration.categoriesProportion);
  const showSlider = useAppSelector((state) => state.userInfoModeration.showProportionSlider);
  const routeDescriptor = useCurrentRouteDescriptor();
  const dispatch = useAppDispatch();
  const onSettingsClick = useCallback(() => {
    dispatch(toggleProportionSliderAction());
  }, [dispatch]);
  const onHideImagesClick = useCallback(() => {
    dispatch(setCategoriesProportionAction(0));
  }, [dispatch]);
  const onHideTextsClick = useCallback(() => {
    dispatch(setCategoriesProportionAction(100));
  }, [dispatch]);
  const onResetProportionsClist = useCallback(() => {
    dispatch(setCategoriesProportionAction(defaultCategoryProportion));
  }, [dispatch]);
  return (
    <>
      <DefaultHeaderTitle titleDescriptor={routeDescriptor!.title!} />
      &emsp;
      <Tooltip placement="bottom" title={<FormattedMessage defaultMessage="Show/hide proportions slider" />}>
        <IconButton
          onClick={onSettingsClick}
          color={showSlider ? "warning" : undefined}
          data-testid="toggle-proportions-slider-button"
        >
          <TuneIcon />
        </IconButton>
      </Tooltip>
      <Tooltip placement="bottom" title={<FormattedMessage defaultMessage="Show only images" />}>
        <IconButton
          onClick={onHideTextsClick}
          color={proportion === 100 ? "primary" : undefined}
          data-testid="show-only-images-button"
        >
          <FormatStrikethroughIcon />
        </IconButton>
      </Tooltip>
      <Tooltip placement="bottom" title={<FormattedMessage defaultMessage="Show only texts" />}>
        <IconButton
          onClick={onHideImagesClick}
          color={proportion === 0 ? "primary" : undefined}
          data-testid="show-only-texts-button"
        >
          <HideImageIcon />
        </IconButton>
      </Tooltip>
      <Tooltip placement="bottom" title={<FormattedMessage defaultMessage="Restore default proportions" />}>
        <IconButton
          onClick={onResetProportionsClist}
          color={proportion === defaultCategoryProportion ? "primary" : undefined}
          data-testid="reset-proportions-button"
        >
          <AlignHorizontalLeftIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default UserInfoModerationHeader;
