import { useAppSelector } from "@store/hooks";
import UserShortUrlButton from "@widgets/UserShortUrlButton";
import { useMemo } from "react";
import { defineMessage, FormattedMessage } from "react-intl";
import useSetDocumentTitle from "./useSetDocumentTitle";

const noNameMessage = defineMessage({ defaultMessage: "User Profile" });
const nameMessage = defineMessage({ defaultMessage: "{nickName}'s Profile" });

const UserProfileHeaderTitle = () => {
  const nickName = useAppSelector((state) => state.userProfile.userProfile?.nickName || "");
  const intlValues = useMemo(() => ({ nickName }), [nickName]);
  useSetDocumentTitle({ messageDescriptor: nickName ? nameMessage : noNameMessage, values: intlValues });
  if (nickName) {
    return (
      <>
        <FormattedMessage {...nameMessage} values={{ nickName }} /> <UserShortUrlButton nickname={nickName} />
      </>
    );
  }
  return <FormattedMessage {...noNameMessage} />;
};

export default UserProfileHeaderTitle;
