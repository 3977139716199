import { ResponseDto } from "@api";
import { AdminApi, AdminDto } from "@api/admin";
import { UserRole } from "@api/user";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkApiType } from "@store/store";

export interface AdminsState {
  allAdmins: AdminDto[];
  allAdminsRequestInProgress: boolean;
}

const initialState: AdminsState = {
  allAdmins: [],
  allAdminsRequestInProgress: false,
};

const name = "admins";

export const loadAllAdminsAction = createAsyncThunk<ResponseDto<AdminDto>, void, ThunkApiType>(
  `${name}/loadAllAdmins`,
  async () => {
    const data = await AdminApi.list({
      offset: 0,
      limit: 10000,
    });
    return data;
  },
  { condition: (_, thunkApi) => !thunkApi.getState().admins.allAdminsRequestInProgress }
);

const adminsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadAllAdminsAction.pending, (state) => {
      state.allAdminsRequestInProgress = true;
    });
    builder.addCase(loadAllAdminsAction.fulfilled, (state, action) => {
      state.allAdminsRequestInProgress = false;
      state.allAdmins = action.payload.content || [];
    });
    builder.addCase(loadAllAdminsAction.rejected, (state) => {
      state.allAdminsRequestInProgress = false;
    });
  },
});

export default adminsSlice.reducer;

export const getAllAdminsMap = createSelector(
  (state: RootState) => state.admins.allAdmins,
  (admins) =>
    admins.reduce((a, x) => {
      if (x.id) {
        a[x.id] = x;
      }
      return a;
    }, {} as Record<number, AdminDto>)
);

export const getAdminById = (id: number | null | undefined) => (state: RootState) => {
  if (typeof id !== "number") {
    return undefined;
  }
  return getAllAdminsMap(state)[id];
};

export const getModeratorsListSelector = createSelector(
  (state: RootState) => state.admins.allAdmins,
  (admins) => admins.filter((x) => x.role === UserRole.MODERATOR || x.role === UserRole.SUPER_MODERATOR)
);
