import { UserRole } from "@api/user";
import useActualizedRef from "@hooks/useActualizedRef";
import AppType from "@models/AppType";
import { activateUser, LoggedInUser } from "@slices/user";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useCallback } from "react";

const validUserRoles = [UserRole.MODERATOR, UserRole.SUPER_MODERATOR];
const isValidUser = (user: LoggedInUser | null, appType: AppType) =>
  user && validUserRoles.includes(user.role) && appType === user.appType;

const findValidUser = (users: LoggedInUser[], appType: AppType) => users.find((x) => isValidUser(x, appType));

const useEnsureValidModeratorUser = () => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state) => state.user);
  const userStateRef = useActualizedRef(userState);
  const ensure = useCallback(
    ({ appType }: { appType: AppType }) => {
      const userState = userStateRef.current;
      if (!userState) {
        return;
      }
      const { currentUser, availableUsers } = userState;
      if (!isValidUser(currentUser, appType)) {
        const validUser = findValidUser(availableUsers, appType);
        if (validUser) {
          dispatch(activateUser(validUser));
        }
      }
    },
    [userStateRef, dispatch]
  );
  return ensure;
};

export default useEnsureValidModeratorUser;
