import AppType from "@models/AppType";
import { RootState } from "@store/store";
import { DurationUnit } from "@utils/duration";
import { MessageDescriptor, defineMessages } from "react-intl";

/** recognized properties go here, key is smth readable, value is actual property name */
export enum ConfigProperty {
  COMMENTS_MODERATION_SHOW_COMMENT_ID = "comments.moderation.show.comment.id",
  COMMENTS_MODERATION_DISTRUST_USERS_FLOW_ENABLED = "comments.moderation.distrust.users.flow.enabled",
  CONTENT_PROMOTION_GROUPS_LIST = "content.promotion.groups.list",
  CONTENT_PROMOTION_GROUPS_DISPLAY_NAMES = "content.promotion.groups.display.names",
  CONTENT_PROMOTION_PROMOTED_DEFAULT_DURATION = "content.promotion.promoted.default.duration",
  CONTENT_PROMOTION_PROMOTED_PROMOTE_TO_TOP = "content.promotion.promoted.promote.to.top",
  SUPERVISED_BADGES_HIGHLIGHT_COLORS = "supervised.badges.highlight.colors",
  VIDEO_MODERATION_IMPORTANT_SUPERVISED_BADGES = "video.moderation.supervised.badges.important",
  VIDEO_MODERATION_HIDDEN_INTERNAL_TAGS = "video.moderation.hidden.internal.tags",
  VIDEO_MODERATION_RECOMMEND_TO_FEATURE_INTERNAL_TAG = "video.moderation.recommend.to.feature.internal.tag",
  VIDEO_MODERATION_DISTRUST_USERS_FLOW_ENABLED = "video.moderation.distrust.users.flow.enabled",
  VIDEO_MODERATION_DELAY_BEFORE_TAGGING_SECONDS = "video.moderation.delay.before.tagging.seconds",
  VIDEO_MODERATON_DESCRIPTION_BLACKLIST_VIOLATIONS_HIGHLIGHT_ENABLED = "video.moderation.description.blacklist.violations.highlight.enabled",
  VIDEO_MODERATION_THREAD_PAGE_SIZE = "video.moderation.thread.page.size",
  VIDEO_MODERATION_BLOCK_NAVIGATION_ON_UNMODERATED_TEXTS = "video.moderation.block.navigation.on.unmoderated.texts",
  MODERATION_NOTIFICATIONS_ENABLED = "moderation.notifications.enabled",
  MODERATION_NOTIFICATIONS_UNCHECKED_VIDEOS_THRESHOLD = "moderation.notifications.unchecked.videos.threshold",
  MODERATION_NOTIFICATIONS_UNCHECKED_COMMENTS_THRESHOLD = "moderation.notifications.unchecked.comments.threshold",
  MODERATION_NOTIFICATIONS_UNCHECKED_COMPLAINTS_THRESHOLD = "moderation.notifications.unchecked.complaints.threshold",
  MODERATION_NOTIFICATIONS_POLLING_PERIOD_SECONDS = "moderation.notifications.polling.period.seconds",
  MODERATION_NOTIFICATIONS_REQUEST_PAGE_SIZE = "moderation.notifications.request.page.size",
  CONTENT_LABELLING_SPECIAL_TAG_NAME = "content.labelling.special.tag.name",
  CONTENT_LABELLING_SPECIAL_TAG_DISPLAY_NAME = "content.labelling.special.tag.display.name",
  CONTENT_LABELLING_INTERNAL_TAGS_PER_ROW = "content.labelling.internal.tags.per.row",
  CONTENT_LABELLING_INTERNAL_TAGS_NAME_SYMBOLS_LIMIT = "content.labelling.internal.tags.name.symbols.limit",
  AUTH_AVAILABLE_COUNTRY_CODES = "auth.available.country.codes",
  MANUAL_NOTIFICATIONS_DEFAULT_SENDER_ID = "manual.notifications.default.sender.id",
  BEATS_MODERATION_BPM_TONE_SUGGESTION_POLLING_INTERVAL_MILLIS = "beats.moderation.bpm.tone.suggestion.polling.interval.ms",
  BEATS_UPLOAD_DEFAULT_USER_ID = "beats.upload.default.user.id",
  BEATS_UPLOAD_CHECK_NAME_UNIQUENESS = "beats.upload.check.name.uniqueness",
  SUPERVISED_BADGES_GROUPS_FOR_SEPARATE_COLUMNS = "supervised.badges.groups.for.separate.columns",
  DEEPLINK_URL_BASE = "deeplink.constructor.url.base",
  SNACKBAR_AUTOHIDE_DEFAULT_MILLIS = "snackbar.autohide.default.millis",
  SNACKBAR_AUTOHIDE_ERROR_MILLIS = "snackbar.autohide.error.millis",
  VERIFICATION_CODES_SEARCH_ENABLED = "verification.codes.search.enabled",
  EXPLORE_BEATS_TOP_OF_WEEK_PERIOD_DAYS = "explore.beats.top_of_week.period.days",
  EXPLORE_BEATS_BEATS_OF_MONTH_PERIOD_DAYS = "explore.beats.beats_of_month.period.days",
  EXPLORE_BEATS_TOP_CHART_PERIOD_DAYS = "explore.beats.top_chart.period.days",
  EXPLORE_BEATS_TOP_OF_WEEK_LIMIT = "explore.beats.top_of_week.limit",
  EXPLORE_BEATS_BEATS_OF_MONTH_LIMIT = "explore.beats.beats_of_month.limit",
  EXPLORE_BEATS_TOP_CHART_LIMIT = "explore.beats.top_chart.limit",
  EXPLORE_ADD_OBJECTS_TO_END = "explore.add.objects.to.end",
  EXPLORE_NOTIFICATION_TEMPLATES_FOR_COMPILATION = "explore.notification.templates.for.compilation",
  EXPLORE_NOTIFICATION_TEMPLATES_FOR_USER = "explore.notification.templates.for.user",
  EXPLORE_NOTIFICATION_TEMPLATES_FOR_BEAT = "explore.notification.templates.for.beat",
  NEW_YEAR_2024_MODE = "new.year.2024.mode",
  /** temporary killswitches go here */
  KS_COMMENTS_MODERATION_USER_STAT_RELEASED = "comments.moderation.user.stat.released",
  KS_SUPER_COMPILATIONS_RELEASED = "compilations.of.compilations.released",
  KS_EXTERNAL_PLATFORM_GROUPS_RELEASED = "external.platform.groups.released",
  KS_COVER_TEXTS_MODERATION_RELEASED = "cover.texts.moderation.released",
  KS_EXPLORE_SECTION_BUTTONS_MY_TEXT_TYPE_RELEASED = "explore.section.buttons.my_text.type.released",
  KS_BEAT_COMPILATIONS_ATTRIBUTES_RELEASED = "beat.compilations.attributes.released",
}

export enum PropertyValueType {
  BOOLEAN = "BOOLEAN",
  STRING_LIST = "STRING_LIST",
  MAP = "MAP",
  PREDEFINED_KEYS_MAP = "PREDEFINED_KEYS_MAP",
  STRING = "STRING",
  DURATION = "DURATION",
  NUMBER = "NUMBER",
  USER_ID = "USER_ID",
}

export enum PropertyGroupType {
  KILLSWITCH = "KILLSWITCH",
  OTHERS = "OTHERS",
  VIDEO_MODERATION = "VIDEO_MODERATION",
  COMMENTS_MODERATION = "COMMENTS_MODERATION",
  CONTENT_PROMOTION = "CONTENT_PROMOTION",
  MODERATION_NOTIFICATIONS = "MODERATION_NOTIFICATIONS",
  CONTENT_LABELLING = "CONTENT_LABELLING",
  EXPLORE = "EXPLORE",
}

export const PropertyGroupTypeTranslactions: Record<PropertyGroupType, MessageDescriptor> = defineMessages({
  [PropertyGroupType.VIDEO_MODERATION]: { defaultMessage: "Video Moderation" },
  [PropertyGroupType.COMMENTS_MODERATION]: { defaultMessage: "Comments Moderation" },
  [PropertyGroupType.CONTENT_PROMOTION]: { defaultMessage: "Content promoting" },
  [PropertyGroupType.OTHERS]: { defaultMessage: "Others" },
  [PropertyGroupType.MODERATION_NOTIFICATIONS]: { defaultMessage: "Moderation notifications" },
  [PropertyGroupType.CONTENT_LABELLING]: { defaultMessage: "Content labelling" },
  [PropertyGroupType.KILLSWITCH]: { defaultMessage: "Killswitches" },
  [PropertyGroupType.EXPLORE]: { defaultMessage: "Explore" },
});

type Duration = Partial<Record<DurationUnit, number>>;
export type AllowedValueTypes = string | string[] | number[] | Record<string, string> | number | boolean | Duration;

export interface ConfigPropertyDescriptor<Type extends AllowedValueTypes> {
  property: ConfigProperty;
  valueType: PropertyValueType;
  group: PropertyGroupType;
  backendTicket: string;
  backendRelease: string;
  description: string;
  defaultValues: Partial<Record<AppType, Type>>;
  selector: (state: RootState) => Type;
  selectorImpl: (runtimeConfig: Record<string, string>, appType: AppType | undefined) => Type;
  /**
   * should be used only in admin configuration page,
   * in case default value format is vastly different from what is actually stored in property
   * (e.g. Duration properties)
   */
  defaultValueSerializer: (value: Type) => string;
  editorProps?: MapWithPredefinedKeysEditorProps | any; // add more here
}

export enum ExploreNotificationMapKey {
  ONLY_PUSH = "ONLY_PUSH",
  GROUPING_ONLY_PUSH = "GROUPING_ONLY_PUSH",
  ONLY_NOTIFICATON = "ONLY_NOTIFICATON",
  GROUPING_ONLY_NOTIFICATON = "GROUPING_ONLY_NOTIFICATON",
  PUSH_AND_NOTIFICATION = "PUSH_AND_NOTIFICATION",
  GROUPING_PUSH_AND_NOTIFICATION = "GROUPING_PUSH_AND_NOTIFICATION",
}

export interface MapWithPredefinedKeysEditorProps {
  possibleKeys: string[];
  valueType: "string" | "number";
}
