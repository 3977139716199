import { GET, POST } from "@utils/fetch";
import { camelCase, mapKeys, snakeCase } from "lodash";
import { GenreAdminDto } from "./genres";
import { MoodDto } from "./moods";

export enum StyleType {
  ILLUSTRATION = "illustration",
  PHOTOGRAPHY = "photography",
  THREE_D_RENDER = "3d render",
  PAINTING = "painting",
  PHOTOREALISM = "photorealism",
}

export enum TaskStatus {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  NOT_FOUND = "NOT_FOUND",
}

export interface CreateGenerationTaskDto {
  beatTitle: string;
  content: string;
  style: StyleType;
  mood: MoodDto[];
  genre: GenreAdminDto[];
  punchify: boolean;
}

export interface GenerateTaskResponse {
  taskId: string;
}

export interface GenerationTaskStatusDto {
  taskId: string;
  taskStatus: TaskStatus;
  taskResult: Record<string, string>;
}

const toSnakeCase = (source: object): object => mapKeys(source, (_, key) => snakeCase(key));

const fromSnakeCase = <Type>(source: object) => mapKeys(source, (_, key) => camelCase(key)) as unknown as Type;

const replacementMap: Record<string, string> = {
  "Кальянный Рэп": "relax rap",
  Лирика: "lyrical",
  Шансон: "chanson",
  Акустика: "acoustic",
  Гитара: "guitar",
  Пианино: "piano",
  Кальян: "hookah",
  "Дворовая Лирика": "Yard Lyrica",
  КАВЁР: "cover",
};

const processGenreName = (genreName: string): string | null => {
  if (/[а-яА-ЯЁё]/.test(genreName)) {
    if (replacementMap[genreName]) {
      return replacementMap[genreName];
    }
    // eslint-disable-next-line no-console
    console.error(`No replacement for ${genreName} found`, replacementMap);
    return null;
  }
  return genreName;
};

export const CoverGeneratorApi = {
  createTask: (request: CreateGenerationTaskDto): Promise<GenerateTaskResponse> =>
    POST<any, any>(
      `api/v1/admin/cover-generator/tasks`,
      toSnakeCase({
        ...request,
        genre: request.genre
          .map((x) => x.name)
          .map((x) => (x ? processGenreName(x) : null))
          .filter((x) => x),
        mood: request.mood.map((x) => x.name),
      })
    ).then((data) => fromSnakeCase(data)),
  checkTaskStatus: (taskId: string): Promise<GenerationTaskStatusDto> =>
    GET<any>(`api/v1/admin/cover-generator/tasks/${taskId}`).then((data) => fromSnakeCase(data)),
};
