import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import * as styles from "./FullscreenLoading.module.scss";

const FullscreenLoading = ({ delayMs = 200 }: { delayMs?: number }) => {
  const [render, setRender] = useState(false);
  useEffect(() => {
    const timeoutHandle = setTimeout(() => {
      setRender(true);
    }, delayMs);
    return () => clearTimeout(timeoutHandle);
  }, [delayMs]);
  if (!render) {
    return null;
  }
  return (
    <div className={styles.root}>
      <CircularProgress size="large" />
    </div>
  );
};

export default FullscreenLoading;
