import { Container, CssBaseline } from "@mui/material";
import store, { persistor } from "@store/store";
import ClientCommentsController from "@widgets/ClientComments/ClientCommentsController";
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import "react-medium-image-zoom/dist/styles.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./app/App";
import AppLocalicationProvider from "./app/AppLocalizationProvider";
import AppSnackbarProvider from "./app/AppSnackbarProvider";
import AppTheme from "./app/AppTheme";
import RejectedErrorsReporter from "./app/RejectedErrorsReporter";
import SnowfallProvider from "./app/SnowfallProvider";
import ModerationNotificationManager from "./app/moderationNotificator/ModerationNotificationManager";
import "./index.module.scss";
const ModalController = lazy(() => import("./app/modalController/ModalController"));

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Container maxWidth={false} disableGutters>
    <Provider store={store}>
      <PersistGate loading={<div>...</div>} persistor={persistor}>
        <AppTheme>
          <AppLocalicationProvider>
            <AppSnackbarProvider>
              <CssBaseline />
              <RejectedErrorsReporter />
              <BrowserRouter>
                <App />
                <ModerationNotificationManager />
                <ClientCommentsController />
                <SnowfallProvider />
                <Suspense>
                  <ModalController />
                </Suspense>
              </BrowserRouter>
            </AppSnackbarProvider>
          </AppLocalicationProvider>
        </AppTheme>
      </PersistGate>
    </Provider>
  </Container>
);
