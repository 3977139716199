import { ChildSortingType, Page, Pageable } from "@api";
import { ContentStatusType } from "@api/contentCommon";
import { DELETE, GET, PATCH, POST, buildQuery } from "@utils/fetch";
import { BatchSetContentTagsRequest, SetContentTagsRequest } from "./contentModeration";

export interface UploadUserAgreementResponse {
  shortUserAgreementUrl: string;
  fullUserAgreementUrl: string;
}

export interface SaveBeatProductionTagRequest {
  id?: string;
  name: string;
  description: string;
}

export enum BeatProductionTagStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface BeatProductionTagDto {
  id: string;
  name: string;
  description: string;
  status: BeatProductionTagStatus;
}

export enum BeatAssignedStatus {
  NEW = "NEW",
  APPROVED = "APPROVED",
  HIDDEN = "HIDDEN",
  DELETED = "DELETED",
}

export enum ControlStatus {
  NEW = "NEW",
  UPLOAD_FINISHED = "UPLOAD_FINISHED",
  AVAILABLE = "AVAILABLE",
  REVOKED = "REVOKED",
  UNPUBLISHED = "UNPUBLISHED",
  UNPUBLISHED_FULL = "UNPUBLISHED_FULL",
}

export enum TextControlStatus {
  NEW = "NEW",
  PENDING = "PENDING",
  AVAILABLE = "AVAILABLE",
  UNPUBLISHED = "UNPUBLISHED",
}

export interface CreateBeatProductionTagMappingRequest {
  tagMappingId?: string | undefined;
  tagId: string;
  beatAssignedStatus: BeatAssignedStatus;
}

export interface BeatProductionTagStatusMappingDto {
  id: string;
  beatAssignedStatus: BeatAssignedStatus;
  tag: BeatProductionTagDto;
}

export interface BeatProductionContentDto {
  contentId: number;
  authorId?: number;
  status: ContentStatusType;
  moderationStatus: BeatAssignedStatus;
  statusAt?: string;
  createdAt?: string;
  deletedAt?: string;
}

export interface BeatProductionContentFilter {
  contentStatuses?: ContentStatusType[];
  moderationStatuses?: BeatAssignedStatus[];
  controlStatuses?: ControlStatus[];
  coverTextsControlStatuses?: TextControlStatus[];
}

export interface BeatBpmToneSuggestionDto {
  contentId: number;
  suggestionStatus: "NEW" | "IN_PROGRESS" | "FAILED" | "COMPLETE";
  bpm?: number;
  tone?: string;
}

const urlPrefix = "api/v1/admin/beat-production";

export const BeatsModerationApi = {
  uploadUserAgreement: ({
    fullUserAgreement,
    shortUserAgreement,
  }: {
    fullUserAgreement: File;
    shortUserAgreement: File;
  }): Promise<UploadUserAgreementResponse> => {
    const formData = new FormData();
    formData.append("fullUserAgreement", fullUserAgreement);
    formData.append("shortUserAgreement", shortUserAgreement);
    return POST(`api/v1/admin/audio/user_agreement`, formData);
  },
  createOrUpdateTag: (request: SaveBeatProductionTagRequest): Promise<BeatProductionTagDto> =>
    POST(`${urlPrefix}/tag`, request),
  listTags: (): Promise<BeatProductionTagDto[]> => GET(`${urlPrefix}/tag/list`),
  deleteTag: (id: string) => DELETE(`${urlPrefix}/tag/${id}`),
  saveTagsOrder: (orderedTagIds: string[]) => POST(`${urlPrefix}/tag/set-order`, { orderedTagIds }),
  setTagStatus: (tagId: string, status: BeatProductionTagStatus) =>
    POST(`${urlPrefix}/tag/set-status`, { tagId, status }),
  loadTagMappings: (): Promise<BeatProductionTagStatusMappingDto[]> => GET(`${urlPrefix}/tag-mapping/list`),
  deleteTagMapping: (id: string) => DELETE(`${urlPrefix}/tag-mapping/${id}`),
  createOrUpdateTagMapping: (
    request: CreateBeatProductionTagMappingRequest
  ): Promise<BeatProductionTagStatusMappingDto> => POST(`${urlPrefix}/tag-mapping`, request),
  listForModeration: ({
    filter,
    pageable,
    sortDirection,
  }: {
    filter: BeatProductionContentFilter;
    pageable: Pageable;
    sortDirection: ChildSortingType;
  }): Promise<Page<BeatProductionContentDto>> =>
    POST(`${urlPrefix}/content/list?${buildQuery({ ...pageable, sortDirection })}`, filter),
  setTagOnBeat: (request: SetContentTagsRequest): Promise<BeatProductionContentDto> =>
    POST(`${urlPrefix}/content`, request),
  batchSetTagsOnBeats: (request: BatchSetContentTagsRequest): Promise<BeatProductionContentDto[]> =>
    POST(`${urlPrefix}/content/batch-set`, request),
  getBpmToneSuggestions: (contentIds: number[]): Promise<BeatBpmToneSuggestionDto[]> =>
    POST(`api/v1/admin/suggestion-bpm-tone/list`, { contentIds }),
  setPromoUgcControlStatus: (id: number, controlStatus: ControlStatus) =>
    PATCH(`api/v1/admin/audio/ugc/promo/${id}/status`, { controlStatus }),
};
