import usePersistentState from "@hooks/usePersistentState";

type DrawerPosition = "left" | "right";

const usePreferredNavigationDrawerPosition = (): [DrawerPosition, (val: DrawerPosition) => void] => {
  const [drawerPosition, setDrawerPosition] = usePersistentState<DrawerPosition>({
    key: "DrawerPosition",
    defaultValue: "left",
  });
  return [drawerPosition, setDrawerPosition];
};

export default usePreferredNavigationDrawerPosition;
