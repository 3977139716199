import { OffsetLimit } from "@api";
import { DELETE, GET, POST, PUT, buildQuery } from "@utils/fetch";

export enum NotificationTemplateCategory {
  NOTIFICATION = "NOTIFICATION",
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
}

export interface NotificationTemplatesFilterType {
  notificationCategory?: string | null;
  notificationType?: string | null;
}

export interface CreateTemplateNotificationDto {
  title?: string;
  text?: string;
  typeTemplate?: string;
  objectType?: string;
  parent?: boolean;
  active?: boolean;
  url?: string;
  senderUserId?: number;
}

export interface NotificationTemplateDto {
  id: number;
  title?: string;
  text?: string;
  pushNotificationType?: string;
  notificationObjectType?: string | null;
  notificationType?: string | null;
  parent?: boolean;
  active?: boolean;
  url?: string;
  senderUserId?: number;
}

export const NotificationTemplatesApi = {
  list: ({
    filter,
    ...rest
  }: {
    filter: NotificationTemplatesFilterType;
  } & OffsetLimit): Promise<{ content?: NotificationTemplateDto[]; total?: number }> =>
    GET(`api/v1/admin/notification-template/list?${buildQuery({ ...rest, ...filter })}`),
  create: ({
    createDto,
    notificationCategory,
  }: {
    createDto: CreateTemplateNotificationDto;
    notificationCategory: NotificationTemplateCategory;
  }) => POST(`api/v1/admin/notification-template?${buildQuery({ notificationCategory })}`, createDto),
  update: ({
    createDto,
    notificationCategory,
  }: {
    createDto: CreateTemplateNotificationDto;
    notificationCategory: NotificationTemplateCategory;
  }): Promise<NotificationTemplateDto> =>
    PUT(`api/v1/admin/notification-template?${buildQuery({ notificationCategory })}`, createDto),
  delete: (id: number) => DELETE(`api/v1/admin/notification-template/${id}`),
  listTypes: (): Promise<string[]> =>
    GET<any>(`api/v1/admin/notification-template/types`).then(({ templateTypes }) => templateTypes as string[]),
};
