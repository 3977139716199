import { Button } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { setLocaleAction, SupportedLocaleType } from "@slices/appSettings";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import classNames from "classnames";
import React, { useMemo } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import translatedMessages from "../../lang/translations.json";
import * as styles from "./AppLocalizationProvider.module.scss";

const localeToEmoji = {
  [SupportedLocaleType.RU]: "🇷🇺",
  [SupportedLocaleType.EN]: "🇬🇧",
};

const extractMessages = (locale: string): Record<string, string> =>
  Object.entries(translatedMessages).reduce(
    (a, [key, value]) => {
      a[key] = value[locale as keyof typeof value] || value.en;
      return a;
    },
    {} as Record<string, string>
  );

const AppLocalicationProvider = ({ children }: { children: React.ReactNode }) => {
  const locale = useAppSelector((state) => state.appSettings.locale);
  const messages = useMemo(() => {
    return extractMessages(locale);
  }, [locale]);
  return (
    <IntlProvider locale={locale} messages={messages}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
    </IntlProvider>
  );
};

export const LanguagePicker = ({ className }: { className?: string }) => {
  const currentLocale = useAppSelector((state) => state.appSettings.locale);
  const dispatch = useAppDispatch();
  return (
    <div className={classNames(className, styles.languagePicker)} data-testid="language-picker">
      <FormattedMessage defaultMessage="Language" />:{" "}
      {Object.values(SupportedLocaleType).map((locale) => (
        <Button
          key={locale}
          size="small"
          className={styles.languageButton}
          variant={locale === currentLocale ? "contained" : "outlined"}
          color="secondary"
          data-testid={`${locale}-locale-button`}
          onClick={() => {
            if (locale !== currentLocale) {
              dispatch(setLocaleAction(locale));
            }
          }}
        >
          {localeToEmoji[locale]}
        </Button>
      ))}
    </div>
  );
};

export default AppLocalicationProvider;
