import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import { SvgIconComponent } from "@mui/icons-material";
import GroupsIcon from "@mui/icons-material/Groups";
import MailIcon from "@mui/icons-material/Mail";
import SendIcon from "@mui/icons-material/Send";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import NavigationTabs from "@widgets/NavigationTabs";
import { MessageDescriptor, defineMessages } from "react-intl";
import { ManualNotificationsViewType } from "./types";

const messagesMap: Record<ManualNotificationsViewType, MessageDescriptor> = defineMessages({
  [ManualNotificationsViewType.TEMPLATES]: { defaultMessage: "TEMPLATES", description: "manual notifications view" },
  [ManualNotificationsViewType.AUDIENCES]: { defaultMessage: "AUDIENCES", description: "manual notifications view" },
  [ManualNotificationsViewType.RULES]: { defaultMessage: "RULES", description: "manual notifications view" },
  [ManualNotificationsViewType.MASS_SENDING]: {
    defaultMessage: "MASS SENDING",
    description: "manual notifications view",
  },
});

const iconsMap: Record<ManualNotificationsViewType, SvgIconComponent> = {
  [ManualNotificationsViewType.TEMPLATES]: MailIcon,
  [ManualNotificationsViewType.AUDIENCES]: SwitchAccountIcon,
  [ManualNotificationsViewType.RULES]: SendIcon,
  [ManualNotificationsViewType.MASS_SENDING]: GroupsIcon,
};

const tabValues = Object.values(ManualNotificationsViewType);

const ManualNotificationsHeaderTitle = () => {
  const route = useCurrentRouteDescriptor();
  useSetDocumentTitle({ messageDescriptor: route?.title });
  return (
    <NavigationTabs possibleValues={tabValues} tabMessagesMap={messagesMap} iconsMap={iconsMap} tabQueryKey="view" />
  );
};

export default ManualNotificationsHeaderTitle;
