import { MentionDto } from "@api/contentCommon";
import { POST } from "@utils/fetch";
import { UserShortDto } from "./user";

export interface CommentDto {
  id: number;
  user?: UserShortDto;
  message?: string;
  likeCount?: number;
  isTest?: boolean;
  isLiked?: boolean;
  commentCount?: number;
  createdAt?: string;
  mentions?: MentionDto[];
}

export const CommentsApi = {
  batchByIds: (commentsIds: number[]): Promise<CommentDto[]> =>
    POST(`api/v1/admin/comments/get-by-ids`, { commentsIds }),
  deleteAllByUser: (userId: number) => POST(`api/v1/admin/comments/hide`, { userId }),
};
