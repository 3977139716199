import AppType from "@models/AppType";
import {
  defaultForAllApps,
  newBooleanParameter,
  newMapParameter,
  newNumberParameter,
  newStringListParameter,
  newStringParameter,
  newUserIdParameter,
} from "../builders";
import environment from "../environment";
import { ConfigProperty, PropertyGroupType } from "../types";

const group = PropertyGroupType.OTHERS;

export default {
  [ConfigProperty.SUPERVISED_BADGES_HIGHLIGHT_COLORS]: newMapParameter({
    property: ConfigProperty.SUPERVISED_BADGES_HIGHLIGHT_COLORS,
    description:
      "Цвета подсветки меток юзеров (ключ - имя метки, значение - background-color, например: '#f24822' или 'rgba(255, 0, 0, 0.5)' или 'red' или 'var(--error)')",
    group,
    defaultValues: {
      [AppType.PUNCH]: {},
      [AppType.CHIPZ]: { NO_KID: "#f24822" },
    },
  }),
  [ConfigProperty.AUTH_AVAILABLE_COUNTRY_CODES]: newStringListParameter({
    property: ConfigProperty.AUTH_AVAILABLE_COUNTRY_CODES,
    description: "Страны, чьи телефонные номера можно использовать при замене логина пользователю",
    group,
    defaultValues: {
      [AppType.PUNCH]: [
        "ru",
        "de",
        "nl",
        "us",
        "gb",
        "fr",
        "se",
        "fi",
        "es",
        "tr",
        "am",
        "ge",
        "lv",
        "pl",
        "be",
        "at",
        "it",
        "by",
        "ca",
        "uz",
        "ch",
      ],
      [AppType.CHIPZ]: ["ru"],
    },
  }),
  [ConfigProperty.MANUAL_NOTIFICATIONS_DEFAULT_SENDER_ID]: newUserIdParameter({
    property: ConfigProperty.MANUAL_NOTIFICATIONS_DEFAULT_SENDER_ID,
    description: "Предлагаемый отправитель для рассылки ручных уведомлений",
    group,
    defaultValues: {
      [AppType.PUNCH]: 18073,
      [AppType.CHIPZ]: 330,
    },
  }),
  [ConfigProperty.BEATS_MODERATION_BPM_TONE_SUGGESTION_POLLING_INTERVAL_MILLIS]: newNumberParameter({
    property: ConfigProperty.BEATS_MODERATION_BPM_TONE_SUGGESTION_POLLING_INTERVAL_MILLIS,
    description: "Как часто (в миллисекундах) пытаться загрузить предложенные bpm/тональность в модерации битов",
    group,
    defaultValues: {
      [AppType.PUNCH]: 300,
    },
  }),
  [ConfigProperty.BEATS_UPLOAD_DEFAULT_USER_ID]: newUserIdParameter({
    property: ConfigProperty.BEATS_UPLOAD_DEFAULT_USER_ID,
    description: "Айди пользователя, выбранного по дефолту при создании бита",
    group,
    defaultValues: {
      [AppType.PUNCH]: environment.isTargetingProduction ? 3459555834254020 : 18073,
    },
  }),
  [ConfigProperty.BEATS_UPLOAD_CHECK_NAME_UNIQUENESS]: newBooleanParameter({
    property: ConfigProperty.BEATS_UPLOAD_CHECK_NAME_UNIQUENESS,
    description: "Проверять или нет названия битов на уникальность при сохранении",
    group,
    defaultValues: {
      [AppType.PUNCH]: true,
    },
  }),
  [ConfigProperty.SUPERVISED_BADGES_GROUPS_FOR_SEPARATE_COLUMNS]: newStringListParameter({
    property: ConfigProperty.SUPERVISED_BADGES_GROUPS_FOR_SEPARATE_COLUMNS,
    description:
      "Группы меток пользователей, которые будут показываться в отдельных колонках в таблице курируемых персон",
    group,
    defaultValues: {
      [AppType.PUNCH]: ["Чат артистов", "Направление музыки"],
      [AppType.CHIPZ]: [],
    },
  }),
  [ConfigProperty.DEEPLINK_URL_BASE]: newStringParameter({
    property: ConfigProperty.DEEPLINK_URL_BASE,
    description: "Базовый урл для конструктора диплинков",
    group,
    defaultValues: environment.apiUrls,
  }),
  [ConfigProperty.SNACKBAR_AUTOHIDE_DEFAULT_MILLIS]: newNumberParameter({
    property: ConfigProperty.SNACKBAR_AUTOHIDE_DEFAULT_MILLIS,
    description: "Как долго (в миллисекундах) снэкбары остаются на экране",
    group,
    defaultValues: defaultForAllApps(environment.isInUiTestsContext ? 10 : 2500),
  }),
  [ConfigProperty.SNACKBAR_AUTOHIDE_ERROR_MILLIS]: newNumberParameter({
    property: ConfigProperty.SNACKBAR_AUTOHIDE_ERROR_MILLIS,
    description: "Как долго (в миллисекундах) снэкбары от обработчика ошибок остаются на экране",
    group,
    defaultValues: defaultForAllApps(environment.isInUiTestsContext ? 10 : 3500),
  }),
  [ConfigProperty.VERIFICATION_CODES_SEARCH_ENABLED]: newBooleanParameter({
    property: ConfigProperty.VERIFICATION_CODES_SEARCH_ENABLED,
    description: "Поиск кодов валидации по номеру телефона",
    group,
    defaultValues: defaultForAllApps(!environment.isTargetingProduction),
  }),
  [ConfigProperty.NEW_YEAR_2024_MODE]: newBooleanParameter({
    property: ConfigProperty.NEW_YEAR_2024_MODE,
    description: "Просто какая-то пропертя",
    group,
    defaultValues: defaultForAllApps(false),
  }),
};
