import { GET } from "@utils/fetch";

export interface AudioTrackToneDto {
  id: number;
  name?: string;
}

export const AudioTonesApi = {
  list: (): Promise<AudioTrackToneDto[]> => GET(`api/v1/admin/audio_tones`),
};
