import { ChildSortingType } from "@api";
import { CommentTemplateResponseDto, CommentTemplatesApi } from "@api/commentTemplates";
import { ProfileBadge, ProfileBadgesApi } from "@api/profileBadges";
import { SupervisedBadgesApi, SupervisionBadgeType, SupervisionBadgesDto } from "@api/supervisedBadges";
import {
  CreateSupervisedPersonDto,
  CreatorCategoryDto,
  LocationDto,
  SupervisedPersonDto,
  SupervisedUsersApi,
} from "@api/supervisedUser";
import { UserRole } from "@api/user";
import AppType from "@models/AppType";
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkApiType } from "@store/store";
import { getCurrentUserAppType, getCurrentUserRole } from "./user";

export const rolesForBadges = [
  UserRole.CONTENT_MANAGER,
  UserRole.ADMINISTRATOR,
  UserRole.WEBADMIN_VIEWER,
  UserRole.SUPERVISOR,
];

export const ANY_BADGE_ID = -1;

export enum SupervisedUsersTableViewMode {
  ALL = "ALL",
  BADGED = "BADGED",
  LABELS_TEMPORARY = "LABELS_TEMPORARY",
  LABELS_PERMANENT = "LABELS_PERMANENT",
}

export interface SupervisedUsersState {
  locations: LocationDto[];
  creatorCategories: CreatorCategoryDto[];
  currentPersonUserId: number | undefined | null;
  profileBadges: ProfileBadge[];
  badgesLoadingInProgress: boolean;
  supervisedBadges: SupervisionBadgesDto[];
  supervisedBadgesLoadingInProgress: boolean;
  supervisedPersonsByUserIdCache: Record<number, SupervisedPersonDto>;
  lastTableViewMode: SupervisedUsersTableViewMode;
  commentTemplatesPerApp: Record<AppType, CommentTemplateResponseDto[]>;
}

const initialState: SupervisedUsersState = {
  locations: [],
  creatorCategories: [],
  profileBadges: [],
  badgesLoadingInProgress: false,
  supervisedBadges: [],
  supervisedBadgesLoadingInProgress: false,
  currentPersonUserId: null,
  supervisedPersonsByUserIdCache: {},
  lastTableViewMode: SupervisedUsersTableViewMode.ALL,
  commentTemplatesPerApp: {
    [AppType.PUNCH]: [],
    [AppType.CHIPZ]: [],
  },
};

const name = "supervisedUsers";

export const loadSupervisedUsersLocations = createAsyncThunk(`${name}/loadLocations`, async () => {
  const data = await SupervisedUsersApi.listLocations();
  return data;
});

export const loadSupervisedCreatorCategories = createAsyncThunk(`${name}/loadCategories`, async () => {
  const data = await SupervisedUsersApi.listCreatorCategories();
  return data;
});

export const refreshProfileBadges = createAsyncThunk(
  `${name}/refreshProfileBadges`,
  async () => {
    const data = await ProfileBadgesApi.list({
      page: 0,
      size: 10000,
      order: "updated_at",
      direction: ChildSortingType.DESC,
    });
    return (data.content || []).map((item) => ({
      ...item,
      cdnUrl: item.cdnUrl ? `${item.cdnUrl}?cacheBuster=${Date.now()}` : item.cdnUrl,
    }));
  },
  {
    condition: (_, { getState }) => !(getState() as RootState).supervisedUsers.badgesLoadingInProgress,
  }
);

export const refreshCommentTemplatesAction = createAsyncThunk<
  { comments: CommentTemplateResponseDto[]; appType: AppType },
  void,
  ThunkApiType
>(
  `${name}/refreshCommentTemplatesAction`,
  async (_, thunkApi) => {
    const { comments } = await CommentTemplatesApi.list();
    return { comments, appType: getCurrentUserAppType(thunkApi.getState()) };
  },
  {
    condition: (_, thunkApi) => {
      return CommentTemplatesApi.allowedRoles.includes(getCurrentUserRole(thunkApi.getState()));
    },
  }
);

export const loadSupervisedBadgesAction = createAsyncThunk(
  `${name}/loadSupervisedBadgesAction`,
  async () => {
    const data = await SupervisedBadgesApi.list({ offset: 0, limit: 1000 });
    return data;
  },
  {
    condition: (_, { getState }) => !(getState() as RootState).supervisedUsers.supervisedBadgesLoadingInProgress,
  }
);

export const loadSupervisedPersonsByUserIdsToCacheAction = createAsyncThunk(
  `${name}/loadSupervisedPersonsByUserIdsToCacheAction`,
  async (userIds: number[]) => {
    if (!userIds?.length) {
      return [];
    }
    const data = await SupervisedUsersApi.batchByUserIds(userIds);
    return data;
  }
);

interface PartialUpdateSupervisedUserActionPayload {
  personId: number;
  patch: Partial<CreateSupervisedPersonDto>;
}

export const partialUpdateSupervisedUserAction = createAsyncThunk(
  `${name}/partialUpdateSupervisedUserAction`,
  async ({ personId, patch }: PartialUpdateSupervisedUserActionPayload) => {
    const person = await SupervisedUsersApi.updatePartial(personId, patch);
    return person;
  }
);

const supervisedUsersSlice = createSlice({
  name,
  initialState,
  reducers: {
    setCurrentPersonUserId: (state, action: PayloadAction<number | null>) => {
      state.currentPersonUserId = action.payload;
    },
    setLastTableViewModeAction: (state, action: PayloadAction<SupervisedUsersTableViewMode>) => {
      state.lastTableViewMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSupervisedUsersLocations.fulfilled, (state, action) => {
      state.locations = action.payload;
    });
    builder.addCase(loadSupervisedCreatorCategories.fulfilled, (state, action) => {
      state.creatorCategories = action.payload;
    });
    builder.addCase(refreshProfileBadges.pending, (state) => {
      state.badgesLoadingInProgress = true;
    });
    builder.addCase(refreshProfileBadges.fulfilled, (state, action) => {
      state.profileBadges = action.payload;
      state.badgesLoadingInProgress = false;
    });
    builder.addCase(refreshProfileBadges.rejected, (state) => {
      state.badgesLoadingInProgress = false;
    });
    builder.addCase(loadSupervisedPersonsByUserIdsToCacheAction.fulfilled, (state, action) => {
      action.payload.forEach((person) => {
        if (person.user?.id) {
          state.supervisedPersonsByUserIdCache[person.user.id] = person;
        }
      });
    });
    builder.addCase(partialUpdateSupervisedUserAction.fulfilled, (state, action) => {
      const person = action.payload;
      if (person.user?.id) {
        state.supervisedPersonsByUserIdCache[person.user.id] = person;
      }
    });
    builder.addCase(loadSupervisedBadgesAction.pending, (state) => {
      state.supervisedBadgesLoadingInProgress = true;
    });
    builder.addCase(loadSupervisedBadgesAction.fulfilled, (state, action) => {
      state.supervisedBadgesLoadingInProgress = false;
      state.supervisedBadges = action.payload;
    });
    builder.addCase(loadSupervisedBadgesAction.rejected, (state) => {
      state.supervisedBadgesLoadingInProgress = false;
    });
    builder.addCase(refreshCommentTemplatesAction.fulfilled, (state, action) => {
      state.commentTemplatesPerApp[action.payload.appType] = action.payload.comments;
    });
  },
});

export const getProfileBadgesMapSelector = createSelector(
  (state: RootState) => state.supervisedUsers.profileBadges,
  (badges) =>
    badges.reduce(
      (a, x) => {
        a[x.id!] = x;
        return a;
      },
      {} as Record<number, ProfileBadge>
    )
);

export const { setCurrentPersonUserId, setLastTableViewModeAction } = supervisedUsersSlice.actions;
export default supervisedUsersSlice.reducer;

export const getSupervisionBadgesMapSelector = createSelector(
  (state: RootState) => state.supervisedUsers.supervisedBadges,
  (badges) =>
    badges.reduce(
      (a, x) => {
        a[x.id!] = x;
        return a;
      },
      {} as Record<number, SupervisionBadgesDto>
    )
);

export const getPermanentSupervisionBadgesSelector = createSelector(
  (state: RootState) => state.supervisedUsers.supervisedBadges,
  (badges) => badges.filter((x) => !x.badgeType || x.badgeType === SupervisionBadgeType.PERMANENT)
);

export const getTemporarySupervisionBadgesSelector = createSelector(
  (state: RootState) => state.supervisedUsers.supervisedBadges,
  (badges) => badges.filter((x) => x.badgeType === SupervisionBadgeType.TEMPORARY)
);
