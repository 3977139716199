import { OffsetLimit, ResponseDto, SortingSortByEnumerated } from "@api";
import { DELETE, GET, PATCH, POST, buildQuery, formDataWithFile } from "@utils/fetch";

export interface BannersListResponse {
  content: BannerAdminDto[];
  total: number;
}

export enum BannerSortType {
  INDEX = "INDEX",
  NAME = "NAME",
}

export enum BannerStatus {
  AVAILABLE = "AVAILABLE",
  UNPUBLISHED = "UNPUBLISHED",
}

export enum BannerType {
  COMMON = "COMMON",
  BEAT = "BEAT",
}

export interface BannerAdminDto {
  id: number;
  name?: string;
  link?: string;
  index?: number;
  bannerStatus?: BannerStatus;
  image?: string;
  createdAt?: string;
  updatedAt?: string;
  bannerType?: BannerType;
}

export interface CreateBannerDto {
  name: string;
  link: string;
  index: number;
  bannerType?: BannerType;
}

export interface UpdateBannerDto {
  name?: string;
  link?: string;
  index?: number;
  bannerStatus?: BannerStatus;
  bannerType?: BannerType;
}

const urlPrefix = "api/v1/admin/banners";

export const BannersApi = {
  list: (
    request: {
      bannerStatus?: BannerStatus | null;
      bannerType?: BannerType | null;
    } & OffsetLimit &
      SortingSortByEnumerated<BannerSortType>
  ): Promise<ResponseDto<BannerAdminDto>> => GET(`${urlPrefix}?${buildQuery(request)}`),
  delete: (id: string | number) => DELETE(`${urlPrefix}/${id}`),
  create: (request: CreateBannerDto): Promise<BannerAdminDto> => POST(urlPrefix, request),
  update: (id: number, request: UpdateBannerDto): Promise<BannerAdminDto> => PATCH(`${urlPrefix}/${id}`, request),
  updateImage: (id: number, image: File): Promise<BannerAdminDto> =>
    POST(`${urlPrefix}/${id}/image`, formDataWithFile("file", image)),
  getById: (id: number): Promise<BannerAdminDto> => GET(`${urlPrefix}/${id}`),
};
