import { AbTestApi, ConfigFilter } from "@api/abTest";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const WEB_ADMIN_SERVICE_NAME = "WEB_ADMIN";

export enum SupportedLocaleType {
  RU = "ru",
  EN = "en",
}

export interface AppSettingsState {
  locale: SupportedLocaleType;
  waitingForRuntimeConfig: boolean;
  runtimeConfig: Record<string, string>;
  blockNavigationMessage: string | null;
  snowflakesEnabled: boolean;
}

const initialState: AppSettingsState = {
  locale: SupportedLocaleType.RU,
  waitingForRuntimeConfig: true,
  runtimeConfig: {},
  blockNavigationMessage: null,
  snowflakesEnabled: true,
};

const name = "appSettings";

export const loadRuntimeConfigurationAction = createAsyncThunk(`${name}/loadRuntimeConfigurationAction`, async () => {
  const items = await AbTestApi.listConfigs({
    serviceName: WEB_ADMIN_SERVICE_NAME,
    configFilter: ConfigFilter.DEFAULT_ONLY,
  });
  return items
    .filter((x) => x.variationId === 0)
    .reduce(
      (a, x) => {
        a[x.propName] = x.propValue;
        return a;
      },
      {} as Record<string, string>
    );
});

const appSettingsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLocaleAction: (state, action: PayloadAction<SupportedLocaleType>) => {
      state.locale = action.payload;
    },
    changeNavigationBlockAction: (state, action: PayloadAction<string | null>) => {
      state.blockNavigationMessage = action.payload;
    },
    toggleSnowflakesEnabledAction: (state) => {
      state.snowflakesEnabled = !state.snowflakesEnabled;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadRuntimeConfigurationAction.fulfilled, (state, action) => {
      state.runtimeConfig = action.payload;
      state.waitingForRuntimeConfig = false;
    });
    builder.addCase(loadRuntimeConfigurationAction.rejected, (state) => {
      state.runtimeConfig = {};
      state.waitingForRuntimeConfig = false;
    });
  },
});

export const { setLocaleAction, changeNavigationBlockAction, toggleSnowflakesEnabledAction } = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
