import { changeNavigationBlockAction } from "@slices/appSettings";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { MessageDescriptor, useIntl } from "react-intl";

const useBlockNavigation = ({ blocked, message }: { blocked: boolean; message: MessageDescriptor }) => {
  const dispatch = useAppDispatch();

  const intl = useIntl();
  useEffect(() => {
    dispatch(changeNavigationBlockAction(blocked ? intl.formatMessage(message) : null));
  }, [blocked, dispatch, intl, message]);
  useEffect(() => {
    if (blocked) {
      window.onbeforeunload = function () {
        return intl.formatMessage(message);
      };
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [blocked, message, intl]);
};

export default useBlockNavigation;

export const useActIfNavigationAllowed = () => {
  const navigationBlockedMessage = useAppSelector((state) => state.appSettings.blockNavigationMessage);
  const { enqueueSnackbar } = useSnackbar();
  return useCallback(
    (act: () => void) => {
      if (navigationBlockedMessage) {
        enqueueSnackbar(navigationBlockedMessage, { variant: "error" });
      } else {
        act();
      }
    },
    [enqueueSnackbar, navigationBlockedMessage]
  );
};
