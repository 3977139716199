/* eslint-disable no-restricted-globals */
/*
import JSONBigint from "json-bigint";
const jsonHandler = JSONBigint({ useNativeBigInt: true });
export const parseJson = jsonHandler.parse;
export const stringifyJson = jsonHandler.stringify;
*/

// this file is a remnant of time when we (shortly) had bigint ids, keeping it that way to be able to switch back quickly if needed again
// also if 64-bit ids are required again, fixtures in cypress should be renamed without .json extension so precision is not lost
export const parseJson = JSON.parse;
export const stringifyJson = JSON.stringify;

// but also hack to allow JSON.stringify without crashing just in case
(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};
