import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import useParsedLocationSearch from "@hooks/useParsedLocationSearch";
import AppType, { appTypeFromString } from "@models/AppType";
import AccessDeniedView from "@widgets/AccessDeniedView";

const AccessDenied = () => {
  const routeConfig = useCurrentRouteDescriptor();
  const query = useParsedLocationSearch();
  const appType: AppType | undefined = typeof query.appType === "string" ? appTypeFromString(query.appType) : undefined;
  return (
    <AccessDeniedView
      requiredAppTypes={appType ? [appType] : routeConfig?.allowedApps}
      requiredRoles={routeConfig?.allowedRoles}
    />
  );
};

export default AccessDenied;
