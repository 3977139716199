import { useCallback, useState } from "react";

const useDialogPayloadState = <Type>() => {
  const [payload, setPayload] = useState<Type | null>(null);
  const openDialog = useCallback((payload: Type) => setPayload(payload), []);
  const closeDialog = useCallback(() => setPayload(null), []);
  return {
    openDialog,
    closeDialog,
    open: payload !== null,
    payload,
  };
};

export default useDialogPayloadState;
