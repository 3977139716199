import { ChildSortingType, ResponseDto } from "@api";
import { DELETE, GET, PATCH, POST, buildQuery } from "@utils/fetch";
import { ContentCategory, ContentType } from "./contentCommon";

export enum VoteType {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}

export interface VoteConfigRequestDto {
  type: VoteType;
  cancellable: boolean;
  maxVotesCount: number;
  feedVotesHiddenCounter: number;
  votes: number[];
  tagId?: number;
  contentThemeId?: number;
  contentType?: ContentType;
  contentCategory?: ContentCategory;
  active?: boolean;
}

export interface VoteConfigDto extends VoteConfigRequestDto {
  id: number;
  createdAt: string;
}

export const VoteConfigsApi = {
  list: ({
    offset,
    limit,
    sortBy = "createdAt",
    sorting = ChildSortingType.DESC,
  }: {
    offset: number;
    limit: number;
    sortBy?: string;
    sorting?: ChildSortingType;
  }): Promise<ResponseDto<VoteConfigDto>> =>
    GET(`api/v1/admin/votes/configurations?${buildQuery({ offset, limit, sortBy, sorting })}`),
  create: (request: VoteConfigRequestDto): Promise<VoteConfigDto> => POST(`api/v1/admin/votes/configurations`, request),
  update: (id: number, request: VoteConfigRequestDto): Promise<VoteConfigDto> =>
    PATCH(`api/v1/admin/votes/configurations/${id}`, request),
  delete: (id: number) => DELETE(`api/v1/admin/votes/configurations/${id}`),
};
