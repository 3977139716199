import { Button, ButtonProps, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface ConfirmationModalProps {
  title: React.ReactNode;
  onConfirm: () => void;
  onDismiss: () => void;
  open: boolean;
  customTestId?: string;
  confirmButtonProps?: ButtonProps;
  confirmButtonContent?: React.ReactNode;
  dismissButtonProps?: ButtonProps;
  dismissButtonContent?: React.ReactNode;
}

const ConfirmationDialog = ({
  title,
  onConfirm,
  onDismiss,
  open,
  customTestId,
  confirmButtonProps,
  dismissButtonProps,
  confirmButtonContent,
  dismissButtonContent,
}: ConfirmationModalProps) => (
  <Dialog open={open} onClose={onDismiss} data-testid={customTestId || "confirmation-dialog"}>
    <DialogTitle>{title}</DialogTitle>
    <DialogActions>
      <Button onClick={onDismiss} data-testid="no-button" {...dismissButtonProps}>
        {dismissButtonContent || <FormattedMessage defaultMessage="No" />}
      </Button>
      <Button onClick={onConfirm} data-testid="yes-button" {...confirmButtonProps}>
        {confirmButtonContent || <FormattedMessage defaultMessage="Yes" />}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
