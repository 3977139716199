import { Page } from "@api";
import { AdminAudioContentDto, AudioTracksApi } from "@api/audioTracks";
import {
  BeatAssignedStatus,
  BeatProductionContentDto,
  BeatProductionContentFilter,
  BeatProductionTagDto,
  BeatProductionTagStatus,
  BeatProductionTagStatusMappingDto,
  BeatsModerationApi,
  ControlStatus,
} from "@api/beatsModeration";
import { ContentStatusType } from "@api/contentCommon";
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkApiType } from "@store/store";
import { head, sortBy, uniq } from "lodash";
import { loadUsersToCache } from "./usersCache";

export enum EditItemStepType {
  EDIT_TRACK_METADATA = "EDIT_TRACK_METADATA",
  CREATE_PROMO_UGC = "CREATE_PROMO_UGC",
  ASSIGN_TAGS = "ASSIGN_TAGS",
}

export interface BeatsModerationState {
  tags: BeatProductionTagDto[];
  tagMappings: BeatProductionTagStatusMappingDto[];
  tracksMap: Record<number, AdminAudioContentDto>;
  // to coordinate players - only one track should play at a time
  playingTrackId: number | null;
  editedItem: BeatProductionContentDto | null;
  editItemStep: EditItemStepType | null;
}

export const defaultFilter: BeatProductionContentFilter = {
  contentStatuses: [ContentStatusType.AVAILABLE],
  controlStatuses: [ControlStatus.UPLOAD_FINISHED],
  moderationStatuses: [BeatAssignedStatus.NEW],
  coverTextsControlStatuses: [],
};

const initialState: BeatsModerationState = {
  tags: [],
  tagMappings: [],
  tracksMap: {},
  playingTrackId: null,
  editedItem: null,
  editItemStep: null,
};

const name = "beatsModeration";

export const refreshTagsAction = createAsyncThunk(`${name}/refreshTagsAction`, async () => {
  const [tags, tagMappings] = await Promise.all([BeatsModerationApi.listTags(), BeatsModerationApi.loadTagMappings()]);
  return { tags, tagMappings };
});

export const loadAdditionalDataForListAction = createAsyncThunk<
  Record<number, AdminAudioContentDto>,
  Page<BeatProductionContentDto>,
  ThunkApiType
>(
  `${name}/loadDataForListAction`,
  async (data, thunkApi) => {
    const contentIds = uniq((data.content || []).map((x) => x.contentId));
    const userIds = uniq((data.content || []).map((x) => x.authorId).filter((x) => x) as number[]);
    thunkApi.dispatch(loadUsersToCache({ ids: userIds, checkExisting: false }));
    const contents = await AudioTracksApi.batchByIds(contentIds);
    return contents.reduce(
      (a, x) => {
        a[x.id] = x;
        return a;
      },
      {} as Record<number, AdminAudioContentDto>
    );
  },
  { condition: (page) => page.content && page.content.length > 0 }
);

export const refreshAudioTrackAction = createAsyncThunk<AdminAudioContentDto | undefined, number, ThunkApiType>(
  `${name}/refreshAudioTrackAction`,
  async (id) => {
    const tracks = await AudioTracksApi.batchByIds([id]);
    return tracks.find((x) => x.id === id);
  }
);

const beatsModerationSlice = createSlice({
  name,
  initialState,
  reducers: {
    setPlayingTrackIdAction: (state, action: PayloadAction<number>) => {
      state.playingTrackId = action.payload;
    },
    setEditedItemAction: (state, action: PayloadAction<BeatProductionContentDto | null>) => {
      state.playingTrackId = null;
      state.editedItem = action.payload;
      state.editItemStep = Object.values(EditItemStepType)[0];
    },
    setEditItemItemStepAction: (state, action: PayloadAction<EditItemStepType>) => {
      if (state.editedItem) {
        state.editItemStep = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshTagsAction.fulfilled, (state, action) => {
      state.tags = action.payload.tags;
      state.tagMappings = action.payload.tagMappings;
    });
    builder.addCase(loadAdditionalDataForListAction.fulfilled, (state, action) => {
      Object.assign(state.tracksMap, action.payload);
    });
    builder.addCase(refreshAudioTrackAction.fulfilled, (state, action) => {
      if (action.payload) {
        state.tracksMap[action.meta.arg] = action.payload;
      }
    });
  },
});

export const { setPlayingTrackIdAction, setEditedItemAction, setEditItemItemStepAction } = beatsModerationSlice.actions;

export default beatsModerationSlice.reducer;

export const getTagForDeleteButtonSelector = createSelector(
  (state: RootState) => state.beatsModeration.tagMappings,
  (tagMappings) =>
    head(
      tagMappings
        .filter(
          (x) => x.beatAssignedStatus === BeatAssignedStatus.DELETED && x.tag.status === BeatProductionTagStatus.ACTIVE
        )
        .map((x) => x.tag)
    )
);

export interface EditorData {
  editedItem: BeatProductionContentDto | null;
  editItemStep: EditItemStepType | null;
  track: AdminAudioContentDto | undefined;
}
export const getDataForEditorSelector = createSelector(
  (state: RootState) => state.beatsModeration.editedItem,
  (state: RootState) => state.beatsModeration.editItemStep,
  (state: RootState) => state.beatsModeration.tracksMap,
  (editedItem, editItemStep, tracksMap) => {
    const result: EditorData = {
      editedItem,
      editItemStep,
      track: editedItem ? tracksMap[editedItem.contentId] : undefined,
    };
    return result;
  }
);

export interface BeatProductionTagDtoWithStatus extends BeatProductionTagDto {
  moderationStatus?: BeatAssignedStatus;
}

export const getActiveTagsWithStatusesSelector = createSelector(
  (state: RootState) => state.beatsModeration.tags,
  (state: RootState) => state.beatsModeration.tagMappings,
  (tags, tagMappings) => {
    const tagIdToStatusMap = tagMappings.reduce(
      (a, x) => {
        a[x.tag.id] = x.beatAssignedStatus;
        return a;
      },
      {} as Record<string, BeatAssignedStatus>
    );
    return sortBy(
      tags
        .filter((x) => x.status === BeatProductionTagStatus.ACTIVE)
        .map((x) => ({
          ...x,
          moderationStatus: tagIdToStatusMap[x.id],
        })) as BeatProductionTagDtoWithStatus[],
      (x) => Object.values(BeatAssignedStatus).indexOf(x.moderationStatus ?? BeatAssignedStatus.NEW),
      (x) => x.name
    );
  }
);
