import { ChildSortingType } from "@api";
import { AchievementsApi } from "@api/achievements";
import { AudioTonesApi } from "@api/audioTones";
import { AudioTracksApi } from "@api/audioTracks";
import { BannersApi, BannerSortType } from "@api/banners";
import { CommentTemplatesApi } from "@api/commentTemplates";
import { ContentAwardsApi } from "@api/contentAwards";
import { ContentStatusType } from "@api/contentCommon";
import { ContentThemesApi } from "@api/contentThemes";
import { FeedConfigurationsApi } from "@api/feedConfigurations";
import { GenresApi } from "@api/genres";
import { InternalTagsApi } from "@api/internalTags";
import { MoodsApi } from "@api/moods";
import { PersonsApi } from "@api/persons";
import { ProfileBadgesApi } from "@api/profileBadges";
import { SupervisedBadgesApi } from "@api/supervisedBadges";
import {
  SupervisedCreatorStatus,
  SupervisedPersonStatus,
  SupervisedUsersApi,
  SupervisedUserStatus,
} from "@api/supervisedUser";
import { TagsApi } from "@api/tags";
import { UniversitiesApi } from "@api/universities";
import { UserRole } from "@api/user";
import { UserInterestsApi } from "@api/userInterests";
import { VoteConfigsApi } from "@api/voteConfigs";
import { WeblinksApi } from "@api/webLinks";
import { ConfigPropertyDescriptor } from "@config";
import AppType from "@models/AppType";
import {
  ContentStatusTranslations,
  SupervisedCreatorStatusTranslations,
  SupervisedPersonStatusTranslations,
  SupervisedUserStatusTranslations,
} from "@models/enumTranslations";
import EnumTable from "@widgets/EnumTable";
import { uniq } from "lodash";
import { lazy } from "react";
import { defineMessage, MessageDescriptor } from "react-intl";

export interface SubpageConfig {
  queryValue: string;
  titleMessage: MessageDescriptor;
  countLoader: () => Promise<number | null>;
  component: React.FC;
  onlyForApp?: AppType;
  onlyForRoles?: UserRole[];
  // TODO READONLY remove later
  hideFromViewer?: boolean;
  killswitch?: ConfigPropertyDescriptor<boolean>;
}

export const SUBPAGE_QUERY_KEY = "view";

export const subpagesConfigs: SubpageConfig[] = [
  {
    queryValue: "audioTones",
    titleMessage: defineMessage({ defaultMessage: "Audio Tone" }),
    countLoader: async () => {
      const data = await AudioTonesApi.list();
      return data.length;
    },
    component: lazy(() => import("./AudioTones/AudioTones")),
    onlyForRoles: [UserRole.CONTENT_MANAGER],
    onlyForApp: AppType.PUNCH,
  },
  {
    queryValue: "persons",
    titleMessage: defineMessage({ defaultMessage: "Persons" }),
    countLoader: async () => {
      const data = await PersonsApi.list();
      return data.length;
    },
    component: lazy(() => import("./Persons/Persons")),
    onlyForRoles: [UserRole.CONTENT_MANAGER],
    onlyForApp: AppType.PUNCH,
  },
  {
    queryValue: "tags",
    titleMessage: defineMessage({ defaultMessage: "Tags" }),
    countLoader: async () => {
      const data = await TagsApi.list({ offset: 0, limit: 1 });
      return data.total || null;
    },
    component: lazy(() => import("./tags/TagsV2")),
    onlyForRoles: [UserRole.CONTENT_MANAGER, UserRole.ADMINISTRATOR, UserRole.SUPERVISOR],
  },
  {
    queryValue: "genres",
    titleMessage: defineMessage({ defaultMessage: "Genres" }),
    countLoader: async () => {
      const data = await GenresApi.list({ offset: 0, limit: 1 });
      return data.total || null;
    },
    component: lazy(() => import("./Genres/Genres")),
    onlyForRoles: [UserRole.CONTENT_MANAGER, UserRole.ADMINISTRATOR],
    onlyForApp: AppType.PUNCH,
  },
  {
    queryValue: "moods",
    titleMessage: defineMessage({ defaultMessage: "Moods", description: "long" }),
    countLoader: async () => {
      const data = await MoodsApi.list({ offset: 0, limit: 1 });
      return data.total || null;
    },
    component: lazy(() => import(/* webpackChunkName: "directories.moods" */ "./Moods/Moods")),
    onlyForRoles: [UserRole.CONTENT_MANAGER, UserRole.ADMINISTRATOR],
    onlyForApp: AppType.PUNCH,
  },
  {
    queryValue: "content_statuses",
    titleMessage: defineMessage({ defaultMessage: "Content Statuses" }),
    countLoader: () => new Promise((resolve) => resolve(Object.values(ContentStatusType).length)),
    component: () => <EnumTable values={Object.values(ContentStatusType)} translations={ContentStatusTranslations} />,
    onlyForRoles: [UserRole.SUPERVISOR, UserRole.CONTENT_MANAGER],
  },
  {
    queryValue: "supervisor_badges",
    titleMessage: defineMessage({ defaultMessage: "Badges for supervised persons" }),
    countLoader: async () => {
      const data = await SupervisedBadgesApi.list({ offset: 0, limit: 100000 });
      return data.length;
    },
    component: lazy(() => import("./supervisedBadges/SupervisedBadges")),
    onlyForRoles: [
      UserRole.ADMINISTRATOR,
      UserRole.SUPERVISOR,
      UserRole.MODERATOR,
      UserRole.CONTENT_MANAGER,
      UserRole.SUPER_MODERATOR,
    ],
  },
  {
    queryValue: "supervised_person_statuses",
    titleMessage: defineMessage({ defaultMessage: "Supervised person statuses" }),
    countLoader: () => new Promise((resolve) => resolve(Object.values(SupervisedPersonStatus).length)),
    component: () => (
      <EnumTable values={Object.values(SupervisedPersonStatus)} translations={SupervisedPersonStatusTranslations} />
    ),
    onlyForRoles: [UserRole.ADMINISTRATOR, UserRole.SUPERVISOR],
  },
  {
    queryValue: "supervised_user_statuses",
    titleMessage: defineMessage({ defaultMessage: "Supervised user statuses" }),
    countLoader: () => new Promise((resolve) => resolve(Object.values(SupervisedUserStatus).length)),
    component: () => (
      <EnumTable values={Object.values(SupervisedUserStatus)} translations={SupervisedUserStatusTranslations} />
    ),
    onlyForRoles: [UserRole.ADMINISTRATOR, UserRole.SUPERVISOR],
  },
  {
    queryValue: "supervised_creator_statuses",
    titleMessage: defineMessage({ defaultMessage: "Supervised creator statuses" }),
    countLoader: () => new Promise((resolve) => resolve(Object.values(SupervisedCreatorStatus).length)),
    component: () => (
      <EnumTable values={Object.values(SupervisedCreatorStatus)} translations={SupervisedCreatorStatusTranslations} />
    ),
    onlyForRoles: [UserRole.ADMINISTRATOR, UserRole.SUPERVISOR],
  },
  {
    queryValue: "supervised_creator_categories",
    titleMessage: defineMessage({ defaultMessage: "Supervised creator categories" }),
    countLoader: () => SupervisedUsersApi.listCreatorCategories().then((data) => data.length),
    component: lazy(() => import("./SupervisedCreatorCategories/SupervisedCreatorCategories")),
    onlyForRoles: [UserRole.ADMINISTRATOR, UserRole.SUPERVISOR],
  },
  {
    queryValue: "profile_badges",
    titleMessage: defineMessage({ defaultMessage: "Profile badges" }),
    countLoader: () => ProfileBadgesApi.list({ page: 0, size: 1 }).then(({ totalElements }) => totalElements || 0),
    component: lazy(() => import("./profileBadges/ProfileBadges")),
    onlyForRoles: [UserRole.ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTENT_MANAGER],
    hideFromViewer: true,
  },
  {
    queryValue: "internal_tags",
    titleMessage: defineMessage({ defaultMessage: "Internal Tags" }),
    countLoader: () => InternalTagsApi.list().then((tags) => tags.length),
    component: lazy(() => import("./internalTags/InternalTags")),
    onlyForRoles: [
      UserRole.ADMINISTRATOR,
      UserRole.SUPERVISOR,
      UserRole.CONTENT_MANAGER,
      UserRole.MODERATOR,
      UserRole.SUPER_MODERATOR,
    ],
    hideFromViewer: true,
  },
  {
    queryValue: "content_themes",
    titleMessage: defineMessage({ defaultMessage: "Content Themes" }),
    countLoader: () => ContentThemesApi.list().then((themes) => themes.length),
    component: lazy(() => import("./contentThemes/ContentThemes")),
    onlyForRoles: [UserRole.ADMINISTRATOR, UserRole.SUPERVISOR, UserRole.CONTENT_MANAGER],
  },
  {
    queryValue: "urls_whitelist",
    titleMessage: defineMessage({ defaultMessage: "Urls whitelist" }),
    countLoader: () => Promise.resolve(null),
    onlyForRoles: [UserRole.MODERATOR, UserRole.SUPER_MODERATOR],
    onlyForApp: AppType.CHIPZ,
    component: lazy(() => import("./UrlsWhitelist/UrlsWhitelist")),
  },
  {
    queryValue: "award_images",
    titleMessage: defineMessage({ defaultMessage: "Award images" }),
    countLoader: () => ContentAwardsApi.loadAwardImages({ offset: 0, limit: 1 }).then(({ total }) => total ?? 0),
    onlyForRoles: [UserRole.CONTENT_MANAGER, UserRole.SUPERVISOR],
    onlyForApp: AppType.PUNCH,
    component: lazy(() => import("./awardImages/AwardImages")),
  },
  {
    queryValue: "audio_tracks",
    titleMessage: defineMessage({ defaultMessage: "Audio Tracks" }),
    countLoader: () => AudioTracksApi.list({ offset: 0, limit: 1 }).then(({ total }) => total ?? 0),
    component: lazy(() => import("./AudioTracks/AudioTracksNewTable")),
    onlyForRoles: [UserRole.CONTENT_MANAGER, UserRole.BEAT_PRODUCER],
    onlyForApp: AppType.PUNCH,
  },
  {
    queryValue: "banners",
    titleMessage: defineMessage({ defaultMessage: "Banners" }),
    countLoader: () =>
      BannersApi.list({ offset: 0, limit: 1, sortBy: BannerSortType.INDEX, sorting: ChildSortingType.ASC }).then(
        ({ total }) => total ?? 0
      ),
    onlyForRoles: [UserRole.CONTENT_MANAGER],
    component: lazy(() => import("./Banners/Banners")),
  },
  {
    queryValue: "universities",
    titleMessage: defineMessage({ defaultMessage: "Universities" }),
    countLoader: () => UniversitiesApi.list({ offset: 0, limit: 1 }).then(({ total }) => total ?? 0),
    onlyForRoles: [UserRole.CONTENT_MANAGER],
    onlyForApp: AppType.CHIPZ,
    component: lazy(() => import("./Universities/Universities")),
  },
  {
    queryValue: "external_platforms",
    titleMessage: defineMessage({ defaultMessage: "External platforms" }),
    countLoader: () => WeblinksApi.listExternalPlatforms().then((items) => items?.length || 0),
    onlyForRoles: [UserRole.CONTENT_MANAGER, UserRole.ADMINISTRATOR],
    onlyForApp: AppType.PUNCH,
    component: lazy(() => import("./ExternalPlatforms/ExternalPlatformsPage")),
  },
  {
    queryValue: "custom_feeds",
    titleMessage: defineMessage({ defaultMessage: "Feed configuration" }),
    countLoader: () => FeedConfigurationsApi.list().then((items) => items?.length || 0),
    onlyForRoles: [UserRole.CONTENT_MANAGER, UserRole.SUPERVISOR, UserRole.ADMINISTRATOR],
    component: lazy(() => import("./CustomFeeds/CustomFeeds")),
  },
  {
    queryValue: "voting_configurations",
    titleMessage: defineMessage({ defaultMessage: "Voting configuration" }),
    countLoader: () => VoteConfigsApi.list({ offset: 0, limit: 1 }).then(({ total }) => total || 0),
    onlyForRoles: [UserRole.CONTENT_MANAGER, UserRole.SUPERVISOR],
    onlyForApp: AppType.PUNCH,
    component: lazy(() => import("./VoteConfigurations/VoteConfigurationsPage")),
  },
  {
    queryValue: "interests",
    titleMessage: defineMessage({ defaultMessage: "Interests" }),
    countLoader: () => UserInterestsApi.list().then((items) => items.length),
    onlyForRoles: [UserRole.CONTENT_MANAGER],
    onlyForApp: AppType.CHIPZ,
    component: lazy(() => import("./Interests/Interests")),
  },
  {
    queryValue: "achievements",
    titleMessage: defineMessage({ defaultMessage: "Achievements" }),
    countLoader: () => AchievementsApi.list({ offset: 0, limit: 1 }).then(({ total }) => total ?? 0),
    component: lazy(() => import("./Achievements/Achievements")),
    onlyForApp: AppType.PUNCH,
    onlyForRoles: [UserRole.SUPERVISOR, UserRole.CONTENT_MANAGER],
  },
  {
    queryValue: "comment_templates",
    titleMessage: defineMessage({ defaultMessage: "Comment templates" }),
    countLoader: () => CommentTemplatesApi.list().then(({ comments }) => comments.length),
    component: lazy(() => import("./CommentTemplates/CommentTemplates")),
    onlyForRoles: CommentTemplatesApi.allowedRoles,
  },
];

export const allowedRoles = uniq(subpagesConfigs.flatMap((x) => x.onlyForRoles || []));
