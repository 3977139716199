import { ConfigProperty } from "@config";
import { LoggedInUser } from "@slices/user";
import { MessageDescriptor } from "react-intl";

export interface SearchData {
  query: string;
  currentUser: LoggedInUser;
  availableUsers: LoggedInUser[];
  resolvedKillswitches: Partial<Record<ConfigProperty, boolean>>;
}

export enum SearchResultType {
  USER = "USER",
  CONTENT = "CONTENT",
  LINK = "LINK",
  MESSAGE = "MESSAGE",
  COPIABLE = "COPIABLE",
}

export const typePriority = {
  [SearchResultType.USER]: 0,
  [SearchResultType.CONTENT]: 1,
  [SearchResultType.LINK]: 2,
  [SearchResultType.MESSAGE]: 3,
  [SearchResultType.COPIABLE]: 0,
};

export interface SearchResult {
  type: SearchResultType;
  adminUrl: string;
  exactMatch?: boolean;
  message: MessageDescriptor;
  messageValues?: any;
  copyValue?: string;
  //
  uid?: string;
}

export type SearchStrategy = (props: SearchData) => Promise<SearchResult[]>;
