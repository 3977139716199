import { ApplicationType } from "@api";
import { DELETE, GET, POST, PUT } from "@utils/fetch";
import { UserRole } from "./user";

export interface InsideTagDto {
  id: number;
  name: string;
}
export interface InsideTagWithSettingsDto extends InsideTagDto {
  visible: boolean;
}

interface ContentInternalTagPayload {
  tagId: number;
  contentId: number;
  applicationType: ApplicationType;
}

export interface InsideTagsSaveSettingsRequest {
  targetRole: UserRole;
  visibleIds: number[];
  restIds: number[];
}

export const InternalTagsApi = {
  list: (): Promise<InsideTagWithSettingsDto[]> => GET(`api/v1/admin/inside_tags`),
  listForRole: (role: UserRole): Promise<InsideTagWithSettingsDto[]> =>
    GET(`api/v1/admin/inside_tags?targetRole=${role}`),
  create: (name: string): Promise<InsideTagDto> => POST(`api/v1/admin/inside_tags`, { name }),
  update: (id: number, name: string): Promise<InsideTagDto> => PUT(`api/v1/admin/inside_tags/${id}`, { name }),
  delete: (id: number) => DELETE(`api/v1/admin/inside_tags/${id}`),
  assignOnContent: ({ tagId, contentId, applicationType }: ContentInternalTagPayload) =>
    POST(`api/v1/admin/${applicationType.toLowerCase()}/ugc/${contentId}/inside_tags/${tagId}`),
  unassignOnContent: ({ tagId, contentId, applicationType }: ContentInternalTagPayload) =>
    DELETE(`api/v1/admin/${applicationType.toLowerCase()}/ugc/${contentId}/inside_tags/${tagId}`),
  saveSettingsForRole: (request: InsideTagsSaveSettingsRequest) => POST(`api/v1/admin/inside_tags/settings`, request),
};
