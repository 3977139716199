import { ConfigProperty, ConfigPropertyDescriptor, properties } from "@config";
import { SearchStrategy } from "../models";
import ByContentId from "./ByContentId";
import ByPhoneNumber from "./ByPhoneNumber";
import ByPhoneNumberValidationCodes from "./ByPhoneNumberValidationCodes";
import ByShortUrl from "./ByShortLink";
import ByUserId from "./ByUserId";
import ByUserName from "./ByUserName";
import ByUserNickname from "./ByUserNickname";

const strategies: SearchStrategy[] = [
  ByUserId,
  ByContentId,
  ByUserNickname,
  ByUserName,
  ByShortUrl,
  ByPhoneNumber,
  ByPhoneNumberValidationCodes,
];

export default strategies;

export const strategiesRelatedKillswitches: ConfigPropertyDescriptor<boolean>[] = [
  properties[ConfigProperty.VERIFICATION_CODES_SEARCH_ENABLED],
];
