import { NotificationTemplatesApi } from "@api/notificationTemplates";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export interface NotificationTemplatesState {
  pushTypes: string[];
}

const initialState: NotificationTemplatesState = {
  pushTypes: [
    "NEW_LIKE",
    "CANCEL_LIKE",
    "NEW_COMMENT",
    "NEW_VIDEO_COMMENT",
    "NEW_CONTENT_FOR_FOLLOWER",
    "NEW_CHALLENGE",
    "NEW_REPIT",
    "RESPONSE_MODERATOR",
    "CHANGE_PROFILE_MODERATOR",
    "CHANGE_CONTENT_MODERATOR",
    "DELETE_CONTENT",
    "BLOCK_USER",
    "UGC_PUBLICATION_SUCCESSFUL",
    "USER_SINGLE_CAMEO_STICKERS_READY",
    "USER_MULTIPLE_CAMEO_STICKERS_READY",
    "NEW_SUBSCRIBER",
    "NEW_USER",
    "MENTION",
    "NEW_REPLY",
    "NEW_LIKE_COMMENT",
    "ORIGIN_UGC_PUBLICATION_FOR_SUBSCRIBERS",
    "ORIGIN_UGC_EXPIRATION_FOR_AUTHOR",
    "ORIGIN_UGC_EXPIRATION_FOR_SUBSCRIBERS",
    "ORIGIN_UGC_NEW_REPEAT",
    "MODERATION_DECISION",
    "NEW_REACTION",
    "PUBLICATION_AFTER_LONG_TIME",
    "PRIVATE_THREAD_PUBLISHED",
    "FULL_PRIVATE_THREAD_PUBLISHED",
    "AUDIO_PROCESSING_FINISHED",
    "AUDIO_PROCESSING_FAILED",
    "BIG_THREAD",
    "FRIENDS_ACTIVITY_IN_THREAD",
    "NEW_CREATOR",
    "SUSPENDED_CHANGE_UGC",
    "SUSPENDED_CHANGE_COMMENT",
    "SUSPENDED_CHANGE_PROFILE",
    "SUSPENDED_CHANGE_PROFILE_AVATAR",
    "SUSPENDED_ALL",
    "RESUMED_CHANGE_UGC",
    "RESUMED_CHANGE_COMMENT",
    "RESUMED_CHANGE_PROFILE",
    "RESUMED_CHANGE_PROFILE_AVATAR",
    "RESUMED_ALL",
    "UGC_DOWNLOAD_READY",
    "CAMEO_STICKERS_GENERATION_FAILED",
    "NEW_CRUSH_USER",
    "VIEWED_CRUSH_USER",
    "NEW_SHY_USER",
    "UGC_PUBLICATION_FOR_SUBSCRIBERS",
    "BANNER",
    "PERSONAL_THREAD_CREATED",
    "GROUP_ORIGIN_UGC_PUBLICATION_FOR_SUBSCRIBERS",
    "FIRST_PUNCH_REMINDER",
    "NEW_WAIT_PUNCH",
    "WAITED_PUNCH_PUBLISHED",
    "BEAT_TOP_APPROVED",
    "BEAT_BAD_DELETED",
    "BEAT_GOOD_HIDDEN",
    "NEW_CONTENT_VOTE",
  ],
};

const name = "notificationTemplates";

export const refreshPushTypesAction = createAsyncThunk(`${name}/refreshPushTypesAction`, async () => {
  return await NotificationTemplatesApi.listTypes();
});

const notificationTemplatesSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refreshPushTypesAction.fulfilled, (state, action) => {
      state.pushTypes = [...action.payload.filter((x) => x)].sort();
    });
  },
});

export default notificationTemplatesSlice.reducer;
