import { ApplicationType, appTypeToApplicationContentType } from "@api";
import { AdminStatusType } from "@api/admin";
import { CreateUserAdminDto, UserApi, UserFullDto } from "@api/user";
import { WaitPunchApi } from "@api/waitPunch";
import AppType from "@models/AppType";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  assignProfileBadgesAction,
  resumeUserPrivilegesAction,
  suspendUserPrivilegeAction,
} from "@store/commonActions/userActions";
import { RootState, ThunkApiType } from "@store/store";
import { uniq } from "lodash";
import { getCurrentUserAppType } from "./user";

export interface UsersCacheState {
  [ApplicationType.AUDIO]: Record<string, UserFullDto>;
  [ApplicationType.VIDEO]: Record<string, UserFullDto>;
  pendingUserIds: {
    [ApplicationType.AUDIO]: number[];
    [ApplicationType.VIDEO]: number[];
  };
  waitPunchCounters: Record<number, number>;
}

const initialState: UsersCacheState = {
  [ApplicationType.AUDIO]: {},
  [ApplicationType.VIDEO]: {},
  pendingUserIds: {
    [ApplicationType.AUDIO]: [],
    [ApplicationType.VIDEO]: [],
  },
  waitPunchCounters: {},
};

const name = "usersCache";

const usersToMap = (users: UserFullDto[]) =>
  users.reduce(
    (a, x) => {
      if (x.id) {
        a[x.id] = x;
      }
      return a;
    },
    {} as Record<string, UserFullDto>
  );

export const loadUsersToCache = createAsyncThunk<
  Partial<UsersCacheState>,
  { ids: number[]; checkExisting: boolean; allowNonBatchRequest?: boolean },
  ThunkApiType
>(`${name}/loadUsersToCache`, async ({ ids, checkExisting, allowNonBatchRequest }, thunkApi) => {
  const appType = thunkApi.getState().user.currentUser!.appType;
  const applicationType = appTypeToApplicationContentType[appType];
  const pendingUserIds = thunkApi.getState().usersCache.pendingUserIds[applicationType];
  const cacheValue = thunkApi.getState().usersCache[applicationType];
  const idsToRequest = uniq(checkExisting ? ids.filter((x) => !cacheValue[x]) : ids).filter(
    (x) => !pendingUserIds.includes(x)
  );
  if (!idsToRequest.length) {
    return {};
  }
  thunkApi.dispatch(
    usersCache.actions.changePendingUsersAction({ userIds: idsToRequest, applicationType, changeAction: "add" })
  );
  if (idsToRequest.length === 1 && allowNonBatchRequest) {
    // need to check later if this is allowed for all roles
    const user = await UserApi.getById(idsToRequest[0]);
    return { [applicationType]: { [idsToRequest[0]]: user } };
  }

  try {
    const data = await UserApi.loadBatch(idsToRequest);
    thunkApi.dispatch(
      usersCache.actions.changePendingUsersAction({ userIds: idsToRequest, applicationType, changeAction: "remove" })
    );
    if (!data.users) {
      return {};
    }
    return { [applicationType]: usersToMap(data.users) };
  } catch (e) {
    thunkApi.dispatch(
      usersCache.actions.changePendingUsersAction({ userIds: idsToRequest, applicationType, changeAction: "remove" })
    );
    throw e;
  }
});

export const updateUserField = createAsyncThunk<
  Partial<UsersCacheState>,
  { userId: number; request: Partial<CreateUserAdminDto> },
  { state: RootState }
>(`${name}/updateUser`, async ({ userId, request }, thunkApi) => {
  const appType = thunkApi.getState().user.currentUser!.appType;
  const applicationType = appTypeToApplicationContentType[appType];
  const user = await UserApi.updatePartial(userId, request);
  return { [applicationType]: { [userId]: user } };
});

export const blockUserAction = createAsyncThunk<
  Partial<UsersCacheState>,
  { userId: number; deleteVideoContent: boolean; deleteComments: boolean },
  { state: RootState }
>(`${name}/blockUserAction`, async ({ userId, deleteComments, deleteVideoContent }, thunkApi) => {
  const appType = thunkApi.getState().user.currentUser!.appType;
  const applicationType = appTypeToApplicationContentType[appType];
  await UserApi.block({ userId, deleteComments, deleteVideoContent });
  const data = await UserApi.loadBatch([userId]);
  if (!data.users) {
    return {};
  }
  return { [applicationType]: usersToMap(data.users) };
});

export const unblockUserAction = createAsyncThunk<Partial<UsersCacheState>, { userId: number }, { state: RootState }>(
  `${name}/unblockUserAction`,
  async ({ userId }, thunkApi) => {
    const appType = thunkApi.getState().user.currentUser!.appType;
    const applicationType = appTypeToApplicationContentType[appType];
    const user = await UserApi.updatePartial(userId, { statusType: AdminStatusType.ACTIVE });
    return { [applicationType]: { [userId]: user } };
  }
);

export const reloadProfileUntilFieldChangesAction = createAsyncThunk<
  void,
  { userId: number; fieldName: string },
  ThunkApiType
>(`${name}/reloadProfileUntilFieldChangesAction`, async ({ userId, fieldName }, thunkApi) => {
  const stepMs = 500;
  const maxIteration = 10;
  const currentProfile = getUserById(userId)(thunkApi.getState());
  const extractValue = (user: UserFullDto | undefined) => {
    if (!user) {
      return undefined;
    }
    return (user as any)[fieldName];
  };
  const currentValue = extractValue(currentProfile);
  thunkApi.dispatch(loadUsersToCache({ ids: [userId], checkExisting: false }));

  const reloadWithTimeout = async (delayMs: number): Promise<UserFullDto | undefined> => {
    return new Promise((resolve) => {
      setTimeout(() => {
        thunkApi
          .dispatch(loadUsersToCache({ ids: [userId], checkExisting: false }))
          .then(() => resolve(getUserById(userId)(thunkApi.getState())));
      }, delayMs);
    });
  };
  for (let i = 0; i < maxIteration; i++) {
    const profile = await reloadWithTimeout(stepMs);
    const updatedValue = extractValue(profile);
    if (updatedValue !== currentValue) {
      break;
    }
  }
});

export const loadWaitPunchCountersAction = createAsyncThunk(
  `${name}/loadWaitPunchCountersAction`,
  async (userIds: number[]) => {
    const data = await WaitPunchApi.getCountersForAuthors(userIds);
    return data;
  },
  {
    condition: (_, { getState }) => getCurrentUserAppType(getState() as RootState) === AppType.PUNCH,
  }
);

interface ChangePendingUsersRequest {
  userIds: number[];
  applicationType: ApplicationType;
  changeAction: "add" | "remove";
}

const usersCache = createSlice({
  name,
  initialState,
  reducers: {
    changePendingUsersAction: (state, action: PayloadAction<ChangePendingUsersRequest>) => {
      const { userIds, applicationType, changeAction } = action.payload;
      if (changeAction === "add") {
        state.pendingUserIds[applicationType].push(...userIds);
      } else {
        state.pendingUserIds[applicationType] = state.pendingUserIds[applicationType].filter(
          (x) => !userIds.includes(x)
        );
      }
    },
    addUsersToCache: (state, action: PayloadAction<{ users: UserFullDto[]; currentApp: AppType }>) => {
      const {
        payload: { users, currentApp },
      } = action;
      const applicationType = appTypeToApplicationContentType[currentApp];
      Object.assign(state[applicationType], usersToMap(users));
    },
  },
  extraReducers: (builder) => {
    const defaultHandler = (state: UsersCacheState, payload: Partial<UsersCacheState>) => {
      Object.values(ApplicationType).forEach((type) => {
        if (payload[type]) {
          Object.assign(state[type], payload[type]);
        }
      });
    };
    builder.addCase(loadUsersToCache.fulfilled, (state, action) => defaultHandler(state, action.payload));
    builder.addCase(updateUserField.fulfilled, (state, action) => defaultHandler(state, action.payload));
    builder.addCase(blockUserAction.fulfilled, (state, action) => defaultHandler(state, action.payload));
    builder.addCase(unblockUserAction.fulfilled, (state, action) => defaultHandler(state, action.payload));
    builder.addCase(suspendUserPrivilegeAction.fulfilled, (state, action) => {
      const { user, appType } = action.payload;
      state[appTypeToApplicationContentType[appType]][user.id!] = user;
    });
    builder.addCase(resumeUserPrivilegesAction.fulfilled, (state, action) => {
      const { user, appType } = action.payload;
      state[appTypeToApplicationContentType[appType]][user.id!] = user;
    });
    builder.addCase(assignProfileBadgesAction.fulfilled, (state, action) => {
      const { user, appType } = action.payload;
      state[appTypeToApplicationContentType[appType]][user.id!] = user;
    });
    builder.addCase(loadWaitPunchCountersAction.fulfilled, (state, action) => {
      Object.assign(state.waitPunchCounters, action.payload);
    });
  },
});

export default usersCache.reducer;

export const { addUsersToCache } = usersCache.actions;

export const getUserById = (id: number | null | undefined) => (state: RootState) => {
  if (typeof id !== "number") {
    return undefined;
  }
  return getCachedUsersMap(state)[id];
};

export const getCachedUsersMap = (state: RootState) => {
  const appType = state.user.currentUser?.appType;
  if (!appType) {
    return {};
  }
  const applicationType = appTypeToApplicationContentType[appType];
  return state.usersCache[applicationType];
};
