import { Page, Pageable } from "@api";
import { POST, buildQuery } from "@utils/fetch";
import { CommentTriggeredPhrase } from "./commentsModeration";

export enum AssignedBeatTextModerationStatus {
  NEW = "NEW",
  DELETED = "DELETED",
  AUTO_DELETED = "AUTO_DELETED",
  APPROVED = "APPROVED",
  AUTO_APPROVED = "AUTO_APPROVED",
}

export enum BeatTextModerationStatus {
  NEW = "NEW",
  DELETED = "DELETED",
  APPROVED = "APPROVED",
}

export enum ManualBeatTextModerationStatus {
  DELETED = "DELETED",
  APPROVED = "APPROVED",
}

export interface BeatTextFilter {
  textIds?: number[];
  moderationStatuses?: BeatTextModerationStatus[];
  assignedModerationStatuses?: AssignedBeatTextModerationStatus[];
}

export interface BeatTextDto {
  textId: number;
  text: string;
  createdAt: string;
  version: number;
  moderationStatus: BeatTextModerationStatus;
  moderatorId?: number;
}

export interface BatchSetBeatTextModerationStatusResponse {
  moderatedTexts?: BeatTextDto[];
  changedTexts?: BeatTextDto[];
}

export interface SetBeatTextModerationStatus {
  textId: number;
  version: number;
  manualModerationStatus: ManualBeatTextModerationStatus;
}

export interface BatchSetBeatTextModerationStatus {
  batch: SetBeatTextModerationStatus[];
}

export interface BeatTextViolationLog {
  id: number;
  textId: number;
  triggeredPhrases: CommentTriggeredPhrase[];
  createdAt: string;
}

export interface BeatTextViolationsResponse {
  violations: BeatTextViolationLog[];
}

export interface CoverTextDto extends BeatTextDto {
  beatId: number;
  assignedModerationStatus: AssignedBeatTextModerationStatus;
}

export const BeatTextsModerationApi = {
  list: ({ filter, pagination }: { filter: BeatTextFilter; pagination: Pageable }): Promise<Page<BeatTextDto>> =>
    POST(`api/v1/admin/moderation-manager/beat-text/list?${buildQuery(pagination)}`, filter),
  setStatuses: (request: BatchSetBeatTextModerationStatus): Promise<BatchSetBeatTextModerationStatusResponse> =>
    POST(`api/v1/admin/moderation-manager/beat-text/status-batch`, request),
  getViolations: (textIds: number[]): Promise<BeatTextViolationsResponse> =>
    POST(`api/v1/admin/moderation-manager/beat-text/violations`, { textIds }),
  listCovers: ({ filter, pagination }: { filter: BeatTextFilter; pagination: Pageable }): Promise<Page<CoverTextDto>> =>
    POST(`api/v1/admin/moderation-manager/beat-text/list/cover?${buildQuery(pagination)}`, filter),
};
