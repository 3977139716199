import { activateUser, LoggedInUser } from "@slices/user";
import { useAppDispatch } from "@store/hooks";
import { useCallback } from "react";

export default () => {
  const dispatch = useAppDispatch();
  return useCallback(
    (user: LoggedInUser) => {
      dispatch(activateUser(user));
    },
    [dispatch]
  );
};
