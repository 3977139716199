import { useResolvedKillswitches } from "@config";
import useBreakpointMatch, { Breakpoint, MatchMode } from "@hooks/useBreakpointMatch";
import useDialogState from "@hooks/useDialogState";
import { AppBar, Drawer, ModalProps, Toolbar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import PerRoleRedirector from "@navigation/PerRoleRedirector";
import routes, {
  canShowInNavigationMenu,
  canUseByApp,
  canUseByKillswitch,
  canUseByRole,
} from "@navigation/routesConfig";
import AccessDenied from "@pages/accessDenied/AccessDenied";
import { loadRuntimeConfigurationAction } from "@slices/appSettings";
import { migrateClientsFromUserSliceAction } from "@slices/client";
import { LoggedInUser } from "@slices/user";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { BlockUserContextProvider } from "@widgets/BlockUserContext";
import ErrorBoundary from "@widgets/ErrorBoundary";
import FullscreenLoading from "@widgets/FulllscreenLoading";
import classNames from "classnames";
import React, { Suspense, useEffect, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import * as styles from "./App.module.scss";
import LoginPage from "./LoginPage";
import MobileNavigationDrawerContent from "./MobileNavigationDrawerContent";
import NavigationDrawer from "./NavigationDrawer";
import HeaderContent from "./header/HeaderContent";
import MobileHeaderContent from "./header/MobileHeaderContent";
import usePreferredNavigationDrawerPosition from "./usePreferredNavigationDrawerPosition";

const AppRouter = ({ user }: { user: LoggedInUser }) => {
  const { role, appType } = user;
  const resolvedKillswitches = useResolvedKillswitches(routes);
  const pathToComponentMap = useMemo(() => {
    return routes.reduce(
      (a, x) => {
        if (!canUseByKillswitch(x, resolvedKillswitches)) {
          return a;
        }
        a[x.path] = canUseByApp(x, appType) && canUseByRole(x, role) ? x.component : AccessDenied;
        return a;
      },
      {} as Record<string, React.FC>
    );
  }, [role, appType, resolvedKillswitches]);

  return (
    <BlockUserContextProvider>
      <Suspense fallback={<CircularProgress />}>
        <ErrorBoundary>
          <Routes>
            {Object.entries(pathToComponentMap).map(([path, Component]) => (
              <Route key={path} path={path} element={<Component />} />
            ))}
            <Route path="*" element={<PerRoleRedirector />} />
          </Routes>
        </ErrorBoundary>
      </Suspense>
    </BlockUserContextProvider>
  );
};

const useNavbarRoutes = (user: LoggedInUser) => {
  const { role, appType } = user;
  const resolvedKillswitches = useResolvedKillswitches(routes);
  const navBarRoutes = useMemo(
    () => routes.filter((x) => canShowInNavigationMenu(x, role, appType, resolvedKillswitches)),
    [role, appType, resolvedKillswitches]
  );
  return navBarRoutes;
};

const DesktopSkeleton = ({ user }: { user: LoggedInUser }) => {
  const navBarRoutes = useNavbarRoutes(user);
  const [drawerPosition] = usePreferredNavigationDrawerPosition();
  return (
    <div className={classNames(styles.root, drawerPosition === "right" && styles.right)}>
      <AppBar className={styles.header} position="static">
        <Toolbar variant="dense" className={styles.toolbar}>
          <HeaderContent />
        </Toolbar>
      </AppBar>
      <NavigationDrawer className={styles.navigation} routes={navBarRoutes} />
      <div className={styles.main}>
        <AppRouter user={user} />
      </div>
    </div>
  );
};

const MobileSkeleton = ({ user }: { user: LoggedInUser }) => {
  const navBarRoutes = useNavbarRoutes(user);
  const { open, closeDialog, openDialog } = useDialogState();
  return (
    <>
      <div className={styles.mobileRoot}>
        <AppBar className={styles.header} position="static">
          <Toolbar variant="dense" className={styles.toolbar}>
            <MobileHeaderContent toggleDrawer={openDialog} />
          </Toolbar>
        </AppBar>
        <div className={styles.main}>
          <AppRouter user={user} />
        </div>
      </div>
      <Drawer
        open={open}
        onClose={closeDialog}
        variant="temporary"
        ModalProps={{ fullWidth: true, fullScreen: true } as Partial<ModalProps>}
      >
        <MobileNavigationDrawerContent appType={user.appType} routes={navBarRoutes} closeDrawer={closeDialog} />
      </Drawer>
    </>
  );
};

const App = () => {
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const isSmall = useBreakpointMatch(Breakpoint.SM, MatchMode.LT);
  const waitingForRuntimeConfig = useAppSelector((state) => state.appSettings.waitingForRuntimeConfig);
  const dispatch = useAppDispatch();
  const currentUserApp = currentUser?.appType;
  useEffect(() => {
    // TODO drop next week
    dispatch(migrateClientsFromUserSliceAction());
  }, [dispatch]);
  useEffect(() => {
    if (!currentUserApp) {
      return;
    }
    let timeoutHandle: NodeJS.Timeout | null = null;
    const reloadConfigAndSchedule = () => {
      dispatch(loadRuntimeConfigurationAction()).then(() => {
        timeoutHandle = setTimeout(reloadConfigAndSchedule, 60_000);
      });
    };
    reloadConfigAndSchedule();
    return () => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
      }
    };
  }, [currentUserApp, dispatch]);

  if (!currentUser) {
    return <LoginPage />;
  }
  if (waitingForRuntimeConfig) {
    return <FullscreenLoading />;
  }
  if (isSmall) {
    return <MobileSkeleton key={currentUser.token} user={currentUser} />;
  }
  return <DesktopSkeleton key={currentUser.token} user={currentUser} />;
};

export default App;
