import { UserRole } from "@api/user";
import AppType from "@models/AppType";
import { getCurrentUserAppType, getCurrentUserRole } from "@slices/user";
import { useAppSelector } from "@store/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const roleToPathMap = {
  [UserRole.MODERATOR]: "videoModeration",
  [UserRole.SUPER_MODERATOR]: "videoModeration",
  [UserRole.CONTENT_MANAGER]: "userSearch",
  [UserRole.ADMINISTRATOR]: "admins",
  [UserRole.SUPER_ADMINISTRATOR]: "admins",
  [UserRole.SUPERVISOR]: "supervisedUsers",
  [UserRole.WEBADMIN_VIEWER]: (appType: AppType) => (appType === AppType.PUNCH ? "audioUGC" : "videoUGC"),
  [UserRole.DATA_ANALYST]: "abTests",
  [UserRole.BEAT_PRODUCER]: "beatsModeration",
} as Record<UserRole, string | ((appType: AppType) => string)>;

const PerRoleRedirector = () => {
  const role = useAppSelector(getCurrentUserRole);
  const app = useAppSelector(getCurrentUserAppType);
  const navigate = useNavigate();
  useEffect(() => {
    if (!role) {
      return;
    }
    const pathOrResolver = roleToPathMap[role];
    const path = typeof pathOrResolver === "function" ? pathOrResolver(app) : pathOrResolver;
    if (path) {
      navigate(`/${path}`, { replace: true });
    }
  }, [role, app, navigate]);
  return null;
};

export default PerRoleRedirector;
