import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import * as styles from "./MobileHeaderContent.module.scss";
import PageTitle from "./PageTitle";
import UserButton from "./UserButton";

const MobileHeaderContent = ({ toggleDrawer }: { toggleDrawer: () => void }) => {
  return (
    <>
      <IconButton onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
      <PageTitle className={styles.title} />
      <UserButton compact />
    </>
  );
};

export default MobileHeaderContent;
