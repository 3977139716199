import { ConfigProperty, ConfigPropertyDescriptor, properties, usePropertyValues } from "@config";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import { NotifiableContentType } from "@slices/moderationNotificator";
import { mapValues, uniq } from "lodash";
import { useMemo } from "react";
import { defineMessages } from "react-intl";

export const notificationTypeToIcon = {
  [NotifiableContentType.UGC]: VideocamIcon,
  [NotifiableContentType.COMMENT]: AnnouncementIcon,
  [NotifiableContentType.COMPLAINT_COMMENT]: SpeakerNotesOffIcon,
  [NotifiableContentType.COMPLAINT_UGC]: VideocamOffIcon,
};

export const routeToNotificationType = {
  [NotifiableContentType.UGC]: "/videoModeration",
  [NotifiableContentType.COMMENT]: "/commentsModeration",
  [NotifiableContentType.COMPLAINT_COMMENT]: "/complaintsModeration?view=comment",
  [NotifiableContentType.COMPLAINT_UGC]: "/complaintsModeration?view=ugc",
};

export const notificationTypeToMessage = defineMessages({
  [NotifiableContentType.UGC]: { defaultMessage: "UGC", description: "nontranslatable" },
  [NotifiableContentType.COMMENT]: { defaultMessage: "Comments" },
  [NotifiableContentType.COMPLAINT_COMMENT]: { defaultMessage: "Complaints on comments" },
  [NotifiableContentType.COMPLAINT_UGC]: { defaultMessage: "Complaints on UGC" },
});

export const log = (message: string) => {
  // eslint-disable-next-line no-console
  console.log(`Moderation Notification: ${message}`);
};

const contentTypeToThresholdPropertyMap: Record<NotifiableContentType, ConfigProperty> = {
  [NotifiableContentType.UGC]: ConfigProperty.MODERATION_NOTIFICATIONS_UNCHECKED_VIDEOS_THRESHOLD,
  [NotifiableContentType.COMMENT]: ConfigProperty.MODERATION_NOTIFICATIONS_UNCHECKED_COMMENTS_THRESHOLD,
  [NotifiableContentType.COMPLAINT_UGC]: ConfigProperty.MODERATION_NOTIFICATIONS_UNCHECKED_COMPLAINTS_THRESHOLD,
  [NotifiableContentType.COMPLAINT_COMMENT]: ConfigProperty.MODERATION_NOTIFICATIONS_UNCHECKED_COMPLAINTS_THRESHOLD,
};
const propertiesList: ConfigPropertyDescriptor<number>[] = uniq(Object.values(contentTypeToThresholdPropertyMap)).map(
  (x: ConfigProperty) => (properties as any)[x] as ConfigPropertyDescriptor<number>
);

export const useResolvedNotificationThresholds = (): Record<NotifiableContentType, number> => {
  const resolvedValues = usePropertyValues(propertiesList);
  return useMemo(() => mapValues(contentTypeToThresholdPropertyMap, (x) => resolvedValues[x] ?? 1), [resolvedValues]);
};
