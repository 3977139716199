import useParsedLocationSearch from "@hooks/useParsedLocationSearch";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import { getContentById } from "@slices/contentCache";
import { useAppSelector } from "@store/hooks";
import { defineMessages, FormattedMessage } from "react-intl";

const messages = defineMessages({
  titleWithNickname: { defaultMessage: "Video by {nickname}" },
});

const defaultText = "UGC";

const ContentCardHeaderTitle = () => {
  const { id } = useParsedLocationSearch();
  const content = useAppSelector(getContentById(id as number));
  useSetDocumentTitle({
    ...(content && {
      messageDescriptor: messages.titleWithNickname,
      values: { nickname: `${content.user?.nickName || content.user?.id}` },
    }),
    ...(!content && { plainText: defaultText }),
  });
  if (content) {
    return (
      <FormattedMessage
        {...messages.titleWithNickname}
        values={{ nickname: content.user?.nickName || content.user?.id }}
      />
    );
  }
  return <span>{defaultText}</span>;
};

export default ContentCardHeaderTitle;
