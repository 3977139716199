import { ChildSortingType, OffsetLimit, ResponseDto, SortingSortByOptional } from "@api";
import { DELETE, GET, PATCH, POST, buildQuery, formDataWithFile } from "@utils/fetch";
import { ContentTagModerationStatus } from "./hashtagsModeration";

const urlPrefix = `api/v1/admin/tags`;

export interface ShortTagDto {
  id: number;
  name?: string;
  moderationStatus: ContentTagModerationStatus;
}

export enum TagType {
  RICHTAG = "RICHTAG",
  CHALLENGE = "CHALLENGE",
}

export interface TagDto {
  id: number;
  name: string;
  createdAt: string;
  moderationStatus: ContentTagModerationStatus;
  type?: TagType;
  description?: string;
  image?: string;
  userCount?: number;
  contentCount?: number;
}

export interface UpdateTagDto {
  type: TagType;
  description?: string;
}

export interface CreateTagDto extends UpdateTagDto {
  name: string;
}

export interface TagsFilter {
  name?: string | null;
  description?: string | null;
  tagType?: TagType | null;
}

export enum TagSorting {
  contentCreatedAt = "contentCreatedAt",
  name = "name",
}

export interface BenefitDto {
  endDate?: string;
  exists: boolean;
  tagId: number;
}

export const TagsApi = {
  list: ({
    filter,
    sorting = ChildSortingType.DESC,
    sortBy = TagSorting.contentCreatedAt,
    offset,
    limit,
  }: {
    filter?: TagsFilter;
  } & OffsetLimit &
    SortingSortByOptional): Promise<ResponseDto<TagDto>> =>
    GET(`${urlPrefix}?${buildQuery({ ...filter, sorting, sortBy, offset, limit })}`),
  create: (request: CreateTagDto): Promise<TagDto> => POST(urlPrefix, request),
  update: (id: number, request: UpdateTagDto): Promise<TagDto> => PATCH(`${urlPrefix}/${id}`, request),
  delete: (id: number) => DELETE(`${urlPrefix}/${id}`),
  getById: (tagId: number): Promise<TagDto> => GET(`${urlPrefix}/${tagId}`),
  batchByIds: (ids: number[]): Promise<TagDto[]> => POST(`${urlPrefix}/get-by-ids`, { ids }),
  uploadImage: (id: number, image: File): Promise<TagDto> =>
    POST(`${urlPrefix}/${id}/image`, formDataWithFile("image", image)),
  getBeatsOnChallenge: (tagId: number): Promise<number[]> => GET(`api/v1/admin/beat/tag/${tagId}`),
  saveBeatsOnChallenge: (tagId: number, beatIds: number[]) =>
    POST(`api/v1/admin/beat/tag/upsert?tagId=${tagId}`, beatIds),
  getBenefitsByTagIds: (tagIds: number[]): Promise<BenefitDto[]> =>
    POST(`api/v1/admin/tags/benefits/get-by-tag-ids`, { ids: tagIds }),
  createOrUpdateBenefit: (tagId: number, endDate: Date | null) =>
    PATCH(`api/v1/admin/tags/${tagId}/benefits`, { endDate: endDate?.toISOString() || null }),
  deleteBenefit: (tagId: number) => DELETE(`api/v1/admin/tags/${tagId}/benefits`),
};
