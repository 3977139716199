import AppType from "@models/AppType";
import { POST } from "@utils/fetch";
import { UserShortDto } from "./user";

export interface RefreshResponse {
  token: string;
  refreshToken: string;
  expiresAt: string;
}

export interface ClientAuthResponse extends RefreshResponse {
  user: UserShortDto;
}

export const ClientAuthApi = {
  requestCode: (request: { phoneNumber: string; deviceUuid: string }, appType: AppType) =>
    POST("api/v1/auth/login/phone/request-code", request, { appType }),
  confirmCode: (
    request: { deviceUuid: string; phoneNumber: string; code: string },
    appType: AppType
  ): Promise<ClientAuthResponse> => POST(`api/v1/auth/login/phone/confirm-code`, request, { appType }),
  refresh: ({
    deviceUuid,
    refreshToken,
    appType,
  }: {
    refreshToken: string;
    deviceUuid: string;
    appType: AppType;
  }): Promise<RefreshResponse> =>
    POST(`api/v1/auth/refresh`, { deviceUuid, refreshToken }, { token: refreshToken, appType }),
  logout: (deviceUuid: string, token: string, appType: AppType) =>
    POST(`api/v1/auth/logout`, { uuid: deviceUuid }, { token, appType }),
};
