import { ContentPunchAdminDto } from "@api/audioContent";
import { ClientCommentsApi } from "@api/clientComments";
import { CommentDto } from "@api/comments";
import { ContentShortChipzAdminDto } from "@api/videoContent";
import useDefaultNetworkErrorHandler from "@hooks/useDefaultNetworkErrorHandler";
import useDialogPayloadState from "@hooks/useDialogPayloadState";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { getClientUserForCurrentApp } from "@slices/client";
import { useAppSelector } from "@store/hooks";
import ConfirmationDialog from "@widgets/ConfirmationDialog";
import DateTime from "@widgets/DateTime";
import LinkToUser from "@widgets/LinkToUser";
import classNames from "classnames";
import { noop, uniqBy } from "lodash";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AddCommentDialogContent from "./AddCommentDialogContent";
import * as styles from "./CommentsDialogContent.module.scss";

const pageSize = 10;

const CommentCard = ({
  comment,
  loadReplies,
  repliesRequested,
  currentUserId,
  parentCommentId,
  onReply,
  onDelete,
}: {
  comment: CommentDto;
  topLevel?: boolean;
  loadReplies: (comment: CommentDto) => void;
  repliesRequested: boolean;
  currentUserId: number;
  parentCommentId?: number;
  onReply: ({ commentId }: { commentId: number }) => void;
  onDelete: ({ commentId, parentCommentId }: { commentId: number; parentCommentId: number | undefined }) => void;
}) => {
  const lines = useMemo(() => (comment.message ?? "").split("\n"), [comment.message]);
  return (
    <Card
      variant="outlined"
      raised
      elevation={currentUserId === comment.user?.id ? 20 : undefined}
      className={classNames(parentCommentId && styles.secondLevel)}
      data-testid={`${parentCommentId ? "reply" : "comment"}-card-${comment.id}`}
    >
      <CardHeader
        className={styles.cardHeader}
        avatar={<Avatar src={comment.user?.avatar ? `${comment.user.avatar}?width=100` : undefined} />}
        title={comment.user ? <LinkToUser id={comment.user.id} nickName={comment.user.nickName} /> : undefined}
        subheader={<DateTime value={comment.createdAt} />}
      />
      <CardContent className={styles.cardContent}>
        <Typography variant="body1">
          {lines.map((x, index) => (
            // immutable so no big deal
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>{x}</div>
          ))}
        </Typography>
      </CardContent>
      <CardActions className={styles.cardActions}>
        {!parentCommentId && (
          <Button size="small" onClick={() => onReply({ commentId: comment.id })} data-testid="reply-button">
            <FormattedMessage defaultMessage="Reply" />
          </Button>
        )}
        {!parentCommentId && !!comment.commentCount && (
          <Button
            size="small"
            onClick={() => loadReplies(comment)}
            disabled={repliesRequested}
            data-testid="show-replies-button"
          >
            <FormattedMessage defaultMessage="Show replies" /> ({comment.commentCount})
          </Button>
        )}
        {comment.user?.id === currentUserId && (
          <Button
            size="small"
            color="warning"
            onClick={() => onDelete({ commentId: comment.id, parentCommentId })}
            data-testid="delete-button"
          >
            <FormattedMessage defaultMessage="Delete" />
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default function CommentsDialogContent({
  content,
  closeDialog,
  reloadContent,
}: {
  content: ContentShortChipzAdminDto | ContentPunchAdminDto;
  closeDialog: () => void;
  reloadContent: () => void;
}) {
  const user = useAppSelector(getClientUserForCurrentApp)!;
  const errorHandler = useDefaultNetworkErrorHandler();
  const [comments, setComments] = useState<CommentDto[]>([]);
  const [repliesMap, setRepliesMap] = useState<Record<number, CommentDto[]>>({});
  const [total, setTotal] = useState<number>(0);
  const refresh = useCallback(() => {
    ClientCommentsApi.getForContent({ contentId: content.id, offset: 0, limit: pageSize })
      .then(({ content, total }) => {
        setComments(content ?? []);
        setTotal(total ?? 0);
      })
      .catch(errorHandler);
  }, [content.id, errorHandler]);
  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadMore = useCallback(() => {
    ClientCommentsApi.getForContent({ contentId: content.id, offset: comments.length, limit: pageSize })
      .then(({ content, total }) => {
        setComments((current) => uniqBy([...current, ...(content ?? [])], "id"));
        setTotal(total ?? 0);
      })
      .catch(errorHandler);
  }, [comments, content.id, errorHandler]);
  const loadReplies = useCallback(
    (comment: CommentDto) => {
      ClientCommentsApi.getReplies({ commentId: comment.id, offset: 0, limit: comment.commentCount || 10 })
        .then(({ content }) => {
          setRepliesMap((current) => ({ ...current, [comment.id]: content ?? [] }));
        })
        .catch(errorHandler);
    },
    [errorHandler]
  );
  const addCommentDialogState = useDialogPayloadState<{ commentId: number | undefined }>();
  const onAddedComment = useCallback(
    (commentId: number | undefined) => {
      reloadContent();
      if (commentId) {
        ClientCommentsApi.getById(commentId).then((comment) => {
          setComments((current) => current.map((x) => (x.id === comment.id ? comment : x)));
          loadReplies(comment);
        });
      } else {
        refresh();
      }
    },
    [refresh, loadReplies, reloadContent]
  );
  const deleteCommentDialogState = useDialogPayloadState<{ commentId: number; parentCommentId: number | undefined }>();
  return (
    <>
      <DialogTitle>{content.description ?? <FormattedMessage defaultMessage="Comments" />}</DialogTitle>
      <DialogContent>
        {comments.map((comment) => {
          const replies = repliesMap[comment.id];
          return (
            <Fragment key={comment.id}>
              <CommentCard
                comment={comment}
                loadReplies={loadReplies}
                topLevel
                repliesRequested={replies !== undefined}
                currentUserId={user.userId}
                onReply={addCommentDialogState.openDialog}
                onDelete={deleteCommentDialogState.openDialog}
              />
              {replies?.map((reply) => (
                <CommentCard
                  key={reply.id}
                  comment={reply}
                  loadReplies={loadReplies}
                  topLevel={false}
                  repliesRequested={false}
                  currentUserId={user.userId}
                  parentCommentId={comment.id}
                  onReply={noop}
                  onDelete={deleteCommentDialogState.openDialog}
                />
              ))}
            </Fragment>
          );
        })}
        {comments.length < (total ?? 0) && (
          <Button
            onClick={loadMore}
            sx={{ mt: "8px" }}
            fullWidth
            variant="contained"
            data-testid="more-comments-button"
          >
            <FormattedMessage defaultMessage="More" />
          </Button>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} variant="outlined">
          <FormattedMessage defaultMessage="Dismiss" />
        </Button>
        <Button
          variant="contained"
          onClick={() => addCommentDialogState.openDialog({ commentId: undefined })}
          data-testid="add-content-comment-button"
        >
          <FormattedMessage defaultMessage="Add comment" />
        </Button>
      </DialogActions>
      <Dialog
        open={addCommentDialogState.open}
        onClose={addCommentDialogState.closeDialog}
        fullWidth
        maxWidth="sm"
        data-testid="add-comment-dialog"
      >
        {addCommentDialogState.payload && (
          <AddCommentDialogContent
            contentId={content.id}
            commentId={addCommentDialogState.payload.commentId}
            closeDialog={addCommentDialogState.closeDialog}
            onAddedComment={onAddedComment}
          />
        )}
      </Dialog>
      <ConfirmationDialog
        customTestId="comment-deletion-confirmation-dialog"
        open={deleteCommentDialogState.open}
        title={<FormattedMessage defaultMessage="Delete this comment?" />}
        onConfirm={() => {
          const { commentId, parentCommentId } = deleteCommentDialogState.payload!;
          ClientCommentsApi.delete(commentId)
            .then(() => {
              reloadContent();
              deleteCommentDialogState.closeDialog();
              if (parentCommentId) {
                ClientCommentsApi.getById(parentCommentId)
                  .then((comment) => {
                    setComments((current) => current.map((x) => (x.id === comment.id ? comment : x)));
                    loadReplies(comment);
                  })
                  .catch(errorHandler);
              } else {
                refresh();
              }
            })
            .catch(errorHandler);
        }}
        onDismiss={deleteCommentDialogState.closeDialog}
        confirmButtonProps={{ color: "warning", variant: "outlined" }}
      />
    </>
  );
}
