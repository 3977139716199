import { useEffect, useState } from "react";
import {
  extraLargeWidth,
  extraSmallWidth,
  largeWidth,
  mediumWidth,
  smallWidth,
} from "../partials/_breakpoints-export.scss";

export enum Breakpoint {
  XS = "XS",
  SM = "SM",
  MD = "MD",
  LG = "LG",
  XL = "XL",
}

export enum MatchMode {
  GT = "GT",
  LT = "LT",
}

const breakpointToWidthMap = {
  [Breakpoint.XS]: extraSmallWidth,
  [Breakpoint.SM]: smallWidth,
  [Breakpoint.MD]: mediumWidth,
  [Breakpoint.LG]: largeWidth,
  [Breakpoint.XL]: extraLargeWidth,
} as Record<Breakpoint, string>;

const pickQuery = (breakpoint: Breakpoint, matchMode: MatchMode): string => {
  const width = breakpointToWidthMap[breakpoint];
  if (matchMode === MatchMode.GT) {
    return `(min-width:${width})`;
  }
  return `(max-width:${width})`;
};

/**
 *
 * @param breakpoint one of possible breakpoints
 * @param matchMode GT will check max-width, LT - min-width
 * @returns
 */
const useBreakpointMatch = (breakpoint: Breakpoint, matchMode: MatchMode): boolean => {
  const query = pickQuery(breakpoint, matchMode);
  const [matches, setMatches] = useState<boolean>(() => window.matchMedia(query).matches);

  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    setMatches(matchMedia.matches);
    const listener = (e: MediaQueryListEvent) => setMatches(e.matches);
    matchMedia.addEventListener("change", listener);
    return () => {
      matchMedia.removeEventListener("change", listener);
    };
  }, [query]);

  return matches;
};

export default useBreakpointMatch;
