import useBreakpointMatch, { Breakpoint, MatchMode } from "@hooks/useBreakpointMatch";
import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import useParsedLocationSearch from "@hooks/useParsedLocationSearch";
import { Breadcrumbs, Link as MuiLink, Stack } from "@mui/material";
import { RouteDescriptor } from "@navigation/routesConfig";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import { SupervisedUsersTableViewMode, setLastTableViewModeAction } from "@slices/supervisedUsers";
import { getUserById } from "@slices/usersCache";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import NavigationTabs from "@widgets/NavigationTabs";
import UserShortUrlButton from "@widgets/UserShortUrlButton";
import { useEffect } from "react";
import { FormattedMessage, defineMessage, defineMessages } from "react-intl";
import { Link, useLocation } from "react-router-dom";

interface TitleProps {
  route: RouteDescriptor;
  pathname: string;
}

const tabMessages = defineMessages({
  [SupervisedUsersTableViewMode.ALL]: { defaultMessage: "Users" },
  [SupervisedUsersTableViewMode.BADGED]: { defaultMessage: "Prof. badges" },
  [SupervisedUsersTableViewMode.LABELS_PERMANENT]: { defaultMessage: "Labels (permanent)" },
  [SupervisedUsersTableViewMode.LABELS_TEMPORARY]: { defaultMessage: "Labels (temporary)" },
});

const TabbedTableTitle = () => {
  const route = useCurrentRouteDescriptor();
  useSetDocumentTitle({ messageDescriptor: route!.title! });
  const lastTab = useAppSelector((state) => state.supervisedUsers.lastTableViewMode);
  const { tab } = useParsedLocationSearch();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (tab) {
      dispatch(setLastTableViewModeAction(tab as SupervisedUsersTableViewMode));
    }
  }, [tab, dispatch]);
  return (
    <NavigationTabs
      possibleValues={Object.values(SupervisedUsersTableViewMode)}
      tabQueryKey="tab"
      tabMessagesMap={tabMessages}
      preferredTab={lastTab}
    />
  );
};

const createDescriptor = defineMessage({ defaultMessage: "Create new person" });

const shortCreateDescriptor = defineMessage({ defaultMessage: "Create" });
const CreateTitle = ({ route, pathname }: TitleProps) => {
  const isSmall = useBreakpointMatch(Breakpoint.SM, MatchMode.LT);
  useSetDocumentTitle({ messageDescriptor: createDescriptor });
  return (
    <Breadcrumbs color="primary">
      <MuiLink component={Link} to={pathname} underline="always">
        <FormattedMessage {...route.title} />
      </MuiLink>
      <FormattedMessage {...(isSmall ? shortCreateDescriptor : createDescriptor)} />
    </Breadcrumbs>
  );
};

const cardDescriptor = defineMessage({ defaultMessage: "Person #{id}" });

const CardTitle = ({ route, pathname }: TitleProps) => {
  const user = useAppSelector((state) => getUserById(state.supervisedUsers.currentPersonUserId)(state));
  const query = useParsedLocationSearch();
  useSetDocumentTitle(
    user?.nickName ? { plainText: user.nickName } : { messageDescriptor: cardDescriptor, values: { id: `${query.id}` } }
  );
  if (!route) {
    return null;
  }
  return (
    <Breadcrumbs color={"primary"}>
      <MuiLink component={Link} to={pathname} underline="always">
        <FormattedMessage {...route.title} />
      </MuiLink>
      {user?.nickName ? (
        <Stack direction="row" spacing="2px" alignItems="center">
          <b>{user.nickName}</b>
          <UserShortUrlButton nickname={user.nickName} />
        </Stack>
      ) : (
        <FormattedMessage {...cardDescriptor} values={{ id: query.id }} />
      )}
    </Breadcrumbs>
  );
};

const SupervisedUsersHeaderTitle = () => {
  const query = useParsedLocationSearch();
  const { pathname } = useLocation();
  const route = useCurrentRouteDescriptor();
  if (!route) {
    return null;
  }
  if (query.view === "create") {
    return <CreateTitle route={route} pathname={pathname} />;
  } else if (query.view === "card") {
    return <CardTitle route={route} pathname={pathname} />;
  }
  return <TabbedTableTitle />;
};

export default SupervisedUsersHeaderTitle;
