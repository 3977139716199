import { environment } from "@config";
import AppType from "@models/AppType";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userProfileReducer from "@slices/userProfile";
import videoModerationReducer from "@slices/videoModeration";
import { registerTokenProviders } from "@utils/fetch";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import adminsReducer from "./slices/admins";
import appSettingsReducer from "./slices/appSettings";
import beatTextsReducer from "./slices/beatTexts";
import beatsModerationReducer from "./slices/beatsModeration";
import clientReducer, { obtainOrRefreshClientTokenAction } from "./slices/client";
import commentsModeration from "./slices/commentsModeration";
import contentCache from "./slices/contentCache";
import contentLabellingReducer from "./slices/contentLabelling";
import contentPromotionsCacheReducer from "./slices/contentPromotionsCache";
import externalPlatformsReducer from "./slices/externalPlatforms";
import internalTagsReducer from "./slices/internalTags";
import keyValueStorageReducer from "./slices/keyValueStorage";
import modalReducer from "./slices/modal";
import moderationNotificatorReducer from "./slices/moderationNotificator";
import moderationTagsReducer from "./slices/moderationTags";
import notificationTemplatesReducer from "./slices/notificationTemplates";
import personsReducer from "./slices/person";
import rejectedErrorsQueueReducer from "./slices/rejectedErrorsQueue";
import supervisedUsersReducer from "./slices/supervisedUsers";
import userReducer from "./slices/user";
import userInfoModerationReducer from "./slices/userInfoModeration";
import usersCache from "./slices/usersCache";

const reducer = combineReducers({
  rejectedErrorsQueue: rejectedErrorsQueueReducer,
  user: persistReducer({ key: "userv2", storage }, userReducer),
  moderationTags: persistReducer({ key: "moderationTags", storage, whitelist: ["tags"] }, moderationTagsReducer),
  videoModeration: persistReducer(
    {
      key: "videoModerationv5",
      storage,
      whitelist: ["commonLoaderOptions", "userLoaderOptions", "playerMode", "staticPreviewEnabled"],
    },
    videoModerationReducer
  ),
  persons: personsReducer,
  userProfile: userProfileReducer,
  admins: adminsReducer,
  supervisedUsers: persistReducer(
    { key: "supervisedUsers", storage, whitelist: ["locations", "creatorCategories", "lastTableViewMode"] },
    supervisedUsersReducer
  ),
  commentsModeration: persistReducer(
    { key: "commentsModeration", storage, whitelist: ["listParams"] },
    commentsModeration
  ),
  usersCache,
  contentCache,
  keyValueStorage: persistReducer(
    { key: "keyValueStorage", storage, whitelist: ["persistentStorage"] },
    keyValueStorageReducer
  ),
  userInfoModeration: persistReducer(
    { key: "userInfoModeration", storage, whitelist: ["categoriesParams", "categoriesProportion"] },
    userInfoModerationReducer
  ),
  contentPromotionsCache: contentPromotionsCacheReducer,
  moderationNotificator: persistReducer(
    { key: "moderationNotificator", storage, whitelist: ["settings", "soundVolume", "disabledTypesInApps"] },
    moderationNotificatorReducer
  ),
  internalTags: persistReducer({ key: "internalTags", storage, whitelist: ["tags"] }, internalTagsReducer),
  contentLabelling: persistReducer(
    {
      key: "contentLabelling",
      storage,
      whitelist: ["filtersMap", "sortingsMap", "staticPreviewEnabled", "playerMode"],
    },
    contentLabellingReducer
  ),
  appSettings: persistReducer(
    { key: "appSettings", storage, whitelist: ["locale", "snowflakesEnabled"] },
    appSettingsReducer
  ),
  beatsModeration: beatsModerationReducer,
  externalPlatforms: externalPlatformsReducer,
  notificationTemplates: persistReducer(
    { key: "notificationTemplates", storage, whitelist: ["pushTypes"] },
    notificationTemplatesReducer
  ),
  client: persistReducer(
    {
      key: "client",
      storage,
      blacklist: ["refreshingClientToken", "contentForClientComments", "likedContentsMap", "followedUsersMap"],
    },
    clientReducer
  ),
  beatTexts: beatTextsReducer,
  modal: modalReducer,
});

if (!environment.isTestBuild) {
  const legacyKeys = ["videoModeration", "videoModerationv2", "videoModerationv3", "videoModerationv4", "user"];
  legacyKeys.forEach((key) => {
    localStorage.removeItem(`persist:${key}`);
  });
}

const store = configureStore({
  devTools: environment.isDevelopmentBuild,
  reducer, // important to keep it that way, or RootState type will become any
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).prepend(
      createStateSyncMiddleware({
        predicate: (action) => action && typeof action.type === "string" && action.type.startsWith("client/"),
      })
    ),
});

initMessageListener(store);
registerTokenProviders(
  () => store.getState().user.currentUser || undefined,
  (appType: AppType) =>
    store.dispatch(obtainOrRefreshClientTokenAction(appType)).then((result) => {
      if (obtainOrRefreshClientTokenAction.fulfilled.match(result)) {
        return result.payload;
      }
      return "";
    })
);

export default store;

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

/** for createAsyncType templates */
export interface ThunkApiType {
  state: RootState;
  dispatch: AppDispatch;
}

/** for passing down thunkApi from cAT somewhere */
export interface GetThunkApi {
  getState: () => RootState;
  dispatch: AppDispatch;
}
