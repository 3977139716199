import { useLayoutEffect, useRef } from "react";

const useActualizedRef = <Type>(value: Type | null) => {
  const ref = useRef<Type | null>(value);
  useLayoutEffect(() => {
    ref.current = value;
  }, [value]);
  return ref;
};

export default useActualizedRef;
