import { ConfigProperty, properties, usePropertyValue } from "@config";
import useActualizedRef from "@hooks/useActualizedRef";
import { setPollingActiveStateAction, storeLoadedDataFromExternalSourceAction } from "@slices/moderationNotificator";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { stringifyJson } from "@utils/json";
import { omit } from "lodash";
import { useEffect, useState } from "react";
import {
  BaseMessage,
  MessageType,
  PollingDoneMessage,
  TakeoverMessage,
  TakeoverReason,
} from "./broadcastChannelProtocol";
import { log } from "./utils";

const sendTakeoverMessage = ({
  tabId,
  broadcastChannel,
  takeoverReason,
}: {
  tabId: number;
  broadcastChannel: BroadcastChannel;
  takeoverReason: TakeoverReason;
}) => {
  const message: TakeoverMessage = {
    type: MessageType.TAKEOVER,
    sourceTabId: tabId,
    takeoverReason,
  };
  log(`Posting message: ${stringifyJson(message)}`);
  broadcastChannel.postMessage(message);
};

const useNegotiator = ({ tabId, broadcastChannel }: { tabId: number; broadcastChannel: BroadcastChannel }) => {
  const dispatch = useAppDispatch();
  const pollingActive = useAppSelector((state) => state.moderationNotificator.pollingActive);
  const pollingActiveRef = useActualizedRef(pollingActive);
  const [visible, setVisible] = useState(document.visibilityState === "visible");
  const visibleRef = useActualizedRef(visible);
  const [lastReceivedMessageTs, setLastReceivedMessageTs] = useState(Date.now());
  const inactivitySeconds =
    usePropertyValue(properties[ConfigProperty.MODERATION_NOTIFICATIONS_POLLING_PERIOD_SECONDS]) * 2;
  useEffect(() => {
    const listener = () => setVisible(document.visibilityState === "visible");
    document.addEventListener("visibilitychange", listener);
    return () => {
      document.removeEventListener("visibilitychange", listener);
    };
  }, []);
  useEffect(() => {
    if (visible) {
      dispatch(setPollingActiveStateAction(true));
      sendTakeoverMessage({ tabId, broadcastChannel, takeoverReason: TakeoverReason.FOREGROUND });
    } else {
      broadcastChannel.postMessage({ type: MessageType.PING_POLLER, sourceTabId: tabId });
    }
  }, [visible, broadcastChannel, tabId, dispatch]);
  useEffect(() => {
    if (pollingActive) {
      return;
    }
    // firing this on every lastReceivedMessageTs change - in case no events for inactivitySecons tab will start polling
    const handle = setTimeout(() => {
      dispatch(setPollingActiveStateAction(true));
      sendTakeoverMessage({ tabId, broadcastChannel, takeoverReason: TakeoverReason.INACTIVITY });
    }, inactivitySeconds * 1000);
    return () => {
      clearTimeout(handle);
    };
  }, [lastReceivedMessageTs, pollingActive, broadcastChannel, dispatch, tabId, inactivitySeconds]);

  useEffect(() => {
    const messageListener = (ev: MessageEvent<any>) => {
      const data = ev.data as BaseMessage;
      log(`Got message: ${stringifyJson(omit(data, "notificationData"))}`);
      switch (data.type) {
        case MessageType.PING_POLLER: {
          if (pollingActiveRef.current) {
            broadcastChannel.postMessage({ type: MessageType.PING_REPLY, sourceTabId: tabId });
          }
          break;
        }
        case MessageType.POLLING_DONE: {
          setLastReceivedMessageTs(Date.now());
          dispatch(storeLoadedDataFromExternalSourceAction((data as PollingDoneMessage).notificationData));
          break;
        }
        case MessageType.PING_REPLY: {
          setLastReceivedMessageTs(Date.now());
          break;
        }
        case MessageType.TAKEOVER: {
          setLastReceivedMessageTs(Date.now());
          const takeoverMessage = data as TakeoverMessage;
          if (takeoverMessage.takeoverReason === TakeoverReason.INACTIVITY && visibleRef.current) {
            return;
          }
          dispatch(setPollingActiveStateAction(false));
          break;
        }
        default: {
          log(`Unhandled message: ${stringifyJson(data)}`);
        }
      }
    };
    broadcastChannel.addEventListener("message", messageListener);
    return () => {
      broadcastChannel.removeEventListener("message", messageListener);
    };
  }, [broadcastChannel, pollingActiveRef, visibleRef, dispatch, tabId]);
};

export default useNegotiator;
