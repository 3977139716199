import { ShortUrlsApi, extractHash } from "@api/shortUrls";
import AppType from "@models/AppType";
import { last } from "lodash";
import { defineMessage } from "react-intl";
import { SearchStrategy } from "../models";
import { makeContentSearchResult, makeMessageSearchResult } from "../searchResultBuilders";

const ByShortUrl: SearchStrategy = async ({ query, availableUsers }) => {
  if (!query.startsWith("http")) {
    return [];
  }
  const handleQuery = async (appType: AppType) => {
    const user = availableUsers.find((x) => x.appType === appType);
    if (!user) {
      return [
        makeMessageSearchResult({
          message: defineMessage({ defaultMessage: "Please add {app} user to decode this short url" }),
          messageValues: { app: appType },
        }),
      ];
    }
    const data = await ShortUrlsApi.resolve(extractHash(query), { appType, token: user.token });
    const contentId = last(data.original?.split("contentId="));
    if (contentId) {
      return [makeContentSearchResult({ contentId, appType, content: undefined })];
    }
    return [];
  };
  if (query.includes("punch")) {
    return handleQuery(AppType.PUNCH);
  } else if (query.includes("chip") || query.includes("spich")) {
    return handleQuery(AppType.CHIPZ);
  }
  return [];
};

export default ByShortUrl;
