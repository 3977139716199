import { UserModeratedContentCounters } from "@api/contentModeration";
import { UserFullDto } from "@api/user";

export interface UserFullDtoExtended extends UserFullDto {
  moderatedContentData?: UserModeratedContentCounters;
}

export enum UserSearchViewMode {
  ALL = "ALL",
  BADGED = "BADGED",
  LABELS_TEMPORARY = "LABELS_TEMPORARY",
  LABELS_PERMANENT = "LABELS_PERMANENT",
}
