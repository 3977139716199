import { Typography } from "@mui/material";
import DefaultHeaderTitle from "@navigation/DefaultHeaderTitle";
import routesConfig from "@navigation/routesConfig";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const PageTitle = ({ className }: { className?: string }) => {
  return (
    <Typography variant="h6" className={className} color="primary">
      <Suspense>
        <Routes>
          {routesConfig.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  route.headerTitleComponent ? (
                    <route.headerTitleComponent />
                  ) : (
                    <DefaultHeaderTitle titleDescriptor={route.title} />
                  )
                }
              />
            );
          })}
        </Routes>
      </Suspense>
    </Typography>
  );
};

export default PageTitle;
