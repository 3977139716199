import { OffsetLimit, ResponseDto, SortingSortByOptional } from "@api";
import { GET, PATCH, POST, buildQuery, formDataWithFile } from "@utils/fetch";
import { AudioTrackToneDto } from "./audioTones";
import { BeatAssignedStatus, ControlStatus } from "./beatsModeration";
import { AssetDto, ContentStatusType, ContentType, NewAssetDto, PeriodDto, UploadResponseDto } from "./contentCommon";
import { ContentModerationStatus } from "./contentModeration";
import { GenreDto } from "./genres";
import { MoodDto } from "./moods";
import { PersonDto } from "./persons";
import { UserShortDto } from "./user";

const urlPrefix = "api/v1/admin/audio_tracks";

export enum TrackType {
  ORIGINAL = "ORIGINAL",
  COVER = "COVER",
  BEATMAKERS = "BEATMAKERS",
}

export interface LoaderAudioRequestModel {
  audioTrackType?: TrackType;
  audioBpm?: number;
  audioTone?: AudioTrackToneDto;
  authors?: PersonDto[];
  producers?: PersonDto[];
  similarMusicians?: PersonDto[];
  periods?: PeriodDto[];
  title?: string;
  assets: NewAssetDto[];
  genres?: GenreDto[];
  moods?: MoodDto[];
  isDraft?: boolean;
  externalPlatformWebLinks?: CreateExternalPlatformWebLinkDto[];
  coverText?: CoverBeatTextDto;
  coverTexts?: CoverBeatTextDto[];
}

export interface AudioTracksFilterType {
  title?: string | null;
  fromAudioBpm?: number | null;
  toAudioBpm?: number | null;
  audioToneId?: number | null;
  trackType?: TrackType | null;
  authorId?: number | null;
  producerId?: number | null;
  similarMusicianId?: number | null;
  moodId?: number | null;
  genreId?: number | null;
  isDraft?: boolean | null;
  contentStatus?: ContentStatusType | null;
  isNew?: boolean | null;
  userId?: number | null;
  controlStatus?: ControlStatus | null;
  moderationStatuses?: ContentModerationStatus[];
}

export enum TextSubtype {
  PLAIN = "PLAIN",
  WITH_TIMINGS = "WITH_TIMINGS",
}

export interface PublishedTextMetadataDto {
  id: number;
  textSubtype: TextSubtype;
  author?: UserShortDto;
}

export interface WebLinkDto {
  id: number;
  externalPlatformId: number;
  name: string;
  icon: string;
  url: string;
}

export interface CreateExternalPlatformWebLinkDto {
  externalPlatformId: number;
  url: string;
}

export interface CoverBeatTextDto {
  text: string;
  textSubtype: TextSubtype;
}

export interface AdminAudioContentDto {
  id: number;
  contentType?: ContentType;
  audioTrackType?: TrackType;
  title?: string;
  description?: string;
  audioBpm?: number;
  audioTone?: AudioTrackToneDto;
  cover?: string;
  listAssets?: AssetDto[];
  authors?: PersonDto[];
  producers?: PersonDto[];
  periods?: PeriodDto[];
  similarMusicians?: PersonDto[];
  genres?: GenreDto[];
  moods?: MoodDto[];
  createdAt?: string;
  updatedAt?: string;
  isNew?: boolean;
  isDraft?: boolean;
  contentStatus?: ContentStatusType;
  rank?: number;
  userId?: number;
  controlStatus?: ControlStatus;
  moderationStatus?: BeatAssignedStatus;
  coverTexts?: PublishedTextMetadataDto[];
  webLinks?: WebLinkDto[];
}

export interface AdminUpdateAudioContentDto {
  audioTrackType?: TrackType;
  title?: string;
  audioBpm?: number;
  audioTone?: AudioTrackToneDto;
  cover?: string;
  authors?: PersonDto[];
  producers?: PersonDto[];
  similarMusicians?: PersonDto[];
  periods?: PeriodDto[];
  genres?: GenreDto[];
  moods?: MoodDto[];
  contentStatus?: ContentStatusType;
  isDraft?: boolean;
  controlStatus?: ControlStatus;
  externalPlatformWebLinks?: CreateExternalPlatformWebLinkDto[];
  updateCoverTextRequired?: boolean;
  coverText?: CoverBeatTextDto;
}

export const AudioTracksApi = {
  list: (
    request: AudioTracksFilterType & OffsetLimit & SortingSortByOptional
  ): Promise<ResponseDto<AdminAudioContentDto>> => GET(`${urlPrefix}?${buildQuery(request)}`),
  batchByIds: (contentIds: number[]): Promise<AdminAudioContentDto[]> =>
    POST(`api/v1/admin/audio_track/get-by-ids`, { contentIds }),
  create: ({
    userId,
    request,
    usePresignedUrls,
  }: {
    userId: number;
    request: LoaderAudioRequestModel;
    usePresignedUrls: boolean;
  }): Promise<UploadResponseDto> => POST(`${urlPrefix}?${buildQuery({ userId, usePresignedUrls })}`, request),
  update: (id: number, request: AdminUpdateAudioContentDto): Promise<void> => PATCH(`${urlPrefix}/${id}`, request),
  getById: (id: number): Promise<AdminAudioContentDto> => GET(`${urlPrefix}/${id}`),
  increaseRank: (ids: number[], rankBase?: number) =>
    POST(`${urlPrefix}/increase_rank?${buildQuery({ rankBase })}`, ids),
  restoreRank: (ids: number[]) => POST(`${urlPrefix}/restore_rank`, ids),
  updateCoverUrl: ({ contentId, coverUrl }: { contentId: number; coverUrl: string }) =>
    PATCH(`api/v1/admin/audio_track/${contentId}/cover`, { coverUrl }),
  uploadCover: ({ contentId, cover }: { contentId: number; cover: File }) =>
    POST(`api/v1/admin/audio_track/${contentId}/cover`, formDataWithFile("file", cover)),
  generatePromoVideo: (contentId: number): Promise<string> =>
    POST(`api/v1/admin/audio_track/${contentId}/generate-promo`),
  unpublish: (id: number) => POST(`api/v1/admin/audio_track/unpublish/${id}`),
};
