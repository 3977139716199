import { ContentModerationStatus } from "@api/contentModeration";
import { ContentModerationTagsApi, ModerationTag } from "@api/contentModerationTags";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { sortBy } from "lodash";

export interface TagMapping {
  id: string;
  contentStatus: ContentModerationStatus;
  contentRepeatStatus: ContentModerationStatus;
}

export interface ModerationTagsState {
  tags: ModerationTag[];
  isLoading: boolean;
  hasError: boolean;
}

const initialState: ModerationTagsState = {
  tags: [],
  isLoading: false,
  hasError: false,
};

export const AUTO_APPROVE_TAG_ID = "00000000-0000-0000-0000-000000000000";
const BLOCKED_USER_TAG_ID = "ffffffff-ffff-ffff-ffff-ffffffffffff";
export const AUTO_HIDE_TAG_ID = "00000000-0000-0000-ffff-ffffffffffff";
export const AUTO_APPROVED_WHITELIST_TAG_ID = "00000000-0000-0000-0000-000000000001";

export const nonHumanTagIds = [
  AUTO_APPROVE_TAG_ID,
  BLOCKED_USER_TAG_ID,
  AUTO_HIDE_TAG_ID,
  AUTO_APPROVED_WHITELIST_TAG_ID,
];

const name = "moderationTags";

export const refreshTagList = createAsyncThunk(`${name}/refreshTagsList`, async () => {
  const data = await ContentModerationTagsApi.listTags();
  return data;
});

const moderationTagsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refreshTagList.pending, (state) => {
      state.isLoading = true;
      state.hasError = false;
    });
    builder.addCase(refreshTagList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.tags = action.payload;
    });
    builder.addCase(refreshTagList.rejected, (state) => {
      state.isLoading = false;
      state.hasError = true;
    });
  },
});

export default moderationTagsSlice.reducer;

export const getTagsForHumans = createSelector(
  (state: RootState) => state.moderationTags.tags,
  (tags) => tags.filter((x) => x.id && !nonHumanTagIds.includes(x.id))
);

export const getTagsWithoutAutoApproveTag = createSelector(
  (state: RootState) => state.moderationTags.tags,
  (tags) => tags.filter((x) => x.id !== AUTO_APPROVE_TAG_ID)
);

export const getTagsMap = createSelector(
  (state: RootState) => state.moderationTags.tags,
  (tags) =>
    tags.reduce(
      (a, x) => {
        if (x.id) {
          a[x.id] = x;
        }
        return a;
      },
      {} as Record<string, ModerationTag>
    )
);

export const getSortedForPickerTagsList = createSelector(
  (state: RootState) => state.moderationTags.tags,
  (tags) => sortBy(tags, (x) => x.id && nonHumanTagIds.includes(x.id))
);

export const getTagsForVideoModerationSelector = createSelector(getTagsWithoutAutoApproveTag, (tags) => {
  if (!tags.length) {
    return { mainTag: undefined, firstLevelTags: [], secondLevelTags: [], nonHumanTags: [] };
  }
  const firstLevelTagsSize = 4;
  const nonHumanTags = tags.filter((x) => x.id && nonHumanTagIds.includes(x.id));
  const filteredTags = tags.filter((x) => (!x.status || x.status === "ACTIVE") && !nonHumanTagIds.includes(x.id!));
  const mainTag = filteredTags[0];
  const firstLevelTags = filteredTags.slice(1, firstLevelTagsSize + 1);
  const secondLevelTags = filteredTags.slice(firstLevelTagsSize + 1);
  return {
    mainTag,
    firstLevelTags,
    secondLevelTags,
    nonHumanTags,
  };
});
