import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import NavigationTabs from "@widgets/NavigationTabs";
import { ComplaintsViewType, tabMessages } from "./types";

const tabs = Object.values(ComplaintsViewType);

const ComplaintsModerationHeaderTitle = () => {
  const route = useCurrentRouteDescriptor();
  useSetDocumentTitle({ messageDescriptor: route!.title! });
  return <NavigationTabs possibleValues={tabs} tabQueryKey="view" tabMessagesMap={tabMessages} />;
};

export default ComplaintsModerationHeaderTitle;
