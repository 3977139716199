import { ApplicationType } from "@api";
import { DELETE, GET, PATCH, POST, buildQuery } from "@utils/fetch";
import { mapValues } from "lodash";
import { CreateUgcAudioDto } from "./audioContent";

export enum ContentType {
  UGC = "UGC",
  CAMEO = "CAMEO",
  PGC = "PGC",
}

export enum ContentStatusType {
  NEW = "NEW",
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
  UNPUBLISHED = "UNPUBLISHED",
  UPLOAD_FINISHED = "UPLOAD_FINISHED",
}

export interface CreateUgcTagModel {
  name: string;
}

export interface PeriodDto {
  startDate?: string;
  stopDate?: string;
}

export enum PlatformType {
  UNKNOWN = "UNKNOWN",
  ANDROID = "ANDROID",
  IOS = "IOS",
}

export enum AssetType {
  ORIGINAL = "ORIGINAL",
  BASE = "BASE",
  TRAILER = "TRAILER",
  UGC = "UGC",
  UGC_TRANSCODED = "UGC_TRANSCODED",
  UGC_STREAM = "UGC_STREAM",
  UGC_ORIGINAL = "UGC_ORIGINAL",
  ORIGIN = "ORIGIN",
  VOICE = "VOICE",
  MUSIC = "MUSIC",
  UGC_FOR_DOWNLOAD = "UGC_FOR_DOWNLOAD",
  UGC_HISTOGRAM = "UGC_HISTOGRAM",
}

export enum AgeLevelType {
  ZERO_PLUS = "ZERO_PLUS",
  SIX_PLUS = "SIX_PLUS",
  TWELVE_PLUS = "TWELVE_PLUS",
  SIXTEEN_PLUS = "SIXTEEN_PLUS",
  EIGHTEEN_PLUS = "EIGHTEEN_PLUS",
}

export interface MentionDto {
  nickname?: string;
  userId?: number;
}

export interface NewAssetDto {
  fileSize: number;
  fileExtension: string;
}

export interface AssetDto {
  id: number;
  contentId?: number;
  filePath?: string;
  playlistPath?: string;
  fileSize?: number;
  duration?: number;
  audioBitrate?: number;
  cdnId?: number;
  screenshotPath?: string | null;
  sharePath?: string;
  assetTypeEnum: AssetType;
  assetStatus: ContentStatusType;
}

export interface DeviceInfo {
  platform: PlatformType;
  userAgent: string | null;
}

export interface UploadResponseDto {
  assets?: LoaderResponseModel[];
  contentId: number;
}

export interface PresignedUrlsDto {
  partNumber: number;
  presignedURL: string;
}

export interface LoaderResponseModel {
  presignedUrls?: PresignedUrlsDto[];
  uploadUrls?: string[];
  uploadToken?: string;
  assetId?: number;
  contentId?: number;
  loaderId?: string;
  cdnClientId?: string;
  cdnId?: string;
}

export const HIDDEN_REPEAT_MARK = "EXCLUDED_FROM_THREAD";

export interface ConflictingTagsForVotesDto {
  conflictingTags: string[];
}

export enum ContentCategory {
  COMMON = "COMMON",
  AUDIO_TRACK = "AUDIO_TRACK",
  FREESTYLE = "FREESTYLE",
  BEATS_PROMO = "BEATS_PROMO",
  INSTRUMENTAL = "INSTRUMENTAL",
}

export interface BaseContentFilterType {
  userId?: number | null;
  description?: string | null;
  tagId?: number | null;
  fromCreatedAt?: Date | null;
  toCreatedAt?: Date | null;
  contentStatus?: ContentStatusType | null;
  insideTagId?: number | null;
  platform?: PlatformType | null;
}

export const filterAdapter = <Type extends BaseContentFilterType>(filter: Type): any =>
  mapValues(filter, (value) => {
    if (value instanceof Date) {
      return value.toISOString();
    }
    if (value === null) {
      return undefined;
    }
    return value;
  });

export interface SetInsideTags {
  contentId: number;
  tagIds: number[];
}
export interface SetInsideTagsToContentsDto {
  contentsTags: SetInsideTags[];
}

export interface VoteOnContentOptionDto {
  id: number;
  imageUrl: string;
  numberOfVotes: number;
  title: string;
}

export interface VoteOnContentDto {
  cancelable: boolean;
  maxVotesCount: number;
  voteType: "PUBLIC" | "PRIVATE";
  options: VoteOnContentOptionDto[];
}

export interface UgcDownloadResponse {
  ready: boolean;
  url?: string;
}

export const ContentCommonApi = {
  uploadCoverImage: (contentId: number, cover: File) => {
    const body = new FormData();
    body.append("file", cover);
    return POST(`api/v1/admin/content/${contentId}/cover`, body);
  },
  setCoverFromUrl: (contentId: number, coverUrl: string) =>
    PATCH(`api/v1/admin/content/${contentId}/cover`, { coverUrl }),
  deleteCover: (contentId: number) => DELETE(`api/v1/admin/content/${contentId}/cover`),
  changeContentStatus: (id: number, contentStatus: ContentStatusType) =>
    PATCH(`api/v1/admin/content/${id}`, { contentStatus }),
  createBeatsPromoContent: (
    userId: number,
    request: CreateUgcAudioDto,
    devicePlatform: PlatformType
  ): Promise<UploadResponseDto> =>
    POST(
      `api/v1/admin/audio/ugc/beats-promo?${buildQuery({
        userId,
        devicePlatform,
      })}`,
      request
    ),
  deleteHashtag: (contentId: number, tagId: number) => DELETE(`api/v1/admin/content/${contentId}/tags/${tagId}`),
  addHashtag: (contentId: number, tagId: number) => POST(`api/v1/admin/content/${contentId}/tags/${tagId}`),
  updatePeriod: (contentId: number, period: PeriodDto) => PATCH(`api/v1/admin/content/${contentId}/period`, period),
  batchSetInsideTags: (request: SetInsideTagsToContentsDto, applicationType: ApplicationType) =>
    POST(`api/v1/admin/${applicationType.toLowerCase()}/ugc/inside_tags`, request),
  markContent: (contentId: number, marks: string[]) => POST(`api/v1/admin/content/${contentId}/mark`, marks),
  unmarkContent: (contentId: number, marks: string[]) => POST(`api/v1/admin/content/${contentId}/unmark`, marks),
  notifyUploadFinished: (contentId: number) => POST(`api/v1/admin/content/${contentId}/upload-finished`),
  checkConflictingTags: (tagNames: string[]): Promise<ConflictingTagsForVotesDto> =>
    POST(
      `api/v1/admin/tags/check`,
      tagNames.map((name) => ({ name }))
    ),
  downloadVideo: ({ contentId, withLogo }: { contentId: number; withLogo: boolean }): Promise<UgcDownloadResponse> =>
    GET(`api/v1/admin/content/${contentId}/download?${buildQuery({ withLogo })}`),
};
