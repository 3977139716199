import { ChildSortingType, CommonModerationStatus, Page } from "@api";
import { AsrApi, AsrDtoResponse, AsrListDtoResponse } from "@api/asr";
import { ControlStatus } from "@api/beatsModeration";
import { ContentStatusType, ContentType } from "@api/contentCommon";
import {
  ContentDescriptionDto,
  ContentDescriptionModerationApi,
  ContentDescriptionModerationLog,
  ContentDescriptionModerationStatus,
  ContentDescriptionViolationsResponse,
  ManualContentDescriptionModerationStatus,
} from "@api/contentDescriptionModeration";
import {
  AssignedModerationStatus,
  ContentCoverDto,
  ContentModerationApi,
  ContentModerationStatus,
  ModerationAction,
  ModerationContent,
  ModerationContentFilter,
  ModerationContentSorting,
  ModerationContentSource,
  ModerationContentWithChildLabels,
  ModerationSettings,
  RepeatedModerationReportFilter,
  ThreadPrivacyFilterEnum,
} from "@api/contentModeration";
import { ModerationTag } from "@api/contentModerationTags";
import { ContentTagModerationStatus, HashtagsModerationApi } from "@api/hashtagsModeration";
import { UserRole } from "@api/user";
import { ModerationUserInfoType } from "@api/userInfoModeration";
import { VideoContentApi } from "@api/videoContent";
import { ConfigProperty, properties } from "@config";
import AppType from "@models/AppType";
import PlayerMode from "@models/PlayerMode";
import { PayloadAction, SerializedError, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { GetThunkApi, RootState, ThunkApiType } from "@store/store";
import { forceTimeZone } from "@utils/date";
import { mapValues, omit, reverse, sortBy, uniq } from "lodash";
import { defineMessage } from "react-intl";
import { loadBeatTextsByContentIdsAction } from "./beatTexts";
import { getContentById, loadContentsToCache, toggleInternalTagAction } from "./contentCache";
import { loadContentPromotionsAction } from "./contentPromotionsCache";
import { getTagsForHumans, nonHumanTagIds } from "./moderationTags";
import { addErrorToQueue } from "./rejectedErrorsQueue";
import { getCurrentUserAppType, getIsReadonlyUser } from "./user";
import { loadUserInfoModerationDataByIdsAction } from "./userInfoModeration";
import { getUserById, loadUsersToCache } from "./usersCache";

export const predefinedFilterPart: ModerationContentFilter = {
  contentStatuses: [ContentStatusType.AVAILABLE],
  sources: [ModerationContentSource.UGC],
  contentTypes: [ContentType.UGC, ContentType.PGC],
  controlStatuses: [ControlStatus.AVAILABLE],
};

export const defaultFilter: ModerationContentFilter = {
  ...predefinedFilterPart,
  moderationStatuses: [
    AssignedModerationStatus.NEW,
    AssignedModerationStatus.AUTO_APPROVED,
    AssignedModerationStatus.AUTO_HIDDEN,
  ],
  threadPrivacy: [ThreadPrivacyFilterEnum.ALL_WITHOUT_PRIVATE_THREADS, ThreadPrivacyFilterEnum.PRIVATE_THREAD_1_TO_N],
};

export interface LoaderOptions {
  filter: ModerationContentFilter;
  pageSize: number;
  sorting: ModerationContentSorting;
  repeatedModeration?: boolean;
}

export const pageSizeOptions = [5, 10, 50, 100, 150];

export const limitedPageSizeOptions = [5];

const defaultLoaderOptions: LoaderOptions = {
  filter: defaultFilter,
  pageSize: pageSizeOptions[0],
  sorting: {
    createdAtSortDirection: ChildSortingType.ASC,
    ratingSortDirection: ChildSortingType.DESC,
    originsFirst: false,
    nudityFirst: false,
    childFirst: false,
    childTenFirst: false,
    childFourteenFirst: false,
  },
  repeatedModeration: undefined,
};

export interface ChangeTagsTask {
  tagIds: string[];
  contentId: number;
  createdAt: number;
}

export interface ChildLabelData {
  childLabelTags: ModerationTag[];
}

interface BaseContentLoaderResult {
  contentIds: number[];
  contentMap: Record<number, ModerationContent>;
  hashtagsModerationStatuses: Record<number, ContentTagModerationStatus>;
  userIdToChildLabelsData: Record<number, ChildLabelData | null>;
}

interface ContentLoaderResult extends BaseContentLoaderResult {
  totalPages: number;
  currentPage: number;
  totalCount: number;
}

interface RepeatedModerationContentLoaderResult extends BaseContentLoaderResult {
  actionsMap: Record<number, ModerationAction[]>;
}

interface BaseLoadableData {
  currentRequestId: string | null;
  contentIds: number[];
  error: SerializedError | null;
  isLoading: boolean;
}

interface LoadableData extends BaseLoadableData {
  totalCount: number;
  totalPages: number;
  currentPage: number;
}

const emptyBaseLoadableData: BaseLoadableData = {
  currentRequestId: null,
  contentIds: [],
  error: null,
  isLoading: false,
};

const emptyLoadableData: LoadableData = {
  ...emptyBaseLoadableData,
  totalCount: 0,
  totalPages: 0,
  currentPage: 0,
};

export interface VideoModerationState {
  commonLoaderOptions: LoaderOptions;
  userLoaderOptions: LoaderOptions;
  threadParentId: number;
  listData: LoadableData;
  threadData: LoadableData;
  changeTagsTasks: Record<number, ChangeTagsTask>; // maps content id
  contentMap: Record<number, ModerationContent>;
  autoApproveTimes: Record<number, number | null>;
  playerMode: PlayerMode;
  hashtagsModerationStatuses: Record<number, ContentTagModerationStatus>;
  staticPreviewEnabled: boolean;
  focusedContentId: number | null;
  userIdToChildLabelsData: Record<number, ChildLabelData | null>;
  distrustTrustedUserCandidateIds: number[];
  moderationSettings: ModerationSettings | null;
  contentDescriptionViolationsMap: Record<number, ContentDescriptionModerationLog>;
  contentDescriptionDtosMap: Record<number, ContentDescriptionDto>;
  repeatedModerationLoadableData: BaseLoadableData;
  repeatedModerationActionsMap: Record<number, ModerationAction[]>;
  coversMap: Record<number, ContentCoverDto>;
  asrMap: Record<number, AsrDtoResponse>;
}

const initialState: VideoModerationState = {
  commonLoaderOptions: { ...defaultLoaderOptions, repeatedModeration: true },
  userLoaderOptions: defaultLoaderOptions,
  threadParentId: 0,
  listData: emptyLoadableData,
  threadData: emptyLoadableData,
  contentMap: {},
  changeTagsTasks: {},
  autoApproveTimes: {},
  hashtagsModerationStatuses: {},
  focusedContentId: null,
  playerMode: PlayerMode.HLS,
  staticPreviewEnabled: true,
  userIdToChildLabelsData: {},
  distrustTrustedUserCandidateIds: [],
  moderationSettings: null,
  contentDescriptionViolationsMap: {},
  contentDescriptionDtosMap: {},
  repeatedModerationLoadableData: emptyBaseLoadableData,
  repeatedModerationActionsMap: {},
  coversMap: {},
  asrMap: {},
};

export const nonModerationRolesToDisplayModerationTags = [UserRole.SUPERVISOR, UserRole.CONTENT_MANAGER];
export const statusesToDisplayModerationTagsForNonModerator = [
  ContentModerationStatus.HIDDEN,
  ContentModerationStatus.DELETED,
];

const name = "videoModeration";

const emptyBaseContentLoaderResult: BaseContentLoaderResult = {
  contentIds: [],
  contentMap: {},
  hashtagsModerationStatuses: {},
  userIdToChildLabelsData: {},
};

const emptyContentLoaderResult: ContentLoaderResult = {
  ...emptyBaseContentLoaderResult,
  totalCount: 0,
  totalPages: 0,
  currentPage: 0,
};

export const loadModerationSettingsAction = createAsyncThunk(`${name}/loadModerationSettingsAction`, async () => {
  const data = await ContentModerationApi.loadModerationSettings();
  return data;
});

const loadContentDescriptionViolationsAction = createAsyncThunk<
  ContentDescriptionViolationsResponse,
  number[],
  ThunkApiType
>(
  `${name}/loadContentDescriptionViolationsAction`,
  async (contentIds: number[]) => {
    const data = await ContentDescriptionModerationApi.loadViolations(contentIds);
    return data;
  },
  {
    condition: (_, thunkApi) =>
      properties[ConfigProperty.VIDEO_MODERATON_DESCRIPTION_BLACKLIST_VIOLATIONS_HIGHLIGHT_ENABLED].selector(
        thunkApi.getState()
      ) === true,
  }
);

const loadContentDescriptionDtosAction = createAsyncThunk(
  `${name}/loadContentDescriptionDtosAction`,
  async (contentIds: number[]) => {
    const data = await ContentDescriptionModerationApi.loadDescriptions(contentIds);
    return data;
  }
);

const descriptionManualStatusEqualsStatus = (
  manualStatus: ManualContentDescriptionModerationStatus,
  status: ContentDescriptionModerationStatus
) => {
  switch (status) {
    case ContentDescriptionModerationStatus.NEW:
      return false;
    case ContentDescriptionModerationStatus.HIDDEN:
    case ContentDescriptionModerationStatus.AUTO_HIDDEN: {
      return manualStatus === ManualContentDescriptionModerationStatus.HIDDEN;
    }
    case ContentDescriptionModerationStatus.APPROVED: {
      return manualStatus === ManualContentDescriptionModerationStatus.APPROVED;
    }
    case ContentDescriptionModerationStatus.DELETED: {
      return manualStatus === ManualContentDescriptionModerationStatus.DELETED;
    }
  }
};

export const setDescriptionModerationStatusAction = createAsyncThunk<
  ContentDescriptionDto | undefined,
  { contentId: number; moderationStatus: ManualContentDescriptionModerationStatus },
  ThunkApiType
>(`${name}/setDescriptionModerationStatusAction`, async ({ contentId, moderationStatus }, thunkApi) => {
  const descriptionDto = thunkApi.getState().videoModeration.contentDescriptionDtosMap[contentId];
  if (!descriptionDto) {
    return undefined;
  }
  if (descriptionManualStatusEqualsStatus(moderationStatus, descriptionDto.moderationStatus)) {
    return undefined;
  }
  const result = await ContentDescriptionModerationApi.setModerationStatuses({
    batch: [
      {
        contentId,
        version: descriptionDto.version,
        manualModerationStatus: moderationStatus,
      },
    ],
  });
  if (result.moderatedDescriptions?.length) {
    return result.moderatedDescriptions[0];
  }
  if (result.changedDescriptions?.length) {
    const dto = result.changedDescriptions[0];
    thunkApi.dispatch(
      addErrorToQueue({
        localizedError: {
          message: defineMessage({
            defaultMessage:
              "Version mismatch for description of content {id}, known version: {oldVersion}, actual version: {newVersion}",
          }),
          values: { id: contentId, oldVersion: descriptionDto.version, newVersion: dto.version },
        },
      })
    );
    return dto;
  }
});

const loadContentCoversDataAction = createAsyncThunk<ContentCoverDto[], number[], ThunkApiType>(
  `${name}/loadContentCoversDataAction`,
  async (contentIds) => {
    const result = await ContentModerationApi.loadContentCovers(contentIds);
    return result.content || [];
  }
);

export const setContentCoverModerationStatusAction = createAsyncThunk<
  ContentCoverDto,
  { contentId: number; moderationStatus: CommonModerationStatus },
  ThunkApiType
>(`${name}/setContentCoverModerationStatusAction`, async ({ contentId, moderationStatus }, thunkApi) => {
  const { version } = thunkApi.getState().videoModeration.coversMap[contentId];
  try {
    const data = await ContentModerationApi.setContentCoverStatus({ contentId, version, moderationStatus });
    thunkApi.dispatch(loadContentsToCache({ ids: [contentId], checkExisting: false }));
    return data;
  } catch (e) {
    thunkApi.dispatch(loadContentCoversDataAction([contentId]));
    throw e;
  }
});

const postprocessModerationContent = async ({
  contents,
  thunkApi,
  sortContent,
}: {
  contents?: ModerationContentWithChildLabels[];
  thunkApi: GetThunkApi;
  sortContent?: (moderationItems: ModerationContent[]) => ModerationContent[];
}): Promise<BaseContentLoaderResult> => {
  if (!contents?.length) {
    return emptyBaseContentLoaderResult;
  }
  const appType = getCurrentUserAppType(thunkApi.getState());
  const directContentIds = contents.map((x) => x.contentId);
  const allContentIds = contents.flatMap((x) =>
    x.parentId && appType === AppType.CHIPZ ? [x.parentId, x.contentId] : [x.contentId]
  );
  thunkApi.dispatch(loadContentCoversDataAction(directContentIds));
  thunkApi.dispatch(loadContentDescriptionViolationsAction(allContentIds));
  thunkApi.dispatch(loadContentDescriptionDtosAction(allContentIds));
  thunkApi.dispatch(loadAsrAction(allContentIds));
  thunkApi.dispatch(loadBeatTextsByContentIdsAction({ contentIds: allContentIds, withModerationData: true }));
  if (!getIsReadonlyUser(thunkApi.getState())) {
    thunkApi.dispatch(loadContentPromotionsAction({ ids: allContentIds }));
  }
  const userIds = contents.map((x) => x.author?.id).filter((x) => x) as number[];
  thunkApi.dispatch(
    loadUserInfoModerationDataByIdsAction({
      userIds,
      userInfoTypes: [ModerationUserInfoType.NICKNAME],
    })
  );
  thunkApi.dispatch(loadUsersToCache({ ids: userIds, checkExisting: true }));
  const newContentIds = contents
    .filter((x) => x.assignedModerationStatus === AssignedModerationStatus.NEW)
    .map((x) => x.contentId);
  if (newContentIds?.length) {
    thunkApi.dispatch(loadAutoApproveTimesAction(newContentIds));
  }
  let hashtagsModerationStatuses: Record<number, ContentTagModerationStatus> = {};
  const contentResult = await thunkApi.dispatch(loadContentsToCache({ ids: allContentIds, checkExisting: false }));
  if (loadContentsToCache.fulfilled.match(contentResult)) {
    const { payload } = contentResult;
    const contents = Object.values(payload).flatMap((x) => Object.values(x));
    hashtagsModerationStatuses = contents
      .flatMap((x) => [...(x.tags || []), ...(x.removedTags || [])])
      .reduce(
        (a, x) => {
          a[x.id] = x.moderationStatus;
          return a;
        },
        {} as Record<number, ContentTagModerationStatus>
      );
  }
  return {
    contentIds: sortContent ? sortContent(contents).map((x) => x.contentId) : directContentIds,
    contentMap: contents.reduce(
      (a, x) => {
        a[x.contentId] = x;
        return a;
      },
      {} as Record<number, ModerationContent>
    ),
    hashtagsModerationStatuses,
    userIdToChildLabelsData: (contents || []).reduce(
      (a, x) => {
        const userId = x.author?.id;
        if (userId) {
          a[userId] = x.childLabel ? { childLabelTags: x.childLabelTags! } : null;
        }
        return a;
      },
      {} as Record<number, ChildLabelData | null>
    ),
  };
};

const postprocessModerationListData = async ({
  response,
  thunkApi,
  sortContent,
  overridePagination,
}: {
  response: Page<ModerationContentWithChildLabels>;
  thunkApi: GetThunkApi;
  sortContent?: (moderationItems: ModerationContent[]) => ModerationContent[];
  overridePagination?: Page<any>;
}): Promise<ContentLoaderResult> => {
  if (!response.content?.length) {
    return emptyContentLoaderResult;
  }
  const baseResult = await postprocessModerationContent({
    contents: response.content,
    thunkApi,
    sortContent,
  });
  return {
    ...baseResult,
    totalCount: (overridePagination || response).totalElements || 0,
    totalPages: (overridePagination || response).totalPages || 0,
    currentPage: (overridePagination || response).number || 0,
  };
};

const loadAsrAction = createAsyncThunk<AsrListDtoResponse, number[], ThunkApiType>(
  `${name}/loadAsrAction`,
  async (contentIds) => {
    return await AsrApi.getPhrasesForContents(contentIds);
  },
  {
    condition: (contentIds, thunkApi) =>
      getCurrentUserAppType(thunkApi.getState()) === AppType.CHIPZ || contentIds.length === 1,
  }
);
const loadAutoApproveTimesAction = createAsyncThunk<Record<number, number>, number[], ThunkApiType>(
  `${name}/loadAutoApproveTimesAction`,
  async (contentIds) => {
    const data = await ContentModerationApi.loadAutoApproveTimes(contentIds);
    if (!data.autoApproveTimes) {
      return {};
    }
    return data.autoApproveTimes.reduce(
      (a, { contentId, autoApproveTime }) => {
        if (contentId && autoApproveTime) {
          a[contentId] = Date.parse(forceTimeZone(autoApproveTime)!);
        }
        return a;
      },
      {} as Record<number, number>
    );
  }
);

const transformFilterForApp = (filter: ModerationContentFilter, appType: AppType): ModerationContentFilter => {
  const { threadPrivacy, contentSubtype, tagIds, textModerationStatuses, ...rest } = filter;
  if (appType === AppType.PUNCH) {
    return {
      ...rest,
      ...(tagIds && tagIds.length > 0 && { tagIds }),
      ...(textModerationStatuses?.length && { textModerationStatuses }),
      ...predefinedFilterPart,
    };
  }
  return {
    ...rest,
    ...(tagIds && tagIds.length > 0 && { tagIds }),
    ...(contentSubtype && { contentSubtype }),
    ...(threadPrivacy?.length && { threadPrivacy }),
    ...predefinedFilterPart,
  };
};

export const loadContentListForModerationAction = createAsyncThunk<
  ContentLoaderResult,
  { userId?: number | null; page: number },
  ThunkApiType
>(`${name}/loadContentListForModerationAction`, async ({ userId, page }, thunkApi) => {
  const state = thunkApi.getState();
  const {
    videoModeration: { commonLoaderOptions, userLoaderOptions },
  } = state;
  const appType = getCurrentUserAppType(state);
  const loaderOptions = userId ? userLoaderOptions : commonLoaderOptions;
  const response = await ContentModerationApi.listForModeration({
    filter: {
      ...transformFilterForApp(loaderOptions.filter, appType),
      ...(userId && { authorIds: [userId] }),
    },
    pagination: { page, size: loaderOptions.pageSize },
    sorting: {
      ...loaderOptions.sorting,
      originsFirst: appType === AppType.CHIPZ && loaderOptions.sorting.originsFirst,
    },
    childLabelsEnabled: true,
    repeatedModeration: loaderOptions.repeatedModeration,
  });
  return await postprocessModerationListData({ response, thunkApi });
});

export const loadModerationDataByContentIdsAction = createAsyncThunk<
  ContentLoaderResult,
  { contentIds: number[] },
  ThunkApiType
>(`${name}/loadModerationDataByContentIdsAction`, async ({ contentIds }, thunkApi) => {
  const response = await ContentModerationApi.listForModeration({
    filter: { contentIds: contentIds },
    pagination: { page: 0, size: contentIds.length },
    sorting: defaultLoaderOptions.sorting,
    childLabelsEnabled: true,
  });
  return await postprocessModerationListData({ response, thunkApi });
});

export const loadModerationDataByContentIdsActionForNonModeratorAction = createAsyncThunk<
  Record<number, ModerationContent>,
  { contentIds: number[] },
  ThunkApiType
>(`${name}/loadModerationDataByContentIdsActionForNonModeratorAction`, async ({ contentIds }) => {
  const response = await ContentModerationApi.listForModeration({
    filter: { contentIds: contentIds },
    pagination: { page: 0, size: contentIds.length },
    sorting: defaultLoaderOptions.sorting,
    childLabelsEnabled: true,
  });
  return (response.content ?? []).reduce(
    (a, x) => {
      a[x.contentId] = x;
      return a;
    },
    {} as Record<number, ModerationContent>
  );
});

export const loadThreadContentForModerationAction = createAsyncThunk<
  ContentLoaderResult,
  { parentId: number; page: number; size: number },
  ThunkApiType
>(`${name}/loadThreadContentForModerationAction`, async ({ parentId, page, size }, thunkApi) => {
  const idsResponse = await VideoContentApi.loadThreadChildren({ parentId, page, size });
  if (!idsResponse.content?.length) {
    return emptyContentLoaderResult;
  }
  const contentIds = page === 0 ? [parentId, ...idsResponse.content] : idsResponse.content;
  const response = await ContentModerationApi.listForModeration({
    filter: { contentIds: contentIds },
    pagination: { page: 0, size: contentIds.length },
    sorting: defaultLoaderOptions.sorting,
    childLabelsEnabled: true,
  });
  return await postprocessModerationListData({
    response,
    thunkApi,
    sortContent: (items) => sortBy(items, (x) => Date.parse(x.createdAt!)),
    overridePagination: idsResponse,
  });
});

export const loadRepeatedModerationReportAction = createAsyncThunk<
  RepeatedModerationContentLoaderResult,
  RepeatedModerationReportFilter,
  ThunkApiType
>(`${name}/loadRepeatedModerationReportAction`, async (filter, thunkApi) => {
  const response = await ContentModerationApi.loadRepeatedModerationReport(filter);
  if (!response.records.length) {
    return { ...emptyBaseContentLoaderResult, actionsMap: {} };
  }
  const contentIds = response.records.map((x) => x.content.contentId);
  const actualizedData = await ContentModerationApi.listForModeration({
    filter: { contentIds },
    pagination: { page: 0, size: contentIds.length },
    sorting: defaultLoaderOptions.sorting,
    childLabelsEnabled: true,
  });
  const actualContentsMap = (actualizedData.content || []).reduce(
    (a, x) => {
      a[x.contentId] = x;
      return a;
    },
    {} as Record<number, ModerationContent>
  );

  const contents = response.records.map((x) => actualContentsMap[x.content.contentId] || x.content);
  const baseResult = await postprocessModerationContent({ contents, thunkApi });
  return {
    ...baseResult,
    actionsMap: response.records.reduce(
      (a, x) => {
        a[x.content.contentId] = reverse([...x.actions]);
        return a;
      },
      {} as Record<number, ModerationAction[]>
    ),
  };
});

interface SaveTagsResult {
  contents: ModerationContent[];
  distrustCandidateIds: number[];
}

const computeDistrustCandidateIds = (updatedContent: ModerationContent[], state: RootState): number[] => {
  if (properties[ConfigProperty.VIDEO_MODERATION_DISTRUST_USERS_FLOW_ENABLED].selector(state) !== true) {
    return [];
  }
  const statusesForDistrust = [AssignedModerationStatus.HIDDEN, AssignedModerationStatus.DELETED];
  const distrustCandidateIds = updatedContent
    .filter((x) => {
      if (!x.author?.id) {
        return false;
      }
      if (!statusesForDistrust.includes(x.assignedModerationStatus)) {
        return false;
      }
      const existingItem = state.videoModeration.contentMap[x.contentId];
      if (statusesForDistrust.includes(existingItem.assignedModerationStatus)) {
        return false;
      }
      const user = getUserById(x.author?.id)(state);
      return user && user.isTrusted;
    })
    .map((x) => x.author!.id!);
  return distrustCandidateIds;
};

export const saveModerationTagsOnContentAction = createAsyncThunk<
  SaveTagsResult,
  Record<number, string[]>,
  ThunkApiType
>(`${name}/saveModerationTagsOnContentAction`, async (patch, thunkApi) => {
  const state = thunkApi.getState();
  const result = await ContentModerationApi.setContentTagsBatch({
    contentTags: Object.entries(patch).map(([key, value]) => {
      const contentId = Number.parseInt(key);
      return {
        contentId,
        tagIds: value.filter((x) => !nonHumanTagIds.includes(x)),
        version: state.videoModeration.contentMap[contentId].version,
      };
    }),
  });
  if (result.rejected?.length) {
    thunkApi.dispatch(
      addErrorToQueue({
        localizedError: {
          message: defineMessage({
            defaultMessage: "Version mismatch for contents: {ids}",
          }),
          values: { ids: result.rejected.map((x) => x.contentId).join(", ") },
        },
      })
    );
  }
  return {
    contents: [...(result.updated || []), ...(result.rejected || [])],
    distrustCandidateIds: computeDistrustCandidateIds(result.updated || [], state),
  };
});

export const changeHashtagTagModerationStatusAction = createAsyncThunk(
  `${name}/changeHashtagTagModerationStatusAction`,
  async ({ tagId, moderationStatus }: { tagId: number; moderationStatus: ContentTagModerationStatus }) => {
    const result = await HashtagsModerationApi.setModerationStatus({ tagId, moderationStatus });
    return result;
  }
);

export const toggleInternalTagExtendedAction = createAsyncThunk<
  void,
  { contentId: number; internalTagId: number },
  ThunkApiType
>(`${name}/toggleInternalTagExtendedAction`, async ({ contentId, internalTagId }, thunkApi) => {
  const state = thunkApi.getState();
  const content = getContentById(contentId)(state);
  if (!content) {
    return;
  }
  if (content?.insideTags?.find((x) => x.id === internalTagId)) {
    // remove tag - simple action
    thunkApi.dispatch(toggleInternalTagAction({ content, tagId: internalTagId, refreshInCache: true }));
    return;
  }

  // assign internal tag, assign moderation tag
  const promises: Promise<any>[] = [];
  const togglePromise = thunkApi.dispatch(
    toggleInternalTagAction({ content, tagId: internalTagId, refreshInCache: true })
  );
  promises.push(togglePromise);
  const assignedTags = getAssignedTagsMapSelector(state)[contentId] || [];
  const mainTag = getTagsForHumans(state).filter((x) => !x.status || x.status === "ACTIVE")[0];
  if (mainTag && mainTag.id && !assignedTags.includes(mainTag.id)) {
    promises.push(thunkApi.dispatch(saveModerationTagsOnContentAction({ [contentId]: [mainTag.id] })));
  }
  await Promise.all(promises);
});

export const ignoreChildLabelForUserAction = createAsyncThunk(
  `${name}/ignoreChildLabelForUserAction`,
  async (userId: number) => {
    await ContentModerationApi.ignoreChildLabelsForUsers([userId]);
  }
);

const videoModerationSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFilterAction: (state, action: PayloadAction<{ filter: ModerationContentFilter; forUser: boolean }>) => {
      const { filter, forUser } = action.payload;
      if (forUser) {
        state.userLoaderOptions.filter = filter;
      } else {
        state.commonLoaderOptions.filter = filter;
      }
    },
    setSortingAction: (state, action: PayloadAction<{ sorting: ModerationContentSorting; forUser: boolean }>) => {
      const { sorting, forUser } = action.payload;
      if (forUser) {
        state.userLoaderOptions.sorting = sorting;
      } else {
        state.commonLoaderOptions.sorting = sorting;
      }
    },
    setPageSizeAction: (state, action: PayloadAction<{ pageSize: number; forUser: boolean }>) => {
      const { pageSize, forUser } = action.payload;
      if (forUser) {
        state.userLoaderOptions.pageSize = pageSize;
      } else {
        state.commonLoaderOptions.pageSize = pageSize;
      }
    },
    addChangeTagsTasks: (state: VideoModerationState, action: PayloadAction<ChangeTagsTask[]>) => {
      action.payload.forEach((item) => {
        state.changeTagsTasks[item.contentId] = item;
      });
    },
    removePendingTagTasks: (state: VideoModerationState, action: PayloadAction<number[]>) => {
      state.changeTagsTasks = omit(state.changeTagsTasks, action.payload);
    },
    setPlayerMode: (state: VideoModerationState, action: PayloadAction<PlayerMode>) => {
      state.playerMode = action.payload;
    },
    setStaticPreviewModeAction: (state, action: PayloadAction<boolean>) => {
      state.staticPreviewEnabled = action.payload;
    },
    setFocusedContentIdAction: (state, action: PayloadAction<number>) => {
      state.focusedContentId = action.payload;
    },
    removeDistrustCandidateIdAction: (state, action: PayloadAction<number>) => {
      state.distrustTrustedUserCandidateIds = state.distrustTrustedUserCandidateIds.filter((x) => x !== action.payload);
    },
    setRepeatedModerationOptionAction: (state, action: PayloadAction<boolean>) => {
      state.commonLoaderOptions.repeatedModeration = action.payload;
    },
  },
  extraReducers: (builder) => {
    const storeMetadataFromContentLoaderResult = (state: VideoModerationState, result: BaseContentLoaderResult) => {
      Object.assign(state.contentMap, result.contentMap);
      Object.assign(state.hashtagsModerationStatuses, result.hashtagsModerationStatuses);
      Object.assign(state.userIdToChildLabelsData, result.userIdToChildLabelsData);
    };
    const storePaginationDataFromContentLoaderResult = (loadableData: LoadableData, result: ContentLoaderResult) => {
      loadableData.totalPages = result.totalPages;
      loadableData.totalCount = result.totalCount;
    };
    // list handlers
    builder.addCase(loadContentListForModerationAction.pending, (state, action) => {
      state.listData.isLoading = true;
      state.listData.error = null;
      state.listData.currentRequestId = action.meta.requestId;
    });
    builder.addCase(loadContentListForModerationAction.rejected, (state, action) => {
      state.listData.isLoading = false;
      state.listData.error = action.error;
    });
    builder.addCase(loadContentListForModerationAction.fulfilled, (state, action) => {
      if (state.listData.currentRequestId !== action.meta.requestId) {
        // stale data from old request
        return state;
      }
      state.listData.isLoading = false;
      state.listData.currentPage = action.meta.arg.page;
      storePaginationDataFromContentLoaderResult(state.listData, action.payload);
      state.listData.contentIds = action.payload.contentIds;
      storeMetadataFromContentLoaderResult(state, action.payload);
    });
    builder.addCase(loadModerationDataByContentIdsAction.fulfilled, (state, action) => {
      storeMetadataFromContentLoaderResult(state, action.payload);
    });
    // tag handlers
    builder.addCase(saveModerationTagsOnContentAction.fulfilled, (state, action) => {
      const { contents, distrustCandidateIds } = action.payload;
      contents.forEach((x) => {
        state.contentMap[x.contentId] = x;
      });
      if (distrustCandidateIds.length) {
        state.distrustTrustedUserCandidateIds = uniq([
          ...state.distrustTrustedUserCandidateIds,
          ...distrustCandidateIds,
        ]);
      }
    });
    // thread handlers
    builder.addCase(loadThreadContentForModerationAction.pending, (state, action) => {
      state.threadData.isLoading = true;
      state.threadData.error = null;
      state.threadData.currentPage = action.meta.arg.page;
      if (state.threadParentId !== action.meta.arg.parentId) {
        state.threadParentId = action.meta.arg.parentId;
        state.threadData.contentIds = [];
      }
    });
    builder.addCase(loadThreadContentForModerationAction.fulfilled, (state, action) => {
      if (state.threadParentId !== action.meta.arg.parentId) {
        return;
      }
      state.threadData.isLoading = false;
      state.threadData.contentIds = action.payload.contentIds.filter((x) => x !== state.threadParentId);
      state.threadData.currentPage = action.meta.arg.page;
      storePaginationDataFromContentLoaderResult(state.threadData, action.payload);
      storeMetadataFromContentLoaderResult(state, action.payload);
    });
    builder.addCase(loadThreadContentForModerationAction.rejected, (state, action) => {
      state.threadData.isLoading = false;
      state.threadData.error = action.error;
    });
    // misc
    builder.addCase(changeHashtagTagModerationStatusAction.fulfilled, (state, action) => {
      state.hashtagsModerationStatuses[action.payload.id] =
        action.payload.moderationStatus || ContentTagModerationStatus.NEW;
    });
    builder.addCase(loadAutoApproveTimesAction.fulfilled, (state, action) => {
      Object.assign(state.autoApproveTimes, action.payload);
    });
    builder.addCase(ignoreChildLabelForUserAction.fulfilled, (state, action) => {
      state.userIdToChildLabelsData[action.meta.arg] = null;
    });
    builder.addCase(loadModerationSettingsAction.fulfilled, (state, action) => {
      state.moderationSettings = action.payload;
    });
    builder.addCase(loadContentDescriptionViolationsAction.fulfilled, (state, action) => {
      Object.assign(
        state.contentDescriptionViolationsMap,
        action.payload.violations.reduce(
          (a, x) => {
            a[x.contentId] = x;
            return a;
          },
          {} as Record<number, ContentDescriptionModerationLog>
        )
      );
    });
    builder.addCase(loadContentDescriptionDtosAction.fulfilled, (state, action) => {
      Object.assign(
        state.contentDescriptionDtosMap,
        (action.payload.content || []).reduce(
          (a, x) => {
            a[x.contentId] = x;
            return a;
          },
          {} as Record<number, ContentDescriptionDto>
        )
      );
    });
    builder.addCase(setDescriptionModerationStatusAction.fulfilled, (state, action) => {
      const optDto = action.payload;
      if (!optDto) {
        return;
      }
      state.contentDescriptionDtosMap[optDto.contentId] = optDto;
    });
    builder.addCase(loadRepeatedModerationReportAction.pending, (state, action) => {
      state.repeatedModerationLoadableData.contentIds = [];
      state.repeatedModerationLoadableData.currentRequestId = action.meta.requestId;
      state.repeatedModerationLoadableData.isLoading = true;
      state.repeatedModerationLoadableData.error = null;
    });
    builder.addCase(loadRepeatedModerationReportAction.rejected, (state, action) => {
      if (state.repeatedModerationLoadableData.currentRequestId !== action.meta.requestId) {
        return;
      }
      state.repeatedModerationLoadableData.isLoading = false;
      state.repeatedModerationLoadableData.error = action.error;
    });
    builder.addCase(loadRepeatedModerationReportAction.fulfilled, (state, action) => {
      if (state.repeatedModerationLoadableData.currentRequestId !== action.meta.requestId) {
        return;
      }
      storeMetadataFromContentLoaderResult(state, action.payload);
      state.repeatedModerationLoadableData.isLoading = false;
      state.repeatedModerationLoadableData.error = null;
      state.repeatedModerationLoadableData.contentIds = action.payload.contentIds;
      Object.assign(state.repeatedModerationActionsMap, action.payload.actionsMap);
    });
    builder.addCase(loadContentCoversDataAction.fulfilled, (state, action) => {
      Object.assign(
        state.coversMap,
        action.payload.reduce(
          (a, x) => {
            a[x.contentId] = x;
            return a;
          },
          {} as Record<number, ContentCoverDto>
        )
      );
    });
    builder.addCase(setContentCoverModerationStatusAction.fulfilled, (state, action) => {
      state.coversMap[action.payload.contentId] = action.payload;
    });
    builder.addCase(loadAsrAction.fulfilled, (state, action) => {
      const asrMap = action.payload.asr.reduce(
        (a, x) => {
          a[x.contentId] = x;
          return a;
        },
        {} as Record<number, AsrDtoResponse>
      );
      Object.assign(state.asrMap, asrMap);
    });
    builder.addCase(loadModerationDataByContentIdsActionForNonModeratorAction.fulfilled, (state, action) => {
      Object.assign(state.contentMap, action.payload);
    });
  },
});

export const {
  setFilterAction,
  setSortingAction,
  setPageSizeAction,
  addChangeTagsTasks,
  removePendingTagTasks,
  setPlayerMode,
  setStaticPreviewModeAction,
  setFocusedContentIdAction,
  removeDistrustCandidateIdAction,
  setRepeatedModerationOptionAction,
} = videoModerationSlice.actions;
export default videoModerationSlice.reducer;

export const getAssignedTagsMapSelector = createSelector(
  (state: RootState) => state.videoModeration.contentMap,
  (contentMap) => mapValues(contentMap, (x) => (x.moderationTags || []).map((x) => x.id!))
);
