// eslint-disable-next-line no-restricted-imports
import { DatePicker as MuiDatePicker, DateTimePicker as MuiDateTimePicker } from "@mui/x-date-pickers";
import { DATETIME_PICKER_FORMAT, DATE_PICKER_FORMAT } from "@utils/date";
import { FormattedMessage, MessageDescriptor } from "react-intl";

interface DatePickerProps {
  minDate?: Date;
  maxDate?: Date;
  size?: "small" | "medium";
  value: Date | null;
  onChange: (value: Date | null) => void;
  testId: string;
  labelMessage: MessageDescriptor;
  error?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  disableFuture?: boolean;
  placement?: "top" | "bottom";
}

// to fix issues in cypress when mobile picker unexpectedly shows
const desktopModeMediaQuery = "@media (min-width: 767px)";

export const DatePicker = ({
  minDate,
  maxDate,
  size = "small",
  value,
  onChange,
  testId,
  labelMessage,
  error,
  disabled,
  autoFocus,
  disableFuture,
  placement,
}: DatePickerProps) => {
  return (
    <MuiDatePicker
      format={DATE_PICKER_FORMAT}
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      onChange={onChange}
      label={<FormattedMessage {...labelMessage} />}
      autoFocus={autoFocus}
      disabled={disabled}
      slotProps={{ textField: { size, error, disabled, inputProps: { "data-testid": testId } }, popper: { placement } }}
      disableFuture={disableFuture}
      desktopModeMediaQuery={desktopModeMediaQuery}
    />
  );
};

export const DateTimePicker = ({
  minDate,
  maxDate,
  size = "small",
  value,
  onChange,
  testId,
  labelMessage,
  error,
  disabled,
  autoFocus,
  disableFuture,
  placement,
}: DatePickerProps) => {
  return (
    <MuiDateTimePicker
      format={DATETIME_PICKER_FORMAT}
      ampm={false}
      minDate={minDate}
      minDateTime={minDate}
      maxDate={maxDate}
      maxDateTime={maxDate}
      value={value}
      onChange={onChange}
      label={<FormattedMessage {...labelMessage} />}
      autoFocus={autoFocus}
      disabled={disabled}
      slotProps={{ textField: { size, error, disabled, inputProps: { "data-testid": testId } }, popper: { placement } }}
      disableFuture={disableFuture}
      timeSteps={{ minutes: 1 }}
      desktopModeMediaQuery={desktopModeMediaQuery}
    />
  );
};
