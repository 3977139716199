import { forceTimeZone } from "@utils/date";
import { FormattedDate, FormattedTime } from "react-intl";

const DateTime = ({
  value,
  defaultValue = null,
}: {
  value: string | number | Date | undefined | null;
  defaultValue?: string | null;
}) => {
  if (!value) {
    if (typeof defaultValue === "string") {
      return <>{defaultValue}</>;
    }
    return defaultValue;
  }
  const tzValue = typeof value === "string" ? forceTimeZone(value) : value;
  return (
    <>
      <FormattedDate value={tzValue} />
      &nbsp;
      <FormattedTime value={tzValue} hour12={false} />
    </>
  );
};

export default DateTime;
