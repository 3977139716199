import { ChildSortingType } from "@api";
import { ComplaintType } from "@api/complaints";
import { SortingParams } from "@widgets/AbstractListView/types";
import { defineMessages } from "react-intl";

export interface CommonFilter {
  status?: ComplaintType;
}

export const defaultFilter: CommonFilter = {
  status: ComplaintType.NEW,
};

export const defaultSorting: SortingParams = {
  order: "any",
  direction: ChildSortingType.DESC,
};

export enum ComplaintsViewType {
  UGC = "ugc",
  COMMENT = "comment",
  USER = "user",
}

export const tabMessages = defineMessages({
  [ComplaintsViewType.UGC]: { defaultMessage: "UGC", description: "nontranslatable" },
  [ComplaintsViewType.COMMENT]: { defaultMessage: "Comments" },
  [ComplaintsViewType.USER]: { defaultMessage: "Users" },
});
