import { UserRole } from "@api/user";
import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import { getCurrentUserRole } from "@slices/user";
import { useAppSelector } from "@store/hooks";
import NavigationTabs from "@widgets/NavigationTabs";
import { useMemo } from "react";
import { tabMessages, VideoModerationTabs } from "./types";

const tabs = Object.values(VideoModerationTabs);

const VideoModerationPageHeaderTitle = () => {
  const route = useCurrentRouteDescriptor();
  useSetDocumentTitle({ messageDescriptor: route!.title! });
  const role = useAppSelector(getCurrentUserRole);
  const disabledTabs = useMemo(() => {
    return Object.values(VideoModerationTabs).filter((x) => {
      if (x === VideoModerationTabs.LABELLING) {
        return role === UserRole.WEBADMIN_VIEWER;
      }
      if (x === VideoModerationTabs.REPEATED) {
        return role !== UserRole.SUPER_MODERATOR;
      }
      return false;
    });
  }, [role]);
  return (
    <NavigationTabs possibleValues={tabs} tabQueryKey="view" tabMessagesMap={tabMessages} disabledTabs={disabledTabs} />
  );
};

export default VideoModerationPageHeaderTitle;
