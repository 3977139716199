import AppType from "@models/AppType";
import {
  defaultForAllApps,
  newBooleanParameter,
  newNumberParameter,
  newStringListParameter,
  newStringParameter,
} from "../builders";
import environment from "../environment";
import { ConfigProperty, PropertyGroupType } from "../types";

const group = PropertyGroupType.VIDEO_MODERATION;

export default {
  [ConfigProperty.VIDEO_MODERATION_IMPORTANT_SUPERVISED_BADGES]: newStringListParameter({
    property: ConfigProperty.VIDEO_MODERATION_IMPORTANT_SUPERVISED_BADGES,
    description: "Метки юзеров, которые будут выводиться первыми в модерке видео",
    group,
    defaultValues: {
      [AppType.PUNCH]: [],
      [AppType.CHIPZ]: ["30+", "Интеграция MDK", "Платники", "Партнерка"],
    },
  }),
  [ConfigProperty.VIDEO_MODERATION_HIDDEN_INTERNAL_TAGS]: newStringListParameter({
    property: ConfigProperty.VIDEO_MODERATION_HIDDEN_INTERNAL_TAGS,
    description: "Имена внутренних тегов, которые НЕ будут выводиться в модерке видео",
    group,
    defaultValues: {
      [AppType.PUNCH]: [],
      [AppType.CHIPZ]: ["30+ (people from 30)"],
    },
  }),
  [ConfigProperty.VIDEO_MODERATION_RECOMMEND_TO_FEATURE_INTERNAL_TAG]: newStringParameter({
    property: ConfigProperty.VIDEO_MODERATION_RECOMMEND_TO_FEATURE_INTERNAL_TAG,
    description:
      "Имя внутреннего тега, который модератор может поставить на контент чтобы порекомендовать его к фичерингу. Если имя пустое или такого тега нет, то будет показана кнопка промоутирования",
    group,
    defaultValues: defaultForAllApps("Рекомендовано к фичерингу"),
  }),
  [ConfigProperty.VIDEO_MODERATION_DISTRUST_USERS_FLOW_ENABLED]: newBooleanParameter({
    property: ConfigProperty.VIDEO_MODERATION_DISTRUST_USERS_FLOW_ENABLED,
    description: "Предлагать удалить пользователей из доверенных при скрытии/удалении их контента",
    group,
    defaultValues: defaultForAllApps(true),
  }),
  [ConfigProperty.VIDEO_MODERATION_DELAY_BEFORE_TAGGING_SECONDS]: newNumberParameter({
    property: ConfigProperty.VIDEO_MODERATION_DELAY_BEFORE_TAGGING_SECONDS,
    description: "Задержка (в секундах) перед выставлением модерационного тега на видео",
    group,
    defaultValues: defaultForAllApps(5),
  }),
  [ConfigProperty.VIDEO_MODERATON_DESCRIPTION_BLACKLIST_VIOLATIONS_HIGHLIGHT_ENABLED]: newBooleanParameter({
    property: ConfigProperty.VIDEO_MODERATON_DESCRIPTION_BLACKLIST_VIOLATIONS_HIGHLIGHT_ENABLED,
    description: "Подсвечивать в описаниях нарушения блеклиста или нет",
    group,
    defaultValues: defaultForAllApps(true),
  }),
  [ConfigProperty.VIDEO_MODERATION_THREAD_PAGE_SIZE]: newNumberParameter({
    property: ConfigProperty.VIDEO_MODERATION_THREAD_PAGE_SIZE,
    description: "Сколько репитов за раз грузить в модерке видео при раскрытии треда",
    group,
    defaultValues: {
      [AppType.CHIPZ]: 20,
    },
  }),
  [ConfigProperty.VIDEO_MODERATION_BLOCK_NAVIGATION_ON_UNMODERATED_TEXTS]: newBooleanParameter({
    property: ConfigProperty.VIDEO_MODERATION_BLOCK_NAVIGATION_ON_UNMODERATED_TEXTS,
    description:
      "Если включено, то при наличии неотмодерированных текстов панчей в модерке, навигация блокируется до тех пор, пока все тексты не будут отмодерированны",
    group,
    defaultValues: {
      [AppType.PUNCH]: environment.isTargetingProduction,
    },
  }),
};
