import { OffsetLimit, ResponseDto } from "@api";
import { DELETE, GET, POST, PUT, buildQuery } from "@utils/fetch";

export enum GenreType {
  NEURAL_NETWORK = "NEURAL_NETWORK",
  AUDIO_TRACK = "AUDIO_TRACK",
}
export interface GenreAdminDto {
  id: number;
  genreType?: GenreType;
  name?: string;
}

export interface GenreDto {
  id: number;
  name?: string;
}

export interface CreateGenreDto {
  name: string;
  genreType?: GenreType;
}

const urlPrefix = "api/v1/admin/genres";

export const GenresApi = {
  list: (request: OffsetLimit): Promise<ResponseDto<GenreAdminDto>> => GET(`${urlPrefix}?${buildQuery(request)}`),
  create: (request: CreateGenreDto) => POST(urlPrefix, request),
  update: (id: number | string, request: CreateGenreDto) => PUT(`${urlPrefix}/${id}`, request),
  delete: (id: number | string) => DELETE(`${urlPrefix}/${id}`),
};
