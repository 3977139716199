import { ChildSortingType } from "@api";
import { SortingParams } from "@widgets/AbstractListView/types";

export enum ManualNotificationsViewType {
  TEMPLATES = "templates",
  AUDIENCES = "audiences",
  RULES = "rules",
  MASS_SENDING = "mass_sending",
}

export const defaultFilter = {};
export const defaultSorting: SortingParams = { order: "any", direction: ChildSortingType.DESC };
