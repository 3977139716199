import { PersonDto, PersonsApi } from "@api/persons";
import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit";

export interface PersonsInitialState {
  error: SerializedError | null;
  list: PersonDto[];
}

const initialState: PersonsInitialState = {
  error: null,
  list: [],
};

const name = "persons";

export const loadPersonsAction = createAsyncThunk(`${name}/loadPersons`, async () => {
  const data = PersonsApi.list();
  return data;
});

const personsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadPersonsAction.pending, (state) => {
      state.error = null;
    });
    builder.addCase(loadPersonsAction.fulfilled, (state: PersonsInitialState, action) => {
      state.list = action.payload;
    });
    builder.addCase(loadPersonsAction.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export default personsSlice.reducer;
