import { environment } from "@config";
import { padStart } from "lodash";
import { FormattedMessage } from "react-intl";

const formattedVersion = (() => {
  if (environment.isDevelopmentBuild) {
    return `${environment.applicationVersion}-${environment.targetEnvName}`;
  }
  const [major, minor, patch] = environment.applicationVersion.split(".");
  return [major, padStart(minor, 2, "0"), patch].join(".");
})();

const displayVersion = [formattedVersion, environment.lastCommitHash].filter((x) => x).join(".");

const gitlabUrl = "https://git.esn.one/esn-web/esn-admin-react/-/commit";

const AppVersion = () => {
  const versionValue = environment.lastCommitHash ? (
    <a href={`${gitlabUrl}/${environment.lastCommitHash}`} target="_blank" rel="noreferrer noopener">
      {displayVersion}
    </a>
  ) : (
    displayVersion
  );
  return <FormattedMessage defaultMessage="Version: {version}" values={{ version: versionValue }} />;
};

export default AppVersion;
