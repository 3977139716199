import { UserApi, UserRole } from "@api/user";
import { validNicknameSymbolsRegex } from "@utils/nickname";
import { composeUserLink } from "@widgets/LinkToUser";
import { defineMessage } from "react-intl";
import { SearchResultType, SearchStrategy } from "../models";

const ByUserNickname: SearchStrategy = async ({ query, currentUser }) => {
  if (!query.match(validNicknameSymbolsRegex)) {
    return [];
  }

  const response = await UserApi.list({ offset: 0, limit: 10, nickname: query });
  const defaultResult = {
    type: SearchResultType.LINK,
    adminUrl:
      currentUser.role === UserRole.SUPERVISOR
        ? `/supervisedUsers?tab=ALL&nickName=${query}`
        : `/userSearch?view=ALL&nickName=${query}`,
    message: defineMessage({ defaultMessage: "Search by nickname {value}" }),
    messageValues: { value: <b>{`"${query}"`}</b> },
  };
  const matchingUser = response?.users?.find((x) => x.nickName?.toLowerCase() === query.toLowerCase());

  if (!matchingUser) {
    return [defaultResult];
  }
  return [
    {
      type: SearchResultType.USER,
      adminUrl: composeUserLink({ id: matchingUser.id!, appType: currentUser.appType }),
      exactMatch: true,
      user: matchingUser,
      message: defineMessage({ defaultMessage: "Open profile of {value}" }),
      messageValues: { value: <b>{matchingUser?.nickName}</b> },
    },
    defaultResult,
  ];
};

export default ByUserNickname;
