import { ApplicationType, Page, Pageable } from "@api";
import { POST, buildQuery } from "@utils/fetch";

export interface SetContentTagModerationStatusRequest {
  tagId: number;
  moderationStatus: ContentTagModerationStatus;
}

export interface BatchSetContentTagsModerationStatusRequest {
  batch: SetContentTagModerationStatusRequest[];
}
export interface ContentTagsFilter {
  tagIds?: number[];
  moderationStatuses?: ContentTagModerationStatus[];
}

export enum ContentTagModerationStatus {
  NEW = "NEW",
  WHITELIST = "WHITELIST",
  BLACKLIST = "BLACKLIST",
}

export interface ContentTag {
  id: number;
  name?: string;
  createdAt?: string;
  deletedAt?: string;
  isTest?: boolean;
  applicationType?: ApplicationType;
  moderationStatus?: ContentTagModerationStatus;
  moderatorId?: number;
}

const urlPrefix = `api/v1/admin/moderation-manager`;

export const HashtagsModerationApi = {
  loadModerationData: ({
    pagination,
    filter,
  }: {
    pagination: Pageable;
    filter: ContentTagsFilter;
  }): Promise<Page<ContentTag>> => POST(`${urlPrefix}/content-tags/list?${buildQuery(pagination)}`, filter),
  setModerationStatus: (request: SetContentTagModerationStatusRequest): Promise<ContentTag> =>
    POST(`${urlPrefix}/content-tags/status`, request),
  setModerationStatusBatch: (request: BatchSetContentTagsModerationStatusRequest): Promise<ContentTag[]> =>
    POST(`${urlPrefix}/content-tags/status-batch`, request),
};
