import { DELETE, GET, PATCH, POST } from "@utils/fetch";
import { UserRole } from "./user";

export interface CommentTemplateResponseDto {
  id: number;
  comment: string;
}

export interface CommentTemplateResponseListDto {
  comments: CommentTemplateResponseDto[];
}

const path = `api/v1/admin/comment-template`;
export const CommentTemplatesApi = {
  allowedRoles: [UserRole.CONTENT_MANAGER, UserRole.SUPERVISOR],
  list: (): Promise<CommentTemplateResponseListDto> => GET(path),
  create: (comment: string): Promise<CommentTemplateResponseDto> => POST(path, { comment }),
  update: (id: number, comment: string): Promise<CommentTemplateResponseDto> => PATCH(`${path}/${id}`, { comment }),
  delete: (id: number) => DELETE(`${path}/${id}`),
};
