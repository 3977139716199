import { ChildSortingType, OffsetLimit, ResponseDto, SortingSortByOptional } from "@api";
import { GET, PATCH, POST, buildQuery } from "@utils/fetch";
import { AudioTrackToneDto } from "./audioTones";
import {
  AssetDto,
  BaseContentFilterType,
  ContentCategory,
  ContentStatusType,
  ContentType,
  CreateUgcTagModel,
  DeviceInfo,
  MentionDto,
  NewAssetDto,
  PeriodDto,
  PlatformType,
  UploadResponseDto,
  VoteOnContentDto,
  filterAdapter,
} from "./contentCommon";
import { ContentModerationStatus } from "./contentModeration";
import { ContentPromotionType } from "./contentPromotions";
import { ThemeDto } from "./contentThemes";
import { InsideTagDto } from "./internalTags";
import { PersonDto } from "./persons";
import { ShortTagDto } from "./tags";
import { UserShortDto } from "./user";
import { CustomWebLinkDto, ExternalPlatformWebLinkDto } from "./webLinks";

export enum PunchModeEnum {
  FREESTYLE = "FREESTYLE",
}

export interface ShortAssetAudioDto {
  filePath?: string;
  playlistPath?: string;
  sharePath?: string;

  /** @format int32 */
  fileSize?: number;

  /** @format int32 */
  duration?: number;
}

export interface AudioTrackDto {
  /** @format int64 */
  id: number;
  authors?: PersonDto[];
  producers?: PersonDto[];
  title?: string;
  description?: string | null;
  cover?: string;
  audioBpm?: number;
  contentCount?: number;
  audioTone?: AudioTrackToneDto;
  listAssets?: ShortAssetAudioDto[];
  isTest?: boolean;
  isFavorite?: boolean;
}

export interface ContentPunchAdminDto {
  id: number;
  contentType?: ContentType;
  title?: string | null;
  description?: string | null;
  viewCount?: number;
  likeCount?: number;
  commentCount?: number;
  shareCount?: number;
  listAssets?: AssetDto[];
  tags?: ShortTagDto[];
  createdAt?: string;
  user?: UserShortDto;
  audioTrack?: AudioTrackDto | null;
  isTest?: boolean;
  contentStatus?: ContentStatusType;
  removedTags?: ShortTagDto[];
  moderationStatus?: ContentModerationStatus;
  insideTags?: InsideTagDto[];
  mentions?: MentionDto[];
  deviceInfo?: DeviceInfo;
  periods?: PeriodDto[];
  mode?: PunchModeEnum;
  freestyleLyrics?: string[];
  externalPlatformWebLinks?: ExternalPlatformWebLinkDto[];
  customWebLinks?: CustomWebLinkDto[];
  cover?: string;
  vote?: VoteOnContentDto;
  contentCategory?: ContentCategory;
  themes?: ThemeDto[];
  originalDescription?: string;
}

export interface AudioUgcListFilterType extends BaseContentFilterType {
  audioTrackId?: number | null;
  audioTrackGenreId?: number | null;
  promoType?: ContentPromotionType;
  onlyFirstPunches?: boolean;
  moderationStatus?: ContentModerationStatus | null;
  promoGroup?: string;
  supervisorId?: number | null;
  mode?: PunchModeEnum | null;
  contentType?: ContentType | null;
  contentCategory?: ContentCategory | null;
  contentThemeId?: number | null;
  currentlyPromoted?: boolean | null;
}

export interface BaseAudioUgcCreateRequest {
  title: string;
  description: string;
  tags: CreateUgcTagModel[];
  mentions: MentionDto[];
  skipTextValidation: boolean;
  isDraft: boolean;
  isVoteVisible: boolean;
  assets: NewAssetDto[];
}

export interface CreateFreestyleDto extends BaseAudioUgcCreateRequest {
  audioTrackId: number;
  freestyleLyrics: string[];
}

export type CreateInstrumentalDto = BaseAudioUgcCreateRequest;

export interface CreateAudioPgcDto extends BaseAudioUgcCreateRequest {
  audioTrackId: number;
  themeId?: number;
}

export interface CreateUgcAudioDto extends BaseAudioUgcCreateRequest {
  audioTrackId: number;
}

export const AudioContentApi = {
  loadBatch: (contentIds: number[]): Promise<ContentPunchAdminDto[]> =>
    POST(`api/v1/admin/audio/content/get-by-ids`, { contentIds }),
  list: ({
    offset,
    limit,
    sortBy = "CREATED_AT",
    sorting = ChildSortingType.DESC,
    filter,
  }: {
    filter?: AudioUgcListFilterType;
  } & OffsetLimit &
    SortingSortByOptional): Promise<ResponseDto<ContentPunchAdminDto>> =>
    GET(
      `api/v1/admin/audio/content?${buildQuery({
        offset,
        limit,
        sortBy,
        sorting, // old field
        sort: sorting, // new field
        ...(filter && filterAdapter(filter)),
      })}`
    ),
  createAudioContent: ({
    userId,
    request,
    usePresignedUrls,
    devicePlatform,
  }: {
    userId: number;
    request: CreateUgcAudioDto;
    usePresignedUrls: boolean;
    devicePlatform: PlatformType;
  }): Promise<UploadResponseDto> =>
    POST(`api/v1/admin/audio/ugc?${buildQuery({ userId, devicePlatform, usePresignedUrls })}`, request),
  updateAudioContent: ({
    contentId,
    tags,
    description,
    mentions,
    devicePlatform,
    userId,
  }: {
    contentId: number;
    tags?: CreateUgcTagModel[];
    description?: string;
    mentions?: MentionDto[];
    devicePlatform: PlatformType;
    userId: number;
  }): Promise<ContentPunchAdminDto> =>
    PATCH(`api/v1/admin/audio/content/${contentId}?${buildQuery({ userId, devicePlatform })}`, {
      tags,
      description,
      mentions,
    }),
  createAudioPgcContent: ({
    userId,
    devicePlatform,
    request,
    usePresignedUrls,
  }: {
    userId: number;
    devicePlatform: PlatformType;
    usePresignedUrls: boolean;
    request: CreateAudioPgcDto;
  }): Promise<UploadResponseDto> =>
    POST(`api/v1/admin/audio/pgc?${buildQuery({ userId, devicePlatform, usePresignedUrls })}`, request),
  createAudioFreeStyleContent: ({
    userId,
    devicePlatform,
    usePresignedUrls,
    request,
  }: {
    userId: number;
    devicePlatform: PlatformType;
    usePresignedUrls: boolean;
    request: CreateFreestyleDto;
  }): Promise<UploadResponseDto> =>
    POST(`api/v1/admin/audio/ugc/freestyle?${buildQuery({ userId, devicePlatform, usePresignedUrls })}`, request),
  createAudioInstrumentalContent: ({
    userId,
    devicePlatform,
    usePresignedUrls,
    request,
  }: {
    userId: number;
    devicePlatform: PlatformType;
    usePresignedUrls: boolean;
    request: CreateInstrumentalDto;
  }): Promise<UploadResponseDto> =>
    POST(`api/v1/admin/audio/ugc/instrumental?${buildQuery({ userId, devicePlatform, usePresignedUrls })}`, request),
};
