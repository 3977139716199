import { ChildSortingType, Page } from "@api";
import { CommentDto, CommentsApi } from "@api/comments";
import {
  CommentBlacklistModerationLogEntry,
  CommentModerationStatus,
  CommentsModerationApi,
  ModerationComment,
  ModerationCommentsFilter,
} from "@api/commentsModeration";
import { ModerationContentSortType } from "@api/contentModeration";
import { ConfigProperty, properties } from "@config";
import { PayloadAction, SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState, ThunkApiType } from "@store/store";
import { uniq } from "lodash";
import { loadContentsToCache } from "./contentCache";
import { addErrorToQueue } from "./rejectedErrorsQueue";
import { getUserById, loadUsersToCache } from "./usersCache";

export interface LoadableParams {
  moderationStatuses: CommentModerationStatus[];
  pageSize: number;
  page: number;
  sortBy: ModerationContentSortType;
  createdAtDirection: ChildSortingType;
  blacklistFirst: boolean;
}

export interface LoadableData {
  currentRequestId: string | null;
  commentIds: number[];
  error: SerializedError | null;
  isLoading: boolean;
  page: number;
  totalPages: number;
  totalElements: number;
}

export enum ModalSourceType {
  author = "author",
  content = "content",
  replies = "replies",
}

export interface ModalConfig {
  sourceType: ModalSourceType;
  sourceId: number;
}

// TODO remove total after 50 released, it's only for backwards compatibility here
type UserCommentModerationStats = Partial<Record<CommentModerationStatus | "TOTAL", number>>;

type UserCommentModerationStatsMap = Record<number, UserCommentModerationStats>;

export interface CommentsModerationState {
  moderationCommentMap: Record<string, ModerationComment>;
  commentDtoMap: Record<string, CommentDto>;
  blacklistChecksMap: Record<string, CommentBlacklistModerationLogEntry>;
  listData: LoadableData;
  listParams: LoadableParams;
  modalData: LoadableData;
  modalParams: LoadableParams;
  modalConfig: ModalConfig | null;
  userData: LoadableData;
  userParams: LoadableParams;
  distrustTrustedUserCandidateIds: number[];
  userCommentModerationStats: UserCommentModerationStatsMap;
}

const defaultData: LoadableData = {
  currentRequestId: null,
  commentIds: [],
  error: null,
  isLoading: false,
  page: 0,
  totalPages: 0,
  totalElements: 0,
};

const defaultPageSize = 100;

export const defaultModalParams: LoadableParams = {
  moderationStatuses: Object.values(CommentModerationStatus),
  pageSize: defaultPageSize,
  page: 0,
  sortBy: ModerationContentSortType.DEFAULT,
  createdAtDirection: ChildSortingType.DESC,
  blacklistFirst: false,
};

const defaultListParams: LoadableParams = {
  moderationStatuses: [CommentModerationStatus.NEW],
  pageSize: defaultPageSize,
  page: 0,
  sortBy: ModerationContentSortType.DEFAULT,
  createdAtDirection: ChildSortingType.DESC,
  blacklistFirst: false,
};

const initialState: CommentsModerationState = {
  moderationCommentMap: {},
  commentDtoMap: {},
  blacklistChecksMap: {},
  listData: defaultData,
  listParams: defaultListParams,
  modalData: defaultData,
  modalParams: defaultModalParams,
  modalConfig: null,
  userData: defaultData,
  userParams: defaultModalParams,
  distrustTrustedUserCandidateIds: [],
  userCommentModerationStats: {},
};

const name = "commentsModeration";

interface LoaderResult {
  moderationResponse: Page<ModerationComment>;
  rawComments: CommentDto[];
  blacklistChecksResult: CommentBlacklistModerationLogEntry[];
}

const enrich = async ({
  response,
  rawComments,
  dispatch,
}: {
  response: Page<ModerationComment>;
  rawComments: CommentDto[];
  dispatch: AppDispatch;
}): Promise<LoaderResult> => {
  const contentIds = uniq((response.content || []).map((x) => x.contentId)).filter(
    (x) => typeof x === "number"
  ) as number[];
  const userIds = uniq([
    ...(response.content || []).map((x) => x.userId),
    ...rawComments.map((x) => x.user?.id),
  ]).filter((x) => typeof x === "number") as number[];
  // note no await here - we don't block for content cus it's only for popup anyways
  dispatch(loadContentsToCache({ ids: contentIds, checkExisting: true }));
  await dispatch(loadUsersToCache({ ids: userIds, checkExisting: true }));
  dispatch(loadBatchUserCommentsModerationStatsAction(userIds));
  let blacklistChecksResult: CommentBlacklistModerationLogEntry[] = [];
  const commentIds = uniq([...(response.content || []).map((x) => x.id), ...rawComments.map((x) => x.id)]) as number[];
  if (commentIds.length) {
    try {
      const blacklistResponse = await CommentsModerationApi.loadBlacklistLog(commentIds);
      if (blacklistResponse.content) {
        blacklistChecksResult = blacklistResponse.content;
      }
    } catch (e) {
      dispatch(addErrorToQueue(e as SerializedError));
    }
  }
  return { moderationResponse: response, rawComments, blacklistChecksResult };
};

const postprocessResponse = async (
  response: Page<ModerationComment>,
  dispatch: AppDispatch,
  state: RootState,
  extraCommentIds: number[]
): Promise<LoaderResult> => {
  if (response.empty || !response.content) {
    return { moderationResponse: response, rawComments: [], blacklistChecksResult: [] };
  }
  const commentDtoMap = state.commentsModeration.commentDtoMap;
  const missingCommentIds = [...response.content.map((x) => x.id!), ...extraCommentIds].filter(
    (x) => !commentDtoMap[x]
  );
  const rawComments = missingCommentIds.length ? await CommentsApi.batchByIds(missingCommentIds) : [];
  return enrich({ response, rawComments, dispatch });
};

export const loadList = createAsyncThunk<LoaderResult, LoadableParams, ThunkApiType>(
  `${name}/loadContent`,
  async (params, thunkApi) => {
    const commentsPage = await CommentsModerationApi.listForModeration({
      page: params.page,
      size: params.pageSize,
      filter: { moderationStatuses: params.moderationStatuses },
      sortBy: params.sortBy || ModerationContentSortType.DEFAULT,
      createdAtDirection: params.createdAtDirection || ChildSortingType.DESC,
      blacklistFirst: params.blacklistFirst,
    });
    return await postprocessResponse(commentsPage, thunkApi.dispatch, thunkApi.getState(), []);
  }
);

export const loadUserCommentsList = createAsyncThunk<
  LoaderResult,
  { params: LoadableParams; userId: number },
  ThunkApiType
>(`${name}/loadUserCommentsList`, async ({ params, userId }, thunkApi) => {
  const commentsPage = await CommentsModerationApi.listForModeration({
    page: params.page,
    size: params.pageSize,
    filter: { moderationStatuses: params.moderationStatuses, userIds: [userId] },
    sortBy: params.sortBy,
    createdAtDirection: params.createdAtDirection || ChildSortingType.DESC,
    blacklistFirst: params.blacklistFirst,
  });
  return await postprocessResponse(commentsPage, thunkApi.dispatch, thunkApi.getState(), []);
});

export const loadByIds = createAsyncThunk<LoaderResult, { commentIds: number[] }, ThunkApiType>(
  `${name}/loadByIds`,
  async ({ commentIds }, thunkApi) => {
    const uniqueIds = uniq(commentIds);
    const [moderationResponse, rawComments] = await Promise.all([
      CommentsModerationApi.listForModeration({
        page: 0,
        size: uniqueIds.length,
        filter: { commentIds: uniqueIds },
        sortBy: ModerationContentSortType.DEFAULT,
        createdAtDirection: ChildSortingType.DESC,
      }),
      CommentsApi.batchByIds(uniqueIds),
    ]);
    return enrich({ response: moderationResponse, rawComments, dispatch: thunkApi.dispatch });
  }
);

export const loadModal = createAsyncThunk<
  LoaderResult,
  { modalConfig: ModalConfig; params: LoadableParams },
  ThunkApiType
>(`${name}/loadModal`, async ({ modalConfig, params }, thunkApi) => {
  const filter: ModerationCommentsFilter = {
    ...(modalConfig.sourceType === ModalSourceType.author && { userIds: [modalConfig.sourceId] }),
    ...(modalConfig.sourceType === ModalSourceType.content && { contentIds: [modalConfig.sourceId] }),
    ...(modalConfig.sourceType === ModalSourceType.replies && { parentIds: [modalConfig.sourceId] }),
    moderationStatuses: params.moderationStatuses,
  };
  const pagination =
    modalConfig.sourceType === ModalSourceType.author
      ? {
          page: params.page,
          size: params.pageSize,
        }
      : // for content and replies requesting a lot and hoping holds on prod
        // if we are successful in future might change this logic to pagination
        { page: 0, size: 1000 };
  const commentsPage = await CommentsModerationApi.listForModeration({
    ...pagination,
    filter,
    sortBy: ModerationContentSortType.DEFAULT,
    createdAtDirection: ChildSortingType.DESC,
  });
  return await postprocessResponse(
    commentsPage,
    thunkApi.dispatch,
    thunkApi.getState(),
    modalConfig.sourceType === ModalSourceType.replies ? [modalConfig.sourceId] : []
  );
});

export const setCommentStatuses = createAsyncThunk<
  { commentsData: ModerationComment[]; distrustCandidateIds: number[] },
  Record<string, CommentModerationStatus>,
  ThunkApiType
>(`${name}/setCommentStatuses`, async (mapping, thunkApi) => {
  const data = await CommentsModerationApi.batchSetStatuses({
    batch: Object.entries(mapping).map(([k, v]) => ({ commentId: Number.parseInt(k), moderationStatus: v })),
  });
  const userIds = data.map((x) => x.userId).filter((x) => x) as number[];
  thunkApi.dispatch(loadBatchUserCommentsModerationStatsAction(userIds));
  const state = thunkApi.getState();
  const distrustFlowEnabled =
    properties[ConfigProperty.COMMENTS_MODERATION_DISTRUST_USERS_FLOW_ENABLED].selector(state);
  const distrustCandidateIds: number[] = distrustFlowEnabled
    ? data
        .filter((x) => {
          if (!x.userId) {
            return false;
          }
          if (x.moderationStatus !== CommentModerationStatus.DELETED) {
            return false;
          }
          const user = getUserById(x.userId)(state);
          return user && user.isTrustedComments;
        })
        .map((x) => x.userId!)
    : [];
  return { commentsData: data, distrustCandidateIds };
});

// TODO drop this and use batch after 50 release
export const loadSingleUserCommentsModerationStatsAction = createAsyncThunk<
  UserCommentModerationStats,
  number,
  ThunkApiType
>(`${name}/loadSingleUserCommentsModerationStatsAction`, async (userId, thunkApi) => {
  const canUseBatchEndpoint = properties[ConfigProperty.KS_COMMENTS_MODERATION_USER_STAT_RELEASED].selector(
    thunkApi.getState() as RootState
  );
  // compatibility layer
  if (!canUseBatchEndpoint) {
    const loader = (statuses?: CommentModerationStatus[]) =>
      CommentsModerationApi.listForModeration({
        page: 0,
        size: 1,
        filter: { userIds: [userId], ...(statuses && { moderationStatuses: statuses }) },
        sortBy: ModerationContentSortType.DEFAULT,
        createdAtDirection: ChildSortingType.DESC,
      }).then(({ totalElements }) => totalElements || 0);
    const [totalCount, approvedCount, deletedCount] = await Promise.all([
      loader(),
      loader([CommentModerationStatus.APPROVED]),
      loader([CommentModerationStatus.DELETED]),
    ]);
    return {
      TOTAL: totalCount,
      [CommentModerationStatus.APPROVED]: approvedCount,
      [CommentModerationStatus.DELETED]: deletedCount,
    };
  }
  const data = await CommentsModerationApi.loadModerationStatsByUserIds([userId]);
  return data[0]?.moderatedCommentsCounts ?? {};
});

export const loadBatchUserCommentsModerationStatsAction = createAsyncThunk<
  UserCommentModerationStatsMap,
  number[],
  ThunkApiType
>(
  `${name}/loadBatchUserCommentsModerationStatsAction`,
  async (userIds) => {
    const uniqueUsers = uniq(userIds);
    const data = await CommentsModerationApi.loadModerationStatsByUserIds(uniqueUsers);
    return data.reduce((a, x) => {
      a[x.userId] = x.moderatedCommentsCounts ?? {};
      return a;
    }, {} as UserCommentModerationStatsMap);
  },
  {
    condition: (_, thunkApi) =>
      properties[ConfigProperty.KS_COMMENTS_MODERATION_USER_STAT_RELEASED].selector(
        thunkApi.getState() as RootState
      ) === true,
  }
);

const commentsModerationSlice = createSlice({
  name,
  initialState,
  reducers: {
    closeModal: (state) => {
      state.modalConfig = null;
      state.modalData = defaultData;
    },
    removeDistrustCandidateIdAction: (state, action: PayloadAction<number>) => {
      state.distrustTrustedUserCandidateIds = state.distrustTrustedUserCandidateIds.filter((x) => x !== action.payload);
    },
  },
  extraReducers: (builder) => {
    const fullfilled = (state: CommentsModerationState, data: LoadableData, result: LoaderResult) => {
      data.isLoading = false;
      const { moderationResponse, rawComments, blacklistChecksResult } = result;
      data.page = result.moderationResponse.number || 0;
      data.totalPages = moderationResponse.totalPages || 0;
      data.totalElements = moderationResponse.totalElements || 0;
      data.commentIds = (moderationResponse.content?.map((x) => x.id).filter((x) => x) as number[]) || [];
      if (moderationResponse.content) {
        Object.assign(
          state.moderationCommentMap,
          moderationResponse.content.reduce(
            (a, x) => {
              a[x.id!] = x;
              return a;
            },
            {} as Record<string, ModerationComment>
          )
        );
      }
      if (rawComments.length) {
        Object.assign(
          state.commentDtoMap,
          rawComments.reduce(
            (a, x) => {
              a[x.id] = x;
              return a;
            },
            {} as Record<string, CommentDto>
          )
        );
      }
      if (blacklistChecksResult.length) {
        Object.assign(
          state.blacklistChecksMap,
          blacklistChecksResult.reduce(
            (a, x) => {
              a[x.commentId] = x;
              return a;
            },
            {} as Record<string, CommentBlacklistModerationLogEntry>
          )
        );
      }
    };
    // list handlers
    builder.addCase(loadList.pending, (state, action) => {
      state.listData.isLoading = true;
      state.listData.error = null;
      state.listData.currentRequestId = action.meta.requestId;
      state.listParams = action.meta.arg;
    });
    builder.addCase(loadList.rejected, (state, action) => {
      state.listData.isLoading = false;
      state.listData.error = action.error;
    });
    builder.addCase(loadList.fulfilled, (state, action) => {
      if (state.listData.currentRequestId !== action.meta.requestId) {
        return;
      }
      fullfilled(state, state.listData, action.payload);
    });
    // modal handlers
    builder.addCase(loadModal.pending, (state, action) => {
      state.modalData.isLoading = true;
      state.modalData.error = null;
      state.modalParams = action.meta.arg.params;
      state.modalConfig = action.meta.arg.modalConfig;
    });
    builder.addCase(loadModal.rejected, (state, action) => {
      state.modalData.isLoading = false;
      state.modalData.error = action.error;
    });
    builder.addCase(loadModal.fulfilled, (state, action) => {
      fullfilled(state, state.modalData, action.payload);
    });
    // user handlers - keeping them separate as
    builder.addCase(loadUserCommentsList.pending, (state, action) => {
      state.userData.isLoading = true;
      state.userData.error = null;
      state.userData.currentRequestId = action.meta.requestId;
      state.userParams = action.meta.arg.params;
    });
    builder.addCase(loadUserCommentsList.rejected, (state, action) => {
      state.userData.isLoading = false;
      state.userData.error = action.error;
    });
    builder.addCase(loadUserCommentsList.fulfilled, (state, action) => {
      if (state.userData.currentRequestId !== action.meta.requestId) {
        return;
      }
      fullfilled(state, state.userData, action.payload);
    });
    // status handlers
    builder.addCase(setCommentStatuses.fulfilled, (state, action) => {
      const { commentsData, distrustCandidateIds } = action.payload;
      Object.assign(
        state.moderationCommentMap,
        commentsData.reduce(
          (a, x) => {
            a[x.id!] = x;
            return a;
          },
          {} as Record<string, ModerationComment>
        )
      );
      state.distrustTrustedUserCandidateIds = uniq([...state.distrustTrustedUserCandidateIds, ...distrustCandidateIds]);
    });
    builder.addCase(loadByIds.fulfilled, (state, action) => {
      const { moderationResponse, rawComments } = action.payload;
      if (moderationResponse.content) {
        Object.assign(
          state.moderationCommentMap,
          moderationResponse.content.reduce(
            (a, x) => {
              a[x.id!] = x;
              return a;
            },
            {} as Record<string, ModerationComment>
          )
        );
      }
      if (rawComments.length) {
        Object.assign(
          state.commentDtoMap,
          rawComments.reduce(
            (a, x) => {
              a[x.id] = x;
              return a;
            },
            {} as Record<string, CommentDto>
          )
        );
      }
    });
    builder.addCase(loadBatchUserCommentsModerationStatsAction.fulfilled, (state, action) => {
      Object.assign(state.userCommentModerationStats, action.payload);
    });
    builder.addCase(loadSingleUserCommentsModerationStatsAction.fulfilled, (state, action) => {
      state.userCommentModerationStats[action.meta.arg] = action.payload;
    });
  },
});

export const { closeModal, removeDistrustCandidateIdAction } = commentsModerationSlice.actions;
export default commentsModerationSlice.reducer;
