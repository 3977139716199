import { Box } from "@mui/system";
import { LoggedInUser } from "@slices/user";
import { useCallback, useEffect, useState } from "react";
import LoginData from "./LoginData";
import LoginForm from "./LoginForm";
import PasswordChangeForm from "./PasswordChangeForm";

const LoginWidget = ({ onLoginCompleted }: { onLoginCompleted: (user: LoggedInUser) => void }) => {
  const [loginData, setLoginData] = useState<LoginData | null>(null);
  useEffect(() => {
    if (loginData && !loginData.shouldChangePassword) {
      onLoginCompleted(loginData.user);
    }
  }, [loginData, onLoginCompleted]);
  const onPasswordFlowCompleted = useCallback(() => {
    if (loginData && loginData.user) {
      onLoginCompleted(loginData.user);
    }
  }, [loginData, onLoginCompleted]);

  const showPasswordChange = loginData && loginData.shouldChangePassword;
  return (
    <Box
      sx={{ display: "grid", gridRowGap: "12px" }}
      data-testid={showPasswordChange ? "password-change-form" : "login-form"}
    >
      {showPasswordChange ? (
        <PasswordChangeForm onCompleted={onPasswordFlowCompleted} loginData={loginData} />
      ) : (
        <LoginForm setLoginData={setLoginData} />
      )}
    </Box>
  );
};

export default LoginWidget;
