import { DELETE, GET, PATCH, POST } from "@utils/fetch";

export interface CreateThemeDto {
  name: string;
  position?: number;
  feedId?: string;
}

export interface ThemeDto {
  id: number;
  name: string;
  position?: number;
  feedId?: string;
}

export const ContentThemesApi = {
  create: (request: CreateThemeDto): Promise<ThemeDto> => POST(`api/v1/admin/themes`, request),
  update: (id: number, request: Partial<CreateThemeDto>): Promise<ThemeDto> =>
    PATCH(`api/v1/admin/themes/${id}`, request),
  delete: (id: number) => DELETE(`api/v1/admin/themes/${id}`),
  list: (): Promise<ThemeDto[]> => GET(`api/v1/admin/themes`),
};
