import { environment } from "@config";
import AppType from "@models/AppType";
import { SerializedError } from "@reduxjs/toolkit";
import { mapValues } from "lodash";
import qs from "query-string";
import { parseJson, stringifyJson } from "./json";

/**
 * monkey-patching fetch for better error messages
 */
if (!environment.isTestBuild) {
  const originalFetch = window.fetch;
  window.fetch = (input, init) =>
    originalFetch(input, init).catch((e) => {
      if (typeof input === "string") {
        return Promise.reject({
          message: `${init?.method ?? "GET"} ${input} request failed`,
        });
      } else {
        return Promise.reject(e);
      }
    });
}

const buildFullUrlForAppType = (url: string, app: AppType | null | undefined): string => {
  if (url.startsWith("http") || url.startsWith("/")) {
    return url;
  }
  if (!app) {
    throw new Error("No app type is set");
  }
  const prefix = environment.apiUrls[app];
  if (!prefix) {
    throw new Error(`Unhandled app type: ${app}`);
  }
  return `${prefix}/${url}`;
};

const trimUrl = (url: string): string => {
  const prefix = "api/v1/";
  const suffix = "?";
  let trimmed = url;
  const indexOfPrefix = trimmed.indexOf(prefix);
  if (indexOfPrefix > 0) {
    trimmed = trimmed.substring(indexOfPrefix + prefix.length);
  }
  const indexOfSuffix = trimmed.indexOf(suffix);
  if (indexOfSuffix > 0) {
    trimmed = trimmed.substring(0, indexOfSuffix);
  }
  return trimmed;
};

interface BetterResponse extends Response {
  request?: Request;
}

export interface ErrorMessagePayloadFormat {
  displayError: string;
  rawResponse?: any;
}

const defaultResponseHandler = async (response: BetterResponse) => {
  const isJson = response.headers.get("content-type")?.includes("application/json");
  const data = isJson ? parseJson(await response.text()) : null;
  if (!response.ok) {
    const displayError = [response.request?.method, trimUrl(response.url), `${response.status}`, data?.message]
      .filter((x) => x)
      .join(" ");
    const payload: ErrorMessagePayloadFormat = {
      displayError,
      rawResponse: data,
    };
    return Promise.reject({
      code: `${response.status}`,
      message: stringifyJson(payload),
    } as SerializedError);
  }
  return data;
};

const jsonContentHeaders = {
  "Content-Type": "application/json",
};

export const buildQuery = (obj: any): string =>
  qs.stringify(
    mapValues(obj, (val) => {
      if (val === null) {
        return undefined;
      }
      if (val instanceof Date) {
        return val.toISOString();
      }
      if (val === "") {
        return undefined;
      }
      return val;
    }),
    { arrayFormat: "comma" }
  );

export interface RequestContext {
  /** defaults to token of current admin user */
  token?: string;
  /** defaults to appType of current admin user */
  appType?: AppType;
  /** defaults to ADMIN */
  requiredTokenType?: "ADMIN" | "USER";
}

export const currentAppClientUserRequestContext: RequestContext = { requiredTokenType: "USER" };

const tokenToAuthHeaders = (token: string | undefined): Record<string, string> => {
  if (!token) {
    return {};
  }
  return {
    Authorization: `Bearer ${token}`,
  };
};

export type CurrentAdminUserProvider = () => { appType: AppType; token: string } | undefined;
export type ClientTokenProvider = (appType: AppType) => Promise<string>;

let currentAdminUserProvider: CurrentAdminUserProvider | null = null;
let clientTokenProvider: ClientTokenProvider | null = null;

export const registerTokenProviders = (
  extCurrentAdminUserProvider: CurrentAdminUserProvider,
  extClientTokenProvider: ClientTokenProvider
) => {
  currentAdminUserProvider = extCurrentAdminUserProvider;
  clientTokenProvider = extClientTokenProvider;
};

const prepareFullUrlAndHeaders = (
  baseUrl: string,
  requestContext?: RequestContext
): Promise<{ url: string; headers: Record<string, string> }> => {
  if (!requestContext) {
    // current admin user flow by default
    const currentUser = currentAdminUserProvider!();
    if (!currentUser) {
      return Promise.reject("No current user to perform request with default context");
    }
    return Promise.resolve({
      url: buildFullUrlForAppType(baseUrl, currentUser.appType),
      headers: tokenToAuthHeaders(currentUser.token),
    });
  }
  if (requestContext.requiredTokenType === "USER") {
    // client user flow
    const appType = requestContext.appType ?? currentAdminUserProvider!()!.appType;
    return clientTokenProvider!(appType).then((token) => {
      return { url: buildFullUrlForAppType(baseUrl, appType), headers: tokenToAuthHeaders(token) };
    });
  }
  // custom admin flow
  return Promise.resolve({
    url: buildFullUrlForAppType(baseUrl, requestContext.appType!),
    headers: tokenToAuthHeaders(requestContext.token),
  });
};

const fetchWrapper = <Request = void, Response = void>({
  method,
  url,
  body,
  requestContext,
}: {
  method: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
  url: string;
  body?: Request;
  requestContext?: RequestContext;
}): Promise<Response> => {
  return prepareFullUrlAndHeaders(url, requestContext).then(({ url, headers }) => {
    const isFormData = body && body instanceof FormData;
    return fetch(url, {
      method,
      ...(body && {
        body: isFormData ? body : stringifyJson(body),
      }),
      headers: {
        ...headers,
        ...(body && !isFormData && jsonContentHeaders),
      },
    }).then(defaultResponseHandler);
  });
};

export const formDataWithFile = (name: string, file: File) => {
  const formData = new FormData();
  formData.append(name, file);
  return formData;
};

export const GET = <Response>(url: string, requestContext?: RequestContext): Promise<Response> =>
  fetchWrapper({ method: "GET", url, requestContext });

export const PATCH = <Request, Response = void>(
  url: string,
  body: Request,
  requestContext?: RequestContext
): Promise<Response> => fetchWrapper({ method: "PATCH", url, body, requestContext });

export const POST = <Request, Response = void>(
  url: string,
  body?: Request,
  requestContext?: RequestContext
): Promise<Response> => fetchWrapper({ method: "POST", url, body, requestContext });

export const PUT = <Request, Response = void>(
  url: string,
  body?: Request,
  requestContext?: RequestContext
): Promise<Response> => fetchWrapper({ method: "PUT", url, body, requestContext });

export const DELETE = <Response = void>(url: string, requestContext?: RequestContext): Promise<Response> =>
  fetchWrapper({ method: "DELETE", url, requestContext });
