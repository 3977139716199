import AppType from "@models/AppType";
import { enUS, Localization, ruRU } from "@mui/material/locale";
import { createTheme, PaletteOptions, ThemeProvider } from "@mui/material/styles";
import { Theme } from "@mui/system";
import { SupportedLocaleType } from "@slices/appSettings";
import { useAppSelector } from "@store/hooks";
import React, { useEffect, useMemo } from "react";
import * as styles from "./AppTheme.module.scss";

const localeToMuiLocale = {
  [SupportedLocaleType.RU]: ruRU,
  [SupportedLocaleType.EN]: enUS,
};

const commonPaletteOptions = {
  secondary: { main: "#CCCCCC" },
  error: { main: "#F24822" },
  success: { main: "#9CFF33" },
  warning: { main: "#FF9500" },
};

const appToPalette: Record<AppType, Partial<PaletteOptions>> = {
  [AppType.PUNCH]: {
    primary: { main: "#9CFF33" },
    ...commonPaletteOptions,
  },
  [AppType.CHIPZ]: { primary: { main: "#c1afff" }, ...commonPaletteOptions },
};

const themeBuilder = (palette: Partial<PaletteOptions>, locale: Localization) =>
  createTheme(
    {
      palette: {
        mode: "dark",
        ...palette,
      },
      shape: {
        borderRadius: 12,
      },
      components: {
        // https://github.com/mui/material-ui/issues/26664#issuecomment-1064038365
        // https://esnteam.atlassian.net/browse/WA-116
        MuiPaper: { defaultProps: { sx: { "& *:focus": { outline: "none" } } } },
      },
    },
    locale
  );

export const useAppThemes = () => {
  const locale = useAppSelector((state) => state.appSettings.locale);
  return useMemo(() => {
    return Object.values(AppType).reduce(
      (a, x) => {
        a[x] = themeBuilder(appToPalette[x], localeToMuiLocale[locale]);
        return a;
      },
      {} as Record<AppType, Theme>
    );
  }, [locale]);
};

const AppTheme = ({ children }: { children: React.ReactNode }) => {
  const locale = useAppSelector((state) => state.appSettings.locale);
  const user = useAppSelector((state) => state.user.currentUser);
  const theme = useMemo(() => {
    if (user) {
      return themeBuilder(appToPalette[user.appType], localeToMuiLocale[locale]);
    }
    return themeBuilder(commonPaletteOptions, localeToMuiLocale[locale]);
  }, [user, locale]);
  useEffect(() => {
    if (user?.appType === AppType.PUNCH) {
      document.body.className = styles.punch;
    } else if (user?.appType === AppType.CHIPZ) {
      document.body.className = styles.chips;
    } else {
      document.body.className = "";
    }
  }, [user]);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AppTheme;
