import { appTypeToLogo } from "@models/AppIcons";
import AppType from "@models/AppType";
import { CloseOutlined } from "@mui/icons-material";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { RouteDescriptor } from "@navigation/routesConfig";
import classNames from "classnames";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import * as styles from "./MobileNavigationDrawerContent.module.scss";

const Item = ({
  route,
  selected,
  closeDrawer,
}: {
  route: RouteDescriptor;
  selected: boolean;
  closeDrawer: () => void;
}) => {
  const Icon = route.icon!;
  return (
    <>
      <Link to={route.path} onClick={closeDrawer} className={classNames(styles.button, selected && styles.selected)}>
        <Typography variant="subtitle1" color="text.primary">
          <FormattedMessage {...route.title} />
        </Typography>
        <Icon color="inherit" />
      </Link>
    </>
  );
};

const MobileNavigationDrawerContent = ({
  appType,
  routes,
  closeDrawer,
}: {
  appType: AppType;
  routes: RouteDescriptor[];
  closeDrawer: () => void;
}) => {
  const { pathname } = useLocation();
  const cleanPathname = useMemo(() => pathname.substring(1), [pathname]);
  const Logo = appTypeToLogo[appType];
  return (
    <div className={styles.container}>
      <AppBar position="static">
        <Toolbar variant="dense" className={styles.toolbar}>
          <Logo height="14px" />
          <IconButton onClick={closeDrawer}>
            <CloseOutlined />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={styles.buttons}>
        {routes.map((route) => (
          <Item key={route.path} route={route} selected={route.path === cleanPathname} closeDrawer={closeDrawer} />
        ))}
      </div>
    </div>
  );
};

export default MobileNavigationDrawerContent;
