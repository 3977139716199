import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import { rolesForBadges } from "@slices/supervisedUsers";
import { getCurrentUserRole } from "@slices/user";
import { useAppSelector } from "@store/hooks";
import NavigationTabs from "@widgets/NavigationTabs";
import { useMemo } from "react";
import { defineMessages } from "react-intl";
import { UserSearchViewMode } from "./types";

const tabMessages = defineMessages({
  [UserSearchViewMode.ALL]: { defaultMessage: "User Search" },
  [UserSearchViewMode.BADGED]: { defaultMessage: "Prof. badges" },
  [UserSearchViewMode.LABELS_PERMANENT]: { defaultMessage: "Labels (permanent)" },
  [UserSearchViewMode.LABELS_TEMPORARY]: { defaultMessage: "Labels (temporary)" },
});

const availableTabs = Object.values(UserSearchViewMode);

const UserSearchPageTitle = () => {
  const route = useCurrentRouteDescriptor();
  useSetDocumentTitle({ messageDescriptor: route!.title! });
  const role = useAppSelector(getCurrentUserRole);
  const disabledTabs = useMemo(() => {
    const disabledTabs = [];
    if (!rolesForBadges.includes(role)) {
      disabledTabs.push(UserSearchViewMode.BADGED);
    }
    return disabledTabs;
  }, [role]);
  return (
    <NavigationTabs
      possibleValues={availableTabs}
      tabQueryKey="view"
      tabMessagesMap={tabMessages}
      disabledTabs={disabledTabs}
    />
  );
};

export default UserSearchPageTitle;
