import { useActIfNavigationAllowed } from "@hooks/useBlockNavigation";
import { SvgIconComponent } from "@mui/icons-material";
import { Box, Tab, Tabs } from "@mui/material";
import classNames from "classnames";
import qs from "query-string";
import React, { useCallback, useEffect, useMemo } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import * as styles from "./NavigationTabs.module.scss";

interface NavigationTabsProps {
  possibleValues: string[];
  tabMessagesMap: Record<string, MessageDescriptor | string>;
  tabMessagesValues?: Record<string, Record<any, any>>;
  tabLabels?: Record<string, React.ReactNode>;
  tabQueryKey: string;
  testIdBase?: string;
  iconsMap?: Record<string, SvgIconComponent>;
  disabledTabs?: string[];
  tabLabelClassName?: string;
  preferredTab?: string | null;
}

const pickDefaultTab = ({
  possibleValues,
  preferredTab,
  disabledTabs,
}: {
  possibleValues: string[];
  preferredTab?: string | null | undefined;
  disabledTabs?: string[];
}) => {
  if (preferredTab) {
    if (!disabledTabs?.includes(preferredTab)) {
      return preferredTab;
    }
  }
  const realPossibleValues = possibleValues.filter((x) => !disabledTabs?.includes(x));
  return realPossibleValues[0];
};

const NavigationTabs = ({
  possibleValues,
  tabMessagesMap,
  tabMessagesValues,
  tabQueryKey,
  testIdBase = "tabs",
  iconsMap,
  disabledTabs,
  tabLabelClassName,
  preferredTab,
  tabLabels,
}: NavigationTabsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentTab = useMemo(() => {
    return (qs.parse(location.search)[tabQueryKey] || "") as string;
  }, [location.search, tabQueryKey]);

  const navigateToTab = useCallback(
    (tab: string, replace?: boolean) => {
      const query = qs.parse(location.search);
      navigate(`${location.pathname}?${qs.stringify({ ...query, [tabQueryKey]: tab })}`, { replace });
    },
    [navigate, location.search, location.pathname, tabQueryKey]
  );

  useEffect(() => {
    if (!possibleValues.includes(currentTab) || disabledTabs?.includes(currentTab)) {
      navigateToTab(pickDefaultTab({ possibleValues, preferredTab, disabledTabs }), true);
    }
  }, [possibleValues, disabledTabs, preferredTab, currentTab, navigateToTab]);

  const navigationGuard = useActIfNavigationAllowed();
  const onTabChange = useCallback(
    (_e: React.SyntheticEvent, value: string) => {
      navigationGuard(() => navigateToTab(value));
    },
    [navigateToTab, navigationGuard]
  );
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }} data-testid={testIdBase}>
      <Tabs value={currentTab} onChange={onTabChange} variant="scrollable" scrollButtons="auto">
        {possibleValues.map((x) => {
          const IconComponent = iconsMap ? iconsMap[x] : undefined;
          const message = tabMessagesMap[x];
          const label = tabLabels?.[x];
          return (
            <Tab
              key={x}
              value={x}
              label={
                label ?? (
                  <div className={classNames(styles.label, tabLabelClassName)}>
                    {IconComponent && <IconComponent fontSize="inherit" />}
                    {message ? (
                      typeof message === "string" ? (
                        message
                      ) : (
                        <FormattedMessage {...message} values={tabMessagesValues?.[x]} />
                      )
                    ) : (
                      x
                    )}
                  </div>
                )
              }
              data-testid={`${testIdBase}-${x}`}
              disabled={disabledTabs?.includes(x)}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default NavigationTabs;
