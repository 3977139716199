import { MessageDescriptor, defineMessages } from "react-intl";

export enum BlackWhiteListCategory {
  LISTS = "LISTS",
  EMOJIS = "EMOJIS",
  REPROCESSING = "REPROCESSING",
}

export const categoryMessages: Record<BlackWhiteListCategory, MessageDescriptor> = defineMessages({
  [BlackWhiteListCategory.LISTS]: { defaultMessage: "Lists" },
  [BlackWhiteListCategory.EMOJIS]: { defaultMessage: "Emojis" },
  [BlackWhiteListCategory.REPROCESSING]: { defaultMessage: "Reprocessing" },
});
