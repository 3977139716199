import { AdminApi } from "@api/admin";
import useDefaultNetworkErrorHandler from "@hooks/useDefaultNetworkErrorHandler";
import { Alert, Button, Typography } from "@mui/material";
import { SerializedError } from "@reduxjs/toolkit";
import { allPasswordChecksPassed, checkPasswordStrength, isValidPasswordString } from "@utils/password";
import PasswordStrengthIndicator from "@widgets/PasswordStrengthIndicator";
import { useCallback, useState } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import LoginData from "./LoginData";
import PasswordTextField from "./PasswordTextField";

const messages = defineMessages({
  newPassword: {
    defaultMessage: "New password",
  },
  newPasswordRepeated: {
    defaultMessage: "Repeat new password",
  },
});

const PasswordChangeForm = ({ onCompleted, loginData }: { onCompleted: () => void; loginData: LoginData }) => {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeated, setNewPasswordRepeated] = useState("");
  const [error, setError] = useState<SerializedError | null>(null);
  const errorHandler = useDefaultNetworkErrorHandler();
  const onSaveClick = useCallback(() => {
    AdminApi.changeOwnPassword(
      {
        oldPassword: loginData.password,
        newPassword,
        newPasswordRepeat: newPasswordRepeated,
      },
      loginData.user.appType,
      loginData.user.token
    )
      .then(() => {
        onCompleted();
      })
      .catch((e) => {
        errorHandler(e);
        setError(e);
      });
  }, [errorHandler, onCompleted, loginData, newPassword, newPasswordRepeated]);
  const checkResult = checkPasswordStrength(newPassword);
  return (
    <>
      <Typography variant="body1" align="right">
        <FormattedMessage defaultMessage="Change password" />
      </Typography>
      <PasswordTextField
        id="new-password"
        autoComplete="new-password"
        password={newPassword}
        setPassword={(p) => {
          if (isValidPasswordString(p)) {
            setNewPassword(p);
          }
        }}
        labelMessage={messages.newPassword}
      />
      <PasswordTextField
        id="new-password-repeat"
        autoComplete="new-password"
        password={newPasswordRepeated}
        setPassword={(p) => {
          if (isValidPasswordString(p)) {
            setNewPasswordRepeated(p);
          }
        }}
        labelMessage={messages.newPasswordRepeated}
      />
      <PasswordStrengthIndicator checkResults={checkResult} singleColumn />
      {error && (
        <Alert severity="error">
          {error.message || <FormattedMessage defaultMessage="Failed to change password" />}
        </Alert>
      )}
      <Button
        disabled={!newPassword || !newPasswordRepeated || !allPasswordChecksPassed(checkResult)}
        variant="contained"
        onClick={onSaveClick}
        data-testid="save-button"
      >
        <FormattedMessage defaultMessage="Save" />
      </Button>
      <Button variant="outlined" onClick={onCompleted} data-testid="skip-button">
        <FormattedMessage defaultMessage="Skip changing password" />
      </Button>
    </>
  );
};

export default PasswordChangeForm;
