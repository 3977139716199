import { FormattedMessage, MessageDescriptor } from "react-intl";

const SafeTranslatedEnum = <Type extends string>({
  value,
  translationsMap,
}: {
  value: Type;
  translationsMap: Record<Type, MessageDescriptor>;
}) => {
  if (translationsMap[value]) {
    return <FormattedMessage {...translationsMap[value]} />;
  }
  return <>{value}</>;
};

export default SafeTranslatedEnum;
