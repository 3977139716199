import { AdminApi } from "@api/admin";
import useDefaultNetworkErrorHandler from "@hooks/useDefaultNetworkErrorHandler";
import { appTypeToLogo } from "@models/AppIcons";
import AppType from "@models/AppType";
import { LinearProgress, ThemeProvider } from "@mui/material";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { SerializedError } from "@reduxjs/toolkit";
import { stringifyJson } from "@utils/json";
import AppVersion from "@widgets/AppVersion";
import BetterSelect from "@widgets/BetterSelect";
import { useCallback, useState } from "react";
import { FormattedMessage, defineMessage } from "react-intl";
import { useAppThemes } from "../AppTheme";
import { safeParseErrorMessagePayload } from "../RejectedErrorsReporter";
import LoginData from "./LoginData";
import PasswordTextField from "./PasswordTextField";

const LoginForm = ({ setLoginData }: { setLoginData: (data: LoginData) => void }) => {
  const appThemes = useAppThemes();
  const [appType, setAppType] = useState(AppType.PUNCH);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [pendingState, setPendingState] = useState<{ inProgress: boolean; error: SerializedError | null }>({
    inProgress: false,
    error: null,
  });
  const errorHandler = useDefaultNetworkErrorHandler();
  const onLoginClick = useCallback(() => {
    setPendingState({ inProgress: true, error: null });
    AdminApi.login({ login: username, password }, appType)
      .then((response) => {
        if (!response.user || !response.token) {
          setPendingState({
            inProgress: false,
            error: { message: `Missing fields in response: ${stringifyJson(response)}` },
          });
        } else {
          setLoginData({
            password,
            shouldChangePassword: response.user.isPasswordChangeRequired || false,
            user: {
              token: response.token,
              appType,
              role: response.user.role!,
              login: username,
              name: response.user.name!,
              userId: response.user.id!,
            },
          });
        }
      })
      .catch((e) => {
        errorHandler(e);
        setPendingState({ inProgress: false, error: e as SerializedError });
      });
  }, [username, password, appType, errorHandler, setLoginData]);
  return (
    <ThemeProvider theme={appThemes[appType]}>
      <Typography variant="body1" align="right">
        <AppVersion />
      </Typography>
      <BetterSelect
        fullWidth
        possibleValues={Object.values(AppType)}
        value={appType}
        onChangeValue={setAppType}
        labelMessage={defineMessage({ defaultMessage: "App" })}
        idBase="login-app-type"
        parseValue={(x) => x as AppType}
        renderOption={(option) => {
          const Logo = appTypeToLogo[option];
          return <Logo height="12px" />;
        }}
      />
      <TextField
        id="login-username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        label={<FormattedMessage defaultMessage="User name" />}
        variant="outlined"
        autoComplete="username"
      />
      <PasswordTextField id="login-password" password={password} setPassword={setPassword} />
      {pendingState.error && (
        <Alert data-testid="login-error-message" severity="error">
          {safeParseErrorMessagePayload(pendingState.error.message ?? "")?.displayError || (
            <FormattedMessage defaultMessage="Failed to log in" />
          )}
        </Alert>
      )}
      {pendingState.inProgress ? (
        <LinearProgress />
      ) : (
        <Button
          variant="contained"
          color="primary"
          disabled={!username || !password}
          onClick={onLoginClick}
          data-testid="login-button"
        >
          <FormattedMessage defaultMessage="Login" description="verb, to log in" />
        </Button>
      )}
      <Typography variant="subtitle2" align="center">
        <FormattedMessage defaultMessage="Please ask your administrator in case you forgot your username or password" />
      </Typography>
    </ThemeProvider>
  );
};

export default LoginForm;
