import { ChildSortingType, Page } from "@api";
import { DELETE, GET, POST, PUT, buildQuery, formDataWithFile } from "@utils/fetch";
import { UserFullDto } from "./user";

export interface ProfileBadgeRequest {
  id?: number;
  description?: string;
}

export interface ProfileBadge {
  id?: number;
  description?: string;
  cdnUrl?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface SetProfileBadgesDto {
  badgeIds: string[];
}

export const ProfileBadgesApi = {
  list: ({
    page,
    size,
    order,
    direction,
  }: {
    page: number;
    size: number;
    order?: string;
    direction?: ChildSortingType;
  }): Promise<Page<ProfileBadge>> =>
    GET(
      `api/v1/admin/profile_badges?${buildQuery({
        page,
        size,
        ...(order && direction && { sort: `${order},${direction}` }),
      })}`
    ),
  update: ({ id, description }: Required<ProfileBadgeRequest>): Promise<ProfileBadge> =>
    PUT(`api/v1/admin/profile_badges`, { id, description }),
  create: ({ description }: { description: string }): Promise<ProfileBadge> =>
    POST(`api/v1/admin/profile_badges`, { description }),
  delete: (id: string): Promise<void> => DELETE(`api/v1/admin/profile_badges/${id}`),
  uploadImage: (id: number, image: File): Promise<ProfileBadge> =>
    POST(`api/v1/admin/profile_badges/${id}/image`, formDataWithFile("image", image)),
  assignToUser: (userId: number, badgeIds: number[]): Promise<UserFullDto> =>
    POST(`api/v1/admin/users/${userId}/profile_badges`, { badgeIds }),
};
