import { GET, POST, RequestContext } from "@utils/fetch";
import { last } from "lodash";

export interface ShortenedUrl {
  url?: string;
  hash?: string;
  original?: string;
  expiresAt?: string;
}

const path = `api/v1/admin/short-urls`;

export const extractHash = (url: string): string => last(url.split("/"))!;

export const ShortUrlsApi = {
  resolve: (hash: string, requestContext?: RequestContext): Promise<ShortenedUrl> =>
    GET(`${path}/resolve/${hash}`, requestContext),
  shorten: (url: string): Promise<ShortenedUrl> => POST(`${path}/shorten`, { url }),
};
