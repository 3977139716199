import { AudioContentApi, ContentPunchAdminDto } from "@api/audioContent";
import { ContentShortChipzAdminDto, VideoContentApi } from "@api/videoContent";
import AppType from "@models/AppType";
import { SearchResult, SearchStrategy } from "../models";
import { makeContentSearchResult } from "../searchResultBuilders";

const processContentResponse = (
  response: ContentPunchAdminDto[] | ContentShortChipzAdminDto[],
  appType: AppType
): SearchResult[] => {
  if (!response.length) {
    return [];
  }
  const content = response[0];
  return [makeContentSearchResult({ content, contentId: content.id, appType })];
};

const ByContentId: SearchStrategy = async ({ query, currentUser }) => {
  const contentId = Number.parseInt(query);
  if (Number.isNaN(contentId)) {
    return [];
  }
  if (currentUser.appType === AppType.PUNCH) {
    const response = await AudioContentApi.loadBatch([contentId]);
    return processContentResponse(response, currentUser.appType);
  }
  const response = await VideoContentApi.loadBatch([contentId]);
  return processContentResponse(response, currentUser.appType);
};

export default ByContentId;
