import { appTypeToApplicationContentType } from "@api";
import { ContentPunchAdminDto } from "@api/audioContent";
import { ClientAuthApi, ClientAuthResponse, RefreshResponse } from "@api/clientAuth";
import { ClientContentApi } from "@api/clientContent";
import { ClientDataApi, ClientDataResponse } from "@api/clientData";
import { ClientUserApi } from "@api/clientUser";
import { ContentShortChipzAdminDto } from "@api/videoContent";
import AppType from "@models/AppType";
import { PayloadAction, SerializedError, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkApiType } from "@store/store";
import { asyncDelay } from "@utils/promise";
import { uniq } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { loadContentsToCache } from "./contentCache";
import { addErrorToQueue } from "./rejectedErrorsQueue";
import { getCurrentUserAppType } from "./user";

/** user of mobile app/web, not admin user */
export interface ClientUser {
  userId: number;
  nickName: string;
  avatar: string;
  token: string;
  refreshToken: string;
  deviceUuid: string;
  tokenExpiresAt: string;
}

export interface UserState {
  deviceUuid: string;
  users: Partial<Record<AppType, ClientUser>>;
  refreshingClientToken: AppType[];
  migrationComplete: boolean;
  contentForClientComments: ContentPunchAdminDto | ContentShortChipzAdminDto | undefined;
  likedContentsMap: Record<AppType, Record<number, boolean>>;
  followedUsersMap: Record<AppType, Record<number, boolean>>;
}

const initialState: UserState = {
  deviceUuid: `wa-${uuidv4()}`,
  users: {},
  refreshingClientToken: [],
  migrationComplete: false,
  contentForClientComments: undefined,
  likedContentsMap: {
    [AppType.PUNCH]: {},
    [AppType.CHIPZ]: {},
  },
  followedUsersMap: {
    [AppType.PUNCH]: {},
    [AppType.CHIPZ]: {},
  },
};

const name = "client";

export const migrateClientsFromUserSliceAction = createAsyncThunk<
  Partial<Record<AppType, ClientUser>>,
  void,
  ThunkApiType
>(
  `${name}/migrateClientsFromUserSliceAction`,
  (_, thunkApi) => {
    return thunkApi.getState().user.clientUsers;
  },
  {
    condition: (_, thunkApi) => !thunkApi.getState().client.migrationComplete,
  }
);

export const obtainOrRefreshClientTokenAction = createAsyncThunk<string, AppType, ThunkApiType>(
  `${name}/obtainOrRefreshClientTokenAction`,
  async (currentApp, thunkApi) => {
    if (!thunkApi.getState().client.users[currentApp]) {
      return "";
    }
    while (thunkApi.getState().client.refreshingClientToken.includes(currentApp)) {
      await asyncDelay(100);
    }
    const clientUser = thunkApi.getState().client.users[currentApp];
    if (!clientUser) {
      return "";
    }
    const now = Date.now();
    const expiresAt = new Date(clientUser.tokenExpiresAt).getTime();
    if (expiresAt - now > 360_000) {
      return clientUser.token;
    }
    thunkApi.dispatch(clientSlice.actions.lockClientTokenUpdateAction(currentApp));
    try {
      const refreshResponse = await ClientAuthApi.refresh({
        deviceUuid: clientUser.deviceUuid,
        refreshToken: clientUser.refreshToken,
        appType: currentApp,
      });
      thunkApi.dispatch(clientSlice.actions.updateTokenForClientUserAction({ refreshResponse, appType: currentApp }));
      return refreshResponse.token;
    } catch (e) {
      thunkApi.dispatch(addErrorToQueue(e as SerializedError));
      thunkApi.dispatch(removeClientUserAction(currentApp));
      return "";
    }
  }
);

export const loadClientFollowingDataAction = createAsyncThunk<
  { appType: AppType; response: ClientDataResponse },
  number[],
  ThunkApiType
>(`${name}/loadClientFollowingDataAction`, async (ids, thunkApi) => {
  const state = thunkApi.getState();
  const appType = getCurrentUserAppType(state);
  const clientUser = state.client.users[appType]!;
  const response = await ClientDataApi.getFollowingData({
    userId: clientUser.userId,
    ids: uniq(ids),
  });
  return { response, appType };
});

export const loadClientLikedDataAction = createAsyncThunk<
  { appType: AppType; response: ClientDataResponse },
  number[],
  ThunkApiType
>(`${name}/loadClientLikedDataAction`, async (ids, thunkApi) => {
  const state = thunkApi.getState();
  const appType = getCurrentUserAppType(state);
  const clientUser = state.client.users[appType]!;
  const response = await ClientDataApi.getLikeData({
    userId: clientUser.userId,
    ids: uniq(ids),
  });
  return { response, appType };
});

export const toggleFollowUserAction = createAsyncThunk<AppType, number, ThunkApiType>(
  `${name}/toggleFollowUserAction`,
  async (userId, thunkApi) => {
    const state = thunkApi.getState();
    const appType = getCurrentUserAppType(state);
    const isFollowed = state.client.followedUsersMap[appType][userId];
    if (isFollowed) {
      await ClientUserApi.unsubscribe(userId);
    } else {
      await ClientUserApi.subscribe(userId);
    }
    return appType;
  }
);

const toggleLikeContentActionImpl = createAsyncThunk<AppType, number, ThunkApiType>(
  `${name}/toggleLikeContentActionImpl`,
  async (contentId, thunkApi) => {
    const state = thunkApi.getState();
    const appType = getCurrentUserAppType(state);
    const isLiked = state.client.likedContentsMap[appType][contentId];
    if (!isLiked) {
      await ClientContentApi.addReaction({ contentId, reactionId: 1 });
    } else {
      await ClientContentApi.deleteReaction({ contentId, reactionId: 1 });
    }
    return appType;
  }
);

export const toggleLikeContentAction = createAsyncThunk<
  ContentPunchAdminDto | ContentShortChipzAdminDto | undefined,
  number,
  ThunkApiType
>(`${name}/toggleLikeContentAction`, async (contentId, thunkApi) => {
  await thunkApi.dispatch(toggleLikeContentActionImpl(contentId));
  await asyncDelay(100);
  const refreshResult = await thunkApi.dispatch(loadContentsToCache({ ids: [contentId], checkExisting: false }));
  if (loadContentsToCache.fulfilled.match(refreshResult)) {
    return refreshResult.payload[appTypeToApplicationContentType[getCurrentUserAppType(thunkApi.getState())]]?.[
      contentId
    ];
  }
});

// client actions are synced across tabs, so passing app in payload is necessary, cuz current user's app may be different in different tab
const clientSlice = createSlice({
  name,
  initialState,
  reducers: {
    migrateClientsAction: (state, action: PayloadAction<Partial<Record<AppType, ClientUser>>>) => {
      state.users = action.payload;
      state.migrationComplete = true;
    },
    setClientUserAction: (
      state,
      action: PayloadAction<{ authResponse: ClientAuthResponse; deviceUuid: string; appType: AppType }>
    ) => {
      const {
        authResponse: { user, token, refreshToken, expiresAt },
        deviceUuid,
        appType,
      } = action.payload;
      state.users[appType] = {
        userId: user.id,
        nickName: user.nickName ?? "",
        avatar: user.avatar ?? "",
        token,
        refreshToken,
        deviceUuid,
        tokenExpiresAt: expiresAt,
      };
    },
    removeClientUserAction: (state, action: PayloadAction<AppType>) => {
      const appType = action.payload;
      delete state.users[appType];
      state.refreshingClientToken = state.refreshingClientToken.filter((x) => x !== appType);
    },
    updateTokenForClientUserAction: (
      state,
      action: PayloadAction<{ refreshResponse: RefreshResponse; appType: AppType }>
    ) => {
      const { refreshResponse, appType } = action.payload;
      if (!state.users[appType]) {
        return;
      }
      const { token, refreshToken, expiresAt } = refreshResponse;
      state.users[appType]!.token = token;
      state.users[appType]!.refreshToken = refreshToken;
      state.users[appType]!.tokenExpiresAt = expiresAt;
      state.refreshingClientToken = state.refreshingClientToken.filter((x) => x !== appType);
    },
    lockClientTokenUpdateAction: (state, action: PayloadAction<AppType>) => {
      state.refreshingClientToken.push(action.payload);
    },
    toggleClientCommentsDialogAction: (
      state,
      action: PayloadAction<ContentPunchAdminDto | ContentShortChipzAdminDto | undefined>
    ) => {
      state.contentForClientComments = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(migrateClientsFromUserSliceAction.fulfilled, (state, action) => {
      state.users = action.payload;
      state.migrationComplete = true;
    });
    builder.addCase(loadClientFollowingDataAction.fulfilled, (state, action) => {
      const { appType, response } = action.payload;
      Object.assign(state.followedUsersMap[appType], response);
    });
    builder.addCase(loadClientLikedDataAction.fulfilled, (state, action) => {
      const { appType, response } = action.payload;
      Object.assign(state.likedContentsMap[appType], response);
    });
    builder.addCase(toggleFollowUserAction.fulfilled, (state, action) => {
      const appType = action.payload;
      const userId = action.meta.arg;
      state.followedUsersMap[appType][userId] = !state.followedUsersMap[appType][userId];
    });
    builder.addCase(toggleLikeContentActionImpl.fulfilled, (state, action) => {
      const appType = action.payload;
      const contentId = action.meta.arg;
      state.likedContentsMap[appType][contentId] = !state.followedUsersMap[appType][contentId];
    });
  },
});

export const { setClientUserAction, removeClientUserAction, toggleClientCommentsDialogAction } = clientSlice.actions;

export default clientSlice.reducer;

export const getClientUserForCurrentApp = (state: RootState) => {
  const appType = state.user.currentUser?.appType;
  if (!appType) {
    return undefined;
  }
  return state.client.users[appType];
};
