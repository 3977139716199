import { DELETE, GET, POST, PUT, formDataWithFile } from "@utils/fetch";

export interface ParameterVariant {
  value: string;
  description: string;
}

export interface PipelinePrototypeParameter {
  name: string;
  description: string;
  paramType: string;
  multiple: boolean;
  variants: ParameterVariant[];
}

export interface PipelinePrototype {
  type: string;
  description: string;
  requiredParameters: PipelinePrototypeParameter[];
}

export interface FeedConfiguration {
  id: number;
  label: string;
  description: string;
  feedId: string;
  enabled: boolean;
  system: boolean;
  order: number;
  provider: PipelinePrototype;
  providerParams: Record<string, string>;
  imageUrl?: string;
}

export interface FeedConfigurationChangeRequest {
  // the following fields can be changed for system=true configs
  label: string;
  description: string;
  order: number;
  // cannot be changed for system=true configs
  feedId: string;
  enabled: boolean;
  providerType: string;
  providerParams: Record<string, string>;
}

export const FeedConfigurationsApi = {
  list: (): Promise<FeedConfiguration[]> => GET(`api/v1/admin/recommendation/config/feed`),
  listPipelinePrototypes: (): Promise<PipelinePrototype[]> => GET(`api/v1/admin/recommendation/pipelines`),
  update: (id: number, request: FeedConfigurationChangeRequest): Promise<FeedConfiguration> =>
    PUT(`api/v1/admin/recommendation/config/feed/${id}`, request),
  delete: (id: number) => DELETE(`api/v1/admin/recommendation/config/feed/${id}`),
  create: (request: FeedConfigurationChangeRequest): Promise<FeedConfiguration> =>
    POST(`api/v1/admin/recommendation/config/feed`, request),
  uploadImage: (id: number, image: File) =>
    POST(`api/v1/admin/recommendation/config/feed/${id}/image`, formDataWithFile("image", image)),
};
