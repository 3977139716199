import { DELETE, GET, POST } from "@utils/fetch";

export interface ModerationTag {
  id: string;
  name: string;
  description?: string;
  status?: "ACTIVE" | "INACTIVE";
}

// TODO to enum
export interface SetContentTagMappingRequest {
  tagId: string;
  contentStatus: "NEW" | "APPROVED" | "APPROVED_18" | "HIDDEN" | "DELETED";
  contentRepeatStatus: "NEW" | "APPROVED" | "APPROVED_18" | "HIDDEN" | "DELETED";
  privateThreadContentStatus?: "NEW" | "APPROVED" | "APPROVED_18" | "HIDDEN" | "DELETED";
  privateThreadContentRepeatStatus?: "NEW" | "APPROVED" | "APPROVED_18" | "HIDDEN" | "DELETED";
}

export interface ContentTagMapping {
  id?: string;
  contentStatus?: "NEW" | "APPROVED" | "APPROVED_18" | "HIDDEN" | "DELETED" | "AUTO_APPROVED";
  moderationTag?: ModerationTag;
  contentRepeatStatus: "NEW" | "APPROVED" | "APPROVED_18" | "HIDDEN" | "DELETED";
  privateThreadContentStatus: "NEW" | "APPROVED" | "APPROVED_18" | "HIDDEN" | "DELETED";
  privateThreadContentRepeatStatus: "NEW" | "APPROVED" | "APPROVED_18" | "HIDDEN" | "DELETED";
}

export interface SetTagStatusRequest {
  tagId: string;
  status: "ACTIVE" | "INACTIVE";
}

const urlPrefix = `api/v1/admin/moderation-manager`;

export const ContentModerationTagsApi = {
  createOrUpdate: (request: ModerationTag): Promise<ModerationTag> => POST(`${urlPrefix}/tag`, request),
  setOrReplaceTagMapping: (request: SetContentTagMappingRequest): Promise<ContentTagMapping> =>
    POST(`${urlPrefix}/tag-mapping`, request),
  setTagStatus: (request: SetTagStatusRequest): Promise<void> => POST(`${urlPrefix}/tag/set-status`, request),
  deleteTag: (tagId: string) => DELETE(`${urlPrefix}/tag/${tagId}`),
  listTags: (): Promise<Array<ModerationTag>> => GET(`${urlPrefix}/tag/list`),
  listTagMappings: (): Promise<ContentTagMapping[]> => GET(`${urlPrefix}/tag-mapping/list`),
  saveTagsOrder: (orderedTagIds: string[]) => POST(`${urlPrefix}/tag/set-order`, { orderedTagIds }),
};
