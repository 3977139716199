import { OffsetLimit, ResponseDto } from "@api";
import { DELETE, GET, PATCH, POST, buildQuery } from "@utils/fetch";

export interface AchievementDto {
  id: number;
  tagId: number;
  earnedImageUrl: string;
  unearnedImageUrl: string;
  startDate: string;
  endDate: string;
  name: string;
  description: string;
  enabled: boolean;
  link?: string;
}

export interface AchievementRequestDto {
  tagId: number;
  name: string;
  description: string;
  enabled: boolean;
  startDate: string;
  endDate: string;
  link?: string;
}

export interface UserAchievementsDto {
  earnedAchievements: number[];
  possibleAchievements: number[];
}

const makeFormData = (image: File) => {
  const formData = new FormData();
  formData.append("image", image);
  return formData;
};

export const AchievementsApi = {
  list: (request: OffsetLimit): Promise<ResponseDto<AchievementDto>> =>
    GET(`api/v1/admin/achievements?${buildQuery(request)}`),
  byId: (id: number): Promise<AchievementDto> => GET(`api/v1/admin/achievements/${id}`),
  create: (request: AchievementRequestDto): Promise<AchievementDto> => POST(`api/v1/admin/achievements`, request),
  update: (id: number, request: AchievementRequestDto): Promise<AchievementDto> =>
    PATCH(`api/v1/admin/achievements/${id}`, request),
  delete: (id: number): Promise<void> => DELETE(`api/v1/admin/achievements/${id}`),
  uploadUnearnedImage: (id: number, image: File): Promise<AchievementDto> =>
    POST(`api/v1/admin/achievements/${id}/unearned_image`, makeFormData(image)),
  uploadEarnedImage: (id: number, image: File): Promise<AchievementDto> =>
    POST(`api/v1/admin/achievements/${id}/earned_image`, makeFormData(image)),
  getForUsers: (userIds: number[]): Promise<Record<number, UserAchievementsDto>> =>
    POST(`api/v1/admin/achievements/get-by-user-ids`, userIds),
  grantForUser: (achievementId: number, userId: number): Promise<void> =>
    POST(`api/v1/admin/achievements/${achievementId}/users/${userId}`, {}),
  withdrawFromUser: (achievementId: number, userId: number): Promise<void> =>
    DELETE(`api/v1/admin/achievements/${achievementId}/users/${userId}`),
};
