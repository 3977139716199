import { ChildSortingType } from "@api";
import { CommentModerationStatus, CommentsModerationApi, ModerationContentSortType } from "@api/commentsModeration";
import { ComplaintType, ComplaintsApi } from "@api/complaints";
import { AssignedModerationStatus, ContentModerationApi } from "@api/contentModeration";
import { UserRole } from "@api/user";
import { ConfigProperty, environment, properties } from "@config";
import AppType from "@models/AppType";
import { PayloadAction, SerializedError, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkApiType } from "@store/store";
import { RequestContext } from "@utils/fetch";
import { addErrorToQueue } from "./rejectedErrorsQueue";
import { predefinedFilterPart } from "./videoModeration";

export const notificationSoundNames = environment.moderationNotificationSoundNames;
export const DEFAULT_SOUND_NAME = notificationSoundNames[0];

export enum NotifiableContentType {
  UGC = "UGC",
  COMMENT = "COMMENT",
  COMPLAINT_UGC = "COMPLAINT_UGC",
  COMPLAINT_COMMENT = "COMPLAINT_COMMENT",
}

export const notifiableContentTypePerApp = {
  [AppType.PUNCH]: Object.values(NotifiableContentType),
  [AppType.CHIPZ]: Object.values(NotifiableContentType),
};

export const notifiableContentTypesForBothApps = Object.values(NotifiableContentType).filter((type) => {
  return (
    Object.values(AppType).filter((app) => notifiableContentTypePerApp[app].includes(type)).length ===
    Object.values(AppType).length
  );
});

export interface Setting {
  /** load data for this type in periodic checks */
  enabled: boolean;
  /** show browser notification on change */
  notification: boolean;
  /** play sound with notification on change */
  sound: boolean;
  soundName?: string;
}

const defaultSetting: Setting = {
  enabled: false,
  notification: false,
  sound: false,
  soundName: DEFAULT_SOUND_NAME,
};

export interface NotificationData {
  itemIds: number[];
  appType: AppType;
  contentType: NotifiableContentType;
}

export interface ModerationNotificatorState {
  settings: Record<NotifiableContentType, Setting>;
  loadedData: NotificationData[];
  previouslyLoadedData: NotificationData[];
  pollingActive: boolean;
  soundVolume: number;
  disabledTypesInApps: Record<AppType, NotifiableContentType[]>;
}

const initialState: ModerationNotificatorState = {
  settings: Object.values(NotifiableContentType).reduce(
    (a, x) => {
      a[x] = defaultSetting;
      return a;
    },
    {} as Record<NotifiableContentType, Setting>
  ),
  loadedData: [],
  previouslyLoadedData: [],
  pollingActive: false,
  soundVolume: 100,
  disabledTypesInApps: Object.values(AppType).reduce(
    (a, x) => {
      a[x] = [];
      return a;
    },
    {} as Record<AppType, NotifiableContentType[]>
  ),
};

const name = "moderationNotificator";

type ObjectIdsLoader = (pageSize: number, requestContext: RequestContext) => Promise<number[]>;

const descriptors: Record<NotifiableContentType, ObjectIdsLoader> = {
  [NotifiableContentType.UGC]: (pageSize, requestContext) =>
    ContentModerationApi.listForModeration(
      {
        pagination: { page: 0, size: pageSize },
        filter: {
          moderationStatuses: [
            AssignedModerationStatus.NEW,
            AssignedModerationStatus.AUTO_APPROVED,
            AssignedModerationStatus.AUTO_HIDDEN,
          ],
          ...predefinedFilterPart,
        },
        excludeFromTrustedUsers: true,
        sorting: {
          createdAtSortDirection: ChildSortingType.DESC,
          ratingSortDirection: null,
          originsFirst: false,
          childFirst: false,
          childFourteenFirst: false,
          childTenFirst: false,
          nudityFirst: false,
        },
      },
      requestContext
    ).then(({ content }) => content?.map((x) => x.contentId) ?? []),
  [NotifiableContentType.COMMENT]: (pageSize, requestContext) =>
    CommentsModerationApi.listForModeration(
      {
        page: 0,
        size: pageSize,
        filter: {
          moderationStatuses: [CommentModerationStatus.NEW],
        },
        createdAtDirection: ChildSortingType.DESC,
        sortBy: ModerationContentSortType.DEFAULT,
      },
      requestContext
    ).then(({ content }) => content?.map((x) => x.id) ?? []),
  [NotifiableContentType.COMPLAINT_UGC]: (pageSize, requestContext) =>
    ComplaintsApi.listOnContent({
      offset: 0,
      limit: pageSize,
      filter: { status: ComplaintType.NEW },
      requestContext,
    }).then(({ content }) => content?.map((x) => x.id) ?? []),
  [NotifiableContentType.COMPLAINT_COMMENT]: (pageSize, requestContext) =>
    ComplaintsApi.listOnComments({
      offset: 0,
      limit: pageSize,
      filter: { status: ComplaintType.NEW },
      requestContext,
    }).then(({ content }) => content?.map((x) => x.id) ?? []),
};

export const checkForUnprocessedItemsAction = createAsyncThunk<NotificationData[], void, ThunkApiType>(
  `${name}/checkForUnprocessedItems`,
  async (_, thunkApi) => {
    const { settings, disabledTypesInApps } = thunkApi.getState().moderationNotificator;
    const pageSize = properties[ConfigProperty.MODERATION_NOTIFICATIONS_REQUEST_PAGE_SIZE].selector(
      thunkApi.getState()
    );
    const enabledChecks = Object.entries(settings)
      .map(([key, value]) => {
        if (value.enabled) {
          return key;
        }
        return null;
      })
      .filter((x) => x) as NotifiableContentType[];
    if (!enabledChecks.length) {
      return [];
    }
    const { availableUsers } = thunkApi.getState().user;

    const result: NotificationData[] = [];
    for (const appType of Object.values(AppType)) {
      const mod = availableUsers.find(
        (x) => x.appType === appType && [UserRole.MODERATOR, UserRole.SUPER_MODERATOR].includes(x.role)
      );
      if (!mod) {
        continue;
      }
      const contentTypes = notifiableContentTypePerApp[appType].filter(
        (x) => enabledChecks.includes(x) && !disabledTypesInApps[appType].includes(x)
      );
      for (const contentType of contentTypes) {
        const loader = descriptors[contentType];
        try {
          const itemIds = await loader(pageSize, { appType, token: mod.token });
          result.push({ appType, itemIds, contentType });
        } catch (e) {
          thunkApi.dispatch(addErrorToQueue(e as SerializedError));
        }
      }
    }
    return result;
  }
);

const moderationNotificatorSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSettingsAction: (state, action: PayloadAction<Record<NotifiableContentType, Setting>>) => {
      Object.assign(state.settings, action.payload);
    },
    storeLoadedDataFromExternalSourceAction: (state, action: PayloadAction<NotificationData[]>) => {
      state.previouslyLoadedData = state.loadedData;
      state.loadedData = action.payload;
    },
    setPollingActiveStateAction: (state, action: PayloadAction<boolean>) => {
      state.pollingActive = action.payload;
    },
    setSoundVolumeAction: (state, action: PayloadAction<number>) => {
      state.soundVolume = action.payload;
    },
    setDisabledTypesInAppsAction: (state, action: PayloadAction<Record<AppType, NotifiableContentType[]>>) => {
      state.disabledTypesInApps = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkForUnprocessedItemsAction.fulfilled, (state, action) => {
      state.previouslyLoadedData = state.loadedData;
      state.loadedData = action.payload;
    });
  },
});

export const {
  setSettingsAction,
  storeLoadedDataFromExternalSourceAction,
  setPollingActiveStateAction,
  setSoundVolumeAction,
  setDisabledTypesInAppsAction,
} = moderationNotificatorSlice.actions;

export const pollingPreconditionSelector = createSelector(
  (state: RootState) => state.user.availableUsers,
  (state: RootState) => state.moderationNotificator.settings,
  (users, settings) => {
    const hasAnyModerators =
      users.filter((x) => x.role === UserRole.MODERATOR || x.role === UserRole.SUPER_MODERATOR).length > 0;
    const hasAnyTypesEnabled = Object.values(settings).filter((x) => x.enabled).length > 0;
    return hasAnyModerators && hasAnyTypesEnabled;
  }
);

export default moderationNotificatorSlice.reducer;
