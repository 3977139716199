import qs from "query-string";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export default () => {
  const { search } = useLocation();
  return useMemo(() => {
    return qs.parse(search, { parseNumbers: true, parseBooleans: true });
  }, [search]);
};
