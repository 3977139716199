import { DELETE, GET, POST, PUT, buildQuery } from "@utils/fetch";

export enum ContentPromotionType {
  PINNED_IN_FIRST_BATCH = "PINNED_IN_FIRST_BATCH",
  WELCOME_BATCH = "WELCOME_BATCH",
  PROMOTED = "PROMOTED",
}

export interface ContentPromotionDto {
  id?: number; // TODO can this rly be undefined
  contentId: number;
  promoType: ContentPromotionType;
  endDate?: string;
  promoRank?: number;
  promoCounter?: number;
  position?: number;
  promoGroup?: string;
  lastModifiedBy?: number;
  startDate?: string;
}

export interface ChangeContentPromotionDto {
  promoType: ContentPromotionType;
  promoCounter?: number;
  promoRank?: number;
  position?: number;
  endDate?: string;
  promoGroup?: string;
  startDate?: string;
}

export interface ChangeContentPromotionDtoForBatch extends ChangeContentPromotionDto {
  id?: number; // omit for creating
  contentId: number;
}

export const ContentPromotionsApi = {
  getByType: ({
    type,
    group,
    currentlyPromoted,
  }: {
    type: ContentPromotionType;
    group?: string;
    currentlyPromoted?: boolean | null;
  }): Promise<ContentPromotionDto[]> =>
    GET<any>(
      `api/v1/admin/ugc/promotion/${type}?${buildQuery({ group, currentlyPromoted, offset: 0, limit: 100000 })}`
    ).then(({ content }) => content ?? []),
  getByContentIds: (contentIds: number[]): Promise<ContentPromotionDto[]> =>
    POST(`api/v1/admin/ugc/promotion/get-by-ids`, { contentIds }),
  saveBatch: (payload: ChangeContentPromotionDtoForBatch[]): Promise<ContentPromotionDto[]> =>
    POST(`api/v1/admin/ugc/promotion`, payload),
  create: ({
    contentId,
    promotion,
  }: {
    contentId: number;
    promotion: ChangeContentPromotionDto;
  }): Promise<ContentPromotionDto> => POST(`api/v1/admin/ugc/${contentId}/promotion`, promotion),
  update: ({
    contentId,
    promotionId,
    promotion,
  }: {
    contentId: number;
    promotionId: number;
    promotion: ChangeContentPromotionDto;
  }): Promise<ContentPromotionDto> => PUT(`api/v1/admin/ugc/${contentId}/promotion/${promotionId}`, promotion),
  delete: ({ contentId, promotionId }: { contentId: number; promotionId: number }) =>
    DELETE(`api/v1/admin/ugc/${contentId}/promotion/${promotionId}`),
};
