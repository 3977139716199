import { IdDto, OffsetLimit } from "@api";
import { DELETE, GET, POST, PUT, buildQuery } from "@utils/fetch";

const urlPrefix = "api/v1/admin/badges";

export enum BadgeIdsPredicateType {
  IN = "IN",
  NOT_IN = "NOT_IN",
}

export enum SupervisedBadgeFilterType {
  ALL = "ALL",
  ONLY_BADGES = "ONLY_BADGES",
}

export enum SupervisionBadgeType {
  TEMPORARY = "TEMPORARY",
  PERMANENT = "PERMANENT",
}

export interface SupervisionBadgesDto {
  id: number;
  name?: string;
  badgeType: SupervisionBadgeType;
  durationSeconds?: number;
  group?: string;
}

export interface CreateSupervisionBadgeDto {
  name?: string;
  badgeType: SupervisionBadgeType;
  durationSeconds?: number;
  group?: string;
}

export interface SupervisedBadgesFilter {
  badgeName?: string | null;
}

export const SupervisedBadgesApi = {
  list: (request: OffsetLimit & SupervisedBadgesFilter): Promise<SupervisionBadgesDto[]> =>
    GET(`${urlPrefix}?${buildQuery(request)}`),
  delete: (id: number) => DELETE(`${urlPrefix}/${id}`),
  create: (request: CreateSupervisionBadgeDto): Promise<IdDto> => POST(`${urlPrefix}`, request),
  update: (id: number, request: CreateSupervisionBadgeDto) => PUT(`${urlPrefix}/${id}`, request),
};
