import { defineMessages, MessageDescriptor } from "react-intl";
import * as Duration from "tinyduration";
import { addDays, addHours, addMinutes, addMonths, addSeconds, addWeeks, addYears } from "./date";

export enum DurationUnit {
  years = "years",
  months = "months",
  weeks = "weeks",
  days = "days",
  hours = "hours",
  minutes = "minutes",
  seconds = "seconds",
}

export const durationUnitLabels: Record<DurationUnit, MessageDescriptor> = defineMessages({
  [DurationUnit.years]: { defaultMessage: "Years" },
  [DurationUnit.months]: { defaultMessage: "Months" },
  [DurationUnit.weeks]: { defaultMessage: "Weeks" },
  [DurationUnit.days]: { defaultMessage: "Days" },
  [DurationUnit.hours]: { defaultMessage: "Hours" },
  [DurationUnit.minutes]: { defaultMessage: "Minutes" },
  [DurationUnit.seconds]: { defaultMessage: "Seconds" },
});

/** only these work for java.time.Duration#parse */
export const DurationUnitsForJavaDuration = [
  DurationUnit.days,
  DurationUnit.hours,
  DurationUnit.minutes,
  DurationUnit.seconds,
];

export const parseIsoDuration = (value: string | null | undefined): Partial<Record<DurationUnit, number>> => {
  if (!value) {
    return {};
  }
  try {
    return Duration.parse(value);
  } catch (e) {
    return {};
  }
};

export const serializeIsoDuration = (value: Partial<Record<DurationUnit, number>>): string | undefined =>
  Duration.serialize(value);

export const addDurationToDate = (date: Date, duration: Partial<Record<DurationUnit, number>>) => {
  return Object.entries(duration).reduce((date, [unit, value]) => {
    switch (unit) {
      case DurationUnit.days:
        return addDays(date, value);
      case DurationUnit.hours:
        return addHours(date, value);
      case DurationUnit.minutes:
        return addMinutes(date, value);
      case DurationUnit.months:
        return addMonths(date, value);
      case DurationUnit.seconds:
        return addSeconds(date, value);
      case DurationUnit.weeks:
        return addWeeks(date, value);
      case DurationUnit.years:
        return addYears(date, value);
      default:
        return date;
    }
  }, date);
};
