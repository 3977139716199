import { UserApi } from "@api/user";
import { defineMessage } from "@formatjs/intl";
import { composeUserLink } from "@widgets/LinkToUser";
import { FormattedMessage } from "react-intl";
import { SearchResultType, SearchStrategy } from "../models";

const ByPhoneNumber: SearchStrategy = async ({ query, currentUser }) => {
  const phone = query.replace(/\D/g, "");
  if (phone.length < 11) {
    return [];
  }
  const response = await UserApi.list({ phone, offset: 0, limit: 1 });
  if (!response.users?.length) {
    return [];
  }
  const user = response.users[0];
  return [
    {
      type: SearchResultType.USER,
      adminUrl: composeUserLink({ id: user.id!, appType: currentUser.appType }),
      exactMatch: true,
      message: defineMessage({ defaultMessage: "Open profile of {value}" }),
      messageValues: {
        value: (
          <>
            <b>{user.nickName}</b> (
            <FormattedMessage defaultMessage="by phone {number}" values={{ number: phone }} />)
          </>
        ),
      },
    },
  ];
};

export default ByPhoneNumber;
