import { ClientCommentsApi } from "@api/clientComments";
import { CommentTemplatesApi } from "@api/commentTemplates";
import useDefaultNetworkErrorHandler from "@hooks/useDefaultNetworkErrorHandler";
import {
  Autocomplete,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { refreshCommentTemplatesAction } from "@slices/supervisedUsers";
import { getCurrentUserAppType, getCurrentUserRole } from "@slices/user";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { noop } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

export default function AddCommentDialogContent({
  contentId,
  commentId,
  onAddedComment,
  closeDialog,
}: {
  contentId: number;
  commentId?: number;
  onAddedComment: (parentCommentId: number | undefined) => void;
  closeDialog: () => void;
}) {
  const role = useAppSelector(getCurrentUserRole);
  const app = useAppSelector(getCurrentUserAppType);
  const commentTemplates = useAppSelector((state) => state.supervisedUsers.commentTemplatesPerApp[app]);
  const [message, setMessage] = useState("");
  const [saveAsTemplate, setSaveAsTemplate] = useState(false);
  const errorHandler = useDefaultNetworkErrorHandler();
  const canUseTemplates = CommentTemplatesApi.allowedRoles.includes(role);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (canUseTemplates) {
      dispatch(refreshCommentTemplatesAction());
    }
  }, [canUseTemplates, dispatch]);
  const autocompleteOptions = useMemo(() => commentTemplates.map((x) => x.comment), [commentTemplates]);
  return (
    <>
      <DialogContent>
        <Autocomplete
          inputValue={message}
          onInputChange={(e, value) => setMessage(value)}
          freeSolo
          options={autocompleteOptions}
          size="small"
          data-testid="message-autocomplete"
          renderInput={(params) => (
            <TextField {...params} multiline autoFocus label={<FormattedMessage defaultMessage="Message" />} />
          )}
        />
        {canUseTemplates && (
          <FormControlLabel
            control={
              <Checkbox
                data-testid="save-as-template-checkbox"
                checked={saveAsTemplate}
                onChange={(e, checked) => setSaveAsTemplate(checked)}
              />
            }
            label={<FormattedMessage defaultMessage="Save this comment as template" />}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>
          <FormattedMessage defaultMessage="Dismiss" />
        </Button>
        <Button
          data-testid="save-comment-button"
          color="primary"
          variant="contained"
          onClick={() => {
            if (saveAsTemplate && canUseTemplates) {
              // swallow errors intentionally here
              CommentTemplatesApi.create(message).catch(noop);
            }
            let promise: Promise<any>;
            if (commentId) {
              promise = ClientCommentsApi.reply({ commentId, mentions: [], text: message, skipTextValidation: true });
            } else {
              promise = ClientCommentsApi.create({ contentId, mentions: [], text: message, skipTextValidation: true });
            }
            promise
              .then(() => {
                onAddedComment(commentId);
                closeDialog();
              })
              .catch(errorHandler);
          }}
        >
          <FormattedMessage defaultMessage="Send" />
        </Button>
      </DialogActions>
    </>
  );
}
