import { UserRole } from "@api/user";
import useLazyHowl from "@hooks/useLazyHowl";
import { ChipsLogo, PunchLogo } from "@models/AppIcons";
import AppType from "@models/AppType";
import CheckIcon from "@mui/icons-material/Check";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Slider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  DEFAULT_SOUND_NAME,
  NotifiableContentType,
  Setting,
  notifiableContentTypePerApp,
  notifiableContentTypesForBothApps,
  notificationSoundNames,
  setDisabledTypesInAppsAction,
  setSettingsAction,
  setSoundVolumeAction,
} from "@slices/moderationNotificator";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { uniq } from "lodash";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { notificationTypeToMessage, useResolvedNotificationThresholds } from "./utils";

const getDisplayNameForSoundFile = (soundFileName: string) =>
  (soundFileName.split("/")[1] || soundFileName).replace(".mp3", "");

const EnableInAppCellContent = ({
  appType,
  contentType,
  setting,
  disabledTypesInApps,
  setDisabledTypesInApps,
}: {
  appType: AppType;
  contentType: NotifiableContentType;
  setting: Setting;
  disabledTypesInApps: Record<AppType, NotifiableContentType[]>;
  setDisabledTypesInApps: Dispatch<SetStateAction<Record<AppType, NotifiableContentType[]>>>;
}) => {
  const suitableUser = useAppSelector((state) =>
    state.user.availableUsers.find(
      (x) => x.appType === appType && [UserRole.SUPER_MODERATOR, UserRole.MODERATOR].includes(x.role)
    )
  );
  if (!notifiableContentTypesForBothApps.includes(contentType) || !suitableUser) {
    return notifiableContentTypePerApp[appType].includes(contentType) ? (
      suitableUser ? (
        <CheckIcon />
      ) : (
        <PersonOffIcon />
      )
    ) : null;
  }
  return (
    <Checkbox
      data-testid={`enable-in-${appType}-checkbox`}
      disabled={!setting.enabled}
      checked={!disabledTypesInApps[appType].includes(contentType)}
      onChange={(_, checked) => {
        setDisabledTypesInApps((current) => {
          const appSettings = current[appType];
          return {
            ...current,
            [appType]: uniq(checked ? appSettings.filter((x) => x !== contentType) : [...appSettings, contentType]),
          };
        });
      }}
    />
  );
};

const ModerationNotificationSettingsDialogContent = ({ closeDialog }: { closeDialog: () => void }) => {
  const dispatch = useAppDispatch();
  const { settings: storedSettings, disabledTypesInApps: storedDisabledTypesInApps } = useAppSelector(
    (state) => state.moderationNotificator
  );
  const [settings, setSettings] = useState(storedSettings);
  const [disabledTypesInApps, setDisabledTypesInApps] = useState(storedDisabledTypesInApps);
  const [notificationPermission, setNotificationPermission] = useState(Notification.permission);
  const notificationEnabled = notificationPermission === "granted";
  const volume = useAppSelector((state) => state.moderationNotificator.soundVolume);
  const getHowl = useLazyHowl();
  const onPlaySound = useCallback(
    (soundName: string) => {
      const sound = getHowl(soundName);
      sound.volume(volume / 100);
      sound.play();
    },
    [volume, getHowl]
  );
  const thresholdsMap = useResolvedNotificationThresholds();
  return (
    <>
      <DialogContent>
        {notificationPermission === "granted" ? (
          <Button color="success" variant="contained" fullWidth data-testid="notification-permission-granted-button">
            <FormattedMessage defaultMessage="Browser notifications are permitted" />
          </Button>
        ) : notificationPermission === "default" ? (
          <Button
            color="warning"
            fullWidth
            variant="contained"
            onClick={() => Notification.requestPermission().then(setNotificationPermission)}
            data-testid="request-notification-permission-button"
          >
            <FormattedMessage defaultMessage="Click to enable browser notifications" />
          </Button>
        ) : (
          <Button color="error" fullWidth variant="contained">
            <FormattedMessage defaultMessage="Browser notifications are disabled" />
          </Button>
        )}
        <Stack spacing={2} direction="row" sx={{ mb: 1, mt: 1 }} alignItems="center">
          <VolumeDown />
          <Slider
            aria-label="Volume"
            value={volume}
            min={0}
            max={100}
            onChange={(e, value) => {
              if (typeof value === "number") {
                dispatch(setSoundVolumeAction(value));
              }
            }}
          />
          <VolumeUp />
          <IconButton onClick={() => onPlaySound(DEFAULT_SOUND_NAME)}>
            <PlayCircleOutlineIcon />
          </IconButton>
        </Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage defaultMessage="Notification type" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Enabled" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Notification" />
              </TableCell>
              <TableCell>
                <FormattedMessage defaultMessage="Sound" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage defaultMessage="Sound name" />
              </TableCell>
              <TableCell align="right">
                <FormattedMessage defaultMessage="Threshold" />
              </TableCell>
              <TableCell align="center">
                <PunchLogo height="12px" />
              </TableCell>
              <TableCell align="center">
                <ChipsLogo height="12px" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(NotifiableContentType).map((type) => {
              const setting = settings[type]!;
              return (
                <TableRow key={type} data-testid={`notification-${type}-row`}>
                  <TableCell>
                    <FormattedMessage {...notificationTypeToMessage[type]} />
                  </TableCell>
                  <TableCell align="center" padding="checkbox">
                    <Checkbox
                      data-testid="enable-checkbox"
                      checked={setting.enabled}
                      onChange={(_, checked) => {
                        setSettings((current) => ({
                          ...current,
                          [type]: {
                            ...current[type],
                            enabled: checked,
                          },
                        }));
                      }}
                    />
                  </TableCell>
                  <TableCell align="center" padding="checkbox">
                    <Checkbox
                      data-testid="notification-checkbox"
                      disabled={!setting.enabled || !notificationEnabled}
                      checked={setting.notification}
                      onChange={(_, checked) => {
                        setSettings((current) => ({
                          ...current,
                          [type]: {
                            ...current[type],
                            notification: checked,
                          },
                        }));
                      }}
                    />
                  </TableCell>
                  <TableCell align="center" padding="checkbox">
                    <Checkbox
                      data-testid="sound-checkbox"
                      disabled={!setting.enabled}
                      checked={setting.sound}
                      onChange={(_, checked) => {
                        setSettings((current) => ({
                          ...current,
                          [type]: {
                            ...current[type],
                            sound: checked,
                          },
                        }));
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center">
                      <FormControl sx={{ flex: 1 }}>
                        <Select
                          size="small"
                          value={setting.soundName || DEFAULT_SOUND_NAME}
                          error={!!setting.soundName && !notificationSoundNames.includes(setting.soundName)}
                          onChange={(e) => {
                            const soundName = e.target.value as string;
                            setSettings((current) => ({
                              ...current,
                              [type]: { ...current[type], soundName },
                            }));
                          }}
                          renderValue={(x) => (x ? getDisplayNameForSoundFile(x) : "-")}
                        >
                          {notificationSoundNames.map((x) => (
                            <MenuItem key={x} value={x}>
                              {getDisplayNameForSoundFile(x)}
                              <IconButton
                                size="small"
                                sx={{ ml: "auto" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onPlaySound(x);
                                }}
                              >
                                <PlayCircleOutlineIcon />
                              </IconButton>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <IconButton
                        size="small"
                        sx={{ ml: "8px" }}
                        onClick={() => onPlaySound(setting.soundName || DEFAULT_SOUND_NAME)}
                      >
                        <PlayCircleOutlineIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                  <TableCell align="right">{thresholdsMap[type]}</TableCell>
                  <TableCell align="center">
                    <EnableInAppCellContent
                      appType={AppType.PUNCH}
                      contentType={type}
                      setting={setting}
                      disabledTypesInApps={disabledTypesInApps}
                      setDisabledTypesInApps={setDisabledTypesInApps}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <EnableInAppCellContent
                      appType={AppType.CHIPZ}
                      contentType={type}
                      setting={setting}
                      disabledTypesInApps={disabledTypesInApps}
                      setDisabledTypesInApps={setDisabledTypesInApps}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          <FormattedMessage defaultMessage="Dismiss" />
        </Button>
        <Button
          color="success"
          variant="outlined"
          onClick={() => {
            dispatch(setSettingsAction(settings));
            dispatch(setDisabledTypesInAppsAction(disabledTypesInApps));
            closeDialog();
          }}
          data-testid="save-button"
        >
          <FormattedMessage defaultMessage="Save" />
        </Button>
      </DialogActions>
    </>
  );
};

export default ModerationNotificationSettingsDialogContent;
