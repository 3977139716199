import AppType, { appTypeToDisplayName } from "@models/AppType";
import UserProfileViewType from "@models/UserProfileViewType";
import { getCurrentUserAppType } from "@slices/user";
import { useAppSelector } from "@store/hooks";
import { buildQuery } from "@utils/fetch";
import React from "react";
import { Link } from "react-router-dom";

interface LinkToUserProps {
  id: number | string;
  view?: UserProfileViewType;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  miscUrlParams?: Record<string, string>;
  className?: string;
  nickName?: string;
  testId?: string;
}

export const composeUserLink = ({
  id,
  view,
  miscUrlParams,
  appType,
}: {
  id: number | string;
  view?: UserProfileViewType;
  miscUrlParams?: Record<string, string>;
  appType: AppType;
}): string => `/user?${buildQuery({ id, view, appType: appTypeToDisplayName[appType], ...miscUrlParams })}`;

const LinkToUser = ({ id, view, children, miscUrlParams, nickName, className, testId, onClick }: LinkToUserProps) => {
  const appType = useAppSelector(getCurrentUserAppType);
  return (
    <Link
      to={composeUserLink({ id, view, miscUrlParams, appType })}
      target="_blank"
      className={className}
      data-testid={testId}
      onClick={onClick}
    >
      {children || nickName || id}
    </Link>
  );
};

export default LinkToUser;
