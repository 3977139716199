import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { LinearProgress, Typography } from "@mui/material";
import { CheckResult } from "@utils/password";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import * as styles from "./PasswordStrengthIndicator.module.scss";

const passwordStrengthSliderMax = 100;

const getSliderStrengthValue = (checkResults: CheckResult[]) => {
  return checkResults.reduce((a, x) => a + (x.passed ? 1 : 0), 0) * (passwordStrengthSliderMax / checkResults.length);
};

const getSliderStrengthColor = (sliderValue: number): "success" | "warning" | "error" => {
  if (sliderValue === passwordStrengthSliderMax) {
    return "success";
  }
  if (sliderValue < 50) {
    return "error";
  }
  return "warning";
};

export default function PasswordStrengthIndicator({
  checkResults,
  singleColumn,
}: {
  checkResults: CheckResult[];
  singleColumn?: boolean;
}) {
  const progress = getSliderStrengthValue(checkResults);
  const color = getSliderStrengthColor(progress);
  return (
    <div className={classNames(styles.root, singleColumn && styles.singleColumn)} data-testid="password-strength">
      <LinearProgress color={color} value={progress} variant="determinate" className={styles.slider} />
      {checkResults.map((x) => (
        <div key={x.type} className={styles.item} data-testid={`${x.type}-check`} data-passed={x.passed}>
          {x.passed ? <CheckIcon color="success" /> : <CloseIcon color="warning" />}
          <Typography variant="caption">
            <FormattedMessage {...x.message} values={x.messageValues} />
          </Typography>
        </div>
      ))}
    </div>
  );
}
