import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import NavigationTabs from "@widgets/NavigationTabs";
import { BlackWhiteListCategory, categoryMessages } from "./types";

const possibleValues = Object.values(BlackWhiteListCategory);

export default function ModerationBlackWhiteListsPageTitle() {
  const route = useCurrentRouteDescriptor();
  useSetDocumentTitle({ messageDescriptor: route!.title! });
  return <NavigationTabs possibleValues={possibleValues} tabQueryKey="category" tabMessagesMap={categoryMessages} />;
}
