import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import NavigationTabs from "@widgets/NavigationTabs";
import { defineMessages } from "react-intl";
import { BeatsModerationViewType } from "./types";

const tabMessages = defineMessages({
  [BeatsModerationViewType.MODERATION]: { defaultMessage: "Moderation" },
  [BeatsModerationViewType.TAGS]: { defaultMessage: "Tags" },
  [BeatsModerationViewType.USER_AGREEMENT]: { defaultMessage: "User Agreement" },
});

const BeatsModerationPageTitle = () => {
  const route = useCurrentRouteDescriptor();
  useSetDocumentTitle({ messageDescriptor: route!.title! });
  return (
    <NavigationTabs
      possibleValues={Object.values(BeatsModerationViewType)}
      tabQueryKey="view"
      tabMessagesMap={tabMessages}
    />
  );
};

export default BeatsModerationPageTitle;
