import { ApplicationType, ChildSortingType, CommonModerationStatus, Page, Pageable } from "@api";
import { GET, POST, RequestContext, buildQuery } from "@utils/fetch";
import { mapValues } from "lodash";
import { AssignedBeatTextModerationStatus } from "./beatTextsModeration";
import { ControlStatus } from "./beatsModeration";
import { ContentStatusType, ContentType } from "./contentCommon";
import { ModerationTag } from "./contentModerationTags";
import { ThreadPrivacyLevel } from "./videoContent";

export interface UserModeratedContentCounters {
  userId: number;
  publishedContentCount: number;
  availableContentCount: number;
  deletedContentCount: number;
}

export interface DateRange {
  /** @format date-time */
  startDate?: string;

  /** @format date-time */
  endDate?: string;
}

export interface AutoApproveTime {
  contentId?: number;
  autoApproveTime?: string;
}

export interface UserId {
  id: number;
}

export interface AutoApproveTimes {
  autoApproveTimes?: AutoApproveTime[];
}

export enum ContentModerationStatus {
  NEW = "NEW",
  APPROVED = "APPROVED",
  APPROVED_18 = "APPROVED_18",
  HIDDEN = "HIDDEN",
  DELETED = "DELETED",
}

export enum ModerationActorType {
  MODERATOR = "MODERATOR",
  TIMER = "TIMER",
  TRUSTED = "TRUSTED",
  AUTO_HIDE = "AUTO_HIDE",
  GCORE = "GCORE",
}

export enum ModerationContentSortType {
  DEFAULT = "DEFAULT",
  RATING = "RATING",
}

const urlPrefix = `api/v1/admin/moderation-manager`;

export interface RepeatedModerationReportFilter {
  after?: Date | null;
  before?: Date | null;
  firstModeratorId?: number | null;
  anotherModeratorId?: number | null;
}

export interface ModerationLogFilterType {
  moderatorIds?: number[];
  authorIds?: number[];
  tagIds?: string[];
  moderationStatuses?: ContentModerationStatus[];
  startDate?: Date | null;
  endDate?: Date | null;
  contentIds?: number[];
}

const filterAdapter = (filter: ModerationLogFilterType) => {
  const { moderatorIds, authorIds, startDate, endDate, moderationStatuses, tagIds, contentIds } = filter;
  const toUserInfoArray = (ids: (string | number)[]): UserId[] =>
    ids.map((id) => ({ id: typeof id === "string" ? Number.parseInt(id) : id }));
  return {
    ...(moderatorIds?.length && { moderatorIds: toUserInfoArray(moderatorIds) }),
    ...(authorIds?.length && { authorIds: toUserInfoArray(authorIds) }),
    ...((startDate || endDate) && {
      dateRange: { startDate: startDate?.toISOString(), endDate: endDate?.toISOString() },
    }),
    ...(moderationStatuses?.length && { moderationStatuses }),
    ...(tagIds?.length && { tagIds }),
    ...(contentIds?.length && { contentIds }),
    moderationObjectTypes: ["CONTENT"],
  };
};

export interface UserModerationSanctionFilter {
  userIds?: number[];
  sanctionTypes?: ("BLOCKED" | "UNBLOCKED")[];
  sanctionTargets?: ("USER" | "DEVICE" | "IPV4_ADDRESS" | "PUBLISHING")[];
  moderationFilters?: ("MANUAL" | "ML_MODEL" | "NLP_MODEL" | "BLACKLIST")[];
  moderatorIds?: number[];
  modelNames?: string[];
  sanctionAppliedDateRange?: DateRange;
  sanctionEndDateRange?: DateRange;
}

export enum ModerationContentSource {
  UGC = "UGC",
  COMMENT = "COMMENT",
}

export enum AssignedModerationStatus {
  NEW = "NEW",
  AUTO_APPROVED = "AUTO_APPROVED",
  AUTO_APPROVED_WHITELIST = "AUTO_APPROVED_WHITELIST",
  APPROVED = "APPROVED",
  APPROVED_18 = "APPROVED_18",
  AUTO_HIDDEN = "AUTO_HIDDEN",
  HIDDEN = "HIDDEN",
  DELETED = "DELETED",
}

export enum AutoModerationStatus {
  NEW = "NEW",
  PROCESSING = "PROCESSING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
}

// content moderation
export enum ContentSubtype {
  original = "original",
  repeat = "repeat",
}

export enum ThreadPrivacyFilterEnum {
  ALL_WITHOUT_PRIVATE_THREADS = "ALL_WITHOUT_PRIVATE_THREADS",
  PRIVATE_THREAD_1_TO_1 = "PRIVATE_THREAD_1_TO_1",
  PRIVATE_THREAD_1_TO_N = "PRIVATE_THREAD_1_TO_N",
}

export enum ChildVectorOutputType {
  SCORE_AGE_10 = "SCORE_AGE_10",
  SCORE_AGE_14 = "SCORE_AGE_14",
}

export interface ChildVectorValue {
  childMean: number;
  childStd: number;
  vectorOutputType: ChildVectorOutputType;
}

export interface ModerationContent {
  contentId: number;
  parentId?: number;
  source: ModerationContentSource;
  contentStatus: ContentStatusType;
  contentModerationStatus: ContentModerationStatus;
  assignedModerationStatus: AssignedModerationStatus;
  gcoreAutoModerationStatus: AutoModerationStatus;
  gcoreComputedModerationStatus: ContentModerationStatus;
  applicationType: ApplicationType;
  createdAt?: string;
  statusAt?: string;
  author?: UserId;
  moderationTags?: ModerationTag[];
  deletedAt?: string;
  draft?: boolean;
  complaintsCount?: number;
  moderationActor?: ModerationActorType;
  commentsCount?: number;
  uniqueViewsCount?: number;
  uniqueReactionsCount?: number;
  rating?: number;
  version?: number;
  threadPrivacyLevel?: ThreadPrivacyLevel;
  nudityProbabilityMin?: number;
  nudityProbabilityMax?: number;
  type?: ContentType;
  childVectorValues?: ChildVectorValue[];
}

export interface ModerationContentWithChildLabels extends ModerationContent {
  childLabel?: boolean;
  childLabelTags?: ModerationTag[];
}

export interface ModerationContentFilter {
  contentIds?: number[];
  contentStatuses?: ContentStatusType[];
  sources?: ModerationContentSource[];
  moderationStatuses?: AssignedModerationStatus[];
  contentTypes?: ContentType[];
  applicationTypes?: ApplicationType[];
  gcoreAutoModerationStatuses?: AutoModerationStatus[];
  gcoreComputedModerationStatuses?: ContentModerationStatus[];
  authorIds?: number[];
  tagIds?: string[];
  actors?: ModerationActorType[];
  contentSubtype?: ContentSubtype;
  internalTagIds?: number[];
  excludedInternalTagIds?: number[];
  threadPrivacy?: ThreadPrivacyFilterEnum[];
  type?: ContentType;
  badgeIds?: number[];
  controlStatuses?: ControlStatus[];
  textModerationStatuses?: AssignedBeatTextModerationStatus[];
}

export interface BatchSetContentTagsResponse {
  updated: ModerationContent[];
  rejected: ModerationContent[];
}

export interface SetContentTagsRequest {
  contentId: number;
  tagIds: string[];
  version?: number;
}

export interface BatchSetContentTagsRequest {
  contentTags: SetContentTagsRequest[];
}

export interface ModerationContentSorting {
  createdAtSortDirection: ChildSortingType;
  ratingSortDirection: ChildSortingType | null;
  originsFirst: boolean;
  nudityFirst: boolean;
  childFirst: boolean;
  childTenFirst: boolean;
  childFourteenFirst: boolean;
}

// user-info moderation

export interface ChildCvSettings {
  detectionEnabled: boolean;
  stdThreshold: number;
  meanThreshold: number;
}

export interface ModerationSettings {
  nudity: {
    detectionEnabled: boolean;
    threshold: number;
  };
  child?: ChildCvSettings;
  childTen?: ChildCvSettings;
  childFourteen?: ChildCvSettings;
}

export interface RepeatedModerationReportItem {
  content: ModerationContent;
  actions: ModerationAction[];
}

export interface RepeatedModerationReport {
  records: RepeatedModerationReportItem[];
}

export interface ContentCoverDto {
  contentId: number;
  cover: string;
  createdAt: string;
  version: number;
  moderationStatus: CommonModerationStatus;
  moderatorId?: number;
}

export interface SetContentCoverModerationStatus {
  contentId: number;
  version: number;
  moderationStatus: CommonModerationStatus;
}

export interface ModerationAction {
  id?: string;
  contentId?: number;
  moderatorId?: UserId;
  authorId?: UserId;
  userId?: UserId;
  commentId?: number;
  actionType?: "SET_TAGS" | "MODERATED";
  tags?: ModerationTag[];
  moderationStatus?: "NEW" | "APPROVED" | "APPROVED_18" | "HIDDEN" | "DELETED";
  createdAt?: string;
  objectType?:
    | "CONTENT"
    | "USER_NAME"
    | "USER_NICKNAME"
    | "USER_AVATAR"
    | "USER_PROFILE_DESCRIPTION"
    | "HASHTAG"
    | "CONTENT_TITLE"
    | "COMMENT";
  moderationVerdict?: "GOOD" | "BAD" | "SUGGESTIVE";
  hashtagId?: number;
  moderationFilter?: "MANUAL" | "ML_MODEL" | "NLP_MODEL" | "BLACKLIST";
  modelName?: string;
  assignedModerationStatus?: AssignedModerationStatus;
  contentModerationStatus?: ContentModerationStatus;
}

export interface UserModerationSanction {
  /** @format uuid */ id?: string;

  /** @format int64 */
  userId?: number;

  /** @format int64 */
  moderatorId?: number;
  moderationFilter?: "MANUAL" | "ML_MODEL" | "NLP_MODEL" | "BLACKLIST";
  modelName?: string;
  sanctionTarget?: "USER" | "DEVICE" | "IPV4_ADDRESS" | "PUBLISHING";
  sanctionType?: "BLOCKED" | "UNBLOCKED";
  deviceId?: string;
  ipv4Address?: string;

  /** @format date-time */
  endDate?: string;

  /** @format date-time */
  createdAt?: string;
}

export const ContentModerationApi = {
  setContentTagsBatch: (request: BatchSetContentTagsRequest): Promise<BatchSetContentTagsResponse> =>
    POST(`${urlPrefix}/content/versioned-batch-set`, request),
  listForModeration: (
    {
      filter,
      pagination,
      sorting,
      childLabelsEnabled,
      repeatedModeration,
      excludeFromTrustedUsers,
    }: {
      filter: ModerationContentFilter;
      pagination: Pageable;
      sorting: ModerationContentSorting;
      childLabelsEnabled?: boolean;
      repeatedModeration?: boolean;
      excludeFromTrustedUsers?: boolean;
    },
    requestContext?: RequestContext
  ): Promise<Page<ModerationContentWithChildLabels>> =>
    POST(
      `${urlPrefix}/content/list?${buildQuery({
        ...pagination,
        ...sorting,
        childLabelsEnabled,
        repeatedModeration,
        excludeFromTrustedUsers,
      })}`,
      filter,
      requestContext
    ),
  loadRepeatedModerationReport: (filter: RepeatedModerationReportFilter): Promise<RepeatedModerationReport> =>
    POST(
      `${urlPrefix}/content/repeated-moderated-report`,
      mapValues(filter, (x) => {
        if (x instanceof Date) {
          return x.toISOString();
        }
        if (!x) {
          return undefined;
        }
        return x;
      })
    ),
  loadAutoApproveTimes: (contentIds: number[]): Promise<AutoApproveTimes> =>
    POST(`${urlPrefix}/content/get-auto-approve-times`, { contentIds }),
  listLogs: ({
    pagination,
    filter,
    sortBy = "createdAt",
    sortDirection = ChildSortingType.DESC,
  }: {
    sortBy?: string;
    sortDirection?: ChildSortingType;
    pagination: Pageable;
    filter: ModerationLogFilterType;
  }): Promise<Page<ModerationAction>> =>
    POST(
      `${urlPrefix}/log/list?${buildQuery({ ...pagination, sort: [sortBy, sortDirection].join(",") })}`,
      filterAdapter(filter)
    ),
  loadUserSanctionsLogs: ({
    page,
    size,
    filter,
    sortDirection = ChildSortingType.DESC,
  }: {
    page: number;
    size: number;
    filter: UserModerationSanctionFilter;
    sortDirection: ChildSortingType;
  }): Promise<Page<UserModerationSanction>> =>
    POST(
      `${urlPrefix}/log/user-sanctions/list?${buildQuery({
        page,
        size,
        sort: `createdAt,${sortDirection.toLowerCase()}`,
      })}`,
      filter
    ),
  loadUserModeratedContentCounters: (userIds: number[]): Promise<UserModeratedContentCounters[]> =>
    POST(`${urlPrefix}/content/get-user-moderated-counters`, userIds),
  loadModerationSettings: (): Promise<ModerationSettings> => GET(`${urlPrefix}/settings`),
  ignoreChildLabelsForUsers: (userIds: number[]) => POST(`${urlPrefix}/labels/child/ignore`, { userIds }),
  loadContentCovers: (contentIds: number[]): Promise<Page<ContentCoverDto>> =>
    POST(`${urlPrefix}/content-cover/list?${buildQuery({ page: 0, size: contentIds.length })}`, { contentIds }),
  setContentCoverStatus: (request: SetContentCoverModerationStatus): Promise<ContentCoverDto> =>
    POST(`${urlPrefix}/content-cover/status`, request),
};
