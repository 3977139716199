import { ChildSortingType, OffsetLimit } from "@api";
import { BadgeIdsPredicateType, SupervisedBadgeFilterType } from "@api/supervisedBadges";
import { endOfDayLocal, formatISOShort, startOfDayLocal } from "@utils/date";
import { DELETE, GET, PATCH, POST, buildQuery } from "@utils/fetch";
import { AdminStatusType } from "./admin";
import { PlatformType } from "./contentCommon";
import { SupervisionBadgesDto } from "./supervisedBadges";
import { InterestForAdminDto } from "./userInterests";

const urlPrefix = "api/v1/admin/users";

export enum UserRole {
  ANONYMOUS = "ANONYMOUS",
  USER = "USER",
  CONTENT_MANAGER = "CONTENT_MANAGER",
  MODERATOR = "MODERATOR",
  ADMINISTRATOR = "ADMINISTRATOR",
  SUPER_ADMINISTRATOR = "SUPER_ADMINISTRATOR",
  SUPER_MODERATOR = "SUPER_MODERATOR",
  SUPERVISOR = "SUPERVISOR",
  WEBADMIN_VIEWER = "WEBADMIN_VIEWER",
  DATA_ANALYST = "DATA_ANALYST",
  BEAT_PRODUCER = "BEAT_PRODUCER",
}

export enum LoginType {
  PHONE = "PHONE",
  APPLE_ID = "APPLE_ID",
  GOOGLE_ID = "GOOGLE_ID",
  SBER_ID = "SBER_ID",
  EMAIL = "EMAIL",
}

export enum SuspendableUserPrivilege {
  // ugc
  CHANGE_UGC = "CHANGE_UGC",
  CHANGE_UGC_IMAGE = "CHANGE_UGC_IMAGE",
  CHANGE_PGC = "CHANGE_PGC",
  CHANGE_AUDIO_TRACK = "CHANGE_AUDIO_TRACK",
  // comments
  CHANGE_COMMENT = "CHANGE_COMMENT",
  // title and description
  CHANGE_PROFILE = "CHANGE_PROFILE",
  CHANGE_PROFILE_AVATAR = "CHANGE_PROFILE_AVATAR",
  // delete own account
  DELETE_USER = "DELETE_USER",
  // publish private threads
  CHANGE_PRIVATE_THREAD = "CHANGE_PRIVATE_THREAD",
  // creating complaints
  CHANGE_COMPLAINT = "CHANGE_COMPLAINT",
}

export interface CreateSocialNetworkDto {
  id?: string;
  type?: SocialNetworkType;
}

export interface SocialNetworkDto {
  id?: string;
  type?: string;
  name?: string;
}

export enum SocialNetworkType {
  VK = "VK",
  Instagram = "Instagram",
  YandexMusic = "YandexMusic",
  AppleMusic = "AppleMusic",
  Telegram = "Telegram",
  SberZvuk = "SberZvuk",
  Spotify = "Spotify",
}

export interface UserFullDto {
  id: number;
  nickName?: string;
  name?: string;
  avatar?: string;
  description?: string;
  countFollowings?: number;
  countFollowers?: number;
  age?: number;
  login?: string;
  loginType?: LoginType;
  statusType?: AdminStatusType;
  birthday?: string;
  socialNetworks?: SocialNetworkDto[];
  availableInviteCount?: number;
  inviteRequired?: boolean;
  authRequired?: boolean;
  givenInviteCount?: number;
  extraPrivileges?: string[];
  profileBadges?: number[];
  suspendedPrivileges?: Record<string, string>;
  isHidden?: boolean;
  isTrusted?: boolean;
  internalComment?: string;
  isTrustedComments?: boolean;
  hasApprovedUgc?: boolean;
  badges?: SupervisionBadgesDto[];
  interests?: Omit<InterestForAdminDto, "position" | "status">[];
}

export interface UsersFilter {
  name?: string | null;
  nickname?: string | null;
  description?: string | null;
  phone?: string | null;
  isHidden?: boolean | null;
  isTrusted?: boolean | null;
  isTrustedComments?: boolean | null;
  hasApprovedUgc?: boolean | null;
  sorting?: ChildSortingType;
  sortBy?: string | null;
  badgeIdsPredicate?: BadgeIdsPredicateType;
  badgeIds?: number[] | null;
  labelIds?: number[] | null;
  supervisedBadgeFilter?: SupervisedBadgeFilterType | null;
  interestIds?: number[] | null;
}

export interface CreateUserAdminDto {
  nickName?: string;
  name?: string;
  description?: string;
  birthday?: string;
  statusType?: AdminStatusType;
  socialNetworks?: CreateSocialNetworkDto[];
  availableInviteCount?: number;
  inviteRequired?: boolean;
  authRequired?: boolean;
  isHidden?: boolean;
  isTrusted?: boolean;
  internalComment?: string;
  isTrustedComments?: boolean;
}

export interface UserLoginDto {
  id: number;
  loginType: LoginType;
  login: string;
}

export interface BlockUserPayload {
  userId: number;
  deleteVideoContent: boolean;
  deleteComments: boolean;
}

export interface UserBasic {
  id: number;
  nickname?: string;
  name?: string;
  role?: UserRole;
}

export interface UserShortDto {
  id: number;
  nickName?: string;
  name?: string | null;
  avatar?: string | null;
  isSubscribed?: boolean;
  description?: string | null;
  countFollowers?: number;
}

export enum UserAttributes {
  nickname = "nickname",
  name = "name",
  birthday = "birthday",
  description = "description",
  count_followings = "count_followings",
  count_followers = "count_followers",
  is_test = "is_test",
  is_verified = "is_verified",
  is_managed = "is_managed",
  avatar = "avatar",
  suspended_privileges = "suspended_privileges",
}

export interface UserChangelogFilters {
  dateFrom?: Date | null;
  dateTo?: Date | null;
  actorId?: number | null;
  attributeName?: string | null;
}

export interface UserProfileChangelogEntry {
  id: number;
  createdAt: string;
  actor: UserBasic;
  user: UserBasic;
  attributeName: string;
  before: string;
  after: string;
  actorIsAdmin: boolean;
}

export interface UserChangelogResponse {
  profileLog: UserProfileChangelogEntry[];
}

export enum UserExtraPrivilegeType {
  CHANGE_ANY_COMMENT = "CHANGE_ANY_COMMENT",
}

export interface DeviceDto {
  id: number;
  userId: number;
  deviceType: PlatformType;
  uuid: string;
}

export interface LatestKnownAppContextDto {
  appVersion: string;
  buildVersion: string;
  osVersion: string;
  arrivedAt: string;
  device: string;
}

export interface UserDevicesAdminDto {
  deviceDto: DeviceDto;
  latestKnownAppContextDto?: LatestKnownAppContextDto;
}

export const UserApi = {
  getById: (userId: number): Promise<UserFullDto> => GET(`${urlPrefix}/${userId}`),
  loadBatch: (ids: number[]): Promise<{ users: UserFullDto[]; total: number }> =>
    POST(`${urlPrefix}/get-by-ids`, { ids }),
  updatePartial: (userId: number, request: CreateUserAdminDto): Promise<UserFullDto> =>
    PATCH(`${urlPrefix}/${userId}`, request),
  list: ({
    badgeIds,
    badgeIdsPredicate,
    labelIds,
    interestIds,
    isHidden,
    isTrusted,
    isTrustedComments,
    hasApprovedUgc,
    ...rest
  }: OffsetLimit & UsersFilter): Promise<{ users: UserFullDto[]; total: number }> =>
    GET(
      `${urlPrefix}?${buildQuery({
        ...rest,
        ...(typeof isHidden === "boolean" && { isHidden }),
        ...(typeof isTrusted === "boolean" && { isTrusted }),
        ...(typeof isTrustedComments === "boolean" && { isTrustedComments }),
        ...(typeof hasApprovedUgc === "boolean" && { hasApprovedUgc }),
        ...(badgeIds?.length &&
          badgeIdsPredicate && {
            badgeIds,
            badgeIdsPredicate,
          }),
        ...(labelIds?.length && { labelIds }),
        ...(interestIds?.length && { interestIds }),
      })}`
    ),
  delete: (userId: number): Promise<void> => DELETE(`${urlPrefix}/${userId}`),
  addLogin: ({ userId, loginType, login }: { userId: number; loginType: LoginType; login: string }): Promise<void> =>
    POST(`${urlPrefix}/${userId}/login?${buildQuery({ login, loginType })}`),
  deleteLogin: (userId: number): Promise<void> => DELETE(`${urlPrefix}/${userId}/login`),
  uploadAvatar: (userId: number, image: File): Promise<void> => {
    const formData = new FormData();
    formData.append("files", image);
    return POST(`${urlPrefix}/${userId}/avatar`, formData);
  },
  create: (request: CreateUserAdminDto): Promise<UserFullDto> =>
    POST(`${urlPrefix}`, { ...request, countLikes: 0, countFollowers: 0, countFollowings: 0 }),
  changeExtraPrivileges: (userId: number, extraPrivileges: string[]): Promise<UserFullDto> =>
    PATCH(`${urlPrefix}/${userId}/privileges`, { extraPrivileges }),
  block: ({ userId, deleteVideoContent, deleteComments }: BlockUserPayload) =>
    POST(`api/v1/admin/users/${userId}/block?${buildQuery({ deleteVideoContent, deleteComments })}`),
  requestShowLogin: (userId: number): Promise<UserLoginDto> => GET(`api/v1/admin/users/${userId}/login`),
  suspendPrivileges: ({
    userId,
    privileges,
    until,
    reason,
    withPush,
  }: {
    userId: number;
    privileges: SuspendableUserPrivilege[];
    until?: Date;
    reason: string;
    withPush: boolean;
  }): Promise<UserFullDto> =>
    POST(`api/v1/admin/users/privileges/suspend`, {
      userId,
      privileges: privileges.map((x) => ({ privilege: x, ...(until && { until: until.toISOString() }) })),
      reason,
      withPush,
    }),
  resumePrivileges: ({
    userId,
    privileges,
    reason,
    withPush,
  }: {
    userId: number;
    privileges: SuspendableUserPrivilege[];
    reason: string;
    withPush: boolean;
  }): Promise<UserFullDto> =>
    POST(`api/v1/admin/users/privileges/resume`, {
      userId,
      privileges,
      reason,
      withPush,
    }),
  loadUserChangeLog: (userId: number, filters: UserChangelogFilters): Promise<UserChangelogResponse> => {
    const { dateFrom, dateTo, actorId, attributeName } = filters;
    return GET(
      `${urlPrefix}/${userId}/changelog?${buildQuery({
        ...(actorId && { actorId }),
        ...(attributeName && (Object.values(UserAttributes) as string[]).includes(attributeName) && { attributeName }),
        ...(dateFrom && { dateFrom: formatISOShort(startOfDayLocal(dateFrom)) }),
        ...(dateTo && { dateTo: formatISOShort(endOfDayLocal(dateTo)) }),
      })}`
    );
  },
  listDevicesOfUser: (userId: number): Promise<UserDevicesAdminDto[]> => GET(`api/v1/admin/users/${userId}/devices`),
  deleteDeviceOfUser: ({ userId, deviceUuid }: { userId: number; deviceUuid: string }) =>
    DELETE(`api/v1/admin/users/${userId}/devices?${buildQuery({ deviceUuid })}`),
};
