import { useCallback, useState } from "react";

export default () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const onOpenMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);
  const onCloseMenu = useCallback(() => setAnchorEl(null), []);
  return { anchorEl, onOpenMenu, onCloseMenu };
};

export const useMenuPayloadState = <Type>() => {
  const [state, setState] = useState<{ payload: Type | null; anchorEl: HTMLElement | null }>({
    payload: null,
    anchorEl: null,
  });
  const onOpenMenu = useCallback((anchorEl: HTMLElement, payload: Type) => setState({ payload, anchorEl }), []);
  const onCloseMenu = useCallback(() => setState({ payload: null, anchorEl: null }), []);
  return {
    ...state,
    onOpenMenu,
    onCloseMenu,
  };
};
