import { NotificationData } from "@slices/moderationNotificator";

export enum MessageType {
  /** when a tab assumes responsibility for polling */
  TAKEOVER = "TAKEOVER",
  /** on mount can use this to check if someone is polling */
  PING_POLLER = "PING_POLLER",
  /** tab polling currently should send this message when PING_POLLER is received */
  PING_REPLY = "PING_REPLY",
  /** polling tab sends this when data is loaded to actualize background tabs state */
  POLLING_DONE = "POLLING_DONE",
}

export enum TakeoverReason {
  /** foreground tab takes over polling unconditionally */
  FOREGROUND = "FOREGROUND",
  /** background tabs can try to start polling in case no PING_REPLY/POLLING_DONE were received during some time */
  INACTIVITY = "INACTIVITY",
}

export interface BaseMessage {
  type: MessageType;
  sourceTabId: number;
}

export interface TakeoverMessage extends BaseMessage {
  takeoverReason: TakeoverReason;
}

export interface PollingDoneMessage extends BaseMessage {
  notificationData: NotificationData[];
}
