import { Howl } from "howler";
import { useCallback, useRef } from "react";

const useLazyHowl = () => {
  const howlsRef = useRef<Record<string, Howl>>({});
  return useCallback((soundName: string) => {
    if (howlsRef.current[soundName]) {
      return howlsRef.current[soundName];
    }
    const howl = new Howl({
      src: [soundName],
    });
    howlsRef.current[soundName] = howl;
    return howl;
  }, []);
};

export default useLazyHowl;
