const hostnameRegex = /admin-react\.(?<envName>[a-z0-9]+)\.esn\.one/;
const newHostnameRegex = /admin-(?<envName>[a-z0-9]+)\.common\.esn\.one/;

export const determineEnvironmentName = ({
  stand,
  hostname,
  nodeEnv,
}: {
  stand?: string | undefined;
  hostname?: string | undefined;
  nodeEnv?: string | undefined;
}): { envName: string | null; newStyleOfUrl: boolean } => {
  if (nodeEnv === "test") {
    return { envName: "test", newStyleOfUrl: false };
  }
  if (stand && stand !== "undefined") {
    return { envName: stand, newStyleOfUrl: ["test4"].includes(stand) };
  }
  if (hostname) {
    const match = hostname.match(newHostnameRegex);
    if (match?.groups && match?.groups.envName) {
      return { envName: match.groups.envName, newStyleOfUrl: true };
    } else {
      const match = hostname.match(hostnameRegex);
      if (match?.groups && match.groups?.envName) {
        return { envName: match.groups.envName, newStyleOfUrl: false };
      }
    }
  }
  return { envName: null, newStyleOfUrl: false };
};
