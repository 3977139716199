import { DELETE, GET, POST, PUT } from "@utils/fetch";

export enum ExternalPlatformLinkGroup {
  STREAMING_LIST = "STREAMING_LIST",
  VIDEO = "VIDEO",
}

export interface ExternalPlatformLinkPatternDto {
  id?: number;
  regexp: string;
  displayedPattern: string;
}

export interface ChangeExternalPlatformDto {
  name: string;
  icon: string;
  group: ExternalPlatformLinkGroup;
  position?: number;
  editIcon?: string;
  linkPatterns?: ExternalPlatformLinkPatternDto[];
  externalWebLinkGroupIds?: number[];
}

export interface ExternalPlatformDto extends Omit<ChangeExternalPlatformDto, "externalWebLinkGroupIds"> {
  id: number;
  externalWebLinkGroups?: ExternalWebLinkGroupDto[];
}

export interface ChangeExternalPlatformWebLinkDto {
  contentId?: number;
  externalPlatformId: number;
  url: string;
}

export interface ExternalPlatformWebLinkDto extends ChangeExternalPlatformWebLinkDto {
  id: number;
}

export interface CustomWeblinkMeta {
  name: string;
  icon: string;
  url: string;
}

export interface ChangeCustomWebLinkDto extends CustomWeblinkMeta {
  contentId: number;
}

export interface CustomWebLinkDto extends ChangeCustomWebLinkDto {
  id: number;
}

export interface WebLinkIconUploadResponse {
  iconUrl: string;
}

export enum WebLinkGroupType {
  COVER = "COVER",
  DEFAULT = "DEFAULT",
}

export interface ChangeExternalWebLinkGroupDto {
  title: string;
  icon: string;
  webLinkGroupType: WebLinkGroupType;
}

export interface ExternalWebLinkGroupDto extends ChangeExternalWebLinkGroupDto {
  id: number;
}

export const WeblinksApi = {
  listExternalPlatforms: (): Promise<ExternalPlatformDto[]> => GET("api/v1/admin/external_platform"),
  createExternalPlatform: (request: ChangeExternalPlatformDto): Promise<ExternalPlatformDto> =>
    POST("api/v1/admin/external_platform", request),
  updateExternalPlatform: (id: number, request: ChangeExternalPlatformDto): Promise<ExternalPlatformDto> =>
    PUT(`api/v1/admin/external_platform/${id}`, request),
  deleteExternalPlatform: (id: number) => DELETE(`api/v1/admin/external_platform/${id}`),
  createExternalPlatformWebLink: (request: ChangeExternalPlatformWebLinkDto): Promise<ExternalPlatformWebLinkDto> =>
    POST(`api/v1/admin/external_platform_web_link`, request),
  updateExternalPlatformWebLink: (
    id: number,
    request: ChangeExternalPlatformWebLinkDto
  ): Promise<ExternalPlatformWebLinkDto> => PUT(`api/v1/admin/external_platform_web_link/${id}`, request),
  deleteExternalPlatformWebLink: (id: number) => DELETE(`api/v1/admin/external_platform_web_link/${id}`),
  createCustomWebLink: (request: ChangeCustomWebLinkDto): Promise<CustomWebLinkDto> =>
    POST(`api/v1/admin/custom_web_link`, request),
  updateCustomWebLink: (id: number, request: ChangeCustomWebLinkDto): Promise<CustomWebLinkDto> =>
    PUT(`api/v1/admin/custom_web_link/${id}`, request),
  deleteCustomWebLink: (id: number) => DELETE(`api/v1/admin/custom_web_link/${id}`),
  uploadWebLinkImage: (file: File | Blob): Promise<WebLinkIconUploadResponse> => {
    const formData = new FormData();
    formData.append("file", file, file instanceof Blob ? "blob.png" : undefined);
    return POST("api/v1/admin/web_link/image", formData);
  },
  getWeblinkGroups: (): Promise<ExternalWebLinkGroupDto[]> => GET("api/v1/admin/external_web_link_group"),
  createWeblinkGroup: (request: ChangeExternalWebLinkGroupDto): Promise<ExternalWebLinkGroupDto> =>
    POST("api/v1/admin/external_web_link_group", request),
  updateWeblinkGroup: (id: number, request: ChangeExternalWebLinkGroupDto): Promise<ExternalWebLinkGroupDto> =>
    PUT(`api/v1/admin/external_web_link_group/${id}`, request),
  deleteWeblinkGroup: (id: number) => DELETE(`api/v1/admin/external_web_link_group/${id}`),
};
