import { DELETE, GET, POST, buildQuery } from "@utils/fetch";

const urlPrefix = "api/v1/admin/config";

export interface ExclusionGroupRawDto {
  id: number;
  name: string;
}

export interface ConfigRawDto {
  id: number | null;
  variationId: number | null;
  serviceName: string;
  propName: string;
  propValue: string;
}

export interface VariationRawDto {
  id: number | null;
  experimentId: number | null;
  description: string;
  trafficDistributionPercent: number;
}

export enum ExperimentStatus {
  DRAFT = "DRAFT",
  READY = "READY",
  STOPPED = "STOPPED",
  REMOVED = "REMOVED",
}

export interface ExperimentRawDto {
  id: number | null;
  name: string;
  applyOrder: number;
  status: ExperimentStatus;
  suitabilityFilterExpression: string;
  hashAllocationPercent: number;
  startsAt: string | null;
  endsAt: string | null;
  createdAt: string | null;
  exclusionGroupId: number | null;
}

export enum ConfigFilter {
  DEFAULT_ONLY = "DEFAULT_ONLY",
  NON_DEFAULT = "NON_DEFAULT",
}

export interface ConfigsRequest {
  serviceName?: string;
  configFilter?: ConfigFilter;
}

export const AbTestApi = {
  listConfigs: (request: ConfigsRequest): Promise<ConfigRawDto[]> => GET(`${urlPrefix}/configs?${buildQuery(request)}`),
  listExclusionGroups: (): Promise<ExclusionGroupRawDto[]> => GET(`${urlPrefix}/exclusiongroups`),
  listExperiments: (): Promise<ExperimentRawDto[]> => GET(`${urlPrefix}/experiments`),
  listVariations: (): Promise<VariationRawDto[]> => GET(`${urlPrefix}/variations`),
  listServiceNames: (): Promise<string[]> => GET(`${urlPrefix}/servicenames`),
  upsertExclusionGroup: ({ id, name }: { id?: number | null; name: string }): Promise<ExclusionGroupRawDto> =>
    POST(`${urlPrefix}/exclusiongroup`, { id, name }),
  upsertConfig: (config: ConfigRawDto): Promise<ConfigRawDto> => POST(`${urlPrefix}/config`, config),
  upsertVariation: (variation: VariationRawDto): Promise<VariationRawDto> => POST(`${urlPrefix}/variation`, variation),
  upsertExperiment: (experiment: ExperimentRawDto): Promise<ExperimentRawDto> =>
    POST(`${urlPrefix}/experiment`, experiment),
  deleteVariation: (id: number | string) => DELETE(`${urlPrefix}/variation/${id}`),
  deleteConfig: (id: number | string) => DELETE(`${urlPrefix}/config/${id}`),
  deleteExperiment: (id: number | string) => DELETE(`${urlPrefix}/experiment/${id}`),
  deleteExclusionGroup: (id: number | string) => DELETE(`${urlPrefix}/exclusiongroup/${id}`),
};
