import AppType, { appTypeToDisplayName } from "@models/AppType";
import { useAppSelector } from "@store/hooks";
import { buildQuery } from "@utils/fetch";
import { Link } from "react-router-dom";

export const composeContentLink = ({ id, appType }: { id: number; appType: AppType }): string =>
  `/contentCard?${buildQuery({ id, appType: appTypeToDisplayName[appType] })}`;

const LinkToContent = ({
  id,
  children,
  className,
  testId,
}: {
  id: number;
  children?: React.ReactNode;
  className?: string;
  testId?: string;
}) => {
  const currentUser = useAppSelector((state) => state.user.currentUser!);
  return (
    <Link
      to={composeContentLink({ id, appType: currentUser.appType })}
      target="_blank"
      className={className}
      data-testid={testId}
    >
      {children || id}
    </Link>
  );
};

export default LinkToContent;
