import { CommentsApi } from "@api/comments";
import { CreateUserAdminDto, SuspendableUserPrivilege, UserFullDto, UserRole } from "@api/user";
import useDefaultNetworkErrorHandler from "@hooks/useDefaultNetworkErrorHandler";
import useDialogPayloadState from "@hooks/useDialogPayloadState";
import { useMenuPayloadState } from "@hooks/useMenuAnchorAndHandlers";
import AppType from "@models/AppType";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { getCurrentUserAppType, getCurrentUserRole } from "@slices/user";
import { updateUserField } from "@slices/usersCache";
import { resumeUserPrivilegesAction, suspendUserPrivilegeAction } from "@store/commonActions/userActions";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { addDays, addMonths } from "@utils/date";
import { DateTimePicker } from "@widgets/DatePickers";
import { lowerFirst, noop } from "lodash";
import React, { Dispatch, SetStateAction, createContext, useCallback, useMemo, useState } from "react";
import { FormattedMessage, defineMessage, defineMessages, useIntl } from "react-intl";

export enum UserContentPublishingState {
  ALLOWED = "allowed",
  RESTRICTED = "restricted",
  HIDDEN = "hidden",
  TRUSTED = "trusted",
}

const validStatesForUgc = Object.values(UserContentPublishingState);
const validStatesForComments = Object.values(UserContentPublishingState).filter((x) => {
  if (x === UserContentPublishingState.HIDDEN) {
    return false;
  }
  return true;
});

export const computeUserUgcPublishingState = (userProfile: UserFullDto): UserContentPublishingState => {
  if (isPrivilegeSuspended(userProfile, SuspendableUserPrivilege.CHANGE_UGC)) {
    return UserContentPublishingState.RESTRICTED;
  }
  if (userProfile.isTrusted) {
    return UserContentPublishingState.TRUSTED;
  }
  if (userProfile.isHidden) {
    return UserContentPublishingState.HIDDEN;
  }
  return UserContentPublishingState.ALLOWED;
};

export const computeUserCommentsPublishingState = (userProfile: UserFullDto): UserContentPublishingState => {
  if (isPrivilegeSuspended(userProfile, SuspendableUserPrivilege.CHANGE_COMMENT)) {
    return UserContentPublishingState.RESTRICTED;
  }
  if (userProfile.isTrustedComments) {
    return UserContentPublishingState.TRUSTED;
  }
  return UserContentPublishingState.ALLOWED;
};

export const restrictionButtonMessages = defineMessages({
  allowed: { defaultMessage: "Allowed" },
  restricted: { defaultMessage: "Restricted" },
  trusted: { defaultMessage: "Auto-approved" },
  hidden: { defaultMessage: "Auto-hidden" },
});

/**
 * omitted privileges mean acting on whole account,
 * if blocking by status enabled will change account status, otherwise will suspend/resume all possible privileges
 */
interface BlockUserContextType {
  onRequestBlockUser: (user: UserFullDto, privileges?: SuspendableUserPrivilege[]) => void;
  onRequestUnblockUser: (user: UserFullDto, anchorEl: HTMLElement, privileges?: SuspendableUserPrivilege[]) => void;
  onRequestChangeUserUgcPublishingState: (user: UserFullDto) => void;
  onRequestChangeUserCommentsPublishingState: (user: UserFullDto) => void;
}

const BlockUserContext = createContext<BlockUserContextType>({
  onRequestBlockUser: noop,
  onRequestUnblockUser: noop,
  onRequestChangeUserUgcPublishingState: noop,
  onRequestChangeUserCommentsPublishingState: noop,
});

export default BlockUserContext;

export const restrictionMessages = defineMessages({
  [SuspendableUserPrivilege.CHANGE_UGC]: { defaultMessage: "Publishing UGC videos" },
  [SuspendableUserPrivilege.CHANGE_PRIVATE_THREAD]: { defaultMessage: "Publishing private threads" },
  [SuspendableUserPrivilege.CHANGE_UGC_IMAGE]: { defaultMessage: "Changing video cover" },
  [SuspendableUserPrivilege.CHANGE_COMMENT]: { defaultMessage: "Publishing comments" },
  [SuspendableUserPrivilege.CHANGE_PROFILE]: { defaultMessage: "Changing name and description" },
  [SuspendableUserPrivilege.CHANGE_PROFILE_AVATAR]: { defaultMessage: "Changing avatar" },
  [SuspendableUserPrivilege.DELETE_USER]: { defaultMessage: "Deleting own account" },
  [SuspendableUserPrivilege.CHANGE_PGC]: { defaultMessage: "Publishing PGC videos" },
  [SuspendableUserPrivilege.CHANGE_AUDIO_TRACK]: { defaultMessage: "Uploading beats" },
  [SuspendableUserPrivilege.CHANGE_COMPLAINT]: { defaultMessage: "Creating complaints" },
});

const commentPresets = [
  "Дети",
  "Ненависть, насилие, буллинг",
  "Дискриминация (раса, религия, меньшинства)",
  "Пропаганда ЛГБТ",
  "Жестокое обращение с детьми, животными и т.д.",
  "Наркотики, азартные игры, оружие",
  "Порнография",
  "Запрещенная к распространению информация",
  "Иная контентная политика приложения",
];

interface BlockByPrivilegePayload {
  user: UserFullDto;
  privileges: SuspendableUserPrivilege[];
}

const privilegesExclusiveForApp: Record<AppType, SuspendableUserPrivilege[]> = {
  [AppType.PUNCH]: [SuspendableUserPrivilege.CHANGE_PGC, SuspendableUserPrivilege.CHANGE_AUDIO_TRACK],
  [AppType.CHIPZ]: [SuspendableUserPrivilege.CHANGE_PRIVATE_THREAD],
};

export const useAllBlockablePrivileges = () => {
  const appType = useAppSelector(getCurrentUserAppType);
  return useMemo(() => {
    if (appType === AppType.PUNCH) {
      return Object.values(SuspendableUserPrivilege).filter(
        (x) => !privilegesExclusiveForApp[AppType.CHIPZ].includes(x)
      );
    }
    return Object.values(SuspendableUserPrivilege).filter((x) => !privilegesExclusiveForApp[AppType.PUNCH].includes(x));
  }, [appType]);
};

export const isPrivilegeSuspended = (user: UserFullDto | undefined, privilege: SuspendableUserPrivilege): boolean => {
  const until = user?.suspendedPrivileges?.[privilege];
  const restricted = !!until && Date.parse(until) > Date.now();
  return restricted;
};

interface ConfirmSuspendPrivilegePayload {
  reason: string;
  until: Date;
  withPush: boolean;
  deleteComments: boolean;
  privilegesOverride?: SuspendableUserPrivilege[];
}

interface ConfirmChangeUserContentPublishingStatePayload {
  state: UserContentPublishingState;
  reason: string;
  until: Date | null;
  withPush: boolean;
  fullBlock: boolean;
  deleteComments?: boolean;
}

const getForeverBanDate = (): Date => addMonths(new Date(), 12 * 10);

const BlockConfigurationWidget = ({
  reason,
  setReason,
  until,
  setUntil,
  withPush,
  setWithPush,
  showWithPush,
  showReason,
  showDuration,
  children,
}: {
  reason: string;
  setReason: Dispatch<SetStateAction<string>>;
  until: Date | null;
  setUntil: Dispatch<SetStateAction<Date | null>>;
  withPush: boolean;
  setWithPush: Dispatch<SetStateAction<boolean>>;
  showWithPush: boolean;
  showReason: boolean;
  showDuration: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <Stack direction="column" spacing={2} sx={{ paddingTop: "8px" }}>
      {showReason && (
        <Autocomplete
          freeSolo
          size="small"
          inputValue={reason}
          onInputChange={(_, value) => setReason(value)}
          options={commentPresets}
          renderInput={(params) => (
            <TextField
              data-testid="reason-autocomplete"
              {...params}
              label={<FormattedMessage defaultMessage="Reason" />}
            />
          )}
        />
      )}
      {showDuration && (
        <DateTimePicker
          value={until}
          onChange={(v) => setUntil(v)}
          labelMessage={defineMessage({ defaultMessage: "Until" })}
          testId="until-input"
        />
      )}
      {showDuration && (
        <Stack direction="row" spacing="1" justifyContent="space-evenly">
          <Button onClick={() => setUntil(addDays(new Date(), 1))} data-testid="day-button">
            <FormattedMessage defaultMessage="Day" />
          </Button>
          <Button onClick={() => setUntil(addDays(new Date(), 7))} data-testid="week-button">
            <FormattedMessage defaultMessage="Week" />
          </Button>
          <Button onClick={() => setUntil(addMonths(new Date(), 1))} data-testid="month-button">
            <FormattedMessage defaultMessage="Month" />
          </Button>
          <Button onClick={() => setUntil(getForeverBanDate())}>
            <FormattedMessage defaultMessage="Forever" />
          </Button>
        </Stack>
      )}
      {showWithPush && (
        <FormControlLabel
          control={
            <Checkbox
              data-testid="with-push-checkbox"
              checked={withPush}
              onChange={(e) => setWithPush(e.target.checked)}
            />
          }
          label={<FormattedMessage defaultMessage="Notify user" />}
        />
      )}
      {children}
    </Stack>
  );
};

const KidsPresetButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Tooltip title={<FormattedMessage defaultMessage="Make this account auto-hidden" />}>
      <Button
        sx={{ ml: "auto" }}
        variant="contained"
        color="warning"
        onClick={onClick}
        data-testid="kids-preset-button"
      >
        <FormattedMessage defaultMessage="Kids" />
      </Button>
    </Tooltip>
  );
};

const SuspendPrivilegeDialogContent = ({
  privileges,
  closeDialog,
  onConfirmSuspendPrivilege,
  onKidsPresetClick,
}: {
  user: UserFullDto;
  privileges: SuspendableUserPrivilege[];
  closeDialog: () => void;
  onConfirmSuspendPrivilege: (payload: ConfirmSuspendPrivilegePayload) => void;
  onKidsPresetClick: () => void;
}) => {
  const [inputValue, setInputValue] = useState("");
  const [until, setUntil] = useState<Date | null>(null);
  const [withPush, setWithPush] = useState(false);
  const [deleteComments, setDeleteComments] = useState(false);
  const intl = useIntl();
  const message = useMemo(() => {
    return privileges.map((x) => lowerFirst(intl.formatMessage(restrictionMessages[x]))).join(", ");
  }, [privileges, intl]);
  return (
    <>
      <DialogTitle>
        <Stack direction="row">
          <FormattedMessage defaultMessage="Suspend privileges" />:
          <KidsPresetButton onClick={onKidsPresetClick} />
        </Stack>
        <Typography variant="body1">{message}</Typography>
      </DialogTitle>
      <DialogContent>
        <BlockConfigurationWidget
          reason={inputValue}
          setReason={setInputValue}
          until={until}
          setUntil={setUntil}
          withPush={withPush}
          setWithPush={setWithPush}
          showReason
          showDuration
          showWithPush
        >
          {privileges.includes(SuspendableUserPrivilege.CHANGE_COMMENT) && (
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="delete-comments-checkbox"
                  checked={deleteComments}
                  onChange={(_, checked) => setDeleteComments(checked)}
                />
              }
              label={<FormattedMessage defaultMessage="Hide previously posted comments of this user" />}
            />
          )}
        </BlockConfigurationWidget>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>
          <FormattedMessage defaultMessage="Dismiss" />
        </Button>
        <Button
          onClick={() =>
            onConfirmSuspendPrivilege({ reason: inputValue.trim(), until: until!, withPush, deleteComments })
          }
          disabled={!until || !inputValue.trim()}
          color="warning"
          variant="outlined"
          data-testid="save-restriction-button"
        >
          <FormattedMessage defaultMessage="Restrict" />
        </Button>
      </DialogActions>
    </>
  );
};

const ChangeUserContentPublishingStateDialog = ({
  user,
  suspendablePrivilege,
  validStates,
  computeStateFunc,
  closeDialog,
  onConfirmChangeUserContentPublishingState,
  onKidsPresetClick,
}: {
  user: UserFullDto;
  suspendablePrivilege: SuspendableUserPrivilege;
  validStates: UserContentPublishingState[];
  computeStateFunc: (user: UserFullDto) => UserContentPublishingState;
  closeDialog: () => void;
  onConfirmChangeUserContentPublishingState: (payload: ConfirmChangeUserContentPublishingStatePayload) => void;
  onKidsPresetClick: () => void;
}) => {
  const [reason, setReason] = useState("");
  const [until, setUntil] = useState<Date | null>(null);
  const [state, setState] = useState(computeStateFunc(user));
  const [withPush, setWithPush] = useState(false);
  const [deleteComments, setDeleteComments] = useState(false);
  return (
    <>
      <DialogTitle>
        <Stack direction="row">
          <FormattedMessage {...restrictionMessages[suspendablePrivilege]} />
          {suspendablePrivilege === SuspendableUserPrivilege.CHANGE_UGC && (
            <KidsPresetButton onClick={onKidsPresetClick} />
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack direction="row">
          <RadioGroup
            row={false}
            value={state}
            onChange={(e) => setState(e.target.value as UserContentPublishingState)}
            data-testid="content-publishing-radios"
          >
            {validStates.map((x) => (
              <FormControlLabel
                key={x}
                value={x}
                control={<Radio data-testid={`${x}-radio`} />}
                label={<FormattedMessage {...restrictionButtonMessages[x]} />}
              />
            ))}
          </RadioGroup>
          <BlockConfigurationWidget
            reason={reason}
            setReason={setReason}
            until={until}
            setUntil={setUntil}
            withPush={withPush}
            setWithPush={setWithPush}
            showReason={state === UserContentPublishingState.RESTRICTED}
            showDuration={state === UserContentPublishingState.RESTRICTED}
            showWithPush={
              state === UserContentPublishingState.RESTRICTED || isPrivilegeSuspended(user, suspendablePrivilege)
            }
          >
            {state === UserContentPublishingState.RESTRICTED &&
              suspendablePrivilege === SuspendableUserPrivilege.CHANGE_COMMENT && (
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid="delete-comments-checkbox"
                      checked={deleteComments}
                      onChange={(_, checked) => setDeleteComments(checked)}
                    />
                  }
                  label={<FormattedMessage defaultMessage="Hide previously posted comments of this user" />}
                />
              )}
          </BlockConfigurationWidget>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>
          <FormattedMessage defaultMessage="Dismiss" />
        </Button>
        <Button
          onClick={() =>
            onConfirmChangeUserContentPublishingState({
              state,
              reason: reason.trim(),
              until,
              withPush,
              fullBlock: false,
              deleteComments,
            })
          }
          disabled={state === UserContentPublishingState.RESTRICTED ? !until || !reason.trim() : false}
          color="warning"
          variant="outlined"
          data-testid="save-button"
        >
          <FormattedMessage defaultMessage="Save" />
        </Button>
      </DialogActions>
    </>
  );
};

export const BlockUserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const role = useAppSelector(getCurrentUserRole);
  const dispatch = useAppDispatch();
  const errorHandler = useDefaultNetworkErrorHandler();
  const {
    open: privilegeDialogOpen,
    payload: privilegeDialogPayload,
    openDialog: openPrivilegeDialog,
    closeDialog: closePrivilegeDialog,
  } = useDialogPayloadState<BlockByPrivilegePayload>();
  const {
    open: userContentPublishingStateDialogOpen,
    payload: userContentPublishingStateDialogPayload,
    openDialog: openUserContentPublishingStateDialog,
    closeDialog: closeUserContentPublishingStateDialog,
  } = useDialogPayloadState<{ user: UserFullDto; privilege: SuspendableUserPrivilege }>();
  const allBlockablePrivileges = useAllBlockablePrivileges();
  const onRequestBlockUser = useCallback(
    (user: UserFullDto, privileges?: SuspendableUserPrivilege[]) => {
      if (role !== UserRole.MODERATOR && role !== UserRole.SUPER_MODERATOR) {
        return;
      }
      if (!privileges) {
        openPrivilegeDialog({ user, privileges: allBlockablePrivileges });
      } else {
        openPrivilegeDialog({ user, privileges });
      }
    },
    [role, allBlockablePrivileges, openPrivilegeDialog]
  );

  const {
    anchorEl: unblockMenuAnchor,
    payload: unblockMenuPayload,
    onOpenMenu: onOpenUnblockMenu,
    onCloseMenu: onCloseUnblockMenu,
  } = useMenuPayloadState<BlockByPrivilegePayload>();
  const onRequestUnblockUser = useCallback(
    (user: UserFullDto, anchorEl: HTMLElement, privileges?: SuspendableUserPrivilege[]) => {
      if (role !== UserRole.MODERATOR && role !== UserRole.SUPER_MODERATOR) {
        return;
      }
      onOpenUnblockMenu(anchorEl, { user, privileges: privileges || allBlockablePrivileges });
    },
    [role, allBlockablePrivileges, onOpenUnblockMenu]
  );
  const onConfirmUnblockUser = useCallback(
    (withPush: boolean) => {
      if (!unblockMenuPayload) {
        return;
      }
      onCloseUnblockMenu();
      const { user, privileges } = unblockMenuPayload;
      dispatch(resumeUserPrivilegesAction({ user, privileges, withPush }));
    },
    [unblockMenuPayload, onCloseUnblockMenu, dispatch]
  );
  const onConfirmSuspendPrivilege = useCallback(
    ({ reason, until, withPush, deleteComments, privilegesOverride }: ConfirmSuspendPrivilegePayload) => {
      if (!privilegeDialogPayload) {
        return;
      }
      const { user, privileges } = privilegeDialogPayload;
      dispatch(
        suspendUserPrivilegeAction({ user, privileges: privilegesOverride || privileges, reason, until, withPush })
      );
      closePrivilegeDialog();
      if (deleteComments) {
        CommentsApi.deleteAllByUser(user.id!).catch(errorHandler);
      }
    },
    [privilegeDialogPayload, dispatch, closePrivilegeDialog, errorHandler]
  );
  const onRequestChangeUserUgcPublishingState = useCallback(
    (user: UserFullDto) => {
      if (role !== UserRole.MODERATOR && role !== UserRole.SUPER_MODERATOR) {
        return;
      }
      openUserContentPublishingStateDialog({ user, privilege: SuspendableUserPrivilege.CHANGE_UGC });
    },
    [role, openUserContentPublishingStateDialog]
  );

  const changeUserUgcPublishingStateImpl = useCallback(
    async ({
      user,
      state,
      reason,
      until,
      fullBlock,
      withPush,
    }: { user: UserFullDto; state: UserContentPublishingState } & ConfirmChangeUserContentPublishingStatePayload) => {
      if (computeUserUgcPublishingState(user) === state) {
        return;
      }
      const privileges = [SuspendableUserPrivilege.CHANGE_UGC, SuspendableUserPrivilege.CHANGE_UGC_IMAGE];
      if (state !== UserContentPublishingState.RESTRICTED) {
        if (privileges.find((x) => isPrivilegeSuspended(user, x))) {
          await dispatch(resumeUserPrivilegesAction({ user, privileges, withPush }));
        }
      } else {
        await dispatch(
          suspendUserPrivilegeAction({
            user,
            reason,
            until: until!,
            privileges: fullBlock ? allBlockablePrivileges : privileges,
            withPush,
          })
        );
      }
      const userUpdate: Partial<CreateUserAdminDto> = {
        isTrusted: state === UserContentPublishingState.TRUSTED,
        isHidden: state === UserContentPublishingState.HIDDEN,
      };
      if (user.isTrusted !== userUpdate.isTrusted || user.isHidden !== userUpdate.isHidden) {
        await dispatch(updateUserField({ userId: user.id!, request: userUpdate }));
      }
    },
    [allBlockablePrivileges, dispatch]
  );
  const onKidsPresetClick = useCallback(() => {
    if (privilegeDialogPayload) {
      closePrivilegeDialog();
      changeUserUgcPublishingStateImpl({
        user: privilegeDialogPayload.user,
        state: UserContentPublishingState.HIDDEN,
        reason: "Дети",
        until: getForeverBanDate(),
        withPush: false,
        fullBlock: false,
      });
    } else if (userContentPublishingStateDialogPayload) {
      closeUserContentPublishingStateDialog();
      changeUserUgcPublishingStateImpl({
        user: userContentPublishingStateDialogPayload.user,
        state: UserContentPublishingState.HIDDEN,
        reason: "Дети",
        until: getForeverBanDate(),
        withPush: false,
        fullBlock: false,
      });
    }
  }, [
    privilegeDialogPayload,
    closePrivilegeDialog,
    userContentPublishingStateDialogPayload,
    closeUserContentPublishingStateDialog,
    changeUserUgcPublishingStateImpl,
  ]);
  const onRequestChangeUserCommentsPublishingState = useCallback(
    (user: UserFullDto) => {
      if (role !== UserRole.MODERATOR && role !== UserRole.SUPER_MODERATOR) {
        return;
      }
      openUserContentPublishingStateDialog({ user, privilege: SuspendableUserPrivilege.CHANGE_COMMENT });
    },
    [role, openUserContentPublishingStateDialog]
  );
  const onConfirmChangeUserContentPublishingState = useCallback(
    ({ state, reason, until, withPush, fullBlock, deleteComments }: ConfirmChangeUserContentPublishingStatePayload) => {
      if (!userContentPublishingStateDialogPayload) {
        return;
      }
      const { user, privilege } = userContentPublishingStateDialogPayload;
      closeUserContentPublishingStateDialog();
      const actForUgc = async () => {
        changeUserUgcPublishingStateImpl({ user, state, reason, until, fullBlock, withPush });
      };
      const actForComment = async () => {
        if (computeUserCommentsPublishingState(user) === state) {
          return;
        }
        if (state !== UserContentPublishingState.RESTRICTED) {
          if (isPrivilegeSuspended(user, privilege)) {
            await dispatch(resumeUserPrivilegesAction({ user, privileges: [privilege], withPush }));
          }
        } else {
          await dispatch(
            suspendUserPrivilegeAction({ user, reason, until: until!, privileges: [privilege], withPush })
          );
        }
        const userUpdate: Partial<CreateUserAdminDto> = {
          isTrustedComments: state === UserContentPublishingState.TRUSTED,
        };
        if (user.isTrustedComments !== userUpdate.isTrustedComments) {
          await dispatch(updateUserField({ userId: user.id!, request: userUpdate }));
        }
        if (state === UserContentPublishingState.RESTRICTED && deleteComments) {
          CommentsApi.deleteAllByUser(user.id!).catch(errorHandler);
        }
      };
      if (privilege === SuspendableUserPrivilege.CHANGE_UGC) {
        actForUgc();
      } else if (privilege === SuspendableUserPrivilege.CHANGE_COMMENT) {
        actForComment();
      }
    },
    [
      dispatch,
      userContentPublishingStateDialogPayload,
      closeUserContentPublishingStateDialog,
      errorHandler,
      changeUserUgcPublishingStateImpl,
    ]
  );
  const contextValue = useMemo<BlockUserContextType>(
    () => ({
      onRequestBlockUser,
      onRequestUnblockUser,
      onRequestChangeUserUgcPublishingState,
      onRequestChangeUserCommentsPublishingState,
    }),
    [
      onRequestBlockUser,
      onRequestUnblockUser,
      onRequestChangeUserUgcPublishingState,
      onRequestChangeUserCommentsPublishingState,
    ]
  );
  return (
    <BlockUserContext.Provider value={contextValue}>
      {children}
      <Dialog
        open={privilegeDialogOpen}
        onClose={closePrivilegeDialog}
        data-testid="confirm-user-privileges-suspend-dialog"
        fullWidth
        maxWidth="sm"
      >
        {privilegeDialogPayload && (
          <SuspendPrivilegeDialogContent
            {...privilegeDialogPayload}
            closeDialog={closePrivilegeDialog}
            onConfirmSuspendPrivilege={onConfirmSuspendPrivilege}
            onKidsPresetClick={onKidsPresetClick}
          />
        )}
      </Dialog>
      <Dialog
        open={userContentPublishingStateDialogOpen}
        onClose={closeUserContentPublishingStateDialog}
        data-testid="confirm-user-content-publishing-change-dialog"
        fullWidth
        maxWidth="sm"
      >
        {userContentPublishingStateDialogPayload && (
          <ChangeUserContentPublishingStateDialog
            user={userContentPublishingStateDialogPayload.user}
            suspendablePrivilege={userContentPublishingStateDialogPayload.privilege}
            onConfirmChangeUserContentPublishingState={onConfirmChangeUserContentPublishingState}
            closeDialog={closeUserContentPublishingStateDialog}
            validStates={
              userContentPublishingStateDialogPayload.privilege === SuspendableUserPrivilege.CHANGE_COMMENT
                ? validStatesForComments
                : validStatesForUgc
            }
            computeStateFunc={
              userContentPublishingStateDialogPayload.privilege === SuspendableUserPrivilege.CHANGE_COMMENT
                ? computeUserCommentsPublishingState
                : computeUserUgcPublishingState
            }
            onKidsPresetClick={onKidsPresetClick}
          />
        )}
      </Dialog>
      <Menu
        anchorEl={unblockMenuAnchor}
        open={!!unblockMenuAnchor}
        onClose={onCloseUnblockMenu}
        MenuListProps={{ dense: true }}
        data-testid="unblock-push-menu"
      >
        <MenuItem onClick={() => onConfirmUnblockUser(true)} data-testid="unblock-with-push-menu-item">
          <FormattedMessage defaultMessage="Notify user" />
        </MenuItem>
        <MenuItem onClick={() => onConfirmUnblockUser(false)} data-testid="unblock-without-push-menu-item">
          <FormattedMessage defaultMessage="Don't notify user" />
        </MenuItem>
      </Menu>
    </BlockUserContext.Provider>
  );
};
