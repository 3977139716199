import { InsideTagWithSettingsDto, InternalTagsApi } from "@api/internalTags";
import { ConfigProperty, properties } from "@config";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { partition } from "lodash";

export interface InternalTagsState {
  tags: InsideTagWithSettingsDto[];
}

const initialState: InternalTagsState = {
  tags: [],
};

const name = "internalTags";

export const refreshInternalTagsListAction = createAsyncThunk(`${name}/refreshInternalTagsListAction`, async () => {
  const tags = await InternalTagsApi.list();
  return tags;
});

const internalTagsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refreshInternalTagsListAction.fulfilled, (state, action) => {
      state.tags = action.payload;
    });
  },
});

export default internalTagsSlice.reducer;

export const getInternalTagsSplitByVisibility = createSelector(
  (state: RootState) => state.internalTags.tags,
  (tags) => {
    const [mainTags, secondaryTags] = partition(tags, (x) => x.visible);
    return { mainTags, secondaryTags };
  }
);

export const getInternalTagForVideoModerationHeartButton = createSelector(
  (state: RootState) => state.internalTags.tags,
  properties[ConfigProperty.VIDEO_MODERATION_RECOMMEND_TO_FEATURE_INTERNAL_TAG].selector,
  (tags, name) => tags.find((x) => x.name === name)
);

export const getInternalTagsForVideoModerationSplitByVisibility = createSelector(
  getInternalTagsSplitByVisibility,
  getInternalTagForVideoModerationHeartButton,
  properties[ConfigProperty.VIDEO_MODERATION_HIDDEN_INTERNAL_TAGS].selector,
  ({ mainTags, secondaryTags }, heartButtonTag, hiddenTagNames) => {
    return {
      mainTags: mainTags.filter((x) => x.id !== heartButtonTag?.id && !hiddenTagNames.includes(x.name)),
      secondaryTags: secondaryTags.filter((x) => x.id !== heartButtonTag?.id && !hiddenTagNames.includes(x.name)),
    };
  }
);
