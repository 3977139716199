import { DELETE, GET, PATCH, POST, buildQuery } from "@utils/fetch";

const urlPrefix = `api/v1/admin/persons`;

export interface PersonDto {
  id: number;
  name?: string;
}

export const PersonsApi = {
  list: (name?: string | null): Promise<PersonDto[]> => GET(`${urlPrefix}?${buildQuery({ name })}`),
  create: (name: string) => POST(urlPrefix, { name }),
  update: (id: number | string, name: string) => PATCH(`${urlPrefix}/${id}`, { name }),
  delete: (id: number) => DELETE(`${urlPrefix}/${id}`),
};
