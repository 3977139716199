import DateFnsAdapter from "@date-io/date-fns";
import { formatDuration, intervalToDuration, minutesToSeconds, secondsToHours, secondsToMinutes } from "date-fns";
import enLocale from "date-fns/locale/en-US";
import ruLocale from "date-fns/locale/ru";

const locales = {
  ru: ruLocale,
  en: enLocale,
} as Record<string, Locale>;

const dateFns = new DateFnsAdapter();

export const { addSeconds, addMinutes, addHours, addDays, addWeeks, addMonths, addYears, isSameDay } = dateFns;

export const startOfDayLocal = (date: Date) => {
  return dateFns.addMinutes(dateFns.startOfDay(date), -date.getTimezoneOffset());
};

export const endOfDayLocal = (date: Date) => {
  return dateFns.addMinutes(dateFns.endOfDay(date), -date.getTimezoneOffset());
};

export const DATE_PICKER_FORMAT = "dd.MM.yyyy";

export const DATETIME_PICKER_FORMAT = "dd.MM.yyyy HH:mm";

export const formatISOShort = (date?: Date): string | undefined => (date ? date.toJSON().substring(0, 19) : undefined);

export const formatIsoNoMillis = (date: Date): string => date.toISOString().split(".")[0] + "Z";

const anyDateToNumber = (date: Date | string | number): number => {
  if (typeof date === "number") {
    return date;
  }
  if (typeof date === "string") {
    return new Date(date).getTime();
  }
  return date.getTime();
};

export const formatDistance = (
  startDate: Date | string | number,
  endDate: Date | string | number,
  lang: string,
  noSeconds: boolean
) => {
  const duration = intervalToDuration({ start: anyDateToNumber(startDate), end: anyDateToNumber(endDate) });
  return formatDuration(duration, {
    ...(noSeconds && { format: ["years", "months", "weeks", "days", "hours", "minutes"] }),
    ...(locales[lang] && { locale: locales[lang] }),
  });
};

export const formatSeconds = (seconds: number, lang: string) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
  return formatDuration(duration, {
    //  format: ["days", "hours", "minutes"],
    ...(locales[lang] && { locale: locales[lang] }),
  });
};

export const forceTimeZone = (dateStr: string | undefined) => {
  if (!dateStr) {
    return dateStr;
  }
  if (dateStr.endsWith("+00:00") || dateStr.endsWith("Z")) {
    return dateStr;
  }
  return dateStr + "+00:00";
};

const MS_PER_SECOND = 1e3;
const SECS_PER_MIN = 60;
const SECS_PER_HOUR = SECS_PER_MIN * 60;
const SECS_PER_DAY = SECS_PER_HOUR * 24;
const SECS_PER_WEEK = SECS_PER_DAY * 7;

export const formatAutoApproveTime = (secondsTillAutoApprove: number): string => {
  if (secondsTillAutoApprove <= 0) {
    return "0s";
  }
  if (secondsTillAutoApprove > SECS_PER_DAY) {
    const days = Math.floor(secondsTillAutoApprove / SECS_PER_DAY);
    const hours = Math.floor((secondsTillAutoApprove - days * SECS_PER_DAY) / SECS_PER_HOUR);
    return [`${days}D`, hours > 0 && `${hours}H`].filter((x) => x).join(" ");
  }
  if (secondsTillAutoApprove > SECS_PER_HOUR) {
    const hours = secondsToHours(secondsTillAutoApprove);
    const minutes = secondsToMinutes(secondsTillAutoApprove - hours * SECS_PER_HOUR);
    return [`${hours}H`, minutes > 0 && `${minutes}m`].filter((x) => x).join(" ");
  }
  const minutes = secondsToMinutes(secondsTillAutoApprove);
  const secondsRemaining = secondsTillAutoApprove - minutesToSeconds(minutes);
  return [minutes > 0 && `${minutes}m`, `${secondsRemaining}s`].filter((x) => x).join(" ");
};

export const serializeDateInDefaultTimezone = (date: Date): string =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();

const unitThresholds = {
  second: 45,
  minute: 45,
  hour: 22,
  day: 5,
};

/** shameless copy of selectUnit from @format-js/intl-utils as they are deprecated */
export const selectUnit = (
  from: Date,
  to: Date
): { value: number; unit: "second" | "minute" | "hour" | "day" | "year" | "month" | "week" } => {
  const secs = (+from - +to) / MS_PER_SECOND;
  if (Math.abs(secs) < unitThresholds.second) {
    return {
      value: Math.round(secs),
      unit: "second",
    };
  }
  const mins = secs / SECS_PER_MIN;
  if (Math.abs(mins) < unitThresholds.minute) {
    return {
      value: Math.round(mins),
      unit: "minute",
    };
  }
  const hours = secs / SECS_PER_HOUR;
  if (Math.abs(hours) < unitThresholds.hour) {
    return {
      value: Math.round(hours),
      unit: "hour",
    };
  }
  const days = secs / SECS_PER_DAY;
  if (Math.abs(days) < unitThresholds.day) {
    return {
      value: Math.round(days),
      unit: "day",
    };
  }
  const years = from.getFullYear() - to.getFullYear();
  if (Math.round(Math.abs(years)) > 0) {
    return {
      value: Math.round(years),
      unit: "year",
    };
  }
  const months = years * 12 + from.getMonth() - to.getMonth();
  if (Math.round(Math.abs(months)) > 0) {
    return {
      value: Math.round(months),
      unit: "month",
    };
  }
  const weeks = secs / SECS_PER_WEEK;
  return {
    value: Math.round(weeks),
    unit: "week",
  };
};
