import { GET, POST, buildQuery } from "@utils/fetch";

export interface WaitPunchWaitingUser {
  userId: number;
  requestedAt: string;
}
export const WaitPunchApi = {
  getCountersForAuthors: (authorIds: number[]): Promise<Record<number, number>> =>
    POST(`api/v1/admin/wait-punch/counters`, authorIds),
  getWaitingUsers: (authorId: number): Promise<WaitPunchWaitingUser[]> =>
    GET(`api/v1/admin/wait-punch/waiting-users?${buildQuery({ authorId })}`),
};
