import { ConfigProperty, properties, usePropertyValue } from "@config";
import { checkForUnprocessedItemsAction } from "@slices/moderationNotificator";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useEffect } from "react";
import { MessageType, PollingDoneMessage } from "./broadcastChannelProtocol";
import { log } from "./utils";

const usePollModerationNotification = ({
  tabId,
  broadcastChannel,
}: {
  tabId: number;
  broadcastChannel: BroadcastChannel;
}) => {
  const pollingIntervalMs =
    usePropertyValue(properties[ConfigProperty.MODERATION_NOTIFICATIONS_POLLING_PERIOD_SECONDS]) * 1000;
  const dispatch = useAppDispatch();
  const active = useAppSelector((state) => state.moderationNotificator.pollingActive);
  useEffect(() => {
    if (!active) {
      // should be disabled only for cypress in ideal life
      return;
    }
    let timeoutHandle: NodeJS.Timeout | null = null;
    let stopped = false;
    const act = () => {
      dispatch(checkForUnprocessedItemsAction()).then((result) => {
        if (stopped) {
          return;
        }
        if (checkForUnprocessedItemsAction.fulfilled.match(result)) {
          const message: PollingDoneMessage = {
            type: MessageType.POLLING_DONE,
            sourceTabId: tabId,
            notificationData: result.payload,
          };
          log(`Posting message: ${message.type}`);
          broadcastChannel.postMessage(message);
        }
        timeoutHandle = setTimeout(() => act(), pollingIntervalMs);
      });
    };
    act();
    return () => {
      stopped = true;
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
      }
    };
  }, [dispatch, active, broadcastChannel, tabId, pollingIntervalMs]);
  return null;
};

export default usePollModerationNotification;
