import { defaultForAllApps, newBooleanParameter, newNumberParameter } from "../builders";
import environment from "../environment";
import { ConfigProperty, PropertyGroupType } from "../types";

const group = PropertyGroupType.MODERATION_NOTIFICATIONS;

export default {
  [ConfigProperty.MODERATION_NOTIFICATIONS_ENABLED]: newBooleanParameter({
    property: ConfigProperty.MODERATION_NOTIFICATIONS_ENABLED,
    description: "Включить или выключить флоу модерационных нотификаций в целом",
    group,
    // they greatly interfere with tests so disable for tests by default
    defaultValues: defaultForAllApps(!environment.isInUiTestsContext),
  }),
  [ConfigProperty.MODERATION_NOTIFICATIONS_UNCHECKED_VIDEOS_THRESHOLD]: newNumberParameter({
    property: ConfigProperty.MODERATION_NOTIFICATIONS_UNCHECKED_VIDEOS_THRESHOLD,
    description: "Какое количество необработанных ВИДЕО триггерит модерационную нотификацию",
    group,
    defaultValues: defaultForAllApps(1),
  }),
  [ConfigProperty.MODERATION_NOTIFICATIONS_UNCHECKED_COMMENTS_THRESHOLD]: newNumberParameter({
    property: ConfigProperty.MODERATION_NOTIFICATIONS_UNCHECKED_COMMENTS_THRESHOLD,
    description: "Какое количество необработанных КОММЕНТАРИЕВ триггерит модерационную нотификацию",
    group,
    defaultValues: defaultForAllApps(5),
  }),
  [ConfigProperty.MODERATION_NOTIFICATIONS_UNCHECKED_COMPLAINTS_THRESHOLD]: newNumberParameter({
    property: ConfigProperty.MODERATION_NOTIFICATIONS_UNCHECKED_COMPLAINTS_THRESHOLD,
    description: "Какое количество необработанных ЖАЛОБ триггерит модерационную нотификацию",
    group,
    defaultValues: defaultForAllApps(1),
  }),
  [ConfigProperty.MODERATION_NOTIFICATIONS_POLLING_PERIOD_SECONDS]: newNumberParameter({
    property: ConfigProperty.MODERATION_NOTIFICATIONS_POLLING_PERIOD_SECONDS,
    description: "Период в секундах между запросами для модерационных нотификаций",
    group,
    defaultValues: defaultForAllApps(10),
  }),
  [ConfigProperty.MODERATION_NOTIFICATIONS_REQUEST_PAGE_SIZE]: newNumberParameter({
    property: ConfigProperty.MODERATION_NOTIFICATIONS_REQUEST_PAGE_SIZE,
    description: "Размер страницы в запросах для модерационных нотификаций",
    group,
    defaultValues: defaultForAllApps(200),
  }),
};
