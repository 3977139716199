import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store/store";

export interface KeyValueStorageState {
  persistentStorage: Record<string, any>;
  volatileStorage: Record<string, any>;
}

const initialState: KeyValueStorageState = {
  persistentStorage: {},
  volatileStorage: {},
};

const name = "keyValueStorage";

const keyValueStorageSlice = createSlice({
  name,
  initialState,
  reducers: {
    setKey: (state, action: PayloadAction<{ key: string; value: any; persistent?: boolean }>) => {
      const { key, value, persistent } = action.payload;
      if (persistent) {
        state.persistentStorage[key] = value;
      } else {
        state.volatileStorage[key] = value;
      }
    },
    removeKey: (state, action: PayloadAction<{ key: string; persistent?: boolean }>) => {
      const { key, persistent } = action.payload;
      if (persistent) {
        delete state.persistentStorage[key];
      } else {
        delete state.volatileStorage[key];
      }
    },
    dropKeysWithPrefix: (state, action: PayloadAction<string>) => {
      const prefix = action.payload;
      Object.keys(state.persistentStorage).forEach((key) => {
        if (key.startsWith(prefix)) {
          delete state.persistentStorage[key];
        }
      });
    },
  },
});

export const { setKey, removeKey, dropKeysWithPrefix } = keyValueStorageSlice.actions;

export default keyValueStorageSlice.reducer;

const getFromMap = <Type>(map: Record<string, any>, key: string, defaultValue: Type) => {
  if (key in map) {
    return map[key] as Type;
  }
  return defaultValue;
};

export const getPersistentValueForKey =
  <Type>(key: string, defaultValue: Type) =>
  (state: RootState): Type =>
    getFromMap(state.keyValueStorage.persistentStorage, key, defaultValue);

export const getValueForKey =
  <Type>(key: string, defaultValue: Type) =>
  (state: RootState): Type =>
    getFromMap(state.keyValueStorage.volatileStorage, key, defaultValue);
