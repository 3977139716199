import { environment } from "@config";
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useCallback } from "react";
import { FormattedMessage, MessageDescriptor, defineMessage } from "react-intl";

const defaultAllMenuItemMessage = defineMessage({ defaultMessage: "All" });

interface BetterSelectProps<Type> extends FormControlProps {
  possibleValues: Type[];
  value: null | Type | Type[];
  labelMessage: MessageDescriptor;
  idBase: string;
  multiple?: boolean;
  onChangeValue?: (value: Type) => void;
  onChangeValues?: (value: Type[]) => void; // when multiple is true
  parseValue?: (value: string) => Type;
  renderOption?: (value: Type) => React.ReactNode;
  renderValue?: (value: Type | Type[]) => React.ReactNode;
  onAllClick?: () => void;
  helperText?: React.ReactNode;
  allMenuItemMessage?: MessageDescriptor;
}

const BetterSelect = <Type,>({
  multiple = false,
  possibleValues,
  value,
  onChangeValue,
  onChangeValues,
  labelMessage,
  idBase,
  parseValue,
  renderOption,
  renderValue,
  onAllClick,
  helperText,
  allMenuItemMessage = defaultAllMenuItemMessage,
  ...formControlProps
}: BetterSelectProps<Type>) => {
  const onChange = useCallback(
    (e: SelectChangeEvent<Type | Type[] | string>) => {
      const { value } = e.target;
      if (value === "" && onAllClick) {
        onAllClick();
        return;
      }
      if (multiple) {
        if (onAllClick && Array.isArray(value) && value.length > 0) {
          const lastElement = value[value.length - 1];
          if (typeof lastElement === "string" && !lastElement) {
            onAllClick();
            return;
          }
        }
        if (!onChangeValues) {
          if (environment.isDevelopmentBuild) {
            // eslint-disable-next-line no-console
            console.warn(`Select ${idBase} is set to multiple, but onChangeValues is not provided!`);
          }
          return;
        }
        if (Array.isArray(value)) {
          onChangeValues(value);
        } else if (typeof value === "string") {
          onChangeValues(value.split(",") as unknown as Type[]);
        }
      } else {
        if (!onChangeValue || !parseValue) {
          if (environment.isDevelopmentBuild) {
            // eslint-disable-next-line no-console
            console.warn(`Select ${idBase} has no onChangeValue or no parseValue provided`);
          }
          return;
        }
        if (typeof value === "string") {
          onChangeValue(parseValue(value));
        } else {
          onChangeValue(value as Type);
        }
      }
    },
    [onChangeValue, onChangeValues, parseValue, onAllClick, idBase, multiple]
  );
  return (
    <FormControl size="small" {...formControlProps}>
      <InputLabel id={`${idBase}-label`}>
        <FormattedMessage {...labelMessage} />
      </InputLabel>
      <Select
        size="small"
        labelId={`${idBase}-label`}
        id={`${idBase}-select`}
        data-testid={`${idBase}-select`}
        multiple={multiple}
        value={value || ""}
        onChange={onChange}
        input={<OutlinedInput label={<FormattedMessage {...labelMessage} />} />}
        renderValue={renderValue}
      >
        {onAllClick && (
          <MenuItem value="" data-testid={`${idBase}-ALL`}>
            <i>
              <FormattedMessage {...allMenuItemMessage} />
            </i>
          </MenuItem>
        )}
        {possibleValues.map((x) => {
          const strVal = `${x}`;
          return (
            <MenuItem key={strVal} value={strVal} selected={value === x} data-testid={`${idBase}-${strVal}`}>
              {renderOption ? renderOption(x) : strVal}
            </MenuItem>
          );
        })}
      </Select>
      {helperText && <FormHelperText data-testid={`${idBase}-helper-text`}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

enum BoolOptions {
  any = "any",
  true = "true",
  false = "false",
}

interface BetterBoolSelectProps {
  value?: boolean | null;
  onChangeValue: (value: boolean | null) => void;
  idBase: string;
  labelMessage: MessageDescriptor;
  formControlProps?: FormControlProps;
  allowAny?: boolean;
}

const allOptions = Object.values(BoolOptions);
const onlyTrueFalseOptions = [BoolOptions.true, BoolOptions.false];
const parseValue = (value: string): BoolOptions => value as BoolOptions;
const renderBoolOption = (x: BoolOptions) => {
  switch (x) {
    case BoolOptions.any: {
      return <FormattedMessage defaultMessage="Any" />;
    }
    case BoolOptions.true: {
      return <FormattedMessage defaultMessage="Yes" />;
    }
    case BoolOptions.false: {
      return <FormattedMessage defaultMessage="No" />;
    }
  }
};

export const BetterBoolSelect = ({
  allowAny,
  value,
  onChangeValue,
  idBase,
  formControlProps,
  labelMessage,
}: BetterBoolSelectProps) => {
  const onChangeValueWrapper = useCallback(
    (option: BoolOptions) => {
      onChangeValue(option === BoolOptions.any ? null : BoolOptions.true === option);
    },
    [onChangeValue]
  );

  return (
    <BetterSelect
      possibleValues={allowAny ? allOptions : onlyTrueFalseOptions}
      value={value === undefined || value === null ? BoolOptions.any : (`${value}` as BoolOptions)}
      onChangeValue={onChangeValueWrapper}
      parseValue={parseValue}
      labelMessage={labelMessage}
      idBase={idBase}
      renderOption={renderBoolOption}
      {...formControlProps}
    />
  );
};

export default BetterSelect;
