import { UserApi, UserRole } from "@api/user";
import { composeUserLink } from "@widgets/LinkToUser";
import { defineMessage } from "react-intl";
import { SearchResult, SearchResultType, SearchStrategy } from "../models";

const ByUserName: SearchStrategy = async ({ query, currentUser }) => {
  if (query.startsWith("http")) {
    return [];
  }
  const response = await UserApi.list({ offset: 0, limit: 10, name: query });
  const defaultResult: SearchResult = {
    type: SearchResultType.LINK,
    adminUrl:
      currentUser.role === UserRole.SUPERVISOR
        ? `/supervisedUsers?tab=ALL&fullName=${query}`
        : `/userSearch?view=ALL&name=${query}`,
    message: defineMessage({ defaultMessage: "Search by name {value}" }),
    messageValues: { value: <b>{`"${query}"`}</b> },
  };
  const matchingUser =
    response?.users?.find((x) => x.name?.toLowerCase() === query.toLowerCase()) ||
    (response?.users?.length === 1 && response.users[0]);

  if (!matchingUser) {
    return [defaultResult];
  }
  return [
    {
      type: SearchResultType.USER,
      adminUrl: composeUserLink({ id: matchingUser.id!, appType: currentUser.appType }),
      user: matchingUser,
      message: defineMessage({ defaultMessage: "Open profile of {value}" }),
      messageValues: {
        value: (
          <>
            <b>{matchingUser?.nickName}</b> ({matchingUser.name})
          </>
        ),
      },
    },
    defaultResult,
  ];
};

export default ByUserName;
