import { UserApi } from "@api/user";
import { composeUserLink } from "@widgets/LinkToUser";
import { defineMessage } from "react-intl";
import { SearchResultType, SearchStrategy } from "../models";

const ByUserId: SearchStrategy = async ({ query, currentUser }) => {
  const userId = Number.parseInt(query);
  if (Number.isNaN(userId)) {
    return [];
  }
  const response = await UserApi.loadBatch([userId]);
  if (!response.users?.length) {
    return [];
  }
  const user = response.users[0];
  return [
    {
      type: SearchResultType.USER,
      adminUrl: composeUserLink({ id: user.id!, appType: currentUser.appType }),
      exactMatch: true,
      message: defineMessage({ defaultMessage: "Open profile of {value}" }),
      messageValues: {
        value: (
          <>
            <b>{user.nickName}</b> (#{userId})
          </>
        ),
      },
    },
  ];
};

export default ByUserId;
