import useCurrentRouteDescriptor from "@hooks/useCurrentRouteDescriptor";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import NavigationTabs from "@widgets/NavigationTabs";
import { defineMessages } from "react-intl";
import { ModerationTagsViewType } from "./types";

const tabMessages = defineMessages({
  [ModerationTagsViewType.NOTIFICATIONS]: { defaultMessage: "Notifications" },
  [ModerationTagsViewType.PUSHES]: { defaultMessage: "Pushes" },
  [ModerationTagsViewType.STATUS_MAPPING]: { defaultMessage: "Content Statuses" },
});

const ModerationTagsPageTitle = () => {
  const route = useCurrentRouteDescriptor();
  useSetDocumentTitle({ messageDescriptor: route!.title! });
  return (
    <NavigationTabs
      possibleValues={Object.values(ModerationTagsViewType)}
      tabQueryKey="view"
      tabMessagesMap={tabMessages}
    />
  );
};

export default ModerationTagsPageTitle;
