import { DELETE, GET, PATCH, POST, formDataWithFile } from "@utils/fetch";

export enum InterestStatus {
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
}

export interface InterestForAdminDto {
  id: number;
  position: number;
  name: string;
  status: InterestStatus;
  image?: string;
}

export interface InterestCreateDto {
  position: number;
  name: string;
  status: InterestStatus;
}

export type InterestUpdateDto = Partial<InterestCreateDto>;

export const UserInterestsApi = {
  list: (): Promise<InterestForAdminDto[]> => GET(`api/v1/admin/interests`),
  create: (request: InterestCreateDto): Promise<InterestForAdminDto> => POST(`api/v1/admin/interests`, request),
  update: (id: number, request: InterestUpdateDto): Promise<InterestForAdminDto> =>
    PATCH(`api/v1/admin/interests/${id}`, request),
  delete: (id: number) => DELETE(`api/v1/admin/interests/${id}`),
  uploadImage: (id: number, image: File): Promise<InterestForAdminDto> =>
    POST(`api/v1/admin/interests/${id}/image`, formDataWithFile("image", image)),
};
