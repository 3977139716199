import { POST } from "@utils/fetch";
import { CommentTriggeredPhrase } from "./commentsModeration";

export interface AsrPhrase {
  start: number;
  end: number;
  phrase: string;
  triggeredPhrases: CommentTriggeredPhrase[];
}

export interface AsrDtoResponse {
  id: string;
  createdAt: string;
  contentId: number;
  asrPhrases: AsrPhrase[];
}

export interface AsrListDtoResponse {
  asr: AsrDtoResponse[];
}

export const AsrApi = {
  getPhrasesForContents: (contentIds: number[]): Promise<AsrListDtoResponse> =>
    POST(`api/v1/admin/moderation-manager/asr/list`, { contentIds }),
};
