import { ConfigProperty, environment, properties, usePropertyValue } from "@config";
import useActualizedRef from "@hooks/useActualizedRef";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@mui/material";
import { LocalizableError, removeErrorsFromQueue } from "@slices/rejectedErrorsQueue";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { ErrorMessagePayloadFormat } from "@utils/fetch";
import { parseJson } from "@utils/json";
import { OptionsObject, SnackbarKey, useSnackbar } from "notistack";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import * as styles from "./RejectedErrorsReporter.module.scss";

export const safeParseErrorMessagePayload = (message: string): ErrorMessagePayloadFormat => {
  try {
    const parsed = parseJson(message);
    if (parsed.displayError) {
      return parsed as ErrorMessagePayloadFormat;
    }
    return {
      displayError: message,
    };
  } catch (e) {
    // not a valid json likely
    return {
      displayError: message,
    };
  }
};

const makeBaseSnackbarProps = (onClose: () => void): OptionsObject => ({
  variant: "error",
  action: (
    <Button size="small" variant="outlined" color="warning" onClick={onClose}>
      <FormattedMessage defaultMessage="Dismiss" />
    </Button>
  ),
});

const RejectedErrorsReporter = () => {
  const queue = useAppSelector((state) => state.rejectedErrorsQueue) as LocalizableError[];
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const autoHideMsProp = usePropertyValue(properties[ConfigProperty.SNACKBAR_AUTOHIDE_ERROR_MILLIS]);
  const autoHideMsRef = useActualizedRef(autoHideMsProp);

  useEffect(() => {
    if (!queue.length) {
      return;
    }
    dispatch(removeErrorsFromQueue(queue));
    if (environment.isInUiTestsContext) {
      return;
    }
    queue.forEach((item) => {
      // eslint-disable-next-line no-console
      console.log("Received error", item);
      if (item.localizedError) {
        const key: SnackbarKey = enqueueSnackbar(
          <FormattedMessage {...item.localizedError.message} values={item.localizedError.values} />,
          makeBaseSnackbarProps(() => closeSnackbar(key))
        );
        return;
      }
      if (!item.message) {
        return;
      }
      const payload = safeParseErrorMessagePayload(item.message);
      if (!payload.rawResponse) {
        const key: SnackbarKey = enqueueSnackbar(
          payload.displayError,
          makeBaseSnackbarProps(() => closeSnackbar(key))
        );
        return;
      }
      // setting manual timeout to prevent autoclose when accordion is expanded
      let timeoutHandle = setTimeout(() => closeSnackbar(key), autoHideMsRef.current!);
      const key = enqueueSnackbar(
        <Accordion
          disableGutters
          className={styles.accordion}
          onChange={(_, expanded) => {
            if (expanded) {
              clearTimeout(timeoutHandle);
            } else {
              timeoutHandle = setTimeout(() => closeSnackbar(key), autoHideMsRef.current!);
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            {payload.displayError}
          </AccordionSummary>
          <AccordionDetails>
            {Object.entries(payload.rawResponse).map(([k, v]) => (
              <div key={k}>{`${k}: ${v}`}</div>
            ))}
          </AccordionDetails>
        </Accordion>,
        {
          autoHideDuration: null,
          ...makeBaseSnackbarProps(() => {
            clearTimeout(timeoutHandle);
            closeSnackbar(key);
          }),
        }
      );
    });
  }, [dispatch, enqueueSnackbar, closeSnackbar, queue, autoHideMsRef]);
  return null;
};

export default RejectedErrorsReporter;
