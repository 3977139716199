import { defineMessages } from "react-intl";

export enum VideoModerationTabs {
  MODERATION = "MODERATION",
  LABELLING = "LABELLING",
  REPEATED = "REPEATED",
}

export const tabMessages = defineMessages({
  [VideoModerationTabs.MODERATION]: { defaultMessage: "Moderation" },
  [VideoModerationTabs.LABELLING]: { defaultMessage: "Labelling" },
  [VideoModerationTabs.REPEATED]: { defaultMessage: "Repeated" },
});
