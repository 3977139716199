import useActivateUser from "@hooks/useActivateUser";
import { Container, Paper } from "@mui/material";
import { LanguagePicker } from "./AppLocalizationProvider";
import LoginWidget from "./login/LoginWidget";
import * as styles from "./LoginPage.module.scss";

const LoginPage = () => {
  const activateUser = useActivateUser();
  return (
    <Container maxWidth="xs" data-testid="login-page">
      <LanguagePicker className={styles.languagePicker} />
      <Paper
        sx={{
          padding: "16px",
          marginTop: "64px",
        }}
      >
        <LoginWidget onLoginCompleted={activateUser} />
      </Paper>
    </Container>
  );
};

export default LoginPage;
