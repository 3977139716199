import { useAppSelector } from "@store/hooks";
import { useEffect } from "react";
import { MessageDescriptor, useIntl } from "react-intl";

export const DEFAULT_TITLE = "ESN";

export default ({
  messageDescriptor,
  values,
  plainText,
}: {
  messageDescriptor?: MessageDescriptor;
  values?: Record<string, string>;
  plainText?: string;
}) => {
  const intl = useIntl();
  const pollingActive = useAppSelector((state) => state.moderationNotificator.pollingActive);
  useEffect(() => {
    let message = "";
    if (!messageDescriptor) {
      message = plainText || DEFAULT_TITLE;
    } else {
      message = intl.formatMessage(messageDescriptor, values);
    }
    document.title = pollingActive ? `*${message}` : message;
  }, [intl, messageDescriptor, values, plainText, pollingActive]);
};
