import routes, { RouteDescriptor } from "@navigation/routesConfig";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export default (): RouteDescriptor | undefined => {
  const { pathname } = useLocation();
  return useMemo(() => {
    const path = pathname.substring(1);
    return routes.find((x) => x.path === path);
  }, [pathname]);
};
