import AppType from "@models/AppType";
import { allApps, newKillswitch } from "../builders";
import { ConfigProperty } from "../types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const release = ({ major = 23, minor, patch = 0 }: { major?: number; minor: number; patch?: number }) =>
  [major, minor, patch].join(".");

export default {
  [ConfigProperty.KS_COMMENTS_MODERATION_USER_STAT_RELEASED]: newKillswitch({
    property: ConfigProperty.KS_COMMENTS_MODERATION_USER_STAT_RELEASED,
    backendRelease: release({ minor: 50 }),
    backendTicket: "https://esnteam.atlassian.net/browse/WA-692",
    description: "Ручка со статистикой модерации комментов юзеров",
    apps: allApps,
  }),
  [ConfigProperty.KS_SUPER_COMPILATIONS_RELEASED]: newKillswitch({
    property: ConfigProperty.KS_SUPER_COMPILATIONS_RELEASED,
    backendRelease: release({ minor: 50 }),
    backendTicket: "https://esnteam.atlassian.net/browse/BACK-5586",
    description: "Создание подборок подборок в эксплоре",
    apps: [AppType.PUNCH],
  }),
  [ConfigProperty.KS_EXTERNAL_PLATFORM_GROUPS_RELEASED]: newKillswitch({
    property: ConfigProperty.KS_EXTERNAL_PLATFORM_GROUPS_RELEASED,
    backendRelease: release({ minor: 50 }),
    backendTicket: "https://esnteam.atlassian.net/browse/BACK-5674",
    description: "Группы для внешних платформ",
    apps: [AppType.PUNCH],
  }),
  [ConfigProperty.KS_COVER_TEXTS_MODERATION_RELEASED]: newKillswitch({
    property: ConfigProperty.KS_COVER_TEXTS_MODERATION_RELEASED,
    backendRelease: release({ minor: 50 }),
    backendTicket: "https://esnteam.atlassian.net/browse/BACK-5653",
    description: "Модерация модераторами текстов кавер-битов",
    apps: [AppType.PUNCH],
  }),
  [ConfigProperty.KS_EXPLORE_SECTION_BUTTONS_MY_TEXT_TYPE_RELEASED]: newKillswitch({
    property: ConfigProperty.KS_EXPLORE_SECTION_BUTTONS_MY_TEXT_TYPE_RELEASED,
    backendRelease: release({ minor: 51 }),
    backendTicket: "https://esnteam.atlassian.net/browse/BACK-5688",
    description: "тип кнопки MY_TEXT в эксплоре",
    apps: [AppType.PUNCH],
  }),
  [ConfigProperty.KS_BEAT_COMPILATIONS_ATTRIBUTES_RELEASED]: newKillswitch({
    property: ConfigProperty.KS_BEAT_COMPILATIONS_ATTRIBUTES_RELEASED,
    backendRelease: release({ minor: 51 }),
    backendTicket: "https://esnteam.atlassian.net/browse/BACK-5689",
    description: "Атрибуты у подборок",
    apps: [AppType.PUNCH],
  }),
};
