import { OffsetLimit, ResponseDto, SortingSortByOptional } from "@api";
import { DELETE, GET, PATCH, POST, buildQuery } from "@utils/fetch";

export interface UniversityAdminDto {
  id: number;
  name: string;
  position: number;
}

export interface UniversitiesFilterType {
  name?: string | null;
}

export const UniversitiesApi = {
  list: (
    request: UniversitiesFilterType & OffsetLimit & SortingSortByOptional
  ): Promise<ResponseDto<UniversityAdminDto>> => GET(`api/v1/admin/universities?${buildQuery(request)}`),
  create: ({ name, position }: { name: string; position: number }): Promise<UniversityAdminDto> =>
    POST(`api/v1/admin/universities`, { name, position }),
  update: ({ id, name, position }: { id: number; name: string; position: number }): Promise<UniversityAdminDto> =>
    PATCH(`api/v1/admin/universities/${id}`, { name, position }),
  delete: (id: number) => DELETE(`api/v1/admin/universities/${id}`),
};
