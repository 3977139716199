import { useAppSelector } from "@store/hooks";
import { RootState } from "@store/store";
import { useMemo } from "react";
import { createSelector } from "reselect";
import { AllowedValueTypes, ConfigProperty, ConfigPropertyDescriptor } from "./types";

export const usePropertyValue = <Type extends AllowedValueTypes>(property: ConfigPropertyDescriptor<Type>): Type =>
  useAppSelector(property.selector);

export const useResolvedKillswitches = (
  propertiesSource: (ConfigPropertyDescriptor<boolean> | { killswitch?: ConfigPropertyDescriptor<boolean> })[]
): Partial<Record<ConfigProperty, boolean>> => {
  const selector = useMemo(
    () =>
      createSelector(
        (state: RootState) => state.appSettings.runtimeConfig,
        (state: RootState) => state.user.currentUser?.appType,
        (config, appType) =>
          propertiesSource.reduce(
            (a, x) => {
              if ("killswitch" in x && x.killswitch) {
                a[x.killswitch.property] = x.killswitch.selectorImpl(config, appType);
              }
              if ("selector" in x && "property" in x) {
                a[x.property] = x.selectorImpl(config, appType);
              }
              return a;
            },
            {} as Partial<Record<ConfigProperty, boolean>>
          )
      ),
    [propertiesSource]
  );
  return useAppSelector(selector);
};

/** properties list change is ignored, so memoization can be skipped */
export const usePropertyValues = <Type extends AllowedValueTypes>(
  properties: ConfigPropertyDescriptor<Type>[]
): Partial<Record<ConfigProperty, Type>> => {
  const selector = useMemo(
    () =>
      createSelector(
        (state: RootState) => state.appSettings.runtimeConfig,
        (state: RootState) => state.user.currentUser?.appType,
        (config, appType) =>
          properties.reduce(
            (a, x) => {
              a[x.property] = x.selectorImpl(config, appType);
              return a;
            },
            {} as Partial<Record<ConfigProperty, Type>>
          )
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return useAppSelector(selector);
};
