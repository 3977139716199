import { ChildSortingType, Pageable } from "@api";
import {
  AssignedModerationStatus,
  ContentModerationApi,
  ContentSubtype,
  ModerationContentFilter,
  ModerationContentSorting,
} from "@api/contentModeration";
import { ConfigProperty, properties } from "@config";
import AppType from "@models/AppType";
import PlayerMode from "@models/PlayerMode";
import { PayloadAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkApiType } from "@store/store";
import { sortBy } from "lodash";
import { loadContentsToCache } from "./contentCache";
import { loadContentPromotionsAction } from "./contentPromotionsCache";
import { getCurrentUserAppType } from "./user";
import { loadUsersToCache } from "./usersCache";
import { predefinedFilterPart } from "./videoModeration";

export interface ContentLabellingFilterType extends ModerationContentFilter {
  needPrepopulateExcludedInternalTagIds: boolean; // ugly but don't wanna hardcode ids in config anymore
}

const defaultFilter: ContentLabellingFilterType = {
  moderationStatuses: [AssignedModerationStatus.APPROVED, AssignedModerationStatus.AUTO_APPROVED_WHITELIST],
  tagIds: [],
  internalTagIds: [],
  excludedInternalTagIds: [],
  badgeIds: [],
  needPrepopulateExcludedInternalTagIds: true,
};

export interface ContentLabellingState {
  contentIds: number[];
  currentRequestId: string | null;
  totalCount: number | null;
  focusedContentId: number | null;
  isLoading: boolean;
  currentPagination: Pageable;
  filtersMap: Record<AppType, ContentLabellingFilterType>;
  sortingsMap: Record<AppType, ModerationContentSorting>;
  playerMode: PlayerMode;
  staticPreviewEnabled: boolean;
}
const defaultSorting: ModerationContentSorting = {
  createdAtSortDirection: ChildSortingType.ASC,
  ratingSortDirection: null,
  originsFirst: false,
  nudityFirst: false,
  childFirst: false,
  childTenFirst: false,
  childFourteenFirst: false,
};

const initialState: ContentLabellingState = {
  contentIds: [],
  currentRequestId: null,
  focusedContentId: null,
  totalCount: null,
  isLoading: false,
  currentPagination: { page: 0, size: 10 },
  filtersMap: {
    [AppType.PUNCH]: defaultFilter,
    [AppType.CHIPZ]: { ...defaultFilter, contentSubtype: ContentSubtype.original },
  },
  sortingsMap: {
    [AppType.PUNCH]: defaultSorting,
    [AppType.CHIPZ]: defaultSorting,
  },
  playerMode: PlayerMode.HLS,
  staticPreviewEnabled: true,
};

const name = "contentLabelling";

export const loadContentLabellingListAction = createAsyncThunk<
  { contentIds: number[]; total: number },
  Pageable,
  ThunkApiType
>(`${name}/loadContentLabellingListAction`, async (pageable, thunkApi) => {
  const state = thunkApi.getState();
  const appType = getCurrentUserAppType(state);
  const filter = state.contentLabelling.filtersMap[appType];
  const sorting = state.contentLabelling.sortingsMap[appType];
  const response = await ContentModerationApi.listForModeration({
    filter: {
      ...predefinedFilterPart,
      moderationStatuses: filter.moderationStatuses,
      ...(filter.tagIds?.length && { tagIds: filter.tagIds }),
      ...(filter.internalTagIds?.length && { internalTagIds: filter.internalTagIds }),
      ...(filter.excludedInternalTagIds?.length && { excludedInternalTagIds: filter.excludedInternalTagIds }),
      ...(filter.badgeIds?.length && { badgeIds: filter.badgeIds }),
      ...(appType === AppType.CHIPZ && filter.contentSubtype && { contentSubtype: filter.contentSubtype }),
    },
    sorting: {
      ...sorting,
      originsFirst: appType === AppType.CHIPZ && sorting.originsFirst,
    },
    pagination: pageable,
  });
  const contentIds = (response.content || []).map((x) => x.contentId).filter((x) => x) as number[];
  const userIds = (response.content || []).map((x) => x.author?.id).filter((x) => x) as number[];
  await Promise.all([
    thunkApi.dispatch(loadContentsToCache({ ids: contentIds, checkExisting: false })),
    thunkApi.dispatch(loadUsersToCache({ ids: userIds, checkExisting: true })),
  ]);
  thunkApi.dispatch(loadContentPromotionsAction({ ids: contentIds }));
  return { contentIds, total: response.totalElements || 0 };
});

export const prepopulateContentLabellingFilterWithExcludeTagIdAction = createAsyncThunk<void, number, ThunkApiType>(
  `${name}/prepopulateContentLabellingFilterWithExcludeTagIdAction`,
  (internalTagId, thunkApi) => {
    const state = thunkApi.getState();
    const appType = getCurrentUserAppType(state);
    const filter = state.contentLabelling.filtersMap[appType];
    if (filter.needPrepopulateExcludedInternalTagIds) {
      thunkApi.dispatch(
        setContentLabellingFilterAction({
          appType,
          filter: { ...filter, needPrepopulateExcludedInternalTagIds: false, excludedInternalTagIds: [internalTagId] },
        })
      );
    }
  }
);

const contentLabellingSlice = createSlice({
  name,
  initialState,
  reducers: {
    setContentLabellingFilterAction: (
      state,
      action: PayloadAction<{ appType: AppType; filter: ContentLabellingFilterType }>
    ) => {
      const { appType, filter } = action.payload;
      state.filtersMap[appType] = filter;
    },
    setContentLabellingSortingsAction: (
      state,
      action: PayloadAction<{ appType: AppType; sorting: ModerationContentSorting }>
    ) => {
      const { appType, sorting } = action.payload;
      state.sortingsMap[appType] = sorting;
    },
    setContentLabellingStaticPreviewModeAction: (state, action: PayloadAction<boolean>) => {
      state.staticPreviewEnabled = action.payload;
    },
    setContentLabellingPlayerModeAction: (state, action: PayloadAction<PlayerMode>) => {
      state.playerMode = action.payload;
    },
    setContentLabellingFocusedContentIdAction: (state, action: PayloadAction<number>) => {
      state.focusedContentId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadContentLabellingListAction.pending, (state, action) => {
      state.currentRequestId = action.meta.requestId;
      state.currentPagination = action.meta.arg;
      state.isLoading = true;
    });
    builder.addCase(loadContentLabellingListAction.rejected, (state, action) => {
      if (action.meta.requestId !== state.currentRequestId) {
        return;
      }
      state.isLoading = false;
    });
    builder.addCase(loadContentLabellingListAction.fulfilled, (state, action) => {
      if (action.meta.requestId !== state.currentRequestId) {
        return;
      }
      state.contentIds = action.payload.contentIds;
      state.totalCount = action.payload.total;
      state.isLoading = false;
    });
  },
});

export const {
  setContentLabellingFilterAction,
  setContentLabellingSortingsAction,
  setContentLabellingStaticPreviewModeAction,
  setContentLabellingPlayerModeAction,
  setContentLabellingFocusedContentIdAction,
} = contentLabellingSlice.actions;

export const getCurrentFilterAndSortingSelector = createSelector(
  (state: RootState) => getCurrentUserAppType(state),
  (state: RootState) => state.contentLabelling,
  (app, labellingState) => ({
    filter: labellingState.filtersMap[app],
    sorting: labellingState.sortingsMap[app],
  })
);

export const getSpecialInternalTagSelector = createSelector(
  (state: RootState) => state.internalTags.tags,
  properties[ConfigProperty.CONTENT_LABELLING_SPECIAL_TAG_NAME].selector,
  properties[ConfigProperty.CONTENT_LABELLING_SPECIAL_TAG_DISPLAY_NAME].selector,
  (tags, specialTagName, specialTagDisplayName) => {
    const tag = tags.find((x) => (x.name || "").toLowerCase() === specialTagName.toLowerCase());
    if (tag) {
      return { ...tag, name: specialTagDisplayName };
    }
    return tag;
  }
);

export const getInternalTagsOrderedForFilterSelector = createSelector(
  (state: RootState) => state.internalTags.tags,
  getSpecialInternalTagSelector,
  (tags, specialTag) => {
    const sortedTags = sortBy(tags, "name");
    if (!specialTag) {
      return sortedTags;
    }
    return [specialTag, ...sortedTags.filter((x) => x.id !== specialTag.id)];
  }
);

export const getDefaultFilterSelector = createSelector(
  (state: RootState) => getCurrentUserAppType(state),
  getSpecialInternalTagSelector,
  (app, specialTag) => {
    const result: ContentLabellingFilterType = {
      ...defaultFilter,
      ...(app === AppType.CHIPZ && { contentSubtype: ContentSubtype.original }),
      ...(specialTag && {
        excludedInternalTagIds: [specialTag.id],
        needPrepopulateExcludedInternalTagIds: false,
      }),
    };
    return result;
  }
);

export default contentLabellingSlice.reducer;
