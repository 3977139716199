import useActualizedRef from "@hooks/useActualizedRef";
import useLazyHowl from "@hooks/useLazyHowl";
import AppType, { appTypeToDisplayName } from "@models/AppType";
import { DEFAULT_SOUND_NAME, notificationSoundNames } from "@slices/moderationNotificator";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { difference, uniq } from "lodash";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import useEnsureValidModeratorUser from "./useEnsureValidModeratorUser";
import { log, notificationTypeToMessage, routeToNotificationType, useResolvedNotificationThresholds } from "./utils";

const useProcessModerationNotifications = () => {
  const dispatch = useAppDispatch();
  const getHowl = useLazyHowl();
  const { loadedData, previouslyLoadedData, settings, pollingActive, soundVolume } = useAppSelector(
    (state) => state.moderationNotificator
  );
  const settingsRef = useActualizedRef(settings);
  const ensureValidUser = useEnsureValidModeratorUser();
  const soundVolumeRef = useActualizedRef(soundVolume);

  const navigate = useNavigate();
  const intl = useIntl();
  const shownNotificationsRef = useRef<Notification[]>([]);
  const activeRef = useActualizedRef(pollingActive);

  const resolvedThresholds = useResolvedNotificationThresholds();

  useEffect(() => {
    if (!activeRef.current) {
      // using ref cuz don't want to re-fire on activation
      return;
    }
    const settings = settingsRef.current!;
    const candidatesForNotification = loadedData.filter((x) => {
      if (!x.itemIds.length) {
        return false;
      }
      const previousData = previouslyLoadedData.find((p) => p.appType === x.appType && p.contentType === x.contentType);
      if (!previousData) {
        // don't shout on page load
        return false;
      }
      const newItemIds = difference(x.itemIds, previousData.itemIds);
      if (newItemIds.length) {
        log(`New item ids: ${newItemIds} for type ${x.contentType} in app ${x.appType}`);
      }
      return newItemIds.length > 0;
    });
    if (!candidatesForNotification.length) {
      return;
    }
    const soundsToPlay = candidatesForNotification
      .map((x) => {
        const setting = settings[x.contentType];
        const threshold = resolvedThresholds[x.contentType];
        const shouldPlay = setting.sound && x.itemIds.length >= threshold;
        if (!shouldPlay) {
          return null;
        }
        return setting.soundName && notificationSoundNames.includes(setting.soundName)
          ? setting.soundName
          : DEFAULT_SOUND_NAME;
      })
      .filter((x) => x) as string[];

    if (soundsToPlay.length) {
      const uniqueSounds = uniq(soundsToPlay);
      log(`Will play sounds: ${uniqueSounds.join(", ")}`);
      uniqueSounds.forEach((x) => {
        const howl = getHowl(x);
        howl.volume((soundVolumeRef.current || 100) / 100);
        howl.play();
      });
    }

    if (Notification.permission !== "granted") {
      return;
    }
    candidatesForNotification.forEach((x) => {
      const setting = settings[x.contentType];
      if (!setting.notification) {
        log(`Notification disabled for ${x.contentType} - skipping`);
        return;
      }
      const threshold = resolvedThresholds[x.contentType];
      if (x.itemIds.length < threshold) {
        log(
          `Amount of unchecked items ${x.itemIds.length} is less than threshold (${threshold}) for ${x.contentType} (${x.appType})`
        );
        return;
      }
      try {
        log(`Will show notification for: ${x.contentType} in ${x.appType}`);
        const notification = new Notification(appTypeToDisplayName[x.appType], {
          body: `${intl.formatMessage(notificationTypeToMessage[x.contentType])}: ${x.itemIds.length}`,
          tag: `${x.appType}${x.contentType}`,
          renotify: true,
          icon: `${x.appType === AppType.PUNCH ? "punch.webp" : "spich.png"}`,
          requireInteraction: true,
          silent: true,
        });
        notification.onclick = () => {
          log(
            `Notification for ${x.contentType} (${x.appType}) clicked, will route to ${
              routeToNotificationType[x.contentType]
            }`
          );
          notification.close();
          window.focus();
          ensureValidUser(x);
          navigate(routeToNotificationType[x.contentType]);
        };
        notification.onclose = () => {
          shownNotificationsRef.current = shownNotificationsRef.current.filter((x) => x !== notification);
        };
        shownNotificationsRef.current.push(notification);
      } catch (e) {
        log(`Failed to post notification: ${e}`);
      }
    });
  }, [
    loadedData,
    previouslyLoadedData,
    settingsRef,
    getHowl,
    soundVolumeRef,
    activeRef,
    dispatch,
    navigate,
    ensureValidUser,
    intl,
    resolvedThresholds,
  ]);
  useEffect(() => {
    window.addEventListener("unload", () => {
      shownNotificationsRef.current.forEach((x) => {
        try {
          x.close();
        } catch (e) {
          log("Failed to close notifications on unload");
        }
      });
    });
  });
  return null;
};

export default useProcessModerationNotifications;
