import { SerializedError } from "@reduxjs/toolkit";
import { MessageDescriptor } from "react-intl";

const REMOVE_FROM_QUEUE = "rejectedErrorsQueue/remove";
const ADD_TO_QUEUE = "rejectedErrorsQueue/add";

export interface LocalizedErrorMessage {
  message: MessageDescriptor;
  values?: any;
}

export interface LocalizableError extends SerializedError {
  localizedError?: LocalizedErrorMessage;
}

const initialState: LocalizableError[] = [];

const reducer = (state = initialState, action: any) => {
  if (action.type && action.type.endsWith("/rejected") && action.error) {
    return [...state, action.error as LocalizableError];
  }
  if (action.type === REMOVE_FROM_QUEUE) {
    const items = action.payload;
    return state.filter((x) => !items.includes(x));
  }
  if (action.type === ADD_TO_QUEUE) {
    return [...state, action.payload];
  }
  return state;
};

export const removeErrorsFromQueue = (items: LocalizableError[]) => ({ type: REMOVE_FROM_QUEUE, payload: items });

export const addErrorToQueue = (item: LocalizableError) => ({ type: ADD_TO_QUEUE, payload: item });

export default reducer;
