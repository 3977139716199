import { capitalize, random, sample } from "lodash";

export const maxNicknameLength = 20;

export const validNicknameSymbolsRegex = /^[A-Za-z0-9_.]+$/;

const adjectives = ["trendy", "nice", "super", "rock", "music", "awesome"];
const nouns = ["panda", "bear", "cat", "chameleon", "wolf", "lion", "elephant"];
const maxNumber = 65535;

/**
 * @returns smth like trendyElephant53013
 */
export const generateRandomNickname = () =>
  `${sample(adjectives)}${capitalize(sample(nouns))}${random(maxNumber)}`.substring(0, maxNicknameLength);
