import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import SafeTranslatedEnum from "./SafeTranslatedEnum";

const EnumTable = ({ values, translations }: { values: string[]; translations: Record<string, MessageDescriptor> }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage defaultMessage="Value" />
          </TableCell>
          <TableCell>
            <FormattedMessage defaultMessage="Description" />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {values.map((value) => (
          <TableRow key={value}>
            <TableCell>{value}</TableCell>
            <TableCell>
              <SafeTranslatedEnum value={value} translationsMap={translations} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default EnumTable;
