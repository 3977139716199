import { ConfigProperty, properties, usePropertyValue } from "@config";
import { pollingPreconditionSelector } from "@slices/moderationNotificator";
import { useAppSelector } from "@store/hooks";
import { useEffect, useState } from "react";
import useNegotiator from "./useNegotiator";
import usePollModerationNotification from "./usePollModerationNotifications";
import useProcessModerationNotifications from "./useProcessModerationNotifications";

const channelName = "moderation-notificator";

const ModerationNotificationManager = () => {
  const [broadcastChannel] = useState(new BroadcastChannel(channelName));
  const [tabId] = useState(Date.now());
  useEffect(() => {
    return () => {
      broadcastChannel.close();
    };
  }, [broadcastChannel]);
  useNegotiator({ tabId, broadcastChannel });
  useProcessModerationNotifications();
  usePollModerationNotification({ tabId, broadcastChannel });
  return null;
};

export default () => {
  const canPoll = useAppSelector(pollingPreconditionSelector);
  const enabled = usePropertyValue(properties[ConfigProperty.MODERATION_NOTIFICATIONS_ENABLED]);
  if (!enabled || !canPoll || !("Notification" in window)) {
    return null;
  }
  return <ModerationNotificationManager />;
};
