import { defineMessage, MessageDescriptor } from "react-intl";

export interface CheckResult {
  message: MessageDescriptor;
  messageValues?: any;
  passed: boolean;
  type: string;
}

type PasswordChecker = (password: string) => CheckResult;

// exported for testing
const minLengthCheck: PasswordChecker = (password) => {
  const minLength = 8;
  return {
    type: "minLengthCheck",
    message: defineMessage({ defaultMessage: "Min length - {num} symbols" }),
    messageValues: { num: minLength },
    passed: password.length >= minLength,
  };
};

const upperAndLowerCaseCheck: PasswordChecker = (password) => {
  return {
    type: "upperAndLowerCaseCheck",
    message: defineMessage({ defaultMessage: "Should contain both uppercase and lowercase latin letters" }),
    passed: /[A-Z]/.test(password) && /[a-z]/.test(password),
  };
};

const digitsCheck: PasswordChecker = (password) => {
  return {
    type: "digitsCheck",
    message: defineMessage({ defaultMessage: "Should contain at least one digit" }),
    passed: /\d/.test(password),
  };
};

const specialSymbols = [
  [32, 47],
  [58, 64],
  [91, 96],
  [123, 126],
]
  .map((range) => {
    const chars = [];
    for (let i = range[0]; i <= range[1]; i++) {
      chars.push(String.fromCharCode(i));
    }
    return chars;
  })
  .flatMap((x) => x);

const specialSymbolsCheck: PasswordChecker = (password) => {
  return {
    type: "specialSymbolsCheck",
    message: defineMessage({ defaultMessage: "Should contain at least one special symbol: {symbols}" }),
    messageValues: { symbols: specialSymbols.map((x) => (x === " " ? "Space" : x)).join("") },
    passed: !!specialSymbols.find((x) => password.includes(x)),
  };
};

const checkers: ((password: string) => CheckResult)[] = [
  minLengthCheck,
  upperAndLowerCaseCheck,
  digitsCheck,
  specialSymbolsCheck,
];

export const checkPasswordStrength = (password: string): CheckResult[] => checkers.map((x) => x(password));

/**
 * See java CharUtils
 * public static boolean isAsciiPrintable(final char ch) {
        return ch >= 32 && ch < 127;
    } 
 */
export const isValidPasswordString = (password: string): boolean => {
  for (let i = 0; i < password.length; i++) {
    const char = password.charCodeAt(i);
    if (char < 32 || char >= 127) {
      return false;
    }
  }
  return true;
};

export const allPasswordChecksPassed = (checks: CheckResult[]) => !checks.find((x) => !x.passed);
