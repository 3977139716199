import { ExternalPlatformDto, ExternalWebLinkGroupDto, WeblinksApi } from "@api/webLinks";
import { ConfigProperty, properties } from "@config";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, ThunkApiType } from "@store/store";

export interface ExternalPlatformsState {
  list: ExternalPlatformDto[];
  groups: ExternalWebLinkGroupDto[];
}

const initialState: ExternalPlatformsState = {
  list: [],
  groups: [],
};

const name = "externalPlatforms";

export const refreshExternalPlatformsListAction = createAsyncThunk(
  `${name}/refreshExternalPlatformsListAction`,
  async () => {
    const platforms = await WeblinksApi.listExternalPlatforms();
    return platforms;
  }
);

export const refreshExternalPlatformGroupsAction = createAsyncThunk<ExternalWebLinkGroupDto[], void, ThunkApiType>(
  `${name}/refreshExternalPlatformGroupsAction`,
  async () => {
    return await WeblinksApi.getWeblinkGroups();
  },
  {
    condition: (_, thunkApi) =>
      properties[ConfigProperty.KS_EXTERNAL_PLATFORM_GROUPS_RELEASED].selector(thunkApi.getState()),
  }
);

const externalPlatformsSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refreshExternalPlatformsListAction.fulfilled, (state, action) => {
      state.list = action.payload;
    });
    builder.addCase(refreshExternalPlatformGroupsAction.fulfilled, (state, action) => {
      state.groups = action.payload;
    });
  },
});

export default externalPlatformsSlice.reducer;

export const getExternalPlaformsMapSelector = createSelector(
  (state: RootState) => state.externalPlatforms.list,
  (list) =>
    list.reduce(
      (a, x) => {
        a[x.id] = x;
        return a;
      },
      {} as Record<number, ExternalPlatformDto>
    )
);

export const getExternalPlaformsIdsListSelector = createSelector(
  (state: RootState) => state.externalPlatforms.list,
  (list) => list.map((x) => x.id)
);

export const getRegexpsForExternalPlatformsSelector = createSelector(
  (state: RootState) => state.externalPlatforms.list,
  (platforms) =>
    platforms.reduce(
      (a, x) => {
        a[x.id] = (x.linkPatterns ?? []).map((x) => new RegExp(x.regexp, "gmi"));
        return a;
      },
      {} as Record<number, RegExp[]>
    )
);
