import { ContentPunchAdminDto } from "@api/audioContent";
import { ContentShortChipzAdminDto } from "@api/videoContent";
import AppType from "@models/AppType";
import { composeContentLink } from "@widgets/LinkToContent";
import { MessageDescriptor, defineMessage } from "react-intl";
import { SearchResult, SearchResultType } from "./models";

export const makeMessageSearchResult = ({
  message,
  messageValues,
}: {
  message: MessageDescriptor;
  messageValues?: any;
}): SearchResult => ({
  type: SearchResultType.MESSAGE,
  adminUrl: "",
  message,
  messageValues,
});

export const makeCopiableSearchResult = ({
  message,
  messageValues,
  copyValue,
}: {
  message: MessageDescriptor;
  messageValues?: any;
  copyValue: string;
}): SearchResult => ({
  type: SearchResultType.COPIABLE,
  adminUrl: "",
  message,
  messageValues,
  copyValue,
});

export const makeContentSearchResult = ({
  contentId,
  content,
  appType,
}: {
  contentId: number | string;
  content: ContentShortChipzAdminDto | ContentPunchAdminDto | undefined;
  appType: AppType;
}) => ({
  type: SearchResultType.CONTENT,
  adminUrl: composeContentLink({ id: typeof contentId === "number" ? contentId : Number.parseInt(contentId), appType }),
  exactMatch: true,
  ...(content
    ? {
        message: defineMessage({ defaultMessage: "Open UGC #{id} by {nickname}" }),
        messageValues: { id: content.id, nickname: <b>{content.user?.nickName || content.user?.id}</b> },
      }
    : {
        message: defineMessage({ defaultMessage: "Open UGC {id}" }),
        messageValues: { id: <b>#{contentId}</b> },
      }),
});
