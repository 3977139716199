import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { defineMessage, FormattedMessage, MessageDescriptor } from "react-intl";

interface PasswordTextFieldProps {
  id: string;
  password: string;
  setPassword: (p: string) => void;
  autoComplete?: string;
  labelMessage?: MessageDescriptor;
}

const defaultLabelMessage = defineMessage({ defaultMessage: "Password" });

const PasswordTextField = ({
  id,
  password,
  setPassword,
  labelMessage = defaultLabelMessage,
  autoComplete = "current-password",
}: PasswordTextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = useCallback(() => setShowPassword((current) => !current), []);
  const onPasswordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
    [setPassword]
  );
  return (
    <TextField
      id={id}
      data-testid={id}
      autoComplete={autoComplete}
      type={showPassword ? "text" : "password"}
      value={password}
      onChange={onPasswordChange}
      label={<FormattedMessage {...labelMessage} />}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShowPassword}
              edge="end"
              data-testid="password-visibility-toggle"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordTextField;
