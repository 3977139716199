import { useResolvedKillswitches } from "@config";
import { useActIfNavigationAllowed } from "@hooks/useBlockNavigation";
import useDefaultNetworkErrorHandler from "@hooks/useDefaultNetworkErrorHandler";
import useLoader from "@hooks/useLoader";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, ListItemText, Menu, MenuItem, TextField } from "@mui/material";
import { useAppSelector } from "@store/hooks";
import copy from "copy-to-clipboard";
import { sortBy } from "lodash";
import { useSnackbar } from "notistack";
import React, { useCallback, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SearchResult, SearchResultType, typePriority } from "./models";
import strategies, { strategiesRelatedKillswitches } from "./strategies";

const fieldName = uuidv4();

const OmniSearchBox = ({
  className,
  fullWidth = false,
  autoFocus = false,
}: {
  className?: string;
  fullWidth?: boolean;
  autoFocus?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const textFieldRef = useRef(null);
  const { currentUser, availableUsers } = useAppSelector((state) => state.user);
  const [inputValue, setInputValue] = useState("");
  const [query, setQuery] = useState("");
  const errorHandler = useDefaultNetworkErrorHandler();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const resolvedKillswitches = useResolvedKillswitches(strategiesRelatedKillswitches);
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAnchorEl(null);
    setInputValue(e.target.value);
  }, []);

  const onCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onSearchClick = useCallback(() => {
    setAnchorEl(textFieldRef.current);
    setQuery(inputValue);
  }, [inputValue]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.code === "Enter") {
        if (inputValue) {
          setAnchorEl(e.currentTarget);
          setQuery(inputValue);
        }
      }
    },
    [inputValue]
  );

  const resetState = useCallback(() => {
    onCloseMenu();
    setQuery("");
  }, [onCloseMenu]);
  const loader = useCallback(
    (query: string) => {
      if (!query) {
        return Promise.resolve([]);
      }
      return Promise.all(
        strategies.map((s) =>
          s({
            currentUser: currentUser!,
            availableUsers,
            query,
            resolvedKillswitches,
          }).catch((e) => {
            errorHandler(e);
            return [] as SearchResult[];
          })
        )
      ).then((data) => {
        const results = sortBy(
          data.flatMap((x) => x).map((x) => ({ ...x, uid: uuidv4() })),
          (x) => x.exactMatch,
          (x) => typePriority[x.type]
        );
        return results;
      });
    },
    [currentUser, availableUsers, resolvedKillswitches, errorHandler]
  );
  const {
    state: { value: result, isLoading },
  } = useLoader({
    params: query,
    loader,
    initialValue: [],
  });

  const navigate = useNavigate();
  const navigationGuard = useActIfNavigationAllowed();

  return (
    <>
      <TextField
        data-testid="omni-search-box"
        inputRef={textFieldRef}
        className={className}
        size="small"
        value={inputValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        fullWidth={fullWidth}
        autoFocus={autoFocus}
        name={fieldName}
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={!inputValue || isLoading}
                size="small"
                color="secondary"
                onClick={onSearchClick}
                data-testid="omni-search-button"
              >
                {isLoading ? <HourglassBottomIcon /> : <SearchIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        autoFocus
        onClose={resetState}
        MenuListProps={{ dense: true }}
        data-testid="omni-search-result"
      >
        {!!anchorEl && !result.length && !isLoading && (
          <MenuItem onClick={resetState}>
            <ListItemText>
              <FormattedMessage defaultMessage="Nothing found" />
            </ListItemText>
          </MenuItem>
        )}
        {!!anchorEl &&
          result.map((item) => (
            <MenuItem
              key={`${item.uid}`}
              onClick={() => {
                if (item.adminUrl) {
                  navigationGuard(() => {
                    navigate(item.adminUrl);
                    resetState();
                  });
                } else if (item.copyValue && item.type === SearchResultType.COPIABLE) {
                  copy(item.copyValue);
                  enqueueSnackbar(
                    <FormattedMessage defaultMessage="Copied: {copyValue}" values={{ copyValue: item.copyValue }} />,
                    {
                      variant: "success",
                    }
                  );
                }
              }}
            >
              <ListItemText>
                <FormattedMessage {...item.message} values={item.messageValues} />
              </ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default OmniSearchBox;
