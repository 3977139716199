import { ApplicationType, appTypeToApplicationContentType } from "@api";
import { ContentPromotionDto, ContentPromotionType, ContentPromotionsApi } from "@api/contentPromotions";
import { ConfigProperty, properties } from "@config";
import AppType from "@models/AppType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { addDurationToDate } from "@utils/duration";
import { DEFAULT_PROMO_GROUP } from "@utils/promotions";
import { groupBy } from "lodash";
import { getCurrentUserAppType } from "./user";

export interface ContentPromotionsCacheState {
  [ApplicationType.VIDEO]: Record<number, ContentPromotionDto[]>;
  [ApplicationType.AUDIO]: Record<number, ContentPromotionDto[]>;
}

const initialState: ContentPromotionsCacheState = {
  [ApplicationType.VIDEO]: {},
  [ApplicationType.AUDIO]: {},
};

const name = "contentPromotionsCache";

export const loadContentPromotionsAction = createAsyncThunk<
  Partial<ContentPromotionsCacheState>,
  { ids: number[] },
  { state: RootState }
>(`${name}/loadContentPromotions`, async ({ ids }, thunkApi) => {
  const appType = thunkApi.getState().user.currentUser!.appType;
  const applicationType = appTypeToApplicationContentType[appType];
  const promotions = await ContentPromotionsApi.getByContentIds(ids);
  const groupedPromotions = groupBy(promotions, "contentId");
  return {
    [applicationType]: ids.reduce(
      (a, x) => {
        a[x] = groupedPromotions[x] || [];
        return a;
      },
      {} as Record<number, ContentPromotionDto[]>
    ),
  };
});

export const removePromotionAction = createAsyncThunk<
  AppType,
  { contentId: number; promotionId: number },
  { state: RootState }
>(`${name}/removePromotionAction`, async ({ contentId, promotionId }, thunkApi) => {
  await ContentPromotionsApi.delete({ contentId, promotionId });
  return getCurrentUserAppType(thunkApi.getState());
});

export const createPromotedPromotionAction = createAsyncThunk<
  { promotion: ContentPromotionDto; appType: AppType },
  { contentId: number; promoGroup?: string; endDate?: Date; promoteToTop?: boolean },
  { state: RootState }
>(
  `${name}/createPromotedPromotionAction`,
  async ({ contentId, promoGroup, endDate, promoteToTop: passedPromoteToTop }, thunkApi) => {
    const configuredDuration = properties[ConfigProperty.CONTENT_PROMOTION_PROMOTED_DEFAULT_DURATION].selector(
      thunkApi.getState()
    );
    const promoteToTopConfig = properties[ConfigProperty.CONTENT_PROMOTION_PROMOTED_PROMOTE_TO_TOP].selector(
      thunkApi.getState()
    );
    const now = new Date();
    const promotion = await ContentPromotionsApi.create({
      contentId,
      promotion: {
        promoType: ContentPromotionType.PROMOTED,
        promoRank: passedPromoteToTop ?? promoteToTopConfig ? -now.getTime() : now.getTime(),
        startDate: new Date().toISOString(),
        endDate: (endDate ?? addDurationToDate(now, configuredDuration)).toISOString(),
        ...(promoGroup && promoGroup !== DEFAULT_PROMO_GROUP && { promoGroup }),
      },
    });
    return { promotion, appType: getCurrentUserAppType(thunkApi.getState()) };
  }
);

const contentPromotionsCache = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadContentPromotionsAction.fulfilled, (state, action) => {
      Object.values(ApplicationType).forEach((type) => {
        if (action.payload[type]) {
          Object.assign(state[type], action.payload[type]);
        }
      });
    });
    builder.addCase(removePromotionAction.fulfilled, (state, action) => {
      const { promotionId, contentId } = action.meta.arg;
      const appType = action.payload;
      const map = state[appTypeToApplicationContentType[appType]];
      const existing = map[contentId] || [];
      map[contentId] = existing.filter((x) => x.id !== promotionId);
    });
    builder.addCase(createPromotedPromotionAction.fulfilled, (state, action) => {
      const { promotion, appType } = action.payload;
      const map = state[appTypeToApplicationContentType[appType]];
      const existing = map[promotion.contentId!] || [];
      map[promotion.contentId!] = [...existing.filter((x) => x.promoType !== promotion.promoType), promotion];
    });
  },
});

export default contentPromotionsCache.reducer;

const emptyCache: Record<number, ContentPromotionDto[]> = {};
export const getContentPromotionsMap = (state: RootState) => {
  const appType = state.user.currentUser?.appType;
  if (!appType) {
    return emptyCache;
  }
  const applicationType = appTypeToApplicationContentType[appType];
  return state.contentPromotionsCache[applicationType];
};

const noPromo: ContentPromotionDto[] = [];
export const getContentPromotions =
  (id: number) =>
  (state: RootState): ContentPromotionDto[] => {
    const appType = state.user.currentUser?.appType;
    if (!appType) {
      return noPromo;
    }
    const applicationType = appTypeToApplicationContentType[appType];
    return state.contentPromotionsCache[applicationType][id] ?? noPromo;
  };
