import AppType from "@models/AppType";
import { DELETE, GET, POST, RequestContext, buildQuery, currentAppClientUserRequestContext } from "@utils/fetch";

export interface ReactionDto {
  id: number;
  name: string;
  reactionUrl?: string;
}

// the following interface is much shorter than the real deal, but I only need this data for now
export interface ContentPunchDto {
  id: number;
  isLiked: boolean;
  likeCount: number;
}

export interface ReactionWithCount {
  count: number;
  reaction: ReactionDto;
}

export interface ContentChipsDto {
  id: number;
  reactions: ReactionWithCount[];
  isLiked: boolean;
}

const chipsUserRequestContext: RequestContext = { appType: AppType.CHIPZ, requiredTokenType: "USER" };

const punchUserRequestContext: RequestContext = { appType: AppType.PUNCH, requiredTokenType: "USER" };

export const ClientContentApi = {
  listReactions: (): Promise<ReactionDto[]> => GET(`api/v1/reaction/list`, currentAppClientUserRequestContext),
  addReaction: ({ contentId, reactionId }: { contentId: number; reactionId: number }) =>
    POST(`api/v1/reaction/content/${contentId}`, { id: reactionId, count: 1 }, currentAppClientUserRequestContext),
  deleteReaction: ({ contentId, reactionId }: { contentId: number; reactionId: number }) =>
    DELETE(`api/v1/reaction/content/${contentId}?${buildQuery({ reactionId })}`, currentAppClientUserRequestContext),
  getChipsContent: (contentId: number): Promise<ContentChipsDto> =>
    GET(`api/v1/video/ugc/${contentId}`, chipsUserRequestContext),
  getPunchContent: (contentId: number): Promise<ContentPunchDto> =>
    GET(`api/v1/audio/ugc/${contentId}`, punchUserRequestContext),
};
