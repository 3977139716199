import { ChildSortingType, CommonModerationStatus, Page } from "@api";
import { POST, buildQuery } from "@utils/fetch";
import { omitBy } from "lodash";

export enum ModerationUserType {
  SPECTATOR = "SPECTATOR",
  PUBLISHER = "PUBLISHER",
}

export enum ModerationUserInfoType {
  NAME = "NAME",
  NICKNAME = "NICKNAME",
  AVATAR = "AVATAR",
  PROFILE_DESCRIPTION = "PROFILE_DESCRIPTION",
  SOCIAL_NETWORK_ID = "SOCIAL_NETWORK_ID",
}
export interface ModerationUserInfoFilter {
  userIds?: number[];
  moderationStatuses?: CommonModerationStatus[];
  userTypes?: ModerationUserType[];
  userInfoTypes?: ModerationUserInfoType[];
}

export interface SetUserInfoModerationStatusRequest {
  userInfoId: number;
  moderationStatus: CommonModerationStatus;
  version: number;
}

export interface BatchSetUserInfoModerationStatusRequest {
  batch: SetUserInfoModerationStatusRequest[];
}

export interface BatchSetUserInfoModerationStatusResponse {
  moderatedUserInfos?: ModerationUserInfo[];
  changedUserInfos?: ModerationUserInfo[];
}

export interface ModerationUserInfoFilter {
  userIds?: number[];
  moderationStatuses?: CommonModerationStatus[];
  userTypes?: ModerationUserType[];
  userInfoTypes?: ModerationUserInfoType[];
}

export interface ModerationUserInfo {
  id: number;
  userId?: number;
  moderationStatus?: CommonModerationStatus;
  moderatorId?: number;
  countFollowers?: number;
  version?: number;
  createdAt?: string;
  deletedAt?: string;
  // type
  userType?: ModerationUserType;
  userInfoType?: ModerationUserInfoType;
  userInfoSubType?: string;
  // legacy fields, keeping for compatibility
  nickname?: string;
  name?: string;
  avatar?: string;
  description?: string;
  socialNetworkId?: string;
  // actual value
  userInfoValue?: string;
}

export interface ModerationUserInfoLogEntry {
  id: number;
  userId: number;
  createdAt: string;
  moderationStatus: CommonModerationStatus;
  moderatorId: number;
  userInfoType: ModerationUserInfoType;
  userInfoSubType?: string | null;
  userInfoValue: string;
}

export interface ModerationUserInfoLogFilter {
  moderatorIds?: number[];
  moderationStatuses?: CommonModerationStatus[];
  userInfoTypes?: ModerationUserInfoType[];
  userIds?: number[];
  startDate?: Date | null;
  endDate?: Date | null;
}

const urlPrefix = `api/v1/admin/moderation-manager`;

export const UserInfoModerationApi = {
  list: ({
    filter,
    page,
    size,
    order,
    direction,
  }: {
    filter: ModerationUserInfoFilter;
    page: number;
    size: number;
    order: string;
    direction: ChildSortingType;
  }): Promise<Page<ModerationUserInfo>> =>
    POST(
      `${urlPrefix}/user-info/list?${buildQuery({
        page,
        size,
        sort: [order, direction].join(","),
      })}`,
      filter
    ),
  setStatusesBatch: (
    request: BatchSetUserInfoModerationStatusRequest
  ): Promise<BatchSetUserInfoModerationStatusResponse> =>
    POST(`${urlPrefix}/user-info/status-batch-with-state`, request),
  listLog: ({
    page,
    size,
    order = "createdAt",
    direction = ChildSortingType.DESC,
    filter,
  }: {
    page: number;
    size: number;
    order?: string;
    direction?: ChildSortingType;
    filter: ModerationUserInfoLogFilter;
  }): Promise<Page<ModerationUserInfoLogEntry>> => {
    const { startDate, endDate, ...restFilter } = filter;
    return POST(`${urlPrefix}/log/user-info/list?${buildQuery({ page, size, sort: `${order},${direction}` })}`, {
      ...omitBy(restFilter, (value) => {
        if (!value) {
          return true;
        }
        if (Array.isArray(value)) {
          if (value.length === 0) {
            return true;
          }
        }
        return false;
      }),
      ...((startDate || endDate) && {
        dateRange: { startDate: startDate?.toISOString(), endDate: endDate?.toISOString() },
      }),
    });
  },
};
