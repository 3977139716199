import AppType from "@models/AppType";

export enum ChildSortingType {
  ASC = "ASC",
  DESC = "DESC",
}

export enum ApplicationType {
  AUDIO = "AUDIO",
  VIDEO = "VIDEO",
}

export const appTypeToApplicationContentType = {
  [AppType.PUNCH]: ApplicationType.AUDIO,
  [AppType.CHIPZ]: ApplicationType.VIDEO,
};

export interface OffsetLimit {
  offset: number;
  limit: number;
}

export interface PageSize {
  page: number;
  size: number;
}

export interface SortingSortBy {
  sorting: ChildSortingType;
  sortBy: string;
}

export interface SortingSortByEnumerated<Type extends string> {
  sorting: ChildSortingType;
  sortBy: Type;
}

export type SortingSortByOptional = Partial<SortingSortBy>;

export interface ResponseDto<Type> {
  content?: Type[];
  total?: number;
}

export interface Page<T> {
  number: number;
  totalPages: number;
  totalElements: number;
  empty: boolean;
  content?: Array<T>;
}

export interface Pageable {
  page: number;
  size: number;
  sort?: string;
}

export interface IdDto {
  id?: number;
}

export enum CommonModerationStatus {
  NEW = "NEW",
  APPROVED = "APPROVED",
  DELETED = "DELETED",
}
