import commentsModeration from "./groups/commentsModeration";
import contentLabelling from "./groups/contentLabelling";
import contentPromotion from "./groups/contentPromotion";
import explore from "./groups/explore";
import killswitches from "./groups/killswitches";
import moderationNotifications from "./groups/moderationNotifications";
import others from "./groups/others";
import videoModeration from "./groups/videoModeration";

const properties = {
  ...killswitches,
  ...videoModeration,
  ...commentsModeration,
  ...contentPromotion,
  ...others,
  ...contentLabelling,
  ...moderationNotifications,
  ...explore,
};

export default properties;
