import { Dialog } from "@mui/material";
import { getClientUserForCurrentApp, toggleClientCommentsDialogAction } from "@slices/client";
import { loadContentsToCache } from "@slices/contentCache";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { useCallback } from "react";
import CommentsDialogContent from "./CommentsDialogContent";

// TODO move this under modal controller
export default function ClientCommentsController() {
  const contentForClientComments = useAppSelector((state) => state.client.contentForClientComments);
  const clientUser = useAppSelector(getClientUserForCurrentApp);
  const dispatch = useAppDispatch();
  const closeDialog = useCallback(() => {
    dispatch(toggleClientCommentsDialogAction(undefined));
  }, [dispatch]);
  const reloadContent = useCallback(() => {
    if (!contentForClientComments) {
      return;
    }
    dispatch(loadContentsToCache({ ids: [contentForClientComments.id], checkExisting: false }));
  }, [contentForClientComments, dispatch]);
  if (!clientUser) {
    return null;
  }
  return (
    <Dialog
      open={!!contentForClientComments}
      onClose={closeDialog}
      fullWidth
      maxWidth="md"
      data-testid="client-comments-dialog"
    >
      {contentForClientComments && (
        <CommentsDialogContent
          content={contentForClientComments}
          closeDialog={closeDialog}
          reloadContent={reloadContent}
        />
      )}
    </Dialog>
  );
}
