import { AdminApi } from "@api/admin";
import { UserRole } from "@api/user";
import { ConfigProperty } from "@config";
import { appTypeToDisplayName } from "@models/AppType";
import DateTime from "@widgets/DateTime";
import { uniqBy } from "lodash";
import { defineMessage } from "react-intl";
import { SearchStrategy } from "../models";
import { makeCopiableSearchResult } from "../searchResultBuilders";

const foundResultMessage = defineMessage({
  defaultMessage: "Validation code: {code} (issued at {issuedAt} in {appType})",
});

const ByPhoneNumberValidationCodes: SearchStrategy = async ({ query, availableUsers, resolvedKillswitches }) => {
  if (resolvedKillswitches[ConfigProperty.VERIFICATION_CODES_SEARCH_ENABLED] !== true) {
    return [];
  }
  const phone = query.replace(/\D/g, "");
  if (phone.length < 11) {
    return [];
  }
  const admins = uniqBy(
    availableUsers.filter((x) => x.role === UserRole.ADMINISTRATOR),
    (x) => x.appType
  );
  if (!admins?.length) {
    return [];
  }
  const searchResults = await Promise.all(
    admins.map((x) =>
      AdminApi.searchPhoneValidationCodes(phone, { appType: x.appType, token: x.token }).then((result) => ({
        appType: x.appType,
        result,
      }))
    )
  );
  return searchResults.flatMap((x) =>
    x.result.map((r) =>
      makeCopiableSearchResult({
        message: foundResultMessage,
        messageValues: {
          code: r.code,
          appType: appTypeToDisplayName[x.appType],
          issuedAt: <DateTime value={r.issuedAt} />,
        },
        copyValue: r.code,
      })
    )
  );
};

export default ByPhoneNumberValidationCodes;
