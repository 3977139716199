import { OffsetLimit, ResponseDto } from "@api";
import { DELETE, GET, POST, PUT, buildQuery } from "@utils/fetch";

const urlPrefix = `api/v1/admin/moods`;

export interface MoodDto {
  id: number;
  name?: string;
  emoji?: string;
}

export interface CreateMoodDto {
  name: string;
  emoji?: string;
}

export interface UpdateMoodDto {
  name?: string;
  emoji?: string;
}

export const MoodsApi = {
  list: (request: OffsetLimit): Promise<ResponseDto<MoodDto>> => GET(`${urlPrefix}?${buildQuery(request)}`),
  create: (request: CreateMoodDto) => POST(`${urlPrefix}`, request),
  delete: (id: number) => DELETE(`${urlPrefix}/${id}`),
  update: (id: number | string, request: UpdateMoodDto) => PUT(`${urlPrefix}/${id}`, request),
};
