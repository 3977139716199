import { appTypeToDisplayName } from "@models/AppType";
import useSetDocumentTitle from "@navigation/useSetDocumentTitle";
import { getCurrentUserAppType } from "@slices/user";
import { useAppSelector } from "@store/hooks";
import NavigationTabs from "@widgets/NavigationTabs";
import { useMemo } from "react";
import { defineMessages } from "react-intl";
import { ADMIN, DEFAULT_CONFIG, EXCLUSION_GROUPS, EXPERIMENTS, EXPORT } from "./views";

const messages = defineMessages({
  [EXPERIMENTS]: { defaultMessage: "Experiments" },
  [EXCLUSION_GROUPS]: { defaultMessage: "Exclusion Groups", description: "nontranslatable" },
  [DEFAULT_CONFIG]: { defaultMessage: "Configuration ({appType})", description: "ab tests default_config tab title" },
  [EXPORT]: { defaultMessage: "Export" },
  [ADMIN]: { defaultMessage: "Web Admin" },
});

const views = [DEFAULT_CONFIG, EXPERIMENTS, EXCLUSION_GROUPS, EXPORT, ADMIN];

const ExperimentsHeaderTitle = () => {
  const currentApp = useAppSelector(getCurrentUserAppType);
  const tabMessagesValuesMap = useMemo(
    () => ({
      [DEFAULT_CONFIG]: {
        appType: appTypeToDisplayName[currentApp],
      },
    }),
    [currentApp]
  );
  useSetDocumentTitle({ messageDescriptor: messages[EXPERIMENTS] });
  return (
    <NavigationTabs
      possibleValues={views}
      tabQueryKey="view"
      tabMessagesMap={messages}
      tabMessagesValues={tabMessagesValuesMap}
    />
  );
};

export default ExperimentsHeaderTitle;
