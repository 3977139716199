import { OffsetLimit, ResponseDto } from "@api";
import { POST, RequestContext, buildQuery } from "@utils/fetch";

export enum ComplaintType {
  NEW = "NEW",
  REJECT = "REJECT",
  PENDING = "PENDING",
  DONE = "DONE",
}

export enum CommentContentType {
  COMMENT = "COMMENT",
  VIDEOCOMMENT = "VIDEOCOMMENT",
}

export enum ComplainContentType {
  AUDIO = "AUDIO",
  CONTENT = "CONTENT",
}

export interface FilterComplaintCommentDto {
  id?: number;
  userId?: number;
  employeeId?: number;
  status?: ComplaintType;
  commentId?: number;
  contentType?: CommentContentType;
}

export interface FilterComplaintContentDto {
  id?: number;
  userId?: number;
  employeeId?: number;
  status?: ComplaintType;
  contentId?: number;
  contentType?: ComplainContentType;
}

export interface FilterComplaintUserDto {
  id?: number;
  fromUserId?: number;
  employeeId?: number;
  status?: ComplaintType;
  onUserId?: number;
}

export interface ComplaintCommentDto {
  id: number;
  userId?: number;
  employeeId?: number;
  status?: ComplaintType;
  description?: string;
  comment?: string;
  commentId?: number;
  contentType?: CommentContentType;
  createdAt?: string;
}

export interface ComplaintContentDto {
  id: number;
  userId?: number;
  employeeId?: number;
  status?: ComplaintType;
  description?: string;
  comment?: string;
  contentId?: number;
  contentType?: ComplainContentType;
  createdAt?: string;
}

export interface ComplaintUserDto {
  id: number;
  fromUserId?: number;
  employeeId?: number;
  status?: ComplaintType;
  description?: string;
  comment?: string;
  onUserId?: number;
  createdAt?: string;
}

export interface SetContentComplaintsStatusesRequest {
  statuses: ContentComplaintsStatus[];
}

export interface ContentComplaintsStatus {
  contentId: number;
  complaintStatus: "NEW" | "REJECT" | "PENDING" | "DONE";
}

const contentUrlPrefix = "api/v1/admin/complaint_content";

const userUrlPrefix = "api/v1/admin/complaint_user";

const commentUrlPrefix = "api/v1/admin/complaint_comment";

export const ComplaintsApi = {
  listOnContent: ({
    filter,
    requestContext,
    ...rest
  }: OffsetLimit & { filter: FilterComplaintContentDto; requestContext?: RequestContext }): Promise<
    ResponseDto<ComplaintContentDto>
  > => POST(`${contentUrlPrefix}/list?${buildQuery(rest)}`, filter, requestContext),
  resolveOnContent: ({ id, status }: { id: number; status: ComplaintType }) =>
    POST(`${contentUrlPrefix}/resolve`, { id, status }),
  listOnComments: ({
    filter,
    requestContext,
    ...rest
  }: OffsetLimit & {
    filter: FilterComplaintCommentDto;
    requestContext?: RequestContext;
  }): Promise<ResponseDto<ComplaintCommentDto>> =>
    POST(`${commentUrlPrefix}/list?${buildQuery(rest)}`, filter, requestContext),
  resolveOnComment: ({ id, status }: { id: number; status: ComplaintType }) =>
    POST(`${commentUrlPrefix}/resolve`, { id, status }),
  listOnUsers: ({
    filter,
    requestContext,
    ...rest
  }: OffsetLimit & {
    filter: FilterComplaintUserDto;
    requestContext?: RequestContext;
  }): Promise<ResponseDto<ComplaintUserDto>> =>
    POST(`${userUrlPrefix}/list?${buildQuery(rest)}`, filter, requestContext),
  resolveOnUser: ({ id, status }: { id: number; status: ComplaintType }) =>
    POST(`${userUrlPrefix}/resolve`, { id, status }),
  setContentComplaintsStatuses: (request: SetContentComplaintsStatusesRequest) =>
    POST(`api/v1/admin/moderation-manager/content/complaint/set-statuses`, request),
};
