import { ShortTagDto, TagDto } from "@api/tags";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum ModalType {
  CONTENT_BY_TAG = "CONTENT_BY_TAG",
}

export interface ModalState {
  shownModals: ModalType[];
  modalPayloads: Partial<Record<ModalType, any>>;
}

const initialState: ModalState = {
  shownModals: [],
  modalPayloads: {},
};

const name = "modal";

const modalSlice = createSlice({
  name,
  initialState,
  reducers: {
    openModalWithContentByTagAction: (state, action: PayloadAction<TagDto | ShortTagDto>) => {
      state.shownModals = [
        ...state.shownModals.filter((x) => x !== ModalType.CONTENT_BY_TAG),
        ModalType.CONTENT_BY_TAG,
      ];
      state.modalPayloads[ModalType.CONTENT_BY_TAG] = action.payload;
    },
    closeModalAction: (state, action: PayloadAction<ModalType>) => {
      delete state.modalPayloads[action.payload];
      state.shownModals = state.shownModals.filter((x) => x !== action.payload);
    },
  },
});

export const { openModalWithContentByTagAction, closeModalAction } = modalSlice.actions;

export default modalSlice.reducer;
