import AppType from "@models/AppType";
import {
  defaultForAllApps,
  newBooleanParameter,
  newNumberParameter,
  newPredefinedKeysMapWithNumericValuesParameter,
} from "../builders";
import { ConfigProperty, ExploreNotificationMapKey, PropertyGroupType } from "../types";

const group = PropertyGroupType.EXPLORE;

export default {
  [ConfigProperty.EXPLORE_BEATS_BEATS_OF_MONTH_LIMIT]: newNumberParameter({
    property: ConfigProperty.EXPLORE_BEATS_BEATS_OF_MONTH_LIMIT,
    group,
    description: "Лимит предложки чарта для BEATS_OF_MONTH",
    defaultValues: { [AppType.PUNCH]: 50 },
  }),
  [ConfigProperty.EXPLORE_BEATS_BEATS_OF_MONTH_PERIOD_DAYS]: newNumberParameter({
    property: ConfigProperty.EXPLORE_BEATS_BEATS_OF_MONTH_PERIOD_DAYS,
    group,
    description: "На сколько дней назад смотреть для BEATS_OF_MONTH",
    defaultValues: { [AppType.PUNCH]: 30 },
  }),
  [ConfigProperty.EXPLORE_BEATS_TOP_CHART_LIMIT]: newNumberParameter({
    property: ConfigProperty.EXPLORE_BEATS_TOP_CHART_LIMIT,
    group,
    description: "Лимит предложки чарта для TOP_CHART",
    defaultValues: { [AppType.PUNCH]: 50 },
  }),
  [ConfigProperty.EXPLORE_BEATS_TOP_CHART_PERIOD_DAYS]: newNumberParameter({
    property: ConfigProperty.EXPLORE_BEATS_TOP_CHART_PERIOD_DAYS,
    group,
    description: "На сколько дней назад смотреть для TOP_CHART",
    defaultValues: { [AppType.PUNCH]: 7 },
  }),
  [ConfigProperty.EXPLORE_BEATS_TOP_OF_WEEK_LIMIT]: newNumberParameter({
    property: ConfigProperty.EXPLORE_BEATS_TOP_OF_WEEK_LIMIT,
    group,
    description: "Лимит предложки чарта для TOP_OF_WEEK",
    defaultValues: { [AppType.PUNCH]: 100 },
  }),
  [ConfigProperty.EXPLORE_BEATS_TOP_OF_WEEK_PERIOD_DAYS]: newNumberParameter({
    property: ConfigProperty.EXPLORE_BEATS_TOP_OF_WEEK_PERIOD_DAYS,
    group,
    description: "На сколько дней назад смотреть для TOP_OF_WEEK",
    defaultValues: { [AppType.PUNCH]: 7 },
  }),
  [ConfigProperty.EXPLORE_ADD_OBJECTS_TO_END]: newBooleanParameter({
    property: ConfigProperty.EXPLORE_ADD_OBJECTS_TO_END,
    group,
    description:
      "Если включено, то при добавлении объекта в подборку/коллекцию, он будет добавлен в конец списка, иначе - в начало",
    defaultValues: defaultForAllApps(false),
  }),
  [ConfigProperty.EXPLORE_NOTIFICATION_TEMPLATES_FOR_BEAT]: newPredefinedKeysMapWithNumericValuesParameter({
    property: ConfigProperty.EXPLORE_NOTIFICATION_TEMPLATES_FOR_BEAT,
    possibleKeys: Object.values(ExploreNotificationMapKey),
    group,
    description: "ID темплейтов для нотификации юзера о добавлении его бита на витрину битов",
    defaultValues: { [AppType.PUNCH]: {} },
  }),
  [ConfigProperty.EXPLORE_NOTIFICATION_TEMPLATES_FOR_COMPILATION]: newPredefinedKeysMapWithNumericValuesParameter({
    property: ConfigProperty.EXPLORE_NOTIFICATION_TEMPLATES_FOR_COMPILATION,
    possibleKeys: Object.values(ExploreNotificationMapKey),
    group,
    description: "ID темплейтов для нотификации юзера о добавлении его бита в подборку витрины битов",
    defaultValues: { [AppType.PUNCH]: {} },
  }),
  [ConfigProperty.EXPLORE_NOTIFICATION_TEMPLATES_FOR_USER]: newPredefinedKeysMapWithNumericValuesParameter({
    property: ConfigProperty.EXPLORE_NOTIFICATION_TEMPLATES_FOR_USER,
    possibleKeys: Object.values(ExploreNotificationMapKey),
    group,
    description: "ID темплейтов для нотификации юзера о добавлении его профиля на витрину битов",
    defaultValues: { [AppType.PUNCH]: {} },
  }),
};
