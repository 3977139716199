/* eslint-disable no-process-env */
import AppType from "@models/AppType";
import { determineEnvironmentName } from "@utils/env";

type EnvUrls = Record<AppType, string>;

const makeEnvUrlsNewStyle = (envName: string): EnvUrls => ({
  [AppType.PUNCH]: `https://api-${envName}.punch.common.esn.one`,
  [AppType.CHIPZ]: `https://api-${envName}.chipz.common.esn.one`,
});

const makeEnvUrls = (envName: string): EnvUrls => ({
  [AppType.PUNCH]: `https://api.punch.${envName}.esn.one`,
  [AppType.CHIPZ]: `https://api.chipz.${envName}.esn.one`,
});

const specialApiUrls: Record<string, Record<AppType, string>> = {
  prod: {
    [AppType.PUNCH]: "https://api.pnchh.com",
    [AppType.CHIPZ]: "https://api.chpsv.com",
  },
  staging: {
    [AppType.PUNCH]: "https://api-staging.pnchh.com",
    [AppType.CHIPZ]: "https://api-staging.chpsv.com",
  },
  cypress: {
    [AppType.CHIPZ]: "http://chipz.cypress",
    [AppType.PUNCH]: "http://punch.cypress",
  },
};

const { envName: currentEnv, newStyleOfUrl } = determineEnvironmentName({
  stand: process.env.NODE_ENV !== "test" && (window as any).Cypress ? "cypress" : process.env.STAND,
  hostname: process.env.NODE_ENV !== "test" ? window.location.hostname : undefined,
  nodeEnv: process.env.NODE_ENV,
});

if (!currentEnv) {
  // eslint-disable-next-line no-console
  console.error("Cannot determine api to use!");
  throw "Cannot determine api to use!";
}

export default {
  targetEnvName: currentEnv,
  isTargetingProduction: currentEnv === "prod",
  isInUiTestsContext: currentEnv === "cypress",
  isProductionBuild: process.env.NODE_ENV === "production",
  isDevelopmentBuild: process.env.NODE_ENV === "development",
  isTestBuild: process.env.NODE_ENV === "test",
  apiUrls: specialApiUrls[currentEnv] ?? (newStyleOfUrl ? makeEnvUrlsNewStyle(currentEnv) : makeEnvUrls(currentEnv)),
  shouldRunStaticChecks: currentEnv === "cypress" || process.env.NODE_ENV === "development",
  applicationVersion: process.env.APP_VERSION!,
  lastCommitHash: process.env.APP_HASH,
  moderationNotificationSoundNames: (process.env.NOTIFICATION_SOUND_FILES ?? "").split(","),
};
