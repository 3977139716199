import AppType from "@models/AppType";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store";
import { parseIsoDuration, serializeIsoDuration } from "@utils/duration";
import { parseJson, stringifyJson } from "@utils/json";
import {
  AllowedValueTypes,
  ConfigProperty,
  ConfigPropertyDescriptor,
  MapWithPredefinedKeysEditorProps,
  PropertyGroupType,
  PropertyValueType,
} from "./types";

export const allApps = Object.values(AppType);
export const defaultForAllApps = <Type extends AllowedValueTypes>(value: Type) =>
  allApps.reduce(
    (a, x) => {
      a[x] = value;
      return a;
    },
    {} as Record<AppType, Type>
  );

const makeSelector = <Type>({
  defaultValues,
  property,
  fallback,
  customParser,
}: {
  defaultValues: Partial<Record<AppType, Type>>;
  property: ConfigProperty;
  fallback: Type;
  customParser?: (x: string) => Type;
}) => {
  const selectorImpl = (runtimeConfig: Record<string, string>, appType: AppType | undefined) => {
    const propertyValue = runtimeConfig[property];
    if (propertyValue !== undefined) {
      try {
        if (customParser) {
          return customParser(propertyValue);
        } else {
          return parseJson(propertyValue) as Type;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Failed to parse ${propertyValue}`, e);
      }
    }
    if (appType && defaultValues[appType] !== undefined) {
      return defaultValues[appType]!;
    }
    return fallback;
  };
  return {
    selector: createSelector(
      (state: RootState) => state.appSettings.runtimeConfig,
      (state: RootState) => state.user.currentUser?.appType,
      selectorImpl
    ),
    selectorImpl,
  };
};

export const newKillswitch = ({
  property,
  backendRelease,
  backendTicket,
  description,
  apps,
}: {
  property: ConfigProperty;
  /** what ticket this killswitch is waiting for */
  backendTicket: string;
  /** in which backend release the backendTicket is planned */
  backendRelease: string;
  /** human description what this does */
  description: string;
  /** if app type is present here, config will be editable in this app */
  apps: AppType[];
}): ConfigPropertyDescriptor<boolean> => {
  const defaultValues = apps.reduce(
    (a, x) => {
      a[x] = false;
      return a;
    },
    {} as Partial<Record<AppType, boolean>>
  );
  return {
    property,
    valueType: PropertyValueType.BOOLEAN,
    group: PropertyGroupType.KILLSWITCH,
    backendRelease,
    backendTicket,
    description,
    defaultValues,
    ...makeSelector({ defaultValues, property, fallback: false }),
    defaultValueSerializer: stringifyJson,
  };
};

export const newMapParameter = ({
  property,
  description,
  defaultValues,
  group,
}: {
  property: ConfigProperty;
  description: string;
  defaultValues: Partial<Record<AppType, Record<string, string>>>;
  group: PropertyGroupType;
}) =>
  newParameter({
    property,
    description,
    valueType: PropertyValueType.MAP,
    group,
    defaultValues,
    ...makeSelector({ defaultValues, property, fallback: {} }),
  });

export const newPredefinedKeysMapWithNumericValuesParameter = ({
  property,
  description,
  defaultValues,
  group,
  possibleKeys,
}: {
  property: ConfigProperty;
  description: string;
  defaultValues: Partial<Record<AppType, Record<string, number>>>;
  group: PropertyGroupType;
  possibleKeys: string[];
}) =>
  newParameter({
    property,
    description,
    valueType: PropertyValueType.PREDEFINED_KEYS_MAP,
    group,
    defaultValues,
    ...makeSelector({ defaultValues, property, fallback: {} }),
    editorProps: <MapWithPredefinedKeysEditorProps>{ possibleKeys, valueType: "number" },
  });

export const newBooleanParameter = ({
  property,
  description,
  defaultValues,
  group,
}: {
  property: ConfigProperty;
  description: string;
  defaultValues: Partial<Record<AppType, boolean>>;
  group: PropertyGroupType;
}) =>
  newParameter({
    property,
    description,
    valueType: PropertyValueType.BOOLEAN,
    group,
    defaultValues,
    ...makeSelector({ defaultValues, property, fallback: false }),
  });

export const newStringListParameter = ({
  property,
  description,
  defaultValues,
  group,
}: {
  property: ConfigProperty;
  description: string;
  defaultValues: Partial<Record<AppType, string[]>>;
  group: PropertyGroupType;
}) =>
  newParameter({
    property,
    description,
    valueType: PropertyValueType.STRING_LIST,
    group,
    defaultValues,
    ...makeSelector({ defaultValues, property, fallback: [] as string[] }),
  });

export const newStringParameter = ({
  property,
  description,
  defaultValues,
  group,
}: {
  property: ConfigProperty;
  description: string;
  defaultValues: Partial<Record<AppType, string>>;
  group: PropertyGroupType;
}) =>
  newParameter({
    property,
    description,
    valueType: PropertyValueType.STRING,
    group,
    defaultValues,
    ...makeSelector({ defaultValues, property, fallback: "", customParser: (x) => x }),
  });

export const newDurationParameter = ({
  property,
  description,
  defaultValues,
  group,
}: {
  property: ConfigProperty;
  description: string;
  defaultValues: Partial<Record<AppType, Duration>>;
  group: PropertyGroupType;
}) =>
  newParameter({
    property,
    description,
    valueType: PropertyValueType.DURATION,
    group,
    defaultValues,
    ...makeSelector({
      defaultValues,
      property,
      fallback: {} as Duration,
      customParser: (value) => parseIsoDuration(value),
    }),
    defaultValueSerializer: (value) => serializeIsoDuration(value)!,
  });

export const newNumberParameter = ({
  property,
  description,
  defaultValues,
  group,
}: {
  property: ConfigProperty;
  description: string;
  defaultValues: Partial<Record<AppType, number>>;
  group: PropertyGroupType;
}) =>
  newParameter({
    property,
    description,
    valueType: PropertyValueType.NUMBER,
    group,
    defaultValues,
    ...makeSelector({
      defaultValues,
      property,
      fallback: 0,
    }),
  });

export const newUserIdParameter = ({
  property,
  description,
  defaultValues,
  group,
}: {
  property: ConfigProperty;
  description: string;
  defaultValues: Partial<Record<AppType, number>>;
  group: PropertyGroupType;
}) =>
  newParameter({
    property,
    description,
    valueType: PropertyValueType.USER_ID,
    group,
    defaultValues,
    ...makeSelector({
      defaultValues,
      property,
      fallback: 0,
    }),
  });

const newParameter = <Type extends AllowedValueTypes>({
  property,
  description,
  defaultValues,
  valueType,
  group,
  selector,
  selectorImpl,
  defaultValueSerializer,
  editorProps,
}: {
  property: ConfigProperty;
  valueType: PropertyValueType;
  group: PropertyGroupType;
  /** human description what this does */
  description: string;
  /** if app type is present here, config will be editable in this app */
  defaultValues: Partial<Record<AppType, Type>>;
  selector: (state: RootState) => Type;
  selectorImpl: (runtimeConfig: Record<string, string>, appType: AppType | undefined) => Type;
  defaultValueSerializer?: (value: Type) => string;
  editorProps?: any;
}): ConfigPropertyDescriptor<Type> => ({
  property,
  valueType,
  backendRelease: "",
  backendTicket: "",
  description,
  defaultValues,
  group,
  selector,
  selectorImpl,
  defaultValueSerializer: (x) => {
    if (defaultValueSerializer) {
      return defaultValueSerializer(x);
    }
    return stringifyJson(x, undefined, 4);
  },
  editorProps,
});
