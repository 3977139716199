import { invert } from "lodash";

enum AppType {
  PUNCH = "PUNCH",
  CHIPZ = "CHIPZ",
}

export const appTypeToDisplayName = {
  [AppType.PUNCH]: "PUNCH",
  [AppType.CHIPZ]: "SPICH",
};

const legacyDisplayNames: Record<AppType, string[]> = {
  [AppType.CHIPZ]: ["CHIPS", "SPICH"],
  [AppType.PUNCH]: [],
};

const displayNameToAppType = invert(appTypeToDisplayName) as Record<string, AppType>;

export const appTypeFromString = (value: string): AppType | undefined => {
  if ((Object.values(AppType) as string[]).includes(value)) {
    return value as AppType;
  }
  if (displayNameToAppType[value]) {
    return displayNameToAppType[value];
  }
  const appTypeFromLegacy = Object.entries(legacyDisplayNames)
    .filter(([, v]) => v.includes(value))
    .map(([key]) => key as AppType);
  return appTypeFromLegacy[0];
};

export default AppType;
