import { OffsetLimit, ResponseDto } from "@api";
import { DELETE, GET, POST, PUT, buildQuery } from "@utils/fetch";
import { TextControlStatus } from "./beatsModeration";

export interface AdminBeatTextDto {
  id: number;
  authorId: number;
  text: string;
  createdAt: string;
  updatedAt: string;
  controlStatus: TextControlStatus;
}

export const BeatTextsApi = {
  getByContentIds: (contentIds: number[]): Promise<Record<number, AdminBeatTextDto> | null> =>
    GET(`api/v1/admin/audio/content/texts/get-by-ids?${buildQuery({ ids: contentIds })}`),
  getByBeatId: ({ beatId, ...rest }: { beatId: number } & OffsetLimit): Promise<ResponseDto<AdminBeatTextDto>> =>
    GET(`api/v1/admin/beats/${beatId}/texts?${buildQuery(rest)}`),
  getByIds: (textIds: number[]): Promise<AdminBeatTextDto[]> =>
    POST(`api/v1/admin/beats/texts/get-by-ids`, { textIds }),
  updateText: (textId: number, authorId: number, text: string, controlStatus: TextControlStatus) =>
    PUT(`api/v1/admin/texts/${textId}`, {
      authorId,
      controlStatus,
      text,
      isNeuralNetworkApplied: false,
    }),
  deleteText: (textId: number) => DELETE(`api/v1/admin/texts/${textId}`),
};
