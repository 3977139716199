import qs from "query-string";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  to: string;
  preserveQuery?: boolean;
}

const Redirect = ({ to, preserveQuery }: Props) => {
  const navigate = useNavigate();
  useEffect(() => {
    const { url, query: toQuery } = qs.parseUrl(to);
    const currentQuery = qs.parse(document.location.search);
    const targetUrl = qs.stringifyUrl({
      url,
      query: {
        ...toQuery,
        ...(preserveQuery && currentQuery),
      },
    });
    navigate(targetUrl, { replace: true });
  }, [navigate, to, preserveQuery]);
  return <div />;
};

export default function MakeRedirect(props: Props) {
  return () => <Redirect {...props} />;
}
