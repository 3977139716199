import { defineMessages } from "react-intl";

export enum ModerationLogViews {
  ugc = "ugc",
  profiles = "profiles",
}

export const viewTabTitles = defineMessages({
  [ModerationLogViews.ugc]: { defaultMessage: "UGC", description: "nontranslatable" },
  [ModerationLogViews.profiles]: { defaultMessage: "Users" },
});
