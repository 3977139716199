import AppType from "@models/AppType";
import { DurationUnit } from "@utils/duration";
import { newBooleanParameter, newDurationParameter, newMapParameter, newStringListParameter } from "../builders";
import { ConfigProperty, PropertyGroupType } from "../types";

const group = PropertyGroupType.CONTENT_PROMOTION;

export default {
  [ConfigProperty.CONTENT_PROMOTION_GROUPS_LIST]: newStringListParameter({
    property: ConfigProperty.CONTENT_PROMOTION_GROUPS_LIST,
    description: "Группы промоутирования контента",
    group,
    defaultValues: {
      [AppType.PUNCH]: [
        "Android",
        "iOS",
        "TopFeed",
        "BeatsPromo",
        "ArtistReleases",
        "rzt",
        "BeatsInFeed",
        "rec_pinned",
      ],
      [AppType.CHIPZ]: ["Android", "iOS"],
    },
  }),
  [ConfigProperty.CONTENT_PROMOTION_GROUPS_DISPLAY_NAMES]: newMapParameter({
    property: ConfigProperty.CONTENT_PROMOTION_GROUPS_DISPLAY_NAMES,
    description: "Как показывать группы промоутирования контента (ключ - промо-группа, значение - имя для отображения)",
    group,
    defaultValues: {
      [AppType.PUNCH]: { rec_pinned: "Пин в РЕК" },
      [AppType.CHIPZ]: {},
    },
  }),
  [ConfigProperty.CONTENT_PROMOTION_PROMOTED_DEFAULT_DURATION]: newDurationParameter({
    property: ConfigProperty.CONTENT_PROMOTION_PROMOTED_DEFAULT_DURATION,
    description: "На сколько времени фичерить контент по умолчанию",
    group,
    defaultValues: {
      [AppType.PUNCH]: {
        [DurationUnit.days]: 1,
      },
      [AppType.CHIPZ]: {
        [DurationUnit.days]: 7,
      },
    },
  }),
  [ConfigProperty.CONTENT_PROMOTION_PROMOTED_PROMOTE_TO_TOP]: newBooleanParameter({
    property: ConfigProperty.CONTENT_PROMOTION_PROMOTED_PROMOTE_TO_TOP,
    description:
      "Если включено, промоутирование контента по умолчанию будет ставить его с отрицательным ранком вперед всех",
    group,
    defaultValues: {
      [AppType.PUNCH]: false,
      [AppType.CHIPZ]: true,
    },
  }),
};
