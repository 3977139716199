import { ApplicationType, appTypeToApplicationContentType } from "@api";
import { AudioContentApi, ContentPunchAdminDto } from "@api/audioContent";
import { ContentCommonApi } from "@api/contentCommon";
import { ContentThemesApi, ThemeDto } from "@api/contentThemes";
import { FeedConfiguration, FeedConfigurationsApi } from "@api/feedConfigurations";
import { InternalTagsApi } from "@api/internalTags";
import { TagDto, TagsApi } from "@api/tags";
import { ContentShortChipzAdminDto, VideoContentApi } from "@api/videoContent";
import AppType from "@models/AppType";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { AppDispatch, RootState, ThunkApiType } from "@store/store";
import { chunk, pick, uniq } from "lodash";
import { getCurrentUserAppType } from "./user";

export interface ContentCacheState {
  [ApplicationType.VIDEO]: Record<number, ContentShortChipzAdminDto>;
  [ApplicationType.AUDIO]: Record<number, ContentPunchAdminDto>;
  hashtagsMap: Record<number, TagDto>;
  themes: ThemeDto[];
  customFeeds: FeedConfiguration[];
}

const initialState: ContentCacheState = {
  [ApplicationType.VIDEO]: {},
  [ApplicationType.AUDIO]: {},
  hashtagsMap: {},
  themes: [],
  customFeeds: [],
};

const name = "contentCache";

const loadContentsToCacheImpl = createAsyncThunk<
  Partial<ContentCacheState>,
  { ids: number[]; appType: AppType },
  { state: RootState; dispatch: AppDispatch }
>(`${name}/loadContentsToCacheImpl`, async ({ ids, appType }) => {
  const applicationType = appTypeToApplicationContentType[appType];
  if (appType === AppType.CHIPZ) {
    const data = await VideoContentApi.loadBatch(ids);
    return {
      [applicationType]: data.reduce(
        (a, x) => {
          if (x.id) {
            a[x.id] = x;
          }
          return a;
        },
        {} as Record<number, ContentShortChipzAdminDto>
      ),
    };
  } else {
    const data = await AudioContentApi.loadBatch(ids);
    return {
      [applicationType]: data.reduce(
        (a, x) => {
          if (x.id) {
            a[x.id] = x;
          }
          return a;
        },
        {} as Record<number, ContentPunchAdminDto>
      ),
    };
  }
});

export const loadContentsToCache = createAsyncThunk<
  Partial<ContentCacheState>,
  { ids: number[]; checkExisting: boolean },
  { state: RootState; dispatch: AppDispatch }
>(`${name}/loadContentsToCache`, async ({ ids, checkExisting }, thunkApi) => {
  const appType = thunkApi.getState().user.currentUser!.appType;
  const applicationType = appTypeToApplicationContentType[appType];
  const cacheValue = thunkApi.getState().contentCache[applicationType];
  const idsToRequest = uniq(checkExisting ? ids.filter((x) => !cacheValue[x]) : ids);
  const accumulatedMap = pick(cacheValue, ids);
  if (!idsToRequest.length) {
    return {
      [applicationType]: accumulatedMap,
    };
  }

  const chunks = chunk(idsToRequest, 50);
  const results = await Promise.all(
    chunks.map((chunk) => thunkApi.dispatch(loadContentsToCacheImpl({ ids: chunk as number[], appType })))
  );
  results.forEach((result) => {
    if (loadContentsToCacheImpl.fulfilled.match(result)) {
      Object.assign(accumulatedMap, result.payload[applicationType]);
    }
  });
  return {
    [applicationType]: accumulatedMap,
  };
});

export const toggleInternalTagAction = createAsyncThunk<
  ContentPunchAdminDto | ContentShortChipzAdminDto | undefined,
  { content: ContentPunchAdminDto | ContentShortChipzAdminDto; tagId: number; refreshInCache: boolean },
  ThunkApiType
>(`${name}/toggleInternalTagAction`, async ({ content, tagId, refreshInCache }, thunkApi) => {
  const isAssigned = !!content.insideTags?.find((x) => x.id === tagId);
  const appType = getCurrentUserAppType(thunkApi.getState());
  const applicationType = appTypeToApplicationContentType[appType];
  const request = {
    contentId: content.id,
    tagId,
    applicationType,
  };
  if (isAssigned) {
    await InternalTagsApi.unassignOnContent(request);
  } else {
    await InternalTagsApi.assignOnContent(request);
  }
  if (refreshInCache) {
    const refreshResult = await thunkApi.dispatch(loadContentsToCacheImpl({ ids: [content.id], appType }));
    if (loadContentsToCacheImpl.fulfilled.match(refreshResult)) {
      return refreshResult.payload[applicationType]?.[content.id];
    }
  }
  return undefined;
});

export const batchSetInsideTagAction = createAsyncThunk<void, { contentIds: number[]; tagId: number }, ThunkApiType>(
  `${name}/batchSetInsideTagAction`,
  async ({ contentIds, tagId }, thunkApi) => {
    const state = thunkApi.getState();
    const appType = getCurrentUserAppType(state);
    const applicationType = appTypeToApplicationContentType[appType];
    const contents = contentIds
      .map((x) => state.contentCache[applicationType][x])
      .filter((x) => {
        if (!x || x.insideTags?.find((t) => t.id === tagId)) {
          return false;
        }
        return true;
      });
    if (!contents.length) {
      return;
    }
    await ContentCommonApi.batchSetInsideTags(
      {
        contentsTags: contents.map((x) => ({
          contentId: x.id,
          tagIds: [tagId, ...(x.insideTags || []).map((x) => x.id)],
        })),
      },
      applicationType
    );
    thunkApi.dispatch(loadContentsToCache({ ids: contents.map((x) => x.id), checkExisting: false }));
  }
);

export const loadContentThemesAction = createAsyncThunk(`${name}/loadContentThemesAction`, async () => {
  const themes = await ContentThemesApi.list();
  return themes;
});

export const loadHashtagsByIdsAction = createAsyncThunk(
  `${name}/loadHashtagsByIdsAction`,
  async (hashtagIds: number[]) => {
    const tags = await TagsApi.batchByIds(uniq(hashtagIds));
    return tags;
  }
);

export const loadCustomFeedsAction = createAsyncThunk(`${name}/loadCustomFeedsAction`, async () => {
  const configs = await FeedConfigurationsApi.list();
  return configs;
});

export const markContentAction = createAsyncThunk<void, { contentId: number; marks: string[] }, ThunkApiType>(
  `${name}/markContentAction`,
  async ({ contentId, marks }) => {
    await ContentCommonApi.markContent(contentId, marks);
  }
);

export const unmarkContentAction = createAsyncThunk<void, { contentId: number; marks: string[] }, ThunkApiType>(
  `${name}/unmarkContentAction`,
  async ({ contentId, marks }) => {
    await ContentCommonApi.unmarkContent(contentId, marks);
  }
);

export const getContentFromCacheAction = createAsyncThunk<
  ContentPunchAdminDto | ContentShortChipzAdminDto | undefined,
  number,
  ThunkApiType
>(`${name}/getContentFromCacheAction`, async (contentId, thunkApi) => {
  return getContentById(contentId)(thunkApi.getState());
});

const contentCache = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadContentsToCacheImpl.fulfilled, (state, action) => {
      Object.values(ApplicationType).forEach((type) => {
        if (action.payload[type]) {
          Object.assign(state[type], action.payload[type]);
        }
      });
    });
    builder.addCase(loadContentThemesAction.fulfilled, (state, action) => {
      state.themes = action.payload;
    });
    builder.addCase(loadHashtagsByIdsAction.fulfilled, (state, action) => {
      Object.assign(
        state.hashtagsMap,
        action.payload.reduce(
          (a, x) => {
            a[x.id!] = x;
            return a;
          },
          {} as Record<number, TagDto>
        )
      );
    });
    builder.addCase(loadCustomFeedsAction.fulfilled, (state, action) => {
      state.customFeeds = action.payload;
    });
    builder.addCase(markContentAction.fulfilled, (state, action) => {
      const { contentId, marks } = action.meta.arg;
      if (state[ApplicationType.VIDEO][contentId]) {
        state[ApplicationType.VIDEO][contentId].marks = [
          ...marks,
          ...(state[ApplicationType.VIDEO][contentId].marks || []),
        ];
      }
    });
    builder.addCase(unmarkContentAction.fulfilled, (state, action) => {
      const { contentId, marks } = action.meta.arg;
      if (state[ApplicationType.VIDEO][contentId]) {
        state[ApplicationType.VIDEO][contentId].marks = (state[ApplicationType.VIDEO][contentId].marks || []).filter(
          (x) => !marks.includes(x)
        );
      }
    });
  },
});

export default contentCache.reducer;

export const getContentById = (id: number | undefined | null) => (state: RootState) => {
  if (typeof id !== "number") {
    return undefined;
  }
  const appType = state.user.currentUser?.appType;
  if (!appType) {
    return undefined;
  }
  const applicationType = appTypeToApplicationContentType[appType];
  return state.contentCache[applicationType][id];
};

export const getAudioContentById = (id: number | undefined | null) => (state: RootState) => {
  if (typeof id !== "number") {
    return undefined;
  }
  return state.contentCache[ApplicationType.AUDIO][id];
};

export const getVideoContentById = (id: number | undefined | null) => (state: RootState) => {
  if (typeof id !== "number") {
    return undefined;
  }
  return state.contentCache[ApplicationType.VIDEO][id];
};

export const getThemesMapSelector = createSelector(
  (state: RootState) => state.contentCache.themes,
  (themes) =>
    themes.reduce(
      (a, x) => {
        a[x.id] = x;
        return a;
      },
      {} as Record<number, ThemeDto>
    )
);

export const getCustomFeedsByFeedIdMapSelector = createSelector(
  (state: RootState) => state.contentCache.customFeeds,
  (feeds) =>
    feeds.reduce(
      (a, x) => {
        a[x.feedId] = x;
        return a;
      },
      {} as Record<string, FeedConfiguration>
    )
);
